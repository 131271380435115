import axios from "axios";
import dotenv from "dotenv";
import { urlChecker } from "../utils/urlChecker";
dotenv.config();

window.getCookie = function(name) {
    var match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
    if (match) return match[2];
};

export const getApiRequest = async(url, access_token = "", params = "") => {
   
   const BASEURL = urlChecker();
   
    access_token =
        access_token == "" ? window.getCookie("access_token") : access_token;
    const headers = {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + access_token,
    };
    const config = {
        headers: headers,
        params: params,
    };
    var start = Date.now();
    const response = await axios
        .get(BASEURL + url, config)
        .then(function(res) {
            let millis = Date.now() - start;
            let milliseconds = millis / 1000;
            res.response_time = milliseconds;
            return res;
        })
        .catch((error) => {
            console.log("error", error);
        });
    return response;
};

export const postApiRequest = async(url, access_token = "", data) => {

    const BASEURL = urlChecker();
    // const BASEURL = (window?.location?.hostname == 'imsva.genexsvc.com') ? process?.env?.REACT_APP_PUBLIC_ENDPOINT_HTTP :  process?.env?.REACT_APP_BASE_URL;
   
    access_token =
        access_token == "" ? window.getCookie("access_token") : access_token;
    const headers = {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + access_token,
    };
    const config = {
        headers: headers,
    };
    const response = await axios
        .post(BASEURL + url, data, config)
        .catch((error) => {
            console.log("error", error);
        });
    return response;
};