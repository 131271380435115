import moment from "moment";
import React, { useEffect, useState } from "react";
import { ListGroup } from "react-bootstrap";
import { BsClockHistory } from "react-icons/bs";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { getApiRequest } from "../../api/CommonApi";
import "./Search.css";

export default function GlobalSearch() {
  const { title } = useParams();
  const [videos, setVideos] = useState("");
  const [faqs, setFaqs] = useState("");
  useEffect(async () => {
    const response = await getApiRequest("/api/search", "", { title: title });
    setVideos(response.data.data.videos);
    setFaqs(response.data.data.faqs);
  }, []);

  console.log("faqs", faqs);
  console.log("videos", videos);

  return (
    <div className="search-page">
      <h2>Search result for "{title}"</h2>
      {faqs.length == 0
        ? "No Result For FAQs"
        : faqs.map((faq, index) => {
            let lnk = faq.type === 'matrix' ? `/table-matrix-base/${faq.id}` : `/details-faqs/${faq.id}`
            return (
              <div className="card">
                <h3>Faq {index + 1}</h3>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    
                    <Link to={lnk}>
                      <div className="resultContent">
                        <Link
                          className="resultTitle"
                          style={{ fontSize: 20, color: "#000" }}
                          to={lnk}
                        >
                          Title: {faq.title}
                        </Link>
                        <br />
                        <br />
                        <span
                          className="resultContents"
                          style={{ fontSize: 14, color: "#000" }}
                        >
                          {faq && faq.summary && faq.summary == "undefined"
                            ? ""
                            : faq?.summary}
                        </span>
                        <br />
                        <span style={{ color: "#000" }}>
                          <BsClockHistory /> Posted at{" "}
                          {moment(faq.created_at).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )}{" "}
                          | Last updated{" "}
                          {moment(faq.updated_at)
                            .subtract(6, "days")
                            .calendar()}
                        </span>
                        <br />
                        <br />
                      </div>
                    </Link>
                  </ListGroup.Item>
                </ListGroup>
              </div>
            );
          })}
      <br />
      {videos.length == 0
        ? "No Result For Videos"
        : videos.map((video, index) => {
            return (
              <div className="card">
                <h3>Video {index + 1}</h3>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <Link to={`/video-details/${video.id}`}>
                      <div className="resultContent">
                        <Link
                          className="resultTitle"
                          style={{ fontSize: 20, color: "#000" }}
                          to={`/video-details/${video.id}`}
                        >
                          Title: {video.title}
                        </Link>
                        <br />
                        <span
                          className="resultContents"
                          style={{ fontSize: 14, color: "#000" }}
                        >
                          Detail: {video.detail}
                        </span>
                        <br />
                        <span style={{ color: "#000" }}>
                          <BsClockHistory /> Posted at{" "}
                          {moment(video.created_at).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )}{" "}
                          | Last updated{" "}
                          {moment(video.updated_at)
                            .subtract(6, "days")
                            .calendar()}
                        </span>
                        <br />
                      </div>
                    </Link>
                  </ListGroup.Item>
                </ListGroup>
              </div>
            );
          })}
    </div>
  );
}
