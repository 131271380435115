import { createInstance, MatomoProvider } from '@datapunt/matomo-tracker-react';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
import store from './services/Store';

var allCookies = decodeURIComponent(document.cookie).split(";");
var matchCookies = document.cookie.match("\\b" + "user" + "=([^;]*)\\b");
if(matchCookies){
  var userCookies = decodeURIComponent(matchCookies[1]);
  var user = JSON.parse(userCookies);
  console.log("user", user);
}

// const urlBase = 'https://172.22.9.65:8443/'
// const instance = createInstance({
//   urlBase: 'https://172.22.9.65:8443/',
//   siteId: 1,
//   userId: user?.username, // optional, default value: `undefined`.
//   trackerUrl: `https://172.22.9.65:8443/matomo.php`, // optional, default value: `${urlBase}matomo.php`
//   srcUrl: `https://172.22.9.65:8443/matomo.js`, // optional, default value: `${urlBase}matomo.js`
//   disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
//   heartBeat: { // optional, enabled by default
//     active: true, // optional, default value: true
//     seconds: 10 // optional, default value: `15
//   },
//   linkTracking: true, // optional, default value: true
//   configurations: { // optional, default value: {}
//     // any valid matomo configuration, all below are optional
//    // disableCookies: true,
//     setSecureCookie: true,
//     setRequestMethod: 'POST'
//   }
// })

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <Provider store={store}>
      {/* <MatomoProvider value={instance}> */}
      <App />
      {/* </MatomoProvider> */}
      </Provider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

