import { Button, Form, Modal, Progress, Rate, Select, Tooltip } from 'antd';
import dotenv from "dotenv";
import moment from 'moment';
import { useState } from 'react';
import { Col, Row } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import { AiOutlinePlayCircle } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { postApiRequest } from '../../../api/CommonApi';
import { Certificate } from '../../../pages/certificate/Certificate';
import { urlChecker } from '../../../utils/urlChecker';
import './MyCoursesCard.css';
dotenv.config();


export default function MyCoursesCard(props) {
    const [cookies] = useCookies(['user']); 
  // const [expired, setExpired] = useState(false);
    const [showCertification, setShowCertification] = useState(false);
    const [certificateCourseId, setCertificateCourseId] = useState();
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [myRating, setMyRating] = useState(5);
    const [myIntructorRating, setMyIntructorRating] = useState(5);
    const [courseFeedback, setCourseFeedback] = useState([]);
    const [feedbackMessage, setFeedbackMessage] = useState();
    const [courseId, setCourseId] = useState();
    const [userFeedback, setUserFeedback] = useState({
        training_objective: "",
        training_interaction: "",
        training_topic: "",
        training_experience: "",
        trainer: "",
        training_environment: "",
    });
    console.log('my single course', props.course)
    const { id, title, cover_image, short_desc, rating, course_instructor_name, created_at, updated_at, course_quiz_completed_status, enroll_user_info: {completion_ratio, expired_at } } = props.course;

    const BASEURL = urlChecker();

    const userInfo = JSON.parse(localStorage.getItem("user_information"));

    function onInputChange(value, field) {
        setUserFeedback({ ...userFeedback, [field]: value });
    }
     
     const viewCertification = async (id) => {
        setShowCertification(true);
        setCourseId(id);
     }
     var expired = false;
     if(expired_at){
         var expiredDate = moment(expired_at)
         var now = moment();
         if (now > expiredDate) {
             var expired = true;
         }
     }

     const storeAgentFeedback = async () => {
        const data = { 
            course_id: courseId,
            user_id: userInfo?.id,
            training_objective: userFeedback.training_objective,
            training_interaction: userFeedback.training_interaction,
            training_topic: userFeedback.training_topic,
            training_experience: userFeedback.training_experience,
            trainer: userFeedback.trainer,
            training_environment: userFeedback.training_environment
        };
        const response = await postApiRequest('/api/user-feedback/create', '', data);
        console.log('my all feedback response', response)
        toast.success(response?.data?.message);       
    };

     function showModal(id){
        setVisible(true);
        setCourseId(id)
    }


    function handleOk() {
        // if (!myRating) {
        //     return toast.error("Course Rating Field must not be empty")
        // }
        // if (!myIntructorRating) {
        //     return toast.error("Instructor Rating Field must not be empty")
        // }
        // if (!feedbackMessage) {
        //     return toast.error("Message Field must not be empty")
        // }
        setVisible(false);
        setLoading(false);
        storeAgentFeedback();
    };
    
    function handleCancel() {
        setVisible(false);
    };

    function handleRating(value){
        setMyRating(value);
    }

    function handleInstructorRating(value){
        setMyIntructorRating(value);

    }

    function onFeedbackMessage(e){
        setFeedbackMessage(e.target.value)
    }

     console.log('view userFeedback', userFeedback)

    
     return (
        <div className="myCoursesCard">
            <div className="card">
                <div className="myCoursesCardThumbnails">
                    { cover_image &&
                        <img src={BASEURL +'/'+ cover_image} alt={title} />
                    }
                    <Link to={`/my-course-details/${id}`}>
                        <AiOutlinePlayCircle className="myCoursesCardPlaybtn" />
                    </Link>
                </div>
                <div className="myCoursesCardInfo">
                    <Link to={`/my-course-details/${id}`}>
                    <span className="myCoursesCardTitle">{title}</span>
                    </Link>
                    {course_instructor_name && 
                    <span className="myCoursesCardInstructor">Instructor Name : {course_instructor_name ?? ''}</span>
                    }
                    <span className="myCoursesCardDesc">{short_desc}</span>
                </div>

                <Progress percent={completion_ratio} showInfo={false} />
                <div className="myCoursesCardRatingPStatus">
                    <div>
                        <span className="myCoursesCardProgrssStatus">{completion_ratio} % Completed</span>
                    </div>
                    <div className="myCoursesCardRatingAndTitle">
                        <span className="myCoursesCardRating">
                        <Rate allowHalf disabled defaultValue={rating} />
                        </span>

                    </div>
                </div>
                { !props.course.user_course_feedback && completion_ratio == 100 && 
                        <div className="myCoursesCardRatingTitle" onClick={() => showModal(id)}>Leave a rating</div>
                }
                {expired && 
                    <button className="btn btn-danger btn-dwn w-100">Expired</button>
                }
                { !expired && completion_ratio == 100 &&
                <>
                    {!course_quiz_completed_status ?
                        <Tooltip title="Please complete quiz to download Certificate" color={'blue'}>
                        <button className="btn btn-success btn-dwn disabled w-100">
                        Download Certificate
                        </button>
                        </Tooltip>
                     : 
                        <button className="btn btn-success btn-dwn w-100" onClick={() => viewCertification(id)}>
                        Download Certificate
                        </button>
                    
                    }
                </>
                }
            </div>

            <Modal
            title="Certification"
            centered
            width={1400}
            visible={showCertification}
            onOk={() => setShowCertification(false)}
            onCancel={() => setShowCertification(false)}
            >
            <Certificate course={props.course}/>
            </Modal>

            <Modal
            visible={visible}
            title="User Feedback"
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                Return
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                Submit
                </Button>,
               
            ]}
            >
            {/* <div>Rate Course : <Rate allowHalf defaultValue={5} onChange={handleRating} value={myRating}/></div>
            <div>Rate Instructor : <Rate allowHalf defaultValue={5} onChange={handleInstructorRating} value={myIntructorRating}/></div> */}
            <div>
            <Form layout="vertical" className="mt-3">
            <Row className="mb-3">
            <Col md={{ span: 7 }}>
            The training objectives were met
            </Col>
            <Col md={{ span: 5 }}>
                <Select
                  showSearch
                  onChange={(value) => onInputChange(value, "training_objective")}
                  placeholder="Select"
                  style={{ width: '100%' }}
                  filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }  
                >
                <Select.Option value="5" key="5">{"Best"}</Select.Option>
                <Select.Option value="4" key="4">{"Better"}</Select.Option>
                <Select.Option value="3" key="3">{"Good"}</Select.Option>
                <Select.Option value="2" key="2">{"Avarage"}</Select.Option>
                <Select.Option value="1" key="1">{"Poor"}</Select.Option>
                </Select>
            </Col>
            </Row>
            <Row className="mb-3">
            <Col md={{ span: 7 }}>
            Participation & interaction were encouraged
            </Col>
            <Col md={{ span: 5 }}>
                <Select
                  showSearch
                  onChange={(value) => onInputChange(value, "training_interaction")}
                  placeholder="Select"
                  style={{ width: '100%' }}
                  filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }  
                >
                <Select.Option value="5" key="5">{"Best"}</Select.Option>
                <Select.Option value="4" key="4">{"Better"}</Select.Option>
                <Select.Option value="3" key="3">{"Good"}</Select.Option>
                <Select.Option value="2" key="2">{"Avarage"}</Select.Option>
                <Select.Option value="1" key="1">{"Poor"}</Select.Option>
                </Select>
            </Col>
            </Row>
            <Row className="mb-3">
            <Col md={{ span: 7 }}>
            The topics covered were relevant to me
            </Col>
            <Col md={{ span: 5 }}>
                <Select
                  showSearch
                  onChange={(value) => onInputChange(value, "training_topic")}
                  placeholder="Select"
                  style={{ width: '100%' }}
                  filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }  
                >
                <Select.Option value="5" key="5">{"Best"}</Select.Option>
                <Select.Option value="4" key="4">{"Better"}</Select.Option>
                <Select.Option value="3" key="3">{"Good"}</Select.Option>
                <Select.Option value="2" key="2">{"Avarage"}</Select.Option>
                <Select.Option value="1" key="1">{"Poor"}</Select.Option>
                </Select>
            </Col>
            </Row>
            <Row className="mb-3">
            <Col md={{ span: 7 }}>
            The training experience will be useful in my work
            </Col>
            <Col md={{ span: 5 }}>
                <Select
                  showSearch
                  onChange={(value) => onInputChange(value, "training_experience")}
                  placeholder="Select"
                  style={{ width: '100%' }}
                  filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }  
                >
                <Select.Option value="5" key="5">{"Best"}</Select.Option>
                <Select.Option value="4" key="4">{"Better"}</Select.Option>
                <Select.Option value="3" key="3">{"Good"}</Select.Option>
                <Select.Option value="2" key="2">{"Avarage"}</Select.Option>
                <Select.Option value="1" key="1">{"Poor"}</Select.Option>
                </Select>
            </Col>
            </Row>
            <Row className="mb-3">
            <Col md={{ span: 7 }}>
            The trainer was well prepared
            </Col>
            <Col md={{ span: 5 }}>
                <Select
                  showSearch
                  onChange={(value) => onInputChange(value, "trainer")}
                  placeholder="Select"
                  style={{ width: '100%' }}
                  filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }  
                >
                <Select.Option value="5" key="5">{"Best"}</Select.Option>
                <Select.Option value="4" key="4">{"Better"}</Select.Option>
                <Select.Option value="3" key="3">{"Good"}</Select.Option>
                <Select.Option value="2" key="2">{"Avarage"}</Select.Option>
                <Select.Option value="1" key="1">{"Poor"}</Select.Option>
                </Select>
            </Col>
            </Row>
            <Row className="mb-3">
            <Col md={{ span: 7 }}>
            The training was conducted with proper facilities (Training Environment)
            </Col>
            <Col md={{ span: 5 }}>
                <Select
                  showSearch
                  onChange={(value) => onInputChange(value, "training_environment")}
                  placeholder="Select"
                  style={{ width: '100%' }}
                  filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  } 
                >
                <Select.Option value="5" key="5">{"Best"}</Select.Option>
                <Select.Option value="4" key="4">{"Better"}</Select.Option>
                <Select.Option value="3" key="3">{"Good"}</Select.Option>
                <Select.Option value="2" key="2">{"Avarage"}</Select.Option>
                <Select.Option value="1" key="1">{"Poor"}</Select.Option>
                </Select>
            </Col>
            </Row>
            </Form>
            </div>
           
            {/* <h5>Message</h5>
            <TextArea rows={3} onChange={onFeedbackMessage} value={feedbackMessage}/> */}
            </Modal>
        </div>
  );
}
