import { ActionTypes } from "../constants/ActionTypes";

const initialState = {
    courses: [],
    tags: [],
}

export const courseReducer = ( state = initialState, {type, payload}) => {
    switch(type){
        case ActionTypes.SET_COURSES:
            return {...state, courses:payload};
        default:
            return state;
    }
}

export const selectedCourseReducer = (state = {}, {type, payload}) => {
    switch (type){
        case ActionTypes.SELECTED_COURSE:
            return {...state, ...payload};
        case ActionTypes.REMOVE_SELECTED_COURSE:
            return {};
        default:
            return state;
    }
}

export const featureCourseReducer = ( state = initialState, {type, payload}) => {
    switch(type){
        case ActionTypes.SET_FEATURE_COURSES:
            return {...state, features_courses:payload};
        default:
            return state;
    }
}

export const courseLessonReducer = ( state = initialState, {type, payload}) => {
    switch(type){
        case ActionTypes.SET_COURSE_LESSON:
            return {...state, course_lesson:payload};
        default:
            return state;
    }
}

// export const courseCategoryReducer = ( state = initialState, {type, payload}) => {
//     switch(type){
//         case ActionTypes.SET_COURSE_CATEGORIES:
//             return {...state, courses:payload};
//         default:
//             return state;
//     }
// }

// export const courseTagReducer = ( state = initialState, {type, payload}) => {
//     switch(type){
//         case ActionTypes.SET_COURSE_TAGS:
//             return {...state, tags:payload};
//         default:
//             return state;
//     }
// }

export const selectedCourseTagReducer = ( state = initialState.tags, {type, payload}) => {
    switch(type){
        case ActionTypes.SELECTED_COURSE_TAGS:
            return {...state, selectedTags:payload};
        default:
            return state;
    }
}
