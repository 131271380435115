import { Button, Col, Divider, Row, Select, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { setConfig, useLazyTranslate } from "react-google-translate";
import { useParams } from "react-router-dom";
import { urlChecker } from "../../utils/urlChecker";
import Preloader from "../common/Preloader";
import "./Faq.css";

export default function FaqDetailsView(props) {
  const { faqId } = useParams();
  const faq = props.faq;
  const category = props.category;
  const tagsRender = props.tagsRender;
  const detail = props?.faq?.detail;
  let [language, setLanguage] = useState("");
  const [searchedText, setSearchedText] = useState("");
  const [translate, { called, data, loading }] = useLazyTranslate({
    language,
  });

  const BASEURL = urlChecker();

  const handleClick = (e) => {
    setSearchedText(data);
  };
  const handleChange = (e) => {
    setLanguage(e);
    setConfig({
      autoTranslate: true,
      key: "AIzaSyCwNzJjT0Q2M2gXZ9nhZ-6lzv8KL-m-zrQ",
      target: e,
    });
  };
  setConfig({
    clientEmail: "test-971@test-translate-315810.iam.gserviceaccount.com",
    privateKey:
      "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQC7PfcPR2WxCXmx\n3ITwhV+aLnNVJo7++w6c8krFWHxYTB5saK6o9QBN5YlJjPRgSHbKFjTCkAS4xJnI\nlv7EHlavRVqqv+0EP1Z+sHt057DkGpP1DPxf1mK/H1aK3mQekaJwkW07/p5hswAI\nj1sPq6xYkErjUwXz+wezmDbaiGWzP3YAp1CNRQ36kyD2MWBqtYyvw+7rL7epeytc\ndI6eEexMaVEzUJXNGmq4QN18YD1nT3RZ6+yXHURehxcJFC0K6dV10KMELL2+6dt8\nGAgkbjinpcpzX9VO/cqDHoMFinFPTS4EGvP/Vxcd/xSwg/pbz1lgZs4Lx2vJls5E\nVUweteVdAgMBAAECggEAASRUYpy4G5CTtk3LAFxQq6Dlue8Q8xaTYMF9dYwEFfxe\nlsvVG2Qc2oMp3u2T45/VgtPVNXB86UixhzP+zoc4GuKSgv/XFKezXYYhT69TLdTz\ndp2kV4Oecsik3B8eDlyrgwZFF2x1jZU25mvMMeO4lmgEVsUB5s2zGpMS7AihwGpf\n1EhVny/yklRBL49fdCO+9h8a+s0BYHKUFWNTuOO8SYzXpJ8KxVb/w0P/W4cj8SEE\nK3vH58V+E20bX9mJVRsU/7/stHrZ5S65Jvzlxyc41Q0pcUliele90vuOE01Z13YP\nRhckre38FZvTAgAakwwJZb5tokbKEVY7cVhj0/4R8QKBgQDyii8ZRuD7SJANysIp\nWG4YKafqUai98PDZxfhcRIKMdqHRrgROsrP398Gan6row2uDuxKgA7nxBfZ+4NRQ\nZXCTVu39H8+iFVZxxSpdo+0wfvF4FoXexx/u/1cmFRxbDmxpyj9GAIuN70nsDFUt\nvrYtLn19GGaCPgjAWl8wDn+b8QKBgQDFoidx9qgfB4Y1i3kR9OmAtt+QYuxDwvdw\n8NfeOCGd7kU2cNfhnZ3ccpKnWiRssIOIn6Yj8dp+XYotoD3S3L162Wg+fW4mmqwV\nCwbshDKZtby0oMqy7dKreNFvacoL3pSm4UwN+2Fyv2HJQ8S0aaMcAKlKWbS/Z4QI\nZgyhPGs8LQKBgQC3R1zYCQuyV98jzPh0LfLkJ3ZQR2zwO8aFimhnsQ3F/0/BF3MB\n1WDSHVMb+VQ5mJMCdLdaW/aDADKJJnTKXB15+dLV2Ngab21ng8rEAEAYyZTHXp3C\n/05OWH4OtR08YeXi+4Zc68SJeS2vRpKHgvo/okYJ5gpwmVgboy8TLaGYwQKBgCIM\nlNUqlL570mCJPfudAVm+p/e9Q01NWsonhFlxvED0WxJNELR7LkZbqu6QNjRPmOnB\n76LDuUwKG5NOxzf79d0jdbVGcOLPxRP/CVtYT0tyJyfLiACZvrDyKMsUI841s9Co\ngizqgVh+pn9BYLNge8yIRKJdoClV0UaNJmHlAeilAoGBAIKHldvogvG2CmS1Geq/\nVSs3MgivAWMGb1yGAHTlcnhknQFrMnOZnMdnHpVVcBYzDdamnYtE0haQ1iLNzrIN\nAMw6ebxX5HRpkVa0w1D0xXk9NZMoXT05HqeaWax9Arvb/I1J4MSxyXL6hWN+GQgE\nUT0EnOyTIdDR4gdTsksysIkJ\n-----END PRIVATE KEY-----\n",
    projectId: "test-translate-315810",
  });

  useEffect(() => {
    translate(detail, language);
  }, [translate, language, searchedText, detail]);
  return !faq ? (
    <Preloader />
  ) : (
    <div className="faqDetailsView">
      <div className="card">
        <Row>
          <Col>
            {faq && faq.feature_image ? (
              <div className="resultContentImage">
                <img
                  className="featureImage"
                  src={BASEURL + "/" + faq.feature_image}
                  alt=""
                />
              </div>
            ) : (
              ""
            )}
          </Col>
          <Col>
            <div className="resultContentBody">
              {/* ladsfk;kfds; */}
              
              <h2 className="faqViewTitle">
                {faq.title ? faq.title : ""}
                {
                  (faq.status == 'archived' || moment(faq?.expiry_date).diff(moment.now(), 'days') < 0) ? <Badge className="archBadge">archived</Badge> : ''
                }
              </h2>
              <div className="faqViewTagCats">
                <div className="categories">
                  <span className="faqViewTagCatsTitle">categories:</span>
                  <Tag>{category?.name}</Tag>
                </div>
                <div className="tags">
                  <span className="faqViewTagCatsTitle">tags:</span>
                  {tagsRender}
                </div>
              </div>
              <span className="faqViewPostTime">
                Posted at{" "}
                {faq.created_at
                  ? moment(faq.created_at).format("MMMM Do YYYY, h:mm:ss a")
                  : ""}{" "}
                | Last updated{" "}
                {faq.updated_at
                  ? moment(faq.updated_at).subtract(6, "days").calendar()
                  : ""}{" "}
              </span>
              <p
                className="faqViewDescription"
                dangerouslySetInnerHTML={{
                  __html: faq?.detail?.replace(
                    /(<? *script)/gi,
                    "illegalscript"
                  ),
                }}
              ></p>
              {searchedText ? <Divider /> : ""}
              <p
                className="faqViewDescription"
                dangerouslySetInnerHTML={{
                  __html: searchedText?.replace(
                    /(<? *script)/gi,
                    "illegalscript"
                  ),
                }}
              ></p>
              
              <div className="faqButtonGrp">
                <Select
                  placeholder="Select"
                  style={{ width: 120 }}
                  onChange={handleChange}
                >
                  {/* <Option value=""></Option> */}
                  <Option value="en">English</Option>
                  {/* <Option value="es">Spanish</Option>
                  <Option value="fr">French</Option>
                  <Option value="de">German</Option>
                  <Option value="it">Italian</Option>
                  <Option value="pt">Portuguese</Option>
                  <Option value="ru">Russian</Option>
                  <Option value="zh">Chinese</Option>
                  <Option value="ja">Japanese</Option>
                  <Option value="ko">Korean</Option>
                  <Option value="ar">Arabic</Option>
                  <Option value="hi">Hindi</Option> */}
                  <Option value="bn">Bengali</Option>
                </Select>
                <Button
                  className="faqviewTranslatebutton"
                  type="button"
                  onClick={handleClick}
                >
                  Translate
                </Button>
                {faq.faq_file ? (
                  <a
                    className="btn file_button"
                    type="button"
                    href={BASEURL + "/" + faq.faq_file}
                    target="_blank"
                  >
                    Download Attachment
                  </a>
                ) : (
                  ""
                )}
              </div>
              <Divider />
              <p>
                **Accuracy level of translator is still below standard level.
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
