import { Button, Form, Modal, Select, Tabs } from "antd";
import dotenv from "dotenv";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { AiOutlineStar } from "react-icons/ai";
import { MdRateReview } from "react-icons/md";
import { VscFileSubmodule } from "react-icons/vsc";
import { useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getApiRequest, postApiRequest } from "../../../api/CommonApi";
import { setChangeAbleValue } from "../../../services/actions/common/ChangeAbleValue";
import Preloader from "../../common/Preloader";
import "./AllCoursCard.css";

dotenv.config();
const { TabPane } = Tabs;

export default function TrainerCourseCard(props) {
  const dispatch = useDispatch();
  const [cookies] = useCookies(["user"]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [courseId, setCourseId] = useState();
  const [paginateInfo, setPaginateInfo] = useState(null);
  const [trainingEvaluation, setTrainingEvaluation] = useState(null);
  const [trainingType, setTrainingType] = useState('');
  const [newEvaluation, setNewEvaluation] = useState(false);
  const [userFeedback, setUserFeedback] = useState({
    training_objective: 0,
    training_interaction: 0,
    training_topic: 0,
    training_experience: 0,
    trainer: 0,
    training_environment: 0,
  });

  const userInfo = JSON.parse(localStorage.getItem("user_information"));

  useEffect(() => {
    loadTrainingEvaluation();
  }, [newEvaluation]);

  function onInputChange(value, field) {
    setUserFeedback({ ...userFeedback, [field]: value });
  }

  function showModal(id, trainingType = null) {
    console.log(id);
    setVisible(true);
    setCourseId(id);
    setTrainingType(trainingType);
  }

  function handleOk() {
    setVisible(false);
    setLoading(false);
    storeAgentFeedback();
  }

  function handleCancel() {
    setVisible(false);
  }

  const loadTrainingEvaluation = async (filterDate = null, page = 1) => {
    console.log('on training page', userInfo)
    // const url = `/api/training/training-evaluation/${userInfo?.id}?page=${page}`;
    // const response = await getApiRequest(url);

    const response = await getApiRequest(`/api/training/training-evaluation/${userInfo?.id}?page=${page}`)
        console.log('training card response', response);

    if (response?.status == 200) {
      setTrainingEvaluation(response?.data?.data);
      setPaginateInfo(response?.data?.meta);
    }
  };

  const storeAgentFeedback = async () => {
    const data = {
      course_id: courseId,
      user_id: userInfo?.id,
      training_type: trainingType,
      training_objective: userFeedback?.training_objective,
      training_interaction: userFeedback?.training_interaction,
      training_topic: userFeedback?.training_topic,
      training_experience: userFeedback?.training_experience,
      trainer: userFeedback?.trainer,
      training_environment: userFeedback?.training_environment,
    };
    console.log('user feed store', data);
  
   const response = await postApiRequest('/api/user-feedback/create', '', data);
   console.log('my all feedback response', response)
    // const response = await postApiRequest(
    //   "/api/user-feedback/create",
    //   "",
    //   data
    // );
    // console.log("my all feedback response", response);
    toast.success(response?.data?.message);
    setNewEvaluation(true)
    dispatch(setChangeAbleValue(true));
  };

  console.log('trainingEvaluation',trainingEvaluation)

  const renderList =
  trainingEvaluation &&
    trainingEvaluation
      .filter((agentCourse) => agentCourse.user_course_feedback != null)
      .map((agentCourse) => {
        const {
          id,
          name,
          bio,
          avatar,
          total_course,
          total_enrolled,
          total_feedback,
          rating,
        } = agentCourse;
        return (
          <div className="elarnigAllCoursCard card" key={id}>
            <Row>
              <Col xs={6}>
                <div className="elarnigAllCoursCardContents">
                  <div className="elarnigAllCoursCardLeft">
                    <Link to={`/course-details/${agentCourse.id}`}>
                      <div className="elarnigAllTrainerCardThumbnails">
                        <img
                          src={
                            agentCourse?.cover_image
                              ? process.env.REACT_APP_BASE_URL +
                                agentCourse?.cover_image
                              : process.env.REACT_APP_BASE_URL +
                                "/images/default.jpg"
                          }
                          alt={agentCourse?.name}
                        />
                      </div>
                    </Link>
                  </div>
                  <div className="elarnigAllCoursCardRight">
                    <h3 className="elarnigAllCoursCardTitle">
                      {agentCourse?.title}
                    </h3>
                    <p className="elarnigAllCoursCardDesc">
                      {bio && bio !== "null" ? bio : ""}
                    </p>
                    <div className="info">
                      <ul className="list">
                        <li className="item">
                          <AiOutlineStar />{" "}
                          {agentCourse?.rating
                            ? agentCourse?.rating?.toFixed(1)
                            : 0}{" "}
                          Rating
                        </li>
                        <li className="item">
                          <MdRateReview />{" "}
                          {agentCourse?.course_feedbacks ?? 0} Reviews
                        </li>
                        <li className="item">
                          <VscFileSubmodule /> {agentCourse?.total_enroll_user ?? 0}{" "}
                          Enrolled
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={6}>
                <Row>
                  {agentCourse?.course_trainers?.map((trainer, i) => (
                    <Col xs={6}>
                      <div className="elarnigAllCoursCardLeft">
                        <div className="elarnigCoursCardThumbnails">
                          <img
                            src={
                              trainer?.avatar
                                ? process.env.REACT_APP_BASE_URL +
                                  trainer?.avatar
                                : process.env.REACT_APP_BASE_URL +
                                  "/images/user.jpg"
                            }
                            alt={trainer?.name}
                          />
                        </div>
                      </div>
                      <div className="elarnigAllCoursCardRight">
                        <h3 className="elarnigAllCoursCardTitle">
                          {trainer?.name}
                        </h3>
                        {trainer?.bio && trainer?.bio !== "null" &&
                        <p
                          className="elarnigAllCoursCardDesc"
                          dangerouslySetInnerHTML={{
                            __html: trainer?.bio,
                          }}
                        ></p>
                        }

                        <div className="info">
                          <ul className="list">
                            <li className="item">
                              <AiOutlineStar />{" "}
                              {agentCourse.user_trainer_rating ? agentCourse?.user_trainer_rating?.toFixed(1) : 0}{" "}
                              Rating
                            </li>
                            <li className="item">
                              <MdRateReview /> {trainer.total_feedback ?? 0}{" "}
                              Reviews
                            </li>
                            <li className="item">
                              <VscFileSubmodule />{" "}
                              {trainer.trainer_courses.length ?? 0} Courses
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </div>
        );
      });


      // const renderHistoryTraineeEvaluation =
      // trainingEvaluation &&
      // trainingEvaluation
      //     .filter((training) => training.user_feedback != null)
      //     .map((training) => {
      //       return (
      //         <div className="elarnigAllCoursCard card" key={training.id}>
      //           <Row>
      //             <Col xs={6}>
      //               <div className="elarnigAllCoursCardContents">
      //                 <div className="elarnigAllCoursCardLeft">
      //                     <div className="elarnigAllTrainerCardThumbnails">
      //                       <img
      //                         src={
      //                           training?.cover_image
      //                             ? process.env.REACT_APP_BASE_URL +
      //                             training?.cover_image
      //                             : process.env.REACT_APP_BASE_URL +
      //                               "/images/default.jpg"
      //                         }
      //                         alt={training?.title}
      //                       />
      //                     </div>
      //                 </div>
      //                 <div className="elarnigAllCoursCardRight">
      //                   <h3 className="elarnigAllCoursCardTitle">
      //                     {training?.title}
      //                   </h3>
      //                   <p className="elarnigAllCoursCardDesc">
      //                     {training?.instructor?.bio && training?.instructor?.bio !== "null" ? training?.instructor?.bio : ""}
      //                   </p>
      //                   <div className="info">
      //                     <ul className="list">
      //                       <li className="item">
      //                         <AiOutlineStar />{" "}
      //                         {training?.rating
      //                           ? training?.rating?.toFixed(1)
      //                           : 0}{" "}
      //                         Rating
      //                       </li>
      //                       <li className="item">
      //                         <MdRateReview />{" "}
      //                         {training?.total_training_feedback ?? 0} Reviews
      //                       </li>
      //                       <li className="item">
      //                         <VscFileSubmodule /> {training?.total_training_trainee ?? 0}{" "}
      //                         Enrolled
      //                       </li>
      //                     </ul>
      //                   </div>
      //                 </div>
      //               </div>
      //             </Col>
      //             <Col xs={6}>
      //             <Row>
      //             { training?.trainer &&
      //               <Col xs={6}>
      //                 <div className="elarnigAllCoursCardLeft">
      //                   <div className="elarnigCoursCardThumbnails">
      //                     <img
      //                       src={
      //                         training?.trainer?.avatar
      //                           ? process.env.REACT_APP_BASE_URL +
      //                           training?.trainer?.avatar
      //                           : process.env.REACT_APP_BASE_URL +
      //                             "/images/user.jpg"
      //                       }
      //                       alt={training?.trainer?.name}
      //                     />
      //                   </div>
      //                 </div>
      //                 <div className="elarnigAllCoursCardRight">
      //                   <h3 className="elarnigAllCoursCardTitle">
      //                     {training?.trainer?.name}
      //                   </h3>
      //                   <p className="elarnigAllCoursCardDesc">
      //                     {training?.trainer?.bio && training?.trainer?.bio !== "null"
      //                       ? training?.trainer?.bio
      //                       : ""}
      //                   </p>
      //                   <div className="info">
      //                     <ul className="list">
      //                       <li className="item">
      //                         <AiOutlineStar />{" "}
      //                         {training?.trainer.rating ? training?.trainer?.rating?.toFixed(1) : 0}{" "}
      //                         Rating
      //                       </li>
      //                       <li className="item">
      //                         <MdRateReview /> {training?.trainer.total_feedback ?? 0}{" "}
      //                         Reviews
      //                       </li>
      //                       <li className="item">
      //                         <VscFileSubmodule />{" "}
      //                         {training?.trainer.trainer_courses.length ?? 0} Courses
      //                       </li>
      //                     </ul>
      //                   </div>
      //                 </div>
      //               </Col>
      //              }
      //           </Row>
      //          </Col>
      //           </Row>
      //         </div>
      //       );
      //     });



      const renderPendingEvaluationList =
      trainingEvaluation &&
       trainingEvaluation
        .filter((agentCourse) => agentCourse.user_course_feedback == null)
        .map((agentCourse) => {
          //const renderPendingEvaluationList = props.agentCourses && props.agentCourses.map((agentCourse) => {
          const {
            id,
            name,
            bio,
            avatar,
            total_course,
            total_enrolled,
            total_feedback,
            rating,
          } = agentCourse;
          return (
            <div className="elarnigAllCoursCard card" key={id}>
              <Row>
                <Col xs={6}>
                  <div className="elarnigAllCoursCardContents">
                    <div className="elarnigAllCoursCardLeft">
                      <Link to={`/course-details/${agentCourse.id}`}>
                        <div className="elarnigAllTrainerCardThumbnails">
                          <img
                            src={
                              agentCourse?.cover_image
                                ? process.env.REACT_APP_BASE_URL +
                                  agentCourse?.cover_image
                                : process.env.REACT_APP_BASE_URL +
                                  "/images/default.jpg"
                            }
                            alt={agentCourse?.name}
                          />
                        </div>
                      </Link>
                    </div>
                    <div className="elarnigAllCoursCardRight">
                      <h3 className="elarnigAllCoursCardTitle">
                        {agentCourse?.title}
                      </h3>
                      <p className="elarnigAllCoursCardDesc">
                        {bio && bio !== "null" ? bio : ""}
                      </p>
                      <div className="info">
                        <ul className="list">
                          <li className="item">
                            <AiOutlineStar />{" "}
                            {agentCourse?.rating
                              ? agentCourse?.rating?.toFixed(1)
                              : 0}{" "}
                            Rating
                          </li>
                          <li className="item">
                            <MdRateReview />{" "}
                            {agentCourse?.user_feetback?.length ?? 0} Reviews
                          </li>
                          <li className="item">
                            <VscFileSubmodule /> {agentCourse?.course_enroll ?? 0}{" "}
                            Enrolled
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={6}>
                {/* {agentCourse?.enroll_user_info?.completion_ratio == 100 ? 'disabled' : ''} */}
                  <Row>
                    <Button onClick={() => showModal(agentCourse.id)}>
                        Start Evaluation
                      </Button>
                  </Row>
                  <Row><div>Please complete course for your Evaluation</div></Row>
                </Col>
              </Row>
            </div>
          );
        });

        // const renderPendingTraineeEvaluation =
        // trainingEvaluation &&
        // trainingEvaluation
        //     .filter((training) => training.user_feedback == null)
        //     .map((training) => {
        //       return (
        //         <div className="elarnigAllCoursCard card" key={training.id}>
        //           <Row>
        //             <Col xs={6}>
        //               <div className="elarnigAllCoursCardContents">
        //                 <div className="elarnigAllCoursCardLeft">
        //                     <div className="elarnigAllTrainerCardThumbnails">
        //                       <img
        //                         src={
        //                           training?.cover_image
        //                             ? process.env.REACT_APP_BASE_URL +
        //                             training?.cover_image
        //                             : process.env.REACT_APP_BASE_URL +
        //                               "/images/default.jpg"
        //                         }
        //                         alt={training?.title}
        //                       />
        //                     </div>
        //                 </div>
        //                 <div className="elarnigAllCoursCardRight">
        //                   <h3 className="elarnigAllCoursCardTitle">
        //                     {training?.title}
        //                   </h3>
        //                   {/* <p className="elarnigAllCoursCardDesc">
        //                     {training?.instructor?.bio && training?.instructor?.bio !== "null" ? training?.instructor?.bio : ""}
        //                   </p> */}
        //                   <div className="info">
        //                     <ul className="list">
        //                       <li className="item">
        //                         <AiOutlineStar />{" "}
        //                         {training?.rating
        //                           ? training?.rating?.toFixed(1)
        //                           : 0}{" "}
        //                         Rating
        //                       </li>
        //                       <li className="item">
        //                         <MdRateReview />{" "}
        //                         {training?.user_feetback?.length ?? 0} Reviews
        //                       </li>
        //                       <li className="item">
        //                         <VscFileSubmodule /> {training?.course_enroll ?? 0}{" "}
        //                         Enrolled
        //                       </li>
        //                     </ul>
        //                   </div>
        //                 </div>
        //               </div>
        //             </Col>
        //             <Col xs={6}>
        //               <Row>
        //                   <Button onClick={() => showModal(training.id, 'physical')}>
        //                     Start Evaluation
        //                   </Button>
        //               </Row>
        //             </Col>
        //           </Row>
        //         </div>
        //       );
        //     });

  return !trainingEvaluation ? (
    <Preloader />
  ) : (
    <div className="searchContent">
      <Row>
        <Col xs={12}>
          <div className="elearnigMyCourseDetailsTabs">
            <Tabs defaultActiveKey="1">
              <TabPane tab="Evaluated History" key="1">
                {renderList}
                {/* {renderHistoryTraineeEvaluation} */}
              </TabPane>

              <TabPane tab="Pending Evaluation" key="2">
                {renderPendingEvaluationList}
                {/* {renderPendingTraineeEvaluation} */}
              </TabPane>
            </Tabs>
            {/* <Pagination
              total={paginateInfo?.total}
              showTotal={total => `Total ${total} items`}
              defaultPageSize={10}
              defaultCurrent={1}
              onChange={(p) => {
                loadTrainingEvaluation(null, p);
              }}
            /> */}
          </div>
        </Col>
      </Row>

      <Modal
        visible={visible}
        title="Your Feedback"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Submit
          </Button>,
        ]}
      >
        {/* <div>Rate Course : <Rate allowHalf defaultValue={5} onChange={handleRating} value={myRating}/></div>
            <div>Rate Instructor : <Rate allowHalf defaultValue={5} onChange={handleInstructorRating} value={myIntructorRating}/></div> */}
        <div>
          <Form layout="vertical" className="mt-3">
            <Row className="mb-3">
              <Col md={{ span: 7 }}>The training objectives were met</Col>
              <Col md={{ span: 5 }}>
                <Select
                  showSearch
                  onChange={(value) =>
                    onInputChange(value, "training_objective")
                  }
                  placeholder="Select"
                  style={{ width: "100%" }}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Select.Option value="5" key="5">
                    {"Best"}
                  </Select.Option>
                  <Select.Option value="4" key="4">
                    {"Better"}
                  </Select.Option>
                  <Select.Option value="3" key="3">
                    {"Good"}
                  </Select.Option>
                  <Select.Option value="2" key="2">
                    {"Avarage"}
                  </Select.Option>
                  <Select.Option value="1" key="1">
                    {"Poor"}
                  </Select.Option>
                </Select>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={{ span: 7 }}>
                Participation & interaction were encouraged
              </Col>
              <Col md={{ span: 5 }}>
                <Select
                  showSearch
                  onChange={(value) =>
                    onInputChange(value, "training_interaction")
                  }
                  placeholder="Select"
                  style={{ width: "100%" }}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Select.Option value="5" key="5">
                    {"Best"}
                  </Select.Option>
                  <Select.Option value="4" key="4">
                    {"Better"}
                  </Select.Option>
                  <Select.Option value="3" key="3">
                    {"Good"}
                  </Select.Option>
                  <Select.Option value="2" key="2">
                    {"Avarage"}
                  </Select.Option>
                  <Select.Option value="1" key="1">
                    {"Poor"}
                  </Select.Option>
                </Select>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={{ span: 7 }}>The topics covered were relevant to me</Col>
              <Col md={{ span: 5 }}>
                <Select
                  showSearch
                  onChange={(value) => onInputChange(value, "training_topic")}
                  placeholder="Select"
                  style={{ width: "100%" }}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Select.Option value="5" key="5">
                    {"Best"}
                  </Select.Option>
                  <Select.Option value="4" key="4">
                    {"Better"}
                  </Select.Option>
                  <Select.Option value="3" key="3">
                    {"Good"}
                  </Select.Option>
                  <Select.Option value="2" key="2">
                    {"Avarage"}
                  </Select.Option>
                  <Select.Option value="1" key="1">
                    {"Poor"}
                  </Select.Option>
                </Select>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={{ span: 7 }}>
                The training experience will be useful in my work
              </Col>
              <Col md={{ span: 5 }}>
                <Select
                  showSearch
                  onChange={(value) =>
                    onInputChange(value, "training_experience")
                  }
                  placeholder="Select"
                  style={{ width: "100%" }}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Select.Option value="5" key="5">
                    {"Best"}
                  </Select.Option>
                  <Select.Option value="4" key="4">
                    {"Better"}
                  </Select.Option>
                  <Select.Option value="3" key="3">
                    {"Good"}
                  </Select.Option>
                  <Select.Option value="2" key="2">
                    {"Avarage"}
                  </Select.Option>
                  <Select.Option value="1" key="1">
                    {"Poor"}
                  </Select.Option>
                </Select>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={{ span: 7 }}>The trainer was well prepared</Col>
              <Col md={{ span: 5 }}>
                <Select
                  showSearch
                  onChange={(value) => onInputChange(value, "trainer")}
                  placeholder="Select"
                  style={{ width: "100%" }}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Select.Option value="5" key="5">
                    {"Best"}
                  </Select.Option>
                  <Select.Option value="4" key="4">
                    {"Better"}
                  </Select.Option>
                  <Select.Option value="3" key="3">
                    {"Good"}
                  </Select.Option>
                  <Select.Option value="2" key="2">
                    {"Avarage"}
                  </Select.Option>
                  <Select.Option value="1" key="1">
                    {"Poor"}
                  </Select.Option>
                </Select>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={{ span: 7 }}>
                The training was conducted with proper facilities (Training
                Environment)
              </Col>
              <Col md={{ span: 5 }}>
                <Select
                  showSearch
                  onChange={(value) =>
                    onInputChange(value, "training_environment")
                  }
                  placeholder="Select"
                  style={{ width: "100%" }}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Select.Option value="5" key="5">
                    {"Best"}
                  </Select.Option>
                  <Select.Option value="4" key="4">
                    {"Better"}
                  </Select.Option>
                  <Select.Option value="3" key="3">
                    {"Good"}
                  </Select.Option>
                  <Select.Option value="2" key="2">
                    {"Avarage"}
                  </Select.Option>
                  <Select.Option value="1" key="1">
                    {"Poor"}
                  </Select.Option>
                </Select>
              </Col>
            </Row>
          </Form>
        </div>

        {/* <h5>Message</h5>
            <TextArea rows={3} onChange={onFeedbackMessage} value={feedbackMessage}/> */}
      </Modal>
    </div>
  );
}
