import { Button, Form, Input } from "antd";
import React from "react";
import { Card, Row } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { getApiRequest } from "../../api/CommonApi";
import "./Login.css";


export const Ad = () => {
  let history = useHistory();
  const [cookies, setCookie] = useCookies(["user"]);
  const dispatch = useDispatch(); 

  const onFinish = async (values) => {
    console.log("user login data", values);
    const response = await getApiRequest("/api/ad-login", "", values);
    console.log("user login check reponse from ad", response);
    // return false;
    if (response?.data?.status === 'success') {
      // dispatch(selectedLoginMethod("form-login"));

      // const res = await getApiRequest(
      //   "/api/user-information",
      //   response?.data?.access_token,
      //   ""
      //   );
      localStorage.setItem(`user_information`, JSON.stringify(response?.data?.user));
      setCookie("information_user", response?.data?.user, { path: "/" });
      // return false;
      setCookie("access_token", response?.data?.access_token, { path: "/" });
      setCookie("refresh_token", response?.data?.refresh_token, { path: "/" });
      setCookie("admin", "false", { path: "/" });
      setCookie('logged_id', 'false', { path: "/" });
      console.log("response", response?.data);
      toast.success(response?.data?.message);
      window.location.href = "/";
    } else {
      toast.error("Invalid Username or Password");
    }
  };
  
  return (
    <>
      <div className="login-component bg-light-100">
        <Row>
          <Card className="login-card">
            <img className="logo" src="/images/logo.png" alt="" />
            <br />
            <Form onFinish={onFinish}>
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  { required: true, message: "Please input your username!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item>
                <Button className="primary-btn" htmlType="submit">
                  Login With AD
                </Button>
              </Form.Item>
            </Form>
            <br />
            {/* <br />
            <a className="left login-form-forgot" href="/forget-password">
              {" "}
              Forget Password?
            </a>
            <br /> */}
          </Card>
        </Row>
      </div>
    </>
  );
};

export default Ad;
