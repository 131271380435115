import { Image } from 'antd';
import dotenv from "dotenv";
import React, { useEffect, useState } from "react";
import { getApiRequest } from "../../api/CommonApi";
import { urlChecker } from '../../utils/urlChecker';
dotenv.config();

export default function ImageView(props) {
  const fileURL = props.fileUrl ? props.fileUrl : "";
  const [activeLesson, setActiveLesson] = useState();

  const BASEURL = urlChecker();


  useEffect(() =>{
     setActiveLesson(props.lesson);
     updateLessonViewStatus();
  });

  const updateLessonViewStatus = async () => {
    const data = {
      progress_status: 'completed',
      lesson_id: props.lesson.id
    };
    console.log('image view lesson status data', data);
    if(data.lesson_id && fileURL){
      const response = await getApiRequest(`/api/courses/lesson/${props?.lesson?.id}`);
      console.log('image view lesson response data', response);
    }
  }


  return (
    <Image
    width={600}
    src={fileURL ? BASEURL + fileURL : BASEURL +'/images/default.jpg'}
    />
  );
}

