import { ActionTypes } from "../../constants/ActionTypes";

export const selectedFavouriteIds = (favouriteIds) => {
    return {
        type: ActionTypes.SELECTED_FAVOURITE_IDS,
        payload: favouriteIds,
    };
}


export const removeSelectedIds = () => {
    return {
        type: ActionTypes.REMOVED_FAVOURITE_IDS,
    };
}