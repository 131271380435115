export const urlChecker = () => {

    let baseUrl;

    if(window?.location.protocol == 'https:'){

        baseUrl = (window?.location?.hostname == process?.env?.REACT_APP_PUBLIC_URL) ? process?.env?.REACT_APP_PUBLIC_ENDPOINT_HTTPS :  process?.env?.REACT_APP_BASE_URL_HTTPS;
    
    }else{
        baseUrl = (window?.location?.hostname == process?.env?.REACT_APP_PUBLIC_URL) ? process?.env?.REACT_APP_PUBLIC_ENDPOINT_HTTP :  process?.env?.REACT_APP_BASE_URL_HTTP;
    }

    return baseUrl;


}
