import { ActionTypes } from "../../constants/ActionTypes";

export const selectedLoginMethod = (method) => {
    return {
        type: ActionTypes.SELECTED_LOGIN_METHOD,
        payload: method,
    }
}


