import { Button, Divider, Input, Upload } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { RiQuestionAnswerLine } from "react-icons/ri";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getApiRequest, postApiRequest } from "../../api/CommonApi";
import { urlChecker } from "../../utils/urlChecker";
import Preloader from "../common/Preloader";
import "./Faq.css";

const { TextArea } = Input;

export default function FaqPreviousQ() {
  const { faqId } = useParams();
  const [cookies] = useCookies(["user"]);
  const [post, setPost] = useState("");
  const [questions, setQuestions] = useState("");
  const [createQuestion, setCreateQuestion] = useState("");
  const [isLoad, setIsLoad] = useState(false);
  const [file, setFile] = useState("");

  const BASEURL = urlChecker();

  useEffect(async () => {
    const params = {
      faq_id: faqId,
    };
    const response = await getApiRequest(
      "/api/faq/get-my-confusion",
      cookies.access_token,
      params
    );
    setQuestions(response.data);
    setIsLoad(true);
  }, [createQuestion]);

  const handleChange = (e) => {
    setPost(e.target.value);
  };
  const handleSubmission = async (e) => {
    const data = new FormData();
    data.append("faq_id", faqId);
    data.append("title", post);
    data.append("file", file);
    
    const response = await postApiRequest(
      "/api/faq/confusion-create",
      cookies.access_token,
      data
    );
    setPost("");
    setFile("");
    setIsLoad(false);
    setCreateQuestion(response?.data);
    toast.success(response?.data?.message);
    
  };
  function handleFileChange(info) {
    setFile(info.file.originFileObj);
  }
  return isLoad == false ? (
    <Preloader />
  ) : (
    <div className="faqPreviousQuestions">
      <div className="card">
        <span className="faqPreviousQTitle">My Previous Questions :</span>
        <Divider />
        {Object.keys(questions).length > 0
          ? questions.map((question) => {
              return (
                <ul className="faqPreviousQandAns">
                  <li className="questionTime">
                    <span className="qandAns">
                      {" "}
                      <AiOutlineQuestionCircle />
                      {question.title}
                    </span>
                    <span className="qandAnsTime">
                      Asked :{" "}
                      {moment(question.created_at).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    </span><br/>
                    <span>
                        {
                            question?.media_file ? (
                                <a className="btn btn-sm" href={ BASEURL + question?.media_file} target="_blank">Attachment Download</a>
                            ) : ''
                        }
                    </span>
                  </li>
                  {question.detail !== null ? (
                    <li className="questionTime">
                      <span className="qandAns">
                        {" "}
                        <RiQuestionAnswerLine /> {question.detail}
                      </span>
                      <span className="qandAnsTime">
                        Answered :{" "}
                        {moment(question.updated_at)
                          .subtract(6, "days")
                          .calendar()}
                      </span>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              );
            })
          : ""}
        <Divider />
        <TextArea
          className="faqPrevQPostTextBox"
          placeholder="Type your question here..."
          allowClear
          autoSize={{ minRows: 2, maxRows: 6 }}
          onChange={handleChange}
          value={post}
        />
        <Upload
          name="avatar"
          accept=".png, .jpg, .jpeg, .gif, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx"
          action={`${BASEURL}/api/courses/test-upload`}
          listType="picture"
          onChange={(info) => handleFileChange(info)}
        >
          <Button>Add Attachment</Button>
        </Upload>
        <Button
          type="button"
          className="faqPrevQPostbutton"
          onClick={handleSubmission}
        >
          post
        </Button>
      </div>
    </div>
  );
}
