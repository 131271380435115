import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Form,
  Input,
  Pagination,
  Select,
  Space,
  Table
} from "antd";
import moment from "moment";
import { React, useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { getApiRequest } from "../../api/CommonApi";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import FaqPreviousQ from "../../components/faq/FaqPreviousQ";
import QuickCall from "../../components/quickcall/QuickCall";
import DynamicModal from "../../components/tableMatrix/DynamicModal";
import "./Faq.css";

const { TextArea } = Input;
const { Option } = Select;

let originData = [];
let conditionalFilters = "";

function TableMatrixBase(props) {
  const { faqId } = useParams();
  let actualId;
  if (props.faqId) {
    actualId = props.faqId;
  } else {
    actualId = faqId;
  }
  const [form] = Form.useForm();
  const [data, setData] = useState();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [columns, setColumns] = useState([]);
  let [searchInput, setSearchInput] = useState("");
  const [paginateInfo, setPaginateInfo] = useState([(total) => 0]);
  const [isLoad, setIsLoad] = useState(false);
  const [title, setTitle] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [group, setGroup] = useState("");
  const [filter, setFilter] = useState([]);
  const [visible, setVisible] = useState(false);
  const [matrixExternalFilter, setMatrixExternalFilter] = useState([]);
  const [exactTextFlag, setExactTextFlag] = useState(false);
  const [columnWidth, setColumnWidth] = useState("");
  const [searchOnFilter, setSearchOnFilter] = useState([]);
  const [showFlag, setShowFlag] = useState(false);
  const [columnTwoValue, setColumnTwoValue] = useState("");
  const [columnCondtions, setColumnCondtions] = useState([]);
  const [likeSearch, setLikeSearch] = useState(false);

  const loadResources = async (page = 1, perPage = 10) => {
    const url =
      Object.values(filter).length > 0
        ? "/api/matrix/single-matrix?exact=" +
          likeSearch +
          "&filters=" +
          encodeURIComponent(JSON.stringify(filter))
        : "/api/matrix/single-matrix";
    const response = await getApiRequest(url, "", {
      id: actualId,
      page: page,
      perPage: perPage
    });


    console.log('vvvvvvv', response)

    // Conditional dependent column set
    // if (Object.keys(filter) == columnCondtions?.column_one) {
    if (filter[columnCondtions?.column_one]) {
      let colTwo = [];
      let colTwoName = columnCondtions?.column_two;
      if (
        filter[columnCondtions?.column_two] &&
        filter[columnCondtions?.column_two].length > 0
      ) {
        // return false;
      } else {
        setColumnTwoValue([]);
        if (response?.data?.data?.originalRows) {
          response?.data?.data?.originalRows.map((item) => {
            colTwo.push(item[colTwoName]);
          });
          setColumnTwoValue(colTwo);
        }
      }
    }

    if (filter[columnCondtions?.column_one]) {
      console.log(
        "wertyuiopasdfghjklzxcvbnm",
        filter[columnCondtions?.column_one]
      );
    }
    // else {
    //   console.log('outside condition')
    //   setColumnTwoValue([]);
    // }
    // End of conditional dependent column set
    document.title = response?.data?.data?.title;
    if(response?.data?.data?.in_between_condition != "" && response?.data?.data?.in_between_condition != null && response?.data?.data?.in_between_condition != undefined){
      setColumnCondtions(JSON.parse(response?.data?.data?.in_between_condition));
    }
    setPaginateInfo(response?.data?.data?.main_data);
    setTitle(response?.data?.data?.title);
    if(response?.data?.data?.matrix_filters != "" && response?.data?.data?.matrix_filters != null && response?.data?.data?.matrix_filters != undefined){
      setMatrixExternalFilter(JSON.parse(response?.data?.data?.matrix_filters));
    }
    setGroup(response?.data?.data?.group_data);

    //Conditional on color codes
    if (
      response?.data?.data?.matrix_filters &&
      response?.data?.data?.matrix_filters != "" &&
      response?.data?.data?.matrix_filters != null &&
      response?.data?.data?.matrix_filters != undefined &&
      response?.data?.data?.matrix_filters.length > 0
    ) {
      JSON.parse(response?.data?.data?.matrix_filters).map((item, index) => {
        if (item.value === "current_date") {
          item.value = moment().format("YYYY-MM-DD");
          // console.log("ghjkghuighjghjghjk", item.value);
        }
        if (index == 0) {
          conditionalFilters = `(record.${item.column} ${item.conditions} "${item.value}" ? "${item.color}" : "")`;
        } else {
          conditionalFilters += ` || (record.${item.column} ${item.conditions} "${item.value}" ? "${item.color}" : "")`;
        }
      });
    }
    //End of Conditional on color codes

    //Conditional on exact text Flag
    if (response?.data?.data?.search === 1) {
      setExactTextFlag(true);
    } else {
      setData(response?.data?.data?.data);
    }
    if (showFlag === true) {
      setData(response?.data?.data?.data);
    }
    //End of Conditional on exact text Flag

    // Matrix Row push to an array for co-ordinate the data
    const t = response?.data?.data?.data[0];
    originData = [];
    if (t) {
      for (let i = 0; i < Object.keys(t).length; i++) {
        let val = Object.keys(t)[i];
        if (response?.data?.data?.search == 0 || response?.data?.data?.search == null || response?.data?.data?.search == '' ) {
          if(Object.keys(t)[i] == "end_date_d"){
            //it should not do any stuff. because in col we don't want to show this... (client requirement :( ...)
          }else{
            originData.push({
              key: i.toString(),
              title: (
                Object.keys(t)[i][0].toUpperCase() + Object.keys(t)[i].slice(1)
              ).replace(/_/g, " "),
              dataIndex: Object.keys(t)[i],
              ...getColumnSearchProps(Object.keys(t)[i]),
            });
          }
        } else {
          originData.push({
            key: i.toString(),
            title: (
              Object.keys(t)[i][0].toUpperCase() + Object.keys(t)[i].slice(1)
            ).replace(/_/g, " "),
            dataIndex: Object.keys(t)[i],
          });
        }
      }
    }
    //End of Matrix Row push to an array for co-ordinate the data

    response?.data?.data?.data?.map((item, index) => {
      item.key = index;
      return item;
    });
    setIsLoad(true);
  };
  useEffect(async () => {
    loadResources();
  }, [isLoad, filter, exactTextFlag, searchText]);

  // Coulmn wise Search
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          {exactTextFlag == false && (
            <div>
              <Button
                onClick={() => handleReset(clearFilters)}
                size="small"
                style={{ width: 90 }}
              >
                Reset
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  setSearchText(selectedKeys[0]);
                  setSearchedColumn(dataIndex);
                }}
              >
                Filter
              </Button>
            </div>
          )}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
      // style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput, 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          // highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={searchText ? [searchText] : []}
          autoEscape
          textToHighlight={text ? text.toString() : []}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    // console.log("selectedKeys", selectedKeys);
    if (exactTextFlag == false) {
      // setExactTextFlag(false);
      setLikeSearch(true);
      setFilter({ ...filter, [dataIndex]: selectedKeys[0] });
    }
    if (selectedKeys === undefined || selectedKeys.length == 0) {
      selectedKeys[0] = "                   ";
      setSearchText("                   ");
      setSearchedColumn("");
    } else {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    }

    // let pwd = {[dataIndex]: selectedKeys[0]};
    // console.log('dataindex', pwd);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setFilter("");
    loadResources();
    setLikeSearch(false);
  };

  const filterOnChange = (e, key) => {
    setFilter({ ...filter, [key]: e });
    // loadResources(1, filter);
  };

  console.log("filter", filter);
  // End of Coulmn wise SearchS

  //Column of the table
  const mergedColumns = originData.map((col) => {
    if (!col.editable) {
      return col;
    }

    // return {
    //   ...col,
    //   onCell: (record) => ({
    //     record,
    //     colSpan: 5,
    //     dataIndex: col.dataIndex,
    //     title: col.title,

    //   }),
    // };
  });
  //End of the column of the table

  // Pop up a window For filter
  const onClickShowDynamicModal = (name, value) => {
    return <DynamicModal visible={true} name={name} value={value} />;
  };
  //End of Pop up a window For filter

  // Pass value to child component
  const passValueChild = (val) => {
    setSearchText("");
    setFilter("");
    loadResources();
    setFilter({ ...filter, [val.name]: val.value });
  };
  //End of Pass value to child component

  // Exact search Filter on the basis of the value
  const onChangeSelectFilter = (field, value) => {
    setShowFlag(true);
    if (field == "column_value") {
      // console.log('column_value', value.target.value);
      setSearchOnFilter({ ...searchOnFilter, [field]: value.target.value });
      return false;
    }
    setSearchOnFilter({ ...searchOnFilter, [field]: value });
    // console.log(field, value);
  };
  const onClickSearchFilter = () => {
    setFilter({
      [searchOnFilter.column_name]: searchOnFilter.column_value,
    });
    console.log("searchOnFilter", searchOnFilter);
  };
  //End Exact search of Filter on the basis of the value

  const resetGlobalFilters = () => {
    toast.success("Global Filters Reset Successfully");
    history.go(0);
  };

  console.log("conditionalFilters", conditionalFilters);

  return (
    <div className="matrixBase">
      {props.faqId ? "" : <QuickCall />}

      <BreadCrumb link="matrix-List/matrix-Base-Table-Details" />
      <br />

      <div className="card MatrixBasedTable">
        <h3>{title}</h3>
        <div className="right">
          {group != undefined && group != null && group != "" ? (
            <Button onClick={() => resetGlobalFilters()}>Clear Filters</Button>
          ) : (
            ""
          )}
        </div>
        {/* <Divider /> */}
        <table className="table">
          <tbody>
            <tr>
              {exactTextFlag == false ? ( 
                group != undefined && group != null && group != "" ? (
                  Object.keys(group).map(function (template_name) {
                    return (
                      <td className="matrix-button-filter">
                        {columnTwoValue &&
                        template_name == columnCondtions.column_two &&
                        filter[columnCondtions?.column_one] ? (
                          <DynamicModal
                            name={template_name}
                            value={columnTwoValue}
                            passValueToParent={passValueChild}
                          />
                        ) : (
                          <DynamicModal
                            name={template_name}
                            value={group[template_name]}
                            passValueToParent={passValueChild}
                          />
                        )}
                      </td>
                    );
                  })
                ) : (
                  ""
                )
              ) : (
                <td>
                  <Select
                    style={{ width: "30%" }}
                    placeholder="Select Column"
                    onChange={(e) => {
                      onChangeSelectFilter("column_name", e);
                    }}
                  >
                    {Object.keys(group).map(function (template_name) {
                      return (
                        <Option value={template_name}>
                          {(
                            template_name[0].toUpperCase() +
                            template_name.slice(1)
                          ).replace(/_/g, " ")}
                        </Option>
                      );
                    })}
                  </Select>
                  <Input
                    className="input-filter-value"
                    onChange={(e) => {
                      onChangeSelectFilter("column_value", e);
                    }}
                    placeholder="Search Value"
                  />
                  <Button
                    className="btn-filter"
                    onClick={() => onClickSearchFilter()}
                  >
                    Search
                  </Button>
                </td>
              )}
            </tr>
          </tbody>
        </table>
        <Divider />
        {isLoad && (
          <Form form={form} component={false}>
            <Table
              dataSource={data}
              columns={mergedColumns}
              pagination={false}
              rowClassName={(record, index) => eval(conditionalFilters)}
            />
          </Form>
        )}
        <Divider />
        {data && paginateInfo && (
          <Pagination
            defaultCurrent={1}
            total={paginateInfo?.total}
            showTotal={(total) => `Total ${total} items on this matrix`}
            defaultPageSize={10}
            showSizeChanger={true}
            onChange={(p, perPage) => {
              loadResources(p, perPage);
            }}
          />
        )}
        <Divider />
      </div>
      {props.faqId ? (
        ""
      ) : (
        <div className="myConfusionsTable card">
          <FaqPreviousQ />
        </div>
      )}
    </div>
  );
}

export default TableMatrixBase;
