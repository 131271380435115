import React, { useEffect, useState } from "react";
import { pdfjs } from "react-pdf";
import 'video.js/dist/video-js.css';
import { getApiRequest } from "../../api/CommonApi";
import { urlChecker } from "../../utils/urlChecker";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export default function DocumentView(props) {
  const posters = "images/slider/slide-1.jpg"
  const fileURL = props.fileUrl ? props.fileUrl : "";
  const [activeLesson, setActiveLesson] = useState();
  const [activeUserLessonStatusId, setActiveUserLessonStatusId] = useState();
  const [lessonFilePath, setLessonFilePath] = useState();
  const [pdfFile, setPdfFile] = useState();
  const [pdfFileName, setPdfFileName] = useState();

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [thePdf, setThePdf] = useState(null);

  const BASEURL = urlChecker();

  useEffect( () =>{
     setActiveLesson(props.lesson);
     setPdfFile(fileURL);
     updateLessonViewStatus();
  });

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const updateLessonViewStatus = async () => {
    const data = {
      progress_status: 'completed',
      lesson_id: props.lesson.id
    };
    if(data.lesson_id && fileURL){
      if(props?.lesson?.duration){
        const response = await getApiRequest(`/api/courses/lesson/${props.lesson.id}`);
      }else{
        const response = await getApiRequest(`/api/course-resources/resource/${props.lesson.id}`);
      }
    //  const response = await postApiRequest('/api/courses/lesson-complete-status', '', data);
//      console.log('document user complete status response', response);
    }
  }

  

  return (
    <div style={{ marginBottom: "10px" }}>
      <iframe
        src={BASEURL + '/' + fileURL}
        frameborder="0"
        width="100%"
      ></iframe>

      {/* <Document
        file={fileURL ? fileURL : ''}
        mode="no-cors"
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} />
      </Document>
      <Row>
        <Col>
          {pageNumber > 1 && (
            <button
              className="btn btn-primary"
              style={{ float: "left" }}
              onClick={() => setPageNumber(pageNumber - 1)}
            >
              Previous page
            </button>
          )}

          {pageNumber < numPages && (
            <button
              className="btn btn-primary"
              style={{ float: "right" }}
              onClick={() => setPageNumber(pageNumber + 1)}
            >
              Next page
            </button>
          )}
        </Col>
      </Row> */}
    </div>
  );
}

