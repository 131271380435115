import { List } from "antd";
import { AiOutlinePlayCircle } from "react-icons/ai";
import { BsClockHistory, BsEye } from "react-icons/bs";
import { urlChecker } from "../../utils/urlChecker";
import "./RelatedVideoList.css";

export default function RelatedVideoList(props) {
  let data = [];
  const BASEURL = urlChecker();

  if (props.videos) {
    data = props.videos.map((video) => ({
      title: video.title,
      totalview: video.views,
      id: video.id,
      thumbnail: video.thumbnail,
    }));
  }

  return (
    <div className="related-video-list card">
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <div className="thumbnails">
                {item.thumbnail != null ? (
                  <img 
                    src={BASEURL + item.thumbnail}
                    alt=""
                  />
                ) : (
                  ""
                )}
              <a href={`/video-details/${item.id}`}>
                <AiOutlinePlayCircle />
              </a>
            </div>
            <div className="description">
              <h3>{<a href={`/video-details/${item.id}`}>{item.title}</a>}</h3>
              <span className="videoUploadTime">
                <BsClockHistory /> Posted at 12 July 2021 05:31 AM
              </span>
              <span>
                <BsEye /> {item.totalview}
              </span>
            </div>
          </List.Item>
        )}
      />
    </div>
  );
}
