import { Divider, Tag } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getApiRequest } from "../../api/CommonApi";
import Preloader from "../../components/common/Preloader";
import StreamPlayer from "../../components/common/StreamPlayer";
import RelatedVideoList from "../../components/relatedvideolist/RelatedVideoList";
import "./VideoDetails.css";

export default function VideoDetails() {
  const { videoId } = useParams();
  const [videoData, setVideoData] = useState("");
  const [tagsRender, setTagsRender] = useState("");
  const [relatedVideo, setRelatedVideo] = useState("");
  const [isLoad, setIsLoad] = useState(false);

  useEffect(async () => {
    let params = "";
    const response = await getApiRequest(
      `/api/video/read/${videoId}`,
      "",
      params
    );
    setVideoData(response.data.data);
    const tagsContent = response.data.data.tags.map((tag) => {
      return <Tag>{tag.tag[0].name}</Tag>;
    });
    setTagsRender(tagsContent);
    const res = await getApiRequest(
      `/api/video/related-video/${videoId}`,
      "",
      params
    );
    setRelatedVideo(res.data.data);
    setIsLoad(true);
  }, [videoId]);
  return (
    <div className="video-details">
      <div className="videoLeft">
        <div className="player">
          {isLoad == false ? (
            <Preloader />
          ) : (
            <StreamPlayer fileUrl={videoData.video_url} videoId={videoId} />
          )}
        </div>
        <div className="description">
          <div className="videoTags">
            <span className="tagsTitle">tags:</span>
            {tagsRender}
          </div>

          <div className="videosTimeing">
            <span className="videoUploadTime">
              Posted at{" "}
              {moment(videoData.created_at).format("MMMM Do YYYY, h:mm:ss a")}{" "}
            </span>
            <Divider type="vertical" />
            <span className="videoViews">{videoData.views} views</span>
          </div>
        </div>
        <div className="videoText">
          <h3 className="videotitle"> {videoData.title}</h3>
          <p className="text">{videoData.description}</p>
        </div>
      </div>
      <div className="videoRight customScrollbar">
        <RelatedVideoList videos={relatedVideo} />
      </div>
    </div>
  );
}
