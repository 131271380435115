import React from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter as Router, Link } from 'react-router-dom';


const NewTestCom = (props) => {
    const products = useSelector((state) => state.allProducts.products);


    const renderProductsHere = products.map((product) => {
        const { id, title, price } = product;
        return (
            <div key={id}>
                <Link to={`/detailsnewtest/${id}`} >
                <p>{title}</p>
                </Link>
                <p>{price} TK</p>
            </div>
        );
    });

    return (
        <>
            {renderProductsHere}
        </>
    )
}

export default NewTestCom
