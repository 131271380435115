import { Tag } from "antd";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { getApiRequest, postApiRequest } from "../../api/CommonApi";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import Preloader from "../../components/common/Preloader";
import FaqDetailsView from "../../components/faq/FaqDetailsView";
import FaqPreviousQ from "../../components/faq/FaqPreviousQ";
import QuickCall from "../../components/quickcall/QuickCall";
import SearchContent from "../../components/searchcontent/SearchContent";
import { setFaqs } from "../../services/actions/faq/FaqAction";
import "./Faq.css";

export default function DetailsFaq() {
  const { faqId } = useParams();
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(["user"]);
  const relatedFaqs = useSelector((state) => state.allFaqs.faqs);
  let [cnt, setCnt] = useState(0);
  const [faq, setFaq] = useState("");
  const [tagsRender, setTagsRender] = useState("");
  const [tags, setTags] = useState("");
  const [category, setCategory] = useState("");
  const [isLoadFaq, setIsLoadFaq] = useState(false);
  const history = useHistory();
  let [pagination, setPagination] = useState(1);

  const userInfo = JSON.parse(localStorage.getItem("user_information"));

  useEffect(async () => {
    return history.listen(async (location) => {
      const dataEditTrack = {
        faq_id: faqId,
        user_id: userInfo?.id,
        type: 1,
        action: "edit",
        end_time: Date.now() / 1000,
        track_id: parseInt(cookies.trackFaq),
      };
      if (cookies.tracking === "true") {
        const trackEditResponse = await postApiRequest(
          "/api/faq-track/store",
          "",
          dataEditTrack
        );
        setCookie("trackFaq", "", { path: "/" });
        setCookie("tracking", "false", { path: "/" });
      }
      console.log(`You changed the page to: ${location.pathname}`);
    });
  }, [history]);

  useEffect(async () => {
    const res = await getApiRequest(`/api/faq/read/${faqId}`, "", "");
    setFaq(res?.data);
    setIsLoadFaq(true);
    setCategory(res?.data?.data?.category);
    setTags(res?.data?.data?.tags);
    if (res.data.data) {
      const tagsContent = res.data.data.tags.map((tag) => {
        return <Tag>{tag.tag[0].name}</Tag>;
      });
      setTagsRender(tagsContent);
    }
    const dataTrack = {
      faq_id: faqId,
      user_id: userInfo?.id,
      type: 1,
      action: "create",
      start_time: Date.now() / 1000,
    };
    console.log(cookies.tracking);
    document.title =  res?.data?.data?.title;
    if (cookies.tracking === "false") {
      const trackResponse = await postApiRequest(
        "/api/faq-track/store",
        "",
        dataTrack
      );
      setCookie("trackFaq", trackResponse.data.data.id, { path: "/" });
      setCookie("tracking", "true", { path: "/" });
    }
  }, [faqId]);

  useEffect(
    async (e) => {
      const params = {
        faq_id: faqId,
        category_id: "",
        tag: "",
        load: pagination,
      };
      const response = await getApiRequest("/api/faq/new-index", "", params);
      dispatch(setFaqs(response?.data?.data));
      setCnt(response?.data?.count);
    },
    [pagination]
  );

  const handleOnClick = (e) => {
    setPagination(pagination + 1);
  };

  window.onbeforeunload = async function () {
    const dataEditTrack = {
      faq_id: faqId,
      user_id: userInfo?.id,
      type: 1,
      action: "edit",
      end_time: Date.now() / 1000,
      track_id: parseInt(cookies.trackFaq),
    };

    if (cookies.tracking === "true") {
      const trackEditResponse = await postApiRequest(
        "/api/faq-track/store",
        "",
        dataEditTrack
      );
      setCookie("trackFaq", "", { path: "/" });
      setCookie("tracking", "false", { path: "/" });
    }
    return "Do you want to leave the faq page?";
  };

  return (
    <>
      <QuickCall />

      <BreadCrumb link="all-FAQs/Detail-FAQ" /><br />

      <div className="detailsFaq">
        {isLoadFaq == false ? (
          <Preloader />
        ) : (
          <div>
          <Row className="searchContenResults">
            <Col xs={12}>
              <FaqDetailsView
                faq={faq.data}
                category={category}
                tagsRender={tagsRender}
              />
              <FaqPreviousQ />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <h3>Related FAQs</h3>
              <SearchContent faqs={relatedFaqs} />
              {cnt > 0  && cnt > relatedFaqs.length ? (
                <button className="loardMore" onClick={handleOnClick}>
                  Load More
                </button>
              ) : (
                ""
              )}
            </Col>
          </Row>
          </div>
        )}
      </div>
    </>
  );
}
