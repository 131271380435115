import { Button, Divider, Input, List, Select, Upload } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getApiRequest, postApiRequest } from '../../api/CommonApi';
import Preloader from '../../components/common/Preloader';
import QuickCall from '../../components/quickcall/QuickCall';
import { urlChecker } from '../../utils/urlChecker';
import './ConfusionBox.css';

const { Option } = Select;

  const { TextArea } = Input;

  
  export default function ConfusionBox() {
      let history = useHistory();
      const [data, setData] = useState('');
      const [load, setloadMore] = useState(1);
      const [isLoad, setIsLoad] = useState(false);
      const [questionText, setQuestionText] = useState('');
      const confusionFile = useSelector(state => state.confusionFile.file);
      const confusionImage = useSelector(state => state.confusionImage.image);
      const [uploadFile, setUploadFile] = useState('');
      const [uploadImage, setUploadImage] = useState('');

      const BASEURL = urlChecker();
      
      
      useEffect(async (e) => {    
          const params = {
              load:load
          }
          const response = await getApiRequest('/api/confusion/read', "", params);
          const values = response.data.data.map((post) => ({
              description:post.comment,
              url:post.forum_image,
              postTime: moment(post.created_at).format('MMMM Do YYYY, h:mm:ss a'),
              id:post.id
          } ))
          setData(values)
          setIsLoad(true)
      }, [load])
      const onChange = (e) => {
        setQuestionText(e.target.value);
      };
    const handleLoadMore = () => {
        const inc = load +1;
        setloadMore(inc);
    }

    const handleSubmission = async (e) => {
        if(!questionText){
            toast.error("Empty Question Field!")
            return false;
        }
        const data = new FormData() 
        data.append('comment', questionText)
        data.append('forum_image', uploadImage)
        const response = await postApiRequest('/api/confusion/create', "", data);
        // console.log(response.data);
        toast.success(response.data.message);
        window.location.href = "/confusion-box"
    }

    function handleFileChange(info) {
        // if(type == "image"){
            setUploadImage(info?.file?.originFileObj)
        // }else{
        //     setUploadFile(info?.file?.originFileObj)
        // }
    }

    return (
        <div className="confusionBox">
            <QuickCall />

            <div className="confusionBoxBody">
                <div className="boxQuestion">
                    <span className="boxTitle">Ask a Question:</span>
                    <Divider />
                    <div className="card">
                        <TextArea placeholder="Type your question here..." allowClear onChange={onChange} required/>
                    </div>
                    <div className="confusionBoxActions">
                    <Upload
                        accept="images/*"
                        action={`${BASEURL}/api/courses/test-upload`}
                        maxCount={1}
                        onChange={(info) => handleFileChange(info, 'image')}>
                            <Button>Click to Upload</Button>
                    </Upload>
                    <button className="confusionBoxPostButton" onClick={handleSubmission}>Post</button>
                    </div>
                </div>

                <div className="confusionBoxDetails">
                    <span className="boxTitle">Confusion Details</span>
                    <Divider />
                    <div className="boxDetailsFlex">
                        <div className="left">
                            <span className="boxTitle">{data.length} Active Communication </span>
                        </div>
                        {/* <div className="left">
                            <span className="boxTitle">sort by:</span>
                            <Select defaultValue="lucy" style={{ width: 100 }}>
                                <Option value="jack">Jack</Option>
                                <Option value="lucy">Lucy</Option>
                            </Select>
                        </div> */}
                    </div>
                </div>

                <div className="confusionBoxResults">
                    {
                        isLoad == false ? (
                            <Preloader/>
                        ):(
                        <List
                        itemLayout="horizontal"
                        dataSource={data}
                        renderItem={item => (
                        <List.Item className="card" >
                            <div className="img">
                                <Avatar src={BASEURL+item.url} />
                            </div>
                            <div className="content-des">
                            <Link to={`/details-confusions/${item.id}`}>
                            
                                <span className="description">{item.description}</span>
                                <span className="postTime">{item.postTime}</span>
                            
                            </Link>
                            </div>
                        </List.Item>
                        )}
                    />
                        )
                    }
                </div>
                <button className="loardMore" onClick={handleLoadMore}>Load More</button>
            </div>
        </div>
    )
}
