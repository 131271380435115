import { Tag } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { Badge, Col, ListGroup, Row } from "react-bootstrap";
import { BsClockHistory } from "react-icons/bs";
import { Link } from "react-router-dom";
import "./SearchContent.css";
// search-results

export default function SearchCallHandleContent(props) {
  const [isLoad, setIsLoad] = useState(false);
  const [faqsRender, setFaqsRender] = useState("");
  console.log('props tags', props?.faqs);
  useEffect(() => {
    if (props.faqs) {
      setIsLoad(true);
      const renderFaqs = props.faqs.map((faq) => {
        const {
          id,
          title,
          detail,
          summary,
          category_name,
          tag_name,
          feature_image,
          type,
          category_id,
          created_at,
          updated_at,
          tags,
          isFavourite,
          status,
        } = faq;

        return (
          <div className="card">
            <ListGroup variant="flush">
              <ListGroup.Item>
                <Link to={`/callhandle-details/${id}`}>
                  <div className="resultContent">
                    <span>
                      <BsClockHistory /> Posted at{" "}
                      {moment(created_at).format("MMMM Do YYYY, h:mm:ss a")} |
                      Last updated{" "}
                      {moment(updated_at).subtract(6, "days").calendar()}
                    </span>
                    <Link
                      className="resultTitle"
                      to={`/callhandle-details/${id}`}
                    >
                      {title}
                      {status == "archived" ? (
                        <Badge className="archBadge">{status}</Badge>
                      ) : (
                        ""
                      )}
                    </Link>
                    {/* <span className="resultContents" dangerouslySetInnerHTML={{__html: detail}}></span> */}
                    <span className="resultContents">
                      {/* {summary?summary:''} */}
                      {
                        summary && summary == "undefined" ? '' : summary
                      }
                      </span>
                    <div className="resultsTagTimes">
                      <div className="resultsCategory">
                        <span>Category:</span>
                        <Tag value={category_id}>{category_name}</Tag>
                      </div>
                      <div className="resultsTag">
                        <span>Tags: </span>
                        {/* Tag show */}
                        {tags.map((tag) => {
                          return (
                            <Tag>
                              <Link value={tag.tag_id}>{tag.tag_name}</Link>
                            </Tag>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Link>
              </ListGroup.Item>
            </ListGroup>
          </div>
        );
      });
      setFaqsRender(renderFaqs);
    }
  }, [props.faqs]);

  return (
    <div className="searchContent">
      <Row>
        <Col xs={12}>
          {isLoad == false ? <p>No Data</p> : faqsRender}
          {/* {renderFaqs} */}
        </Col>
      </Row>
    </div>
  );
}
