import { ActionTypes } from "../../constants/ActionTypes";

export const setChangeAbleValue = (value) => {
    return {
        type: ActionTypes.CHANGE_ABLE_VALUE,
        payload: value,
    }
}


