import { Rate, Tag } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { AiOutlineSafetyCertificate, AiOutlineYoutube } from "react-icons/ai";
import { BsCardList } from "react-icons/bs";
import { FiMonitor } from "react-icons/fi";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { postApiRequest } from "../../../api/CommonApi";
import StreamPlayer from "../../common/StreamPlayer";
//import Videoplayer from "../../videoplayer/VidoePlayer";
import "./CoursesDetailsBannerCard.css";

export default function CoursesDetailsBannerCard() {
  let course = useSelector((state) => state.course);
  console.log("get course from store", course);
  const [cookies] = useCookies(["user"]);
  const [addWishlist, setAddWishlist] = useState(false);
  const [enrollStatus, setEnrollStatus] = useState(false);
  const { title, course_file, short_desc, rating, course_enroll, created_at, updated_at } = course;

  useEffect(async () => {
    await checkUserEnrolledStatus();
    await checkUserWishlistStatus();
  }, []);

  const checkUserEnrolledStatus = async () => {
    const data = {
      course_id: course.id,
    };
    const response = await postApiRequest(
      "/api/course-enrolled/check",
      "",
      data
    );
    response?.data?.data ? setEnrollStatus(true) : setEnrollStatus(false);
    console.log("my enrolled user status", response);
  };

  const checkUserWishlistStatus = async () => {
    const data = {
      course_id: course.id,
    };
    const response = await postApiRequest(
      "/api/course-wishlist/check",
      "",
      data
    );
    response?.data?.data ? setAddWishlist(true) : setAddWishlist(false);
    console.log("course wishlist check response", response);
  };

  const enrollToCourse = async () => {
    const data = {
      course_id: course.id,
    };
    const response = await postApiRequest(
      "/api/course-enrolled/create",
      "",
      data
    );
    toast.success(response?.data?.message);
    response?.data?.data ? setEnrollStatus(true) : setEnrollStatus(false);
    console.log("course enroll response", response);
  };

  const addToWishlist = async () => {
    const data = {
      course_id: course.id,
    };
    const response = await postApiRequest(
      "/api/course-wishlist/create",
      "",
      data
    );
    response?.data?.data ? setAddWishlist(true) : setAddWishlist(false);
    toast.success(response?.data?.message);
    console.log("course wishlist response", response);
  };

  console.log("add to wishlist", addWishlist);
  return (
    <>
      <div className="elearningCoursesDetailsBannerCard card">
        <div className="content">
          <div className="elearningCoursesDetailsBannerCardLeft">
            <h3 className="elearningCoursesDetailsTitle">
              {course ? course.title : ""}
            </h3>
            <p className="elarnigAllCoursCardDesc">{short_desc ?? ""}</p>
            <span className="elearningCoursesDetailsPostTime">
              Updated at {moment(created_at).format("DD MMMM YYYY HH:mm A")} |
              Last updated {moment(updated_at).fromNow()}
            </span>
            <span className="elearningCoursesDetailsInstruction">
              Trainer : {" "}
              {course.course_instructor_name ? course.course_instructor_name : ""}
            </span>
            <span className="elearningCoursesDetailsReview">
              <Rate disabled defaultValue={rating} />
            </span>
            <div className="elearningCoursesDetailsTags">
              {course?.tags &&
                course.tags.map((item, i) => (
                  <Tag key={i}>{item?.tag?.name}</Tag>
                ))}
            </div>
            {!addWishlist && (
              <button
                className="elearningCoursesDetailsBannerCardLeftBtn"
                onClick={addToWishlist}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.99998 15.1099C7.7722 15.1099 7.5526 15.0273 7.38146 14.8774C6.73509 14.3123 6.11193 13.7811 5.56212 13.3126L5.55932 13.3102C3.94738 11.9365 2.55542 10.7502 1.58691 9.58167C0.504272 8.27527 0 7.03662 0 5.68347C0 4.36877 0.450805 3.15588 1.26928 2.26807C2.09753 1.36975 3.234 0.875 4.46972 0.875C5.3933 0.875 6.23912 1.16699 6.98363 1.7428C7.35936 2.03345 7.69994 2.38916 7.99998 2.80408C8.30015 2.38916 8.64061 2.03345 9.01646 1.7428C9.76097 1.16699 10.6068 0.875 11.5304 0.875C12.766 0.875 13.9026 1.36975 14.7308 2.26807C15.5493 3.15588 16 4.36877 16 5.68347C16 7.03662 15.4958 8.27527 14.4132 9.58154C13.4447 10.7502 12.0528 11.9364 10.4411 13.3099C9.89036 13.7792 9.26622 14.3112 8.61839 14.8777C8.44737 15.0273 8.22765 15.1099 7.99998 15.1099ZM4.46972 1.81226C3.49889 1.81226 2.60705 2.19971 1.95825 2.90332C1.2998 3.61755 0.937132 4.60486 0.937132 5.68347C0.937132 6.82153 1.3601 7.83936 2.30847 8.98364C3.22509 10.0897 4.58849 11.2516 6.1671 12.5969L6.17003 12.5994C6.72191 13.0697 7.34752 13.6029 7.99864 14.1722C8.65367 13.6018 9.28026 13.0677 9.83323 12.5967C11.4117 11.2513 12.775 10.0897 13.6916 8.98364C14.6399 7.83936 15.0628 6.82153 15.0628 5.68347C15.0628 4.60486 14.7002 3.61755 14.0417 2.90332C13.393 2.19971 12.5011 1.81226 11.5304 1.81226C10.8192 1.81226 10.1662 2.03833 9.5897 2.48413C9.07591 2.88159 8.718 3.38403 8.50816 3.7356C8.40025 3.91638 8.21031 4.02429 7.99998 4.02429C7.78966 4.02429 7.59972 3.91638 7.49181 3.7356C7.28209 3.38403 6.92418 2.88159 6.41027 2.48413C5.83373 2.03833 5.18078 1.81226 4.46972 1.81226Z"
                    fill="black"
                  />
                </svg>
              </button>
            )}
            {addWishlist && (
              <button
                className="elearningCoursesDetailsBannerCardLeftBtn"
                onClick={addToWishlist}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.89738 2.31648C3.11265 0.893976 4.76067 0.0948607 6.47905 0.0948607C8.19743 0.0948607 9.84546 0.893976 11.0607 2.31648L12.9595 4.53781L14.8583 2.31648C15.4561 1.59176 16.1711 1.0137 16.9618 0.616031C17.7524 0.218361 18.6028 0.00904146 19.4633 0.000286492C20.3237 -0.00846847 21.1771 0.183517 21.9735 0.56504C22.7699 0.946563 23.4935 1.50998 24.1019 2.22242C24.7104 2.93487 25.1916 3.78206 25.5174 4.71458C25.8433 5.6471 26.0072 6.64626 25.9998 7.65377C25.9923 8.66127 25.8135 9.65695 25.4739 10.5827C25.1342 11.5084 24.6405 12.3457 24.0216 13.0457L12.9595 26L1.89738 13.0457C0.68249 11.6227 0 9.6931 0 7.68107C0 5.66905 0.68249 3.7394 1.89738 2.31648Z"
                    fill="#E59830"
                  />
                </svg>
              </button>
            )}
          </div>
          <div className="elearningCoursesDetailsBannerCardRight">
            <div className="elearnigMyCoursesVideoPlayer">
              <StreamPlayer fileUrl={course_file}/>
              {/* <VideoPlay 
                        fileUrl={course_file}
                        lesson={''}
                        /> */}
              {/* <Videoplayer video={course_file} /> */}
            </div>
            <div className="buttons">
              {console.log('wwww', course.enable_enrollment)}
              {course.enable_enrollment == 1 && !enrollStatus && (
                <button
                  className="elearningCoursesDetailsBannerCardRightbtn"
                  onClick={enrollToCourse}
                >
                  Enroll Now
                </button>
              )}
              { enrollStatus && (
                <button className="elearningCoursesDetailsBannerCardRightbtnEnrolledbtn">
                  Enrolled
                </button>
              )}
              {!addWishlist && (
                <button
                  className="elearningCoursesDetailsBannerCardLeftBtn"
                  onClick={addToWishlist}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.99998 15.1099C7.7722 15.1099 7.5526 15.0273 7.38146 14.8774C6.73509 14.3123 6.11193 13.7811 5.56212 13.3126L5.55932 13.3102C3.94738 11.9365 2.55542 10.7502 1.58691 9.58167C0.504272 8.27527 0 7.03662 0 5.68347C0 4.36877 0.450805 3.15588 1.26928 2.26807C2.09753 1.36975 3.234 0.875 4.46972 0.875C5.3933 0.875 6.23912 1.16699 6.98363 1.7428C7.35936 2.03345 7.69994 2.38916 7.99998 2.80408C8.30015 2.38916 8.64061 2.03345 9.01646 1.7428C9.76097 1.16699 10.6068 0.875 11.5304 0.875C12.766 0.875 13.9026 1.36975 14.7308 2.26807C15.5493 3.15588 16 4.36877 16 5.68347C16 7.03662 15.4958 8.27527 14.4132 9.58154C13.4447 10.7502 12.0528 11.9364 10.4411 13.3099C9.89036 13.7792 9.26622 14.3112 8.61839 14.8777C8.44737 15.0273 8.22765 15.1099 7.99998 15.1099ZM4.46972 1.81226C3.49889 1.81226 2.60705 2.19971 1.95825 2.90332C1.2998 3.61755 0.937132 4.60486 0.937132 5.68347C0.937132 6.82153 1.3601 7.83936 2.30847 8.98364C3.22509 10.0897 4.58849 11.2516 6.1671 12.5969L6.17003 12.5994C6.72191 13.0697 7.34752 13.6029 7.99864 14.1722C8.65367 13.6018 9.28026 13.0677 9.83323 12.5967C11.4117 11.2513 12.775 10.0897 13.6916 8.98364C14.6399 7.83936 15.0628 6.82153 15.0628 5.68347C15.0628 4.60486 14.7002 3.61755 14.0417 2.90332C13.393 2.19971 12.5011 1.81226 11.5304 1.81226C10.8192 1.81226 10.1662 2.03833 9.5897 2.48413C9.07591 2.88159 8.718 3.38403 8.50816 3.7356C8.40025 3.91638 8.21031 4.02429 7.99998 4.02429C7.78966 4.02429 7.59972 3.91638 7.49181 3.7356C7.28209 3.38403 6.92418 2.88159 6.41027 2.48413C5.83373 2.03833 5.18078 1.81226 4.46972 1.81226Z"
                      fill="black"
                    />
                  </svg>
                </button>
              )}
              {addWishlist && (
                <button
                  className="elearningCoursesDetailsBannerCardLeftBtn"
                  onClick={addToWishlist}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M1.89738 2.31648C3.11265 0.893976 4.76067 0.0948607 6.47905 0.0948607C8.19743 0.0948607 9.84546 0.893976 11.0607 2.31648L12.9595 4.53781L14.8583 2.31648C15.4561 1.59176 16.1711 1.0137 16.9618 0.616031C17.7524 0.218361 18.6028 0.00904146 19.4633 0.000286492C20.3237 -0.00846847 21.1771 0.183517 21.9735 0.56504C22.7699 0.946563 23.4935 1.50998 24.1019 2.22242C24.7104 2.93487 25.1916 3.78206 25.5174 4.71458C25.8433 5.6471 26.0072 6.64626 25.9998 7.65377C25.9923 8.66127 25.8135 9.65695 25.4739 10.5827C25.1342 11.5084 24.6405 12.3457 24.0216 13.0457L12.9595 26L1.89738 13.0457C0.68249 11.6227 0 9.6931 0 7.68107C0 5.66905 0.68249 3.7394 1.89738 2.31648Z"
                      fill="#E59830"
                    />
                  </svg>
                </button>
              )}
            </div>
            <div className="info">
              <span className="elearningCoursesDetailsBannerCardRightTitle">
                This Courses Includes :
              </span>
              <ul className="elearningCoursesDetailsBannerCardRightList">
                <li className="elearningCoursesDetailsBannerCardRightItem">
                  <AiOutlineYoutube /> Course video tutorial
                </li>
                <li className="elearningCoursesDetailsBannerCardRightItem">
                  <BsCardList /> {" "}
                  {course && course.course_lessons ? course.course_lessons.length : 0} Lesson
                </li>
                {course && course.course_resources &&  
                <li className="elearningCoursesDetailsBannerCardRightItem">
                  <AiOutlineSafetyCertificate /> {course.course_resources.length} Downloadable Resources
                </li>
                }
                <li className="elearningCoursesDetailsBannerCardRightItem">
                  <FiMonitor /> Access on Mobile & TV
                </li>
                {course.course && course.course.is_certified && 
                <li className="elearningCoursesDetailsBannerCardRightItem">
                  <AiOutlineSafetyCertificate /> Certification of Completion
                </li>
                
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
