import { Button, Comment, Form, Input, List, Tooltip } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { getApiRequest, postApiRequest } from '../../../api/CommonApi';

const { TextArea } = Input;

export default function QAComponent() {
    const dispatch = useDispatch();
    let course = useSelector((state) => state.course);
    const { id } = useParams();
    const [cookies] = useCookies(['user']); 
    const { course_qas } = course;
    const [question, setQuestion] = useState();
    const [storeQuestion, setStoreQuestion] = useState();
    const [activeQuestionIndex, setActiveQuestionIndex] = useState();
    const [courseQA, setCourseQA] = useState([]);

    const userInfo = JSON.parse(localStorage.getItem("user_information"));

    useEffect( async () => {
        await fetchCourseQA();
    }, [storeQuestion]);

    const fetchCourseQA = async () => {
      const response = await getApiRequest(`/api/courses/course-qas/${id}`);
      console.log('fetch course question answers', response);
      setCourseQA(response?.data?.data);
    }

    const mData = courseQA.map((question, i) => ({
        parentId: question.id,
        actions: [<span key="comment-list-reply-to-0" onClick={()=>{handleOnReply(i)}} >Reply to</span>],
        author: question.user.name,
        avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
        content: (
            <p>
             {question.comment}
            </p>
          ),
          datetime: (
            <Tooltip title={moment(question.created_at).format('YYYY-MM-DD HH:mm:ss')}>
                <span>{moment(question.created_at).fromNow()}</span>
            </Tooltip>
            ),
       
            children: question.question_answers.map((children, i) => ({
                actions: [<span key="comment-list-reply-to-0">Reply to</span>],
                author: children.user.name,
                avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
                content: (
                <p>
                    {children.comment}
                </p>
                ),
                datetime: (
                <Tooltip title={moment(children.created_at).format('YYYY-MM-DD HH:mm:ss')}>
                    <span>{moment(children.created_at).fromNow()}</span>
                </Tooltip>
                ),
            })),
      }));


    const onChange = (e) => {
        setQuestion(e.target.value);
    };

    function handleOnReply(index){
        index = activeQuestionIndex == index ? '100' : index;
       setActiveQuestionIndex(index);
    }


    const handleSubmit = async (parentQId) => {
        const data = { 
            parent_qid: parentQId ? parentQId : 0,
            course_id: id,
            user_id: userInfo?.id,
            comment: question
        };
        console.log('agent QA data', data);

        const response = await postApiRequest('/api/course-qas/create', '', data);
        console.log('agent couse response', response);
        setStoreQuestion(response?.data?.data);
        setQuestion('');
        setActiveQuestionIndex('');
        toast.success(response?.data?.message);

    };

    return (
        <>
        <Form.Item>
            <TextArea rows={4} placeholder="Type your question here..." onChange={onChange} value={question}/>
        </Form.Item>
        <Form.Item>
            <Button htmlType="submit" onClick={() => handleSubmit()} type="primary">
            Submit Question
            </Button>
        </Form.Item>

        <List
        className="comment-list"
        header={`${mData.length} Question & Answers`}
        itemLayout="horizontal"
        dataSource={mData}
        renderItem={(item, i) => (
        <li>
            <Comment
            actions={item.actions}
            author={item.author}
            avatar={item.avatar}
            content={item.content}
            datetime={item.datetime}
            />
            <ul>
            {item.children.length > 0 && 
            <List
                className="comment-list"
                header={ item.children.length > 0 ? `${item.children.length} Answers` : ''}
                itemLayout="horizontal"
                dataSource={item.children}
                renderItem={childQA => (
                <li>
                    <Comment
                    author={childQA.author}
                    avatar={childQA.avatar}
                    content={childQA.content}
                    datetime={childQA.datetime}
                    />
                </li>
                )}
                />
                }
                { i == activeQuestionIndex && 
                <>
                <Form.Item>
                    <TextArea rows={4} placeholder="Type your question here..." onChange={onChange} value={question}/>
                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit" onClick={() => handleSubmit(item.parentId)} type="primary">
                    Add Reply
                    </Button>
                </Form.Item>
                </>
                }
            </ul>
        </li>
        )}
    />
    </>
    )
}
