import { Button, DatePicker, Divider, Pagination, Select, Tag } from 'antd';
import dotenv from "dotenv";
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { useCookies } from "react-cookie";
import { AiOutlineClose, AiOutlineDownload, AiOutlineSearch } from "react-icons/ai";
import { getApiRequest } from "../../api/CommonApi";
import { urlChecker } from '../../utils/urlChecker';
import './Reports.css';

dotenv.config();
const { RangePicker } = DatePicker;
const Option = Select.Option;
export const AgentQuizReport = () => {
    const BASEURL = urlChecker();

    const [cookies] = useCookies(["user"]);
    const [quizReportData, setQuizReportData] = useState([]);
    const [paginateInfo, setPaginateInfo] = useState([]);
    const [allQuiz, setallQuiz] = useState([]);
    const [userList, setUserList] = useState([]);
    const [groups, setGroups] = useState([]);
    const [tags, setTags] = useState([]);
    const [refreshFilter, setRefreshFilter] = useState(false);

    const [filterDate, setFilterDate] = useState(null);

    const [filterParams, setFilterParams] = useState({
        quiz_id: null,
        agent_id: null,
        group_id: null,
        tags: null,
    });
    const [extraUrl, setExtraUrl] = useState('');

    const userInfo = JSON.parse(localStorage.getItem("user_information"));

    useEffect(() => {
        loadAllQuizzes();
        loadUserList();
        loadGroups();
        loadTags();
    }, [refreshFilter, filterParams.group_id]);

    useEffect(() => {
        getQuizReport();
        loadAllQuizzes();
        loadUserList();
    }, [filterParams.quiz_id, filterParams.tags]);

    const getDateFiler = (date, dateString) => {
        console.log(date, dateString);
        const startDate = moment(dateString[0]).format('YYYY-MM-DD');
        const endDate = moment(dateString[1]).format('YYYY-MM-DD');
        // return { startDate, endDate };
        // getQuizReport([startDate, endDate]);
        setFilterDate([startDate, endDate]);
    }   

    const loadAllQuizzes = async () => {
        const response = await getApiRequest("/api/quiz/index", "");
        setallQuiz(response?.data?.data);
    };
    const loadUserList = async () => {
        const response = await getApiRequest("/api/user-list")
        setUserList(response?.data?.data);
    };
    const loadGroups = async () => {
        const res = await getApiRequest("/api/process/index");
        console.log('res', res);
        setGroups(res?.data?.data);
    };

    const loadTags = async () => {
        let res = await getApiRequest("/api/quiz/all-tags", "");
        if (res.status === 200) {
            setTags(res.data);
        }
    }
    




    const getQuizReport = (filterDate = null, others = null, page = 1) => {
        let url = filterDate ? `/api/quiz-attempt/agent-reports?start_date=${filterDate[0]}&end_date=${filterDate[1]}&page=${page}&agent_id=${userInfo?.id}` : '/api/quiz-attempt/agent-reports' + `?page=${page}&agent_id=${userInfo?.id}`;

        if (others != null) {
            url = url + others;
        }
        getApiRequest(url, '', filterParams).then(response => {
            setQuizReportData(response?.data?.data);
            setPaginateInfo(response?.data?.meta);
            console.log('return response', response);
        }).catch(error => {
            console.log(error);
        });
    }

    const filterReport = () => {
        let myextraUrl = '';
        if (filterParams.quiz_id != null) {
            myextraUrl += `&quiz_id=${filterParams.quiz_id}`;
        }
        if (filterParams.agent_id != null) {
            myextraUrl += `&agent_id=${filterParams.agent_id}`;
        }
        console.log("asdasd", myextraUrl);
        if (myextraUrl != '') {
            getQuizReport(filterDate, myextraUrl);
        } else {
            getQuizReport(filterDate);
        }

    }

    const exportReport = () => {
        const url = filterDate ? `/api/agent-reports?start_date=${filterDate[0]}&end_date=${filterDate[1]}&export=true` : '/api/agent-reports?export=true';
        window.open(BASEURL + url);
    }

    const clearFilter = () => {
        setFilterParams({});
        setRefreshFilter(true);
    }

    const onInputChange = (name, value) => {
        setFilterParams({ ...filterParams, [name]: value.join() });
    }


    return (
        <>
            {/* {quizReportData?.length > 0 && ( */}
                <div className="AgentQuizReport">
                    <div className="reportTableArea card">
                        <h5 className="text-center">Agent Quiz Report</h5>
                        <Divider />
                        <Row>
                            <Col md={12}>
                                <Row>
                                    <Col md={9}>
                                        <label>Quiz</label>
                                        <Select
                                            mode="multiple"
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Please select quiz"
                                            onChange={(value) => onInputChange("quiz_id", value)}
                                            filterOption={(input, option) =>
                                                option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {allQuiz &&
                                                allQuiz.map((quiz, index) => (
                                                    <Option value={quiz.id}>{quiz.title}</Option>
                                                ))}
                                        </Select>
                                    </Col>
                                  
                                    <Col md={3}>
                                        <label>Tag</label>
                                        <Select
                                            mode="multiple"
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Please select agent"
                                            onChange={(e) => onInputChange("tags", e)}
                                            filterOption={(input, option) =>
                                                option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {tags.map((tag) => (
                                            <Select.Option key={tag} value={tag}>
                                                {tag}
                                            </Select.Option>
                                            ))}
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12} className="mt-2">
                                <Button className="float-right" style={{ marginLeft: "10px" }} onClick={() => exportReport()}> <AiOutlineDownload /> Export</Button>
                                <Button className="float-right" style={{ marginLeft: "10px" }} onClick={() => clearFilter()}> <AiOutlineClose /> Clear Filter</Button>
                                <Button className="float-right" onClick={() => filterReport()}> <AiOutlineSearch /> Filter</Button>
                                <RangePicker className="float-right" onChange={getDateFiler} />
                            </Col>
                        </Row>

                        <Divider />
                        <Table responsive hover className="reportTable">
                            <thead className="reportheading">
                                <tr>
                                    <th className="font-bold gray-text-200 text-center">Quiz Name</th>
                                    <th className="font-bold gray-text-200 text-center">Supervisor ID</th>
                                    <th className="font-bold gray-text-200 text-center">1st Attempt Date</th>
                                    <th className="font-bold gray-text-200 text-center">Completion Date</th>
                                    <th className="font-bold gray-text-200 text-center">Duration [Min]</th>
                                    <th className="font-bold gray-text-200 text-center">Number of Question</th>
                                    <th className="font-bold gray-text-200 text-center">Number of Answer</th>
                                    <th className="font-bold gray-text-200 text-center">Achieved Marks</th>
                                    <th className="font-bold gray-text-200 text-center">Percentage(%)</th>
                                    <th className="font-bold gray-text-200 text-center">LOB</th>
                                </tr>
                            </thead>
                            <tbody>
                                {quizReportData?.length > 0 && quizReportData.map((quizReport) => {
                                    return (<tr>
                                        <td className="text-center">{quizReport.quiz_title}</td>
                                        <td className="text-center">{quizReport.supervisor}</td>
                                        <td className="text-center">{moment(quizReport.first_attempt_time).isValid() ? moment(quizReport.first_attempt_time).format("DD/MM/YYYY") : '--'}</td>
                                        <td className="text-center">{quizReport.completion_time != 'N/A' ? moment(quizReport.completion_time).format("DD/MM/YYYY") : 'N/A'} </td>
                                        <td className="text-center">{quizReport.min_duration}</td>
                                        <td className="text-center">{quizReport.number_of_question}</td>
                                        <td className="text-center">{quizReport.number_of_answer}</td>
                                        <td className="text-center">{quizReport.achieved_marks}</td>
                                        <td className="text-center">{quizReport.achieved_marks_percent}</td>
                                        <td>
                                            {quizReport.groups.map((group) => {
                                                return (<Tag color="blue" key={group}>{group}</Tag>);
                                            })}
                                        </td>
                                    </tr>)

                                })}

                            </tbody>
                        </Table>
                        {paginateInfo && (
                            <Pagination defaultCurrent={1} total={paginateInfo.total} onChange={(page) => {
                                getQuizReport(filterDate, extraUrl, page);
                            }} />
                        )}
                    </div>
                </div>
             {/* )} */}
        </>
    )
}