import { List } from "antd";
import { AiOutlinePlayCircle } from "react-icons/ai";
import { BsEye } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { postApiRequest } from '../../api/CommonApi';
import { selectedFavouriteIds } from '../../services/actions/faq/SelectedFavourite';
import { urlChecker } from "../../utils/urlChecker";
import "./VideoCart.css";

export default function FavouriteVideoContent(props) {
  const dispatch = useDispatch();
  
  const BASEURL = urlChecker();

  async function handleFavourite(e, id, action) {
    dispatch(selectedFavouriteIds(id));
    const response = await postApiRequest("/api/favourite/unset", "", {
      fk_id: id,
      type: 2,
    });
    if (response.data.code === 200) {
      // e.classList.add("favourite");
      toast.success(response.data.message);
    } else {
      toast.error(response.data.message);
    }
    console.log(response.data);
    console.log("favourite", id);
  }
  let data = "";
  if (props.videos) {
    data = props.videos.map((video) => ({
      // const { id, title, description,views, isFavourite,tags } = video;
      title: video?.video?.title,
      totalview: video?.video?.views,
      description: video?.video?.description,
      posted: video?.video?.created_at,
      url: video?.video?.video_url,
      id: video?.video?.id,
      thumbnail: video?.video?.thumbnail,
    }));
  } else {
    data = props.videos;
  }
  return (
    <div className="video-cart">
      <div className="card">
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <div className="thumbnails">
                {item.thumbnail != null ? (
                  <img
                    src={BASEURL + item.thumbnail}
                    alt=""
                  />
                ) : (
                  ""
                )}
                <Link to={`/video-details/${item.id}`}>
                  <AiOutlinePlayCircle />
                </Link>
              </div>

              <div className="description">
                <span className="videoUploadTime">Posted at {item.posted}</span>
                <h3>{<a href="#">{item.title}</a>}</h3>
                <p className="videoText">{item.description}</p>
                <span>
                  <BsEye /> {item.totalview} views
                </span>
              </div>

              <svg
                className="resultsBookmarks"
                value={item.id}
                onClick={(e) => handleFavourite(e, item.id, "unfavourite")}
                width="16"
                height="22"
                viewBox="0 0 16 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.4518 0H0.644531C0.288578 0 0 0.288578 0 0.644531V21.3555C0 21.6162 0.157051 21.8512 0.397891 21.9509C0.477641 21.984 0.561387 22 0.644402 22C0.812109 22 0.976981 21.9346 1.10026 21.8112L7.54828 15.3632L13.996 21.8112C14.1804 21.9956 14.4576 22.0507 14.6984 21.9509C14.9393 21.8512 15.0963 21.6162 15.0963 21.3555V0.644531C15.0963 0.288578 14.8078 0 14.4518 0Z"
                  fill="#E59830"
                />
              </svg>
            </List.Item>
          )}
        />
      </div>
    </div>
  );
}
