import { ActionTypes } from "../../constants/ActionTypes";

export const setVideos = (videos) => {
    return {
        type: ActionTypes.SET_VIDEOS,
        payload: videos,
    };
}

export const selectedVideo = (video) => {
    return {
        type: ActionTypes.SELECTED_VIDEO,
        payload: video
    };
}

export const removeSelectedVideo = () => {
    return {
        type: ActionTypes.REMOVE_SELECTED_VIDEO,
    };
}

export const setFlagVideo = (flag) => {
    return {
        type: ActionTypes.SET_FLAG_VIDEO,
        payload: flag
    };
}