import React, { useEffect, useState } from "react";
import { Col, Row } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getApiRequest } from "../../api/CommonApi";
import AllCoursDescription from '../../components/elearning/allcoursdescription/AllCoursDescription';
import CoursesDetailsBannerCard from '../../components/elearning/coursesdetailsbannercard/CoursesDetailsBannerCard';
import QuickCall from '../../components/quickcall/QuickCall';
import {
    removeSelectedCourse, selectedCourse
} from "../../services/actions/CourseAction";
import './Elearning.css';


export default function ElearningCoursesDetails() {
    const { id } = useParams();
    console.log('my course id', id);

    const [cookies] = useCookies(['user']); 
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(true)

    const fetchCourseDetail = async (id) => {
        const response = await getApiRequest(`/api/courses/details/${id}`)
        console.log('course details response', response.data.data);
        dispatch(selectedCourse(response.data.data));
        setLoading(false);
    };

    useEffect(() => {
        if (id && id !== "") fetchCourseDetail(id);
        return () => {
          dispatch(removeSelectedCourse());
        };
      }, [id]);

    if (isLoading) {
    return <div>Loading ... </div>;
    } else {
    return (
        <div className="elearningCoursesDetails">
             {/* QuickCall-component  */}
             <QuickCall />
            <div className="elearningCoursesDetailsBannerCard">
                <CoursesDetailsBannerCard />
            </div>

            <Row className="margin-5">
                <Col md={8} className="ge-card">
                    <AllCoursDescription />
                </Col>
                <Col md={4}>
                    
                </Col>
            </Row>
        </div>
    )}
}
