import { ActionTypes } from "../constants/ActionTypes";

const initialState = {
    loginMethod: [],
}

export const loginMethodReducer = ( state = initialState, {type, payload}) => {
    switch(type){
        case ActionTypes.SELECTED_LOGIN_METHOD:
            return {...state, loginMethod:payload};
        default:
            return state;
    }
}