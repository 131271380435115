import './ElearnigFilter.css'
import { Checkbox, Divider, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { selectedLevelStore, selectedLanguageStore } from '../../../services/actions/common/LevelAction';
import { categoryTypeStore } from "../../../services/actions/faq/CategorySelectAction"
import { tagStore } from "../../../services/actions/faq/TagSelectedAction";

let tags;
let categories;
let tagList = '';
let levels;
let languages;

const { Option } = Select;
  
export default function ElearnigFilter(props) {

    const dispatch = useDispatch();

    if(props.tags){
        tags = props.tags;
    }

    if(props.categories){
        categories = props.categories.map((category) => ({
            label: category.title,
            value: category.id 
          }
      ));
    }

    if(props.levels){
        levels = props.levels.map((level) => ({
            label: level.title,
            value: level.id 
        }
        ));
    }

    if(props.languages){
        languages = props.languages.map((language) => ({
            label: language.title,
            value: language.id 
        }
        ));
    }


    if(tags){
    tagList = tags.map((tag)=>{
        return <Option value={tag.id} key={tag.id}>{tag.name}</Option>
    })
    }

    function onChangeCategory(checkedValues) {
        console.log('checked = ', checkedValues);
        dispatch(categoryTypeStore(checkedValues));
    }

    function handleChange(value) {
        console.log('selected tags', value)
        dispatch(tagStore(value));
    }

    function onChangeLevel(checkedValues) {
        console.log('checked = ', checkedValues);
        dispatch(selectedLevelStore(checkedValues));
    }

    function onChangeLanguage(checkedValues) {
        console.log('checked = ', checkedValues);
        dispatch(selectedLanguageStore(checkedValues));
    }


    return (
        <div className="elearnigFilter">
            <div className="card">
                <div className="filterDivision">
                    <span className="eFilterTitle">CATEGORY</span>
                    <Checkbox.Group options={categories} defaultValue={['General']} onChange={onChangeCategory} />
                </div>
                <Divider />
                <div className="filterDivision">
                <div className="searchFilterTag">
                <span className="eFilterTitle">Tag</span>
                <Select
                    className="searchfilterSelect"
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Please select Tag"
                    onChange={handleChange}
                    >
                      {tagList}
                </Select>
                </div>
                </div>
                <div className="filterDivision">
                    <span className="eFilterTitle">LEVEL</span>
                    <Checkbox.Group options={levels} defaultValue={['General']} onChange={onChangeLevel} />
                </div>
                <Divider />
                <div className="filterDivision">
                    <span className="eFilterTitle">LANGUAGE</span>
                    <Checkbox.Group options={languages} defaultValue={['General']} onChange={onChangeLanguage} />
                </div>
            </div>
        </div>
    )
}
