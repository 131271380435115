import { Input } from 'antd';
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useAsyncDebounce, useFilters, useGlobalFilter, usePagination, useTable } from 'react-table';
import { getApiRequest } from '../../api/CommonApi';
import FaqPreviousQ from '../../components/faq/FaqPreviousQ';
import QuickCall from '../../components/quickcall/QuickCall';
import './Faq.css';

const { TextArea } = Input;


    function GlobalFilter({
        preGlobalFilteredRows,
        globalFilter,
        setGlobalFilter,
    }) {
        const count = preGlobalFilteredRows.length
        const [value, setValue] = React.useState(globalFilter)
        const onChange = useAsyncDebounce(value => {
            setGlobalFilter(value || undefined)
        }, 200)

        return (
            <span>
                Search:{' '}
                <input
                    className="form-control react-datatable-control"
                    value={value || ""}
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={`${count} records...`}
                />
            </span>
        )
    }

    function DefaultColumnFilter({
        column: { filterValue, preFilteredRows, setFilter },
    }) {
        const count = preFilteredRows.length

        return (
            <input
                className="form-control react-datatable-control"
                value={filterValue || ''}
                onChange={e => {
                    setFilter(e.target.value || undefined)
                }}
                placeholder={`Search ${count} records...`}
            />
        )
    }

    function Table({ columns, data }) {

        const defaultColumn = React.useMemo(
            () => ({
                // Default Filter UI
                Filter: DefaultColumnFilter,
            }),
            []
        )

        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
            preGlobalFilteredRows,
            setGlobalFilter,
            state,
            page,
            canPreviousPage,
            canNextPage,
            pageOptions,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            setPageSize,
            state: { pageIndex, pageSize },
        } = useTable(
            {
                columns,
                data,
                defaultColumn,
                initialState: { pageIndex: 0, pageSize: 5 },
            },
            useFilters,
            useGlobalFilter,
            usePagination
        )

        return (
            <div>
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
               
                <table className="table" {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                        <div>{column.canFilter ? column.render('Filter') : null}</div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <br />
                {/* pagination */}
                <ul className="pagination">
                <li className="page-item" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    <Link className="page-link">First</Link>
                </li>
                <li className="page-item" onClick={() => previousPage()} disabled={!canPreviousPage}>
                    <Link className="page-link">{'<'}</Link>
                </li>
                <li className="page-item" onClick={() => nextPage()} disabled={!canNextPage}>
                    <Link className="page-link">{'>'}</Link>
                </li>
                <li className="page-item" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    <Link className="page-link">Last</Link>
                </li>
                <li>
                    <Link className="page-link">
                        Page{' '}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>{' '}
                    </Link>
                </li>
                
                <select
                    className="form-control react-datatable-control"
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                    style={{ width: '120px', height: '37px' }}
                >
                    {[5, 10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </ul>
                <br />
                <div>Showing the first 5 results of {rows.length} rows</div>
                
            </div>
        )
    }

    function MatrixBase() {
        const [dataSrc, setDataSrc] = useState('')
        const [column, setColumn] = useState('')
        const [faqs, setFaqs] = useState('');
        const { faqId } = useParams();
        useEffect(async () => {
            const response = await getApiRequest('/api/matrix/single-matrix', '', {id:faqId});
            console.log("wow",response.data.data.column);
            // return false;
            let data = [];
            let columnData = [];
            let associateHead = [];
            let formatData = [];
            let flag = 0;
            if(Object.keys(response.data.data).length > 0){
                    if(flag == 0){
                        JSON.parse(response.data.data.column).map((col) =>{
                            if(col){
                                let colData = {
                                'Header':col,
                                'accessor':col.toLowerCase()
                                }
                                columnData.push(colData);
                                associateHead.push(col.toLowerCase());
                            }
                        })
                        flag = 1;
                    }
                    JSON.parse(response.data.data.dataSource).map((val) => {
                        let obj = {};
                        val.map((v, index) => {
                            obj[associateHead[index]] = v; 
                        })
                        data.push(obj);
                    })
                setColumn(columnData);
                setDataSrc(data);
            }
        }, [])
        console.log('jei', dataSrc)
        return (
            <div className="matrixBase">
                <QuickCall />
                <div className="card">
                    {
                        (Object.keys(column).length > 0 && Object.keys(dataSrc).length)? (
                            <Table columns={column} data={dataSrc} />
                        ) :''
                    }
                </div>
                <div className="myConfusionsTable card">
                    <FaqPreviousQ/>
                </div>
            </div>
    )
}

export default MatrixBase;