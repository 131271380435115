import dotenv from "dotenv";
import React, { useEffect, useState } from "react";
import { getApiRequest, postApiRequest } from "../../api/CommonApi";
import { urlChecker } from "../../utils/urlChecker";
import VideoJS from './VideoJS';
dotenv.config();

function CourseStream(props) {
  const playerRef = React.useRef(null);
  const [chatFocus, setChatFocus] = useState(true);

  const posters = "images/slider/slide-1.jpg"
  const videoURL = props.fileUrl ? props.fileUrl : "";

  const BASEURL = urlChecker();

  const fullPath = BASEURL + videoURL;
  const [activeLesson, setActiveLesson] = useState();

  useEffect(() => {
      window.addEventListener('focus', handleActivityTrue);
      window.addEventListener('blur', handleActivityFalse);
  }, [chatFocus]);

  useEffect(() => {
    setActiveLesson(props.lesson);
    //changePlayerOptions(props.lesson)
  }, [activeLesson]);

  if(activeLesson?.show_progressbar == 0) {
    require('./Videoplay.css');
  }
  console.log('pros lesson activeLesson', activeLesson);
  const handleActivityFalse = () => {
    setChatFocus(false);
    //if(activeLesson){
     const player = playerRef.current;
     (player && player.player_ !=null) ? player?.pause() : '';
    //}
  };

  const handleActivityTrue = () => {
    setChatFocus(true);
   // if(activeLesson){
    const player = playerRef.current;
    (player && player.player_ !=null) ? playerRef.current?.play() : '';
   // }
  };

  const videoJsOptions = { 
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    aspectRatio: '16:9',
    poster: activeLesson ? "/images/microphone-icon.jpg" : "" ,
   // poster: "/images/microphone-icon.jpg",
    sources: [{
      src: fullPath,
      type: activeLesson?.type == 'video' ? 'video/mp4' : 'audio/mp3'
    }]
  }

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    player.on('waiting', () => {
      console.log('player is waiting');
    });

    player.on('dispose', () => {
      console.log('player will dispose');
    });

    player.on('play', async () => {
      if(props.lesson){
        console.log('player start');
        const response = await getApiRequest(`/api/courses/lesson/${props?.lesson?.id}`);
        console.log('return start response', response);
      }
    });

    player.on('pause', async () => {
      const data = {
        progress_status: 'pause',
        watch_time: player.currentTime(),
        lesson_id: props?.lesson?.id
      };
      if(props.lesson){
        console.log('player pause');
        const response = await postApiRequest('/api/courses/lesson-complete-status', '', data);
      }
    });

    player.on('ended', async () => {
      const data = { 
        progress_status: 'end',
        watch_time: player.currentTime(),
        lesson_id: props?.lesson?.id
      };
      console.log('player will end', data);
      if(data.lesson_id){
        console.log('player end');
      const response = await postApiRequest('/api/courses/lesson-complete-status', '', data);
      }
    });
  };

  const changePlayerOptions = (lesson) => {
    // you can update the player through the Video.js player instance
    if (!playerRef.current) {
      return;
    }
    // [update player through instance's api]
    playerRef.current.src([{src: lesson.file, type: 'video/mp4'}]);
    playerRef.current.autoplay(false);
  };


  return (
    <>
      <VideoJS 
      options={videoJsOptions} 
      onReady={handlePlayerReady} 
      />
    </>
  );
}

export default CourseStream;