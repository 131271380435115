import { Spin } from 'antd';
import React from 'react';
import "./Preloader.css";

export default function Preloader() {
    return (
        <div className="preloader">
            {/* <p>Loading...</p> */}
            <Spin tip="Loading..."></Spin>
        </div>
    )
}
