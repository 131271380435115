import { ActionTypes } from "../../constants/ActionTypes";

const initialState = {
    initState: [],
}

export const changeAbleValueReducer = ( state = initialState, {type, payload}) => {
    switch(type){
        case ActionTypes.CHANGE_ABLE_VALUE:
            return {...state, availableValue:payload};
        default:
            return state;
    }
}
