import { useParams } from "react-router";
import QuickCall from "../../components/quickcall/QuickCall";
import { WebinarDetails } from "../../components/webinars/WebinarDetails";
import "./Webinar.css";

export const WebinarShow = () => {
  const { webinar_id } = useParams();
  return (
    <div className="allQuiz">
      <QuickCall />

      <WebinarDetails webinar_id={webinar_id} />
    </div>
  );
};
