import { SearchOutlined } from "@ant-design/icons";
import { Collapse, Divider, Input, Pagination } from "antd";
import { useEffect, useState } from "react";
import { getApiRequest } from "../../api/CommonApi";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import Preloader from "../../components/common/Preloader";
import QuickCall from "../../components/quickcall/QuickCall";
import "./InterActiveDemo.css";

const { Panel } = Collapse;

function callback(key) {
  console.log(key);
}

export const InterActiveDemo = () => {
  const [ussd, setUssd] = useState("");
  const [isLoad, setIsLoad] = useState(false);
  const [name, setName] = useState("");
  const [paginateInfo, setPaginateInfo] = useState(null);

  const loadResources = async (page = 1) => {
    const param = {
      name: name,
      for: 'agent',
      page: page,
    };
    const response = await getApiRequest("/api/ussd-app/index", "", param);
    setUssd(response.data.data.data);
    setIsLoad(true);
    setPaginateInfo(response.data.data);
  }

  useEffect(() => {
    loadResources();
    document.title = "Interactive Demo";
  }, [name]);

  const onChangeSearch = async (e) => {
    setName(e.target.value);
  };

  return (
    <div className="interActiveDemo">
      <QuickCall />

      <BreadCrumb link="FAQ-Library/Interactive-Demo" /><br/>

      <h2 className="interactivedemoTitle">interactive demo</h2>
      <Divider />
      <div className="interActiveDemoBody">
        <div className="interActiveDemoSearch card">
          <Input
            size="large"
            placeholder="Search ..."
            onChange={onChangeSearch}
            prefix={<SearchOutlined />}
          />
        </div>
        <div className="interActiveDemoCollapse">
          {/* <Collapse onChange={callback}>
                    {
                        isLoad == false ? <Preloader/> : (
                            ussd.map((app, index) =>{
                                return (
                                    <Panel header={app.name} key={index}>
                                    <div className="content">
                                        <h2 className="collapseTitle">action :</h2>
                                        <Divider/>
                                        <ui className="collapseList">
                                            {
                                                app.actions.map((action) =>{
                                                    return (
                                                        <li className="collapseListItem"><Link to={`/interactive-views/${app.id}`}>{action.action_name}</Link></li>
                                                    )
                                                })
                                            }
                                        </ui>
                                    </div>
                                    <Divider/>
                                    </Panel>
                                )
                            })
                        )
                    }
                    
                </Collapse> */}

          {isLoad == false ? (
            <Preloader />
          ) : (
            ussd.map((app, index) => {
              return (
                <a href={`/interactive-views/${app.id}`}>
                  <p className="card ussdPan" key={index}>
                      {app.name}
                  </p>
                </a>
              );
            })
          )}
        </div>
      </div>
      {paginateInfo && (
          <Pagination
            defaultCurrent={1}
            total={paginateInfo.total}
            showTotal={(total) => `Total ${total} items`}
            onChange={(p) => {
              loadResources(p);
            }}
          />
        )}
    </div>
  );
};
