import { Button, Divider, Modal, Progress, Rate, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect, useState } from "react";
import { useCookies } from 'react-cookie';
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { getApiRequest, postApiRequest } from '../../../api/CommonApi';
import './AllCoursDescription.css';


const { Option } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}

export default function Feedback() {
    const [cookies] = useCookies(['user']); 
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [myRating, setMyRating] = useState(5);
    const [myIntructorRating, setMyIntructorRating] = useState(5);
    const [courseFeedback, setCourseFeedback] = useState([]);
    const [feedbackMessage, setFeedbackMessage] = useState();
    const [newFeedback, setNewFeedback] = useState();
    const [sort, setSort] = useState('latest');
    const [searchText, setSearchText] = useState();
    const { id } = useParams();

    let course = useSelector((state) => state.course);

    const user = JSON.parse(localStorage.getItem("user_information"));

    useEffect( () => {
         fetchCourseFeedback();
    }, [newFeedback, sort, searchText]);

    const fetchCourseFeedback = async () => {
      const params = {
        feedbacksearch: searchText,
        sort: sort
      }
      const response = await getApiRequest(`/api/courses/course-feedbacks/${id}`, '', params);
       const feedbackList = response?.data?.data && response?.data?.data?.length > 0 && response?.data?.data.map((feedback)=>{
          return {
              name: feedback?.user?.name,
              feedback: feedback?.feedback,
              rating: feedback?.rating,
              created_at: feedback?.created_at
            }
        });

        setCourseFeedback(feedbackList ? feedbackList : []);

    }

    const storeAgentFeedback = async () => {
        const data = { 
            course_id: id,
            user_id: user?.id,
            rating: myRating,
            instructor_rating: myIntructorRating,
            feedback: feedbackMessage
        };
        console.log('my all feedback data', data)

        const response = await postApiRequest('/api/course-feedback/create', '', data);
        console.log('my all feedback response', response)
        toast.success(response?.data?.message);
        setNewFeedback(response?.data?.data)
       
    };

    function showModal(){
        setVisible(true);
    }

    function handleOk() {
        if (!myRating) {
            return toast.error("Course Rating Field must not be empty")
        }
        if (!myIntructorRating) {
            return toast.error("Instructor Rating Field must not be empty")
        }
        if (!feedbackMessage) {
            return toast.error("Message Field must not be empty")
        }
        setVisible(false);
        setLoading(false);
        storeAgentFeedback();
    };
    
    function handleCancel() {
        setVisible(false);
    };

    function handleRating(value){
        setMyRating(value);
    }

    function handleInstructorRating(value){
        setMyIntructorRating(value);

    }

    function onFeedbackMessage(e){
        setFeedbackMessage(e.target.value)
    }

    const handleDropdown = (e) => {
        console.log('my course sorting', e)
        setSort(e);
    }

    const handleSearch = (e) => {
        console.log('search test', e.target.value)
        setSearchText(e.target.value);
    }
    

    return (
            <div className="info">
                <h3 className="allCoursDescriptionTitle"> Feedback</h3>
                <Divider />
                <div className="allCoursDescriptionFeedback">
                    <div className="left">
                        <span className="allCoursDescriptionRatingPoint">{course.rating ? course.rating.toFixed(1) : 0}</span>
                        <span className="allCoursDescriptionRatingStar">
                            <Rate allowHalf defaultValue={course.rating ? course.rating : 0} />
                            Course Rating
                        </span>
                    </div>
                    <div className="right">
                        <ul className="rightList">
                            <li className="rightItem">
                                <Progress percent={course?.rating_info?.five > 0 ? course?.rating_info?.five : 0} />
                                <span className="allCoursDescriptionProgressStar">
                                <Rate disabled defaultValue={5} />
                                </span>
                            </li>
                            <li className="rightItem">
                                <Progress percent={course?.rating_info?.four > 0 ? course?.rating_info?.four : 0} />
                                <span className="allCoursDescriptionProgressStar">
                                <Rate disabled defaultValue={4} />
                                </span>
                            </li>
                            <li className="rightItem">
                                <Progress percent={course?.rating_info?.three > 0 ? course?.rating_info?.three : 0} />
                                <span className="allCoursDescriptionProgressStar">
                                <Rate disabled defaultValue={3} />
                                </span>
                            </li>
                            <li className="rightItem">
                                <Progress percent={course?.rating_info?.two > 0 ? course?.rating_info?.two : 0} />
                                <span className="allCoursDescriptionProgressStar">
                                <Rate disabled defaultValue={2} />
                                </span>
                            </li>
                            <li className="rightItem">
                                <Progress percent={course?.rating_info?.one > 0 ? course?.rating_info?.one : 0} />
                                <span className="allCoursDescriptionProgressStar">
                                <Rate disabled defaultValue={1} />
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>

                {/* <h3 className="allCoursDescriptionTitle">Reviews</h3>
                <Divider />
                <div className="allCoursDescriptionSearchFilter">
                    <div className="left">
                        <Input placeholder="Search Reviews" onKeyDown={handleSearch} prefix={<HiOutlineSearch />} />
                    </div>
                    <div className="right">
                        <Select defaultValue="All Ratings" style={{ width: 120 }} onChange={handleDropdown}>
                        <Option value="latest">Latest</Option>
                        <Option value="older">Older</Option>
                        </Select>
                    </div>
                </div>

                <Button shape="round" className="mb-3" onClick={showModal}>Add Feedback</Button>
                <List
                    className="allCoursDescriptionRatingList"
                    itemLayout="horizontal"
                    dataSource={courseFeedback}
                    renderItem={item => (
                    <List.Item>
                        {
                            <div className="istItem">
                                <div className="img">
                                    <Avatar icon={<UserOutlined />} />
                                </div>
                                <div className="info">
                                    <h4 className="listItemTitle">{item.name}</h4>
                                    <span className="listItemRatings">
                                        <Rate allowHalf disabled defaultValue={item.rating} />
                                        <Tooltip title={moment(item.created_at).format('DD-MM-YYYY hh:mm A')}>
                                            <span>{moment(item.created_at).fromNow()}</span>
                                        </Tooltip>
                                    </span>
                                    <span className="listItemDes">{item.feedback}</span>
                                </div>
                            </div>

                            
                        }
                        
                    </List.Item>
                    )}
                /> */}

                {/* <button className="allCoursDescriptionLoadMoreButton">See More Reviews</button> */}


            <Modal
            visible={visible}
            title="Add Feedback"
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                Return
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                Submit
                </Button>,
               
            ]}
            >
            <div>Rate Course : <Rate allowHalf defaultValue={5} onChange={handleRating} value={myRating}/></div>
            <div>Rate Instructor : <Rate allowHalf defaultValue={5} onChange={handleInstructorRating} value={myIntructorRating}/></div>
            <h5>Message</h5>
            <TextArea rows={3} onChange={onFeedbackMessage} value={feedbackMessage}/>
            </Modal>

        </div>
    )
}
