import { Collapse, Divider } from 'antd';
import { useState } from 'react';
import { AiOutlinePlayCircle } from 'react-icons/ai';
import { VscCircleFilled } from 'react-icons/vsc';
import './RightAccordion.css';


const { Panel } = Collapse;

function callback(key) {
  console.log(key);
}

export default function RightAccordion(props) {

    const course = props.course;
    console.log('right accordien', course);
    const [courseTotalTime, setCourseTotalTime] = useState(0);

    var totalTimeInMin = course?.total_course_duration ?? 0;
    let mHours = Math.floor(totalTimeInMin / 60);
    let mMinutes = totalTimeInMin % 60;

    return (
        <div className="myCourseDetailsRightAccordion">
            <span className="myCourseDetailsRightAccordionTitle">Courses Content</span>
            <Divider />
            <div className="myCourseDetailsRightAccordionDetails">
                <ul className="RightAccordionDetailsList">
                    <li className="RightAccordionDetailsItem">{course && course.course_sections ? course.course_sections.length : 0} Sections</li>
                    <li className="RightAccordionDetailsItem"><VscCircleFilled /> {course && course.course_lessons ? course.course_lessons.length : 0} Lessons</li>
                    <li className="RightAccordionDetailsItem"><VscCircleFilled /> {mHours} hours {mMinutes} mins</li>
                </ul>
            </div>
            <div className="myCourseDetailsRightAccordionArea">
                <Collapse defaultActiveKey={['1']} onChange={callback}>
                { course && course.course_sections.map((section, i) => (
                    <Panel 
                        header={
                            <div className="myCourseDetailsRightAccordionHeading">
                                <div className="info">
                                    <h3 className="myCourseDetailsRightAccordionHeadingTitle">{section.title}</h3>
                                    {/* <span className="myCourseDetailsRightAccordionTime"><VscCircleFilled /> {course?.course?.total_course_duration} min</span> */}
                                </div>
                            </div>
                        }
                        key={i+1}>
                       
                        <ul className="rightAccordionDscList">
                            {section.section_lessons.length > 0 && 
                            <li className="rightAccordionDscItem sectionLevel">Course Lesson</li>
                            }
                            { section.section_lessons && section?.section_lessons.map((lesson, i) => (
                            <li className="rightAccordionDscItem" onClick={() => showContent(lesson)} key={i}>
                                <span className={`rightAccordionDscItemTitle ${(lesson?.lesson_status?.status != 'completed') ? 'font-weight-bold' : ''}`} ><AiOutlinePlayCircle /> {lesson?.title}</span>
                                <span className="rightAccordionDscItemTime">{lesson.duration} min</span>
                            </li>
                            ))}

                            {section.section_quizzes.length > 0 && 
                            <li className="rightAccordionDscItem sectionLevel">Course Quiz</li>
                            }
                            
                            { section.section_quizzes && section?.section_quizzes.map((quiz, i) => (
                            <li className="rightAccordionDscItem" onClick={() => startCourseQuiz(quiz)} key={i}>
                                <span className={`rightAccordionDscItemTitle ${(quiz?.quiz_status?.status != 'completed') ? 'font-weight-bold' : ''}`} ><AiOutlinePlayCircle /> {quiz?.quiz?.title}</span>
                                <span className="rightAccordionDscItemTime">{quiz?.quiz?.quiz_duration} min</span>
                            </li>
                            ))}

                            {section.section_quizzes.length > 0 && 
                            <li className="rightAccordionDscItem sectionLevel">Course Resources</li>
                            }
                            
                            { section.section_resources && section?.section_resources.map((resource, i) => (
                            <li className="rightAccordionDscItem"  onClick={() => showContent(resource)} key={i}>
                                <span className={`rightAccordionDscItemTitle font-weight-bold`}><AiOutlinePlayCircle /> {resource?.title}</span>
                                <span className="rightAccordionDscItemTime">{resource?.resource?.duration} min</span>
                            </li>
                            ))}
                        </ul>
                    </Panel>
                    ))}
                   
                    { course.other_resources  &&
                    <Panel 
                        header={
                            <div className="myCourseDetailsRightAccordionHeading">
                                <div className="info">
                                    <h3 className="myCourseDetailsRightAccordionHeadingTitle">Other Resources</h3>
                                </div>
                            </div>
                        }
                        key={3}>
                        <ul className="rightAccordionDscList">
                            { Object.keys(course.other_resources?.other_lessons).length>0 && 
                            <li className="rightAccordionDscItem sectionLevel">Course Lesson</li>
                            }
                            
                            { Object.values(course.other_resources?.other_lessons).map((lesson, i) => (
                                <li className="rightAccordionDscItem" onClick={() => showContent(lesson)} key={i}>
                                <span className={`rightAccordionDscItemTitle ${(lesson?.lesson_status?.status != 'completed') ? 'font-weight-bold' : ''}`} ><AiOutlinePlayCircle /> {lesson?.title}</span>
                                <span className="rightAccordionDscItemTime">{lesson.duration} min</span>
                            </li>
                            ))}

                            { Object.keys(course.other_resources?.other_quizzes).length>0 && 
                            <li className="rightAccordionDscItem sectionLevel">Course Quiz</li>
                            }
                            
                            { Object.values(course.other_resources.other_quizzes).map((quiz, i) => (
                            <li className="rightAccordionDscItem" onClick={() => startCourseQuiz(quiz)} key={i}>
                                <span className={`rightAccordionDscItemTitle ${(quiz?.quiz_status?.status != 'completed') ? 'font-weight-bold' : ''}`} ><AiOutlinePlayCircle /> {quiz?.quiz?.title}</span>
                                <span className="rightAccordionDscItemTime">{quiz?.quiz?.quiz_duration} min</span>
                            </li>
                            ))}

                            { Object.keys(course.other_resources?.other_resources).length>0 &&
                            <li className="rightAccordionDscItem sectionLevel">Course Resources</li>
                            }
                            
                            { Object.values(course.other_resources?.other_resources).map((resource, i) => (
                            <li className="rightAccordionDscItem"  onClick={() => showContent(resource)} key={i}>
                                <span className={`rightAccordionDscItemTitle font-weight-bold`}><AiOutlinePlayCircle /> {resource?.title}</span>
                            </li>
                            ))}
                        </ul>
                    </Panel>
                    }
                    
                    {/* <button className="myCourseDetailsRightAccordionMoreButton">more view</button> */}
                </Collapse>
            </div>
        </div>
    )
}
