import { Divider } from 'antd';
import moment from "moment";
import { useEffect, useState } from 'react';
import { BsBell } from 'react-icons/bs';
import { getApiRequest } from '../../api/CommonApi';
import QuickCall from '../../components/quickcall/QuickCall';
import './Notifications.css';


export const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState([]);

    useEffect(() => {
        fetchUserNotifications();
        fetchUnreadNotifications();
      }, []);
  
      const fetchUserNotifications = async () =>{
        const response = await getApiRequest('/api/user/notifications');
        setNotifications(response?.data);
      };


    const fetchUnreadNotifications = async () => {
        const response = await getApiRequest("/api/user/notifications/unread");
        setUnreadNotifications(response?.data);
    };

      const handleStatus = async (notification) => {
          const response = await getApiRequest('/api/user/notification/read/' + notification.id);
          fetchUserNotifications();
      }
      
    return (
        <div className="notificationsPage">
            <QuickCall/>

            <h3>Notifications</h3>
            <Divider/>

            <ul className="notificationsList">
                {notifications && notifications.map((notification, i) => (
                    <li className="notificationsListItem">
                       <div className="card">
                           <div className="notificationsCard">
                           <div className="left"><BsBell/></div>
                           <div className="mid" onClick={() => handleStatus(notification)}>
                               <h3 className={`title ${notification.read_at ? 'readme' : ''}`}>{notification?.data?.subject}</h3>
                               <p className="subtitle">{notification?.data?.message}</p>
                           </div>
                           <div className="right">
                               <span>
                                   {notification.created_at ? moment.utc(notification.created_at).local().format("hh:mm A DD MMMM YYYY ") : ''}
                               </span></div>
                           </div>
                       </div>
                   </li>
                ))}   
                
            </ul>
        </div>
    )
}
