import { ActionTypes } from "../../constants/ActionTypes"

const initialState = {
    levels: [],
    languages: []
}

export const levelReducer = ( state = initialState, {type, payload}) => {
    switch(type){
        case ActionTypes.SELECTED_LEVEL:
            return {...state, levels:payload};
        default:
            return state;
    }
}

export const languageReducer = ( state = initialState, {type, payload}) => {
    switch(type){
        case ActionTypes.SELECTED_LANGUAGE:
            return {...state, languages:payload};
        default:
            return state;
    }
}

export const newQAReducer = ( state = initialState, {type, payload}) => {
    switch(type){
        case ActionTypes.SET_NEW_QUESTION:
            return {...state, newQA:payload};
        default:
            return state;
    }
}