import { Card, Divider, Select } from "antd";
import dotenv from "dotenv";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { pdfjs } from "react-pdf";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getApiRequest, postApiRequest } from "../../api/CommonApi";
import { urlChecker } from "../../utils/urlChecker";
import "./Preshift.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
dotenv.config();
const { Option } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}

let searchText = "";

export default function PreShiftBriefing() {
  // const dispatch = useDispatch();
  const { preshift_id } = useParams();
  const history = useHistory();
  const [cookies] = useCookies(["user"]);
  const [preShift, setPreShift] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [thePdf, setThePdf] = useState(null);

  const BASEURL = urlChecker();

  useEffect(() => {
    getActivePreshiftBriefing();
  }, []);

  const getActivePreshiftBriefing = async () => {
    const response = await getApiRequest(
      "/api/preshift-briefings/show?id=" + preshift_id
    );
    console.log("my preshift response", response);

    setPreShift(response?.data?.data);
    // const thePdf = await getApiRequest(`/api/get-pdf`, "");
    setThePdf(response?.data?.data?.file);
  };

  const startPreshiftQuiz = (id, preShift_id) => {
    postApiRequest(`/api/quiz-attempt/start`, "", {
      quiz_id: id,
      pre_shift_briefing_id: preShift_id,
    })
      .then((res) => {
        if (!res.data.data) {
          toast.error("Quiz already attend!");
          return false;
        }
        console.log("preshift quiz start status", res.status);
        let firstQuestion = res.data.data.question;
        let attempt = res.data.data.attempt;
        res.status === 200 &&
          history.push(`/quiz-attempt/${attempt.id}/${firstQuestion.id}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const showMedia = (file) => {
    // get file extension
    let extension = file.split(".").pop();
    if (extension === "mp3") {
      return (
        <audio controls>
          <source
            src={BASEURL + file}
            type="audio/mpeg"
          />
        </audio>
      );
    } else if (extension === "mp4") {
      return (
        <video controls style={{ width: "100%" }}>
          <source
            src={BASEURL + file}
            type="video/mp4"
          />
        </video>
      );
    } else {
      return <h2>No media found</h2>;
    }
  };

  console.log("preshift pdf add console", preShift);

  return (
    preShift && (
      <div className="elearnigMyCourses">
        <span className="elearnigAllCourseTitle">Preshift Briefing</span>
        <Divider className="featuredDivider" />

        <Row>
          <Col md={12} className="content-display mb-4 ">
            <div style={{ marginBottom: "10px" }}>
              {preShift.file.split(".").pop() == "pdf" ? (
                <>
                  <iframe
                    src={BASEURL + preShift.file}
                    frameborder="0"
                    width="100%"
                  ></iframe>
                </>
              ) : (
                showMedia(preShift.file)
              )}
            </div>
            <Card>
              {/* {preShift && preShift.created_at && (
              <p>
                <strong>Date</strong> :{" "}
                {moment(preShift.created_at).format("DD MMMM YYYY")}
              </p>
            )} */}
              {preShift && preShift.created_by && (
                <p className="text-capitalize">
                  <strong>Created By </strong>: {preShift.created_by.name}
                </p>
              )}
              {preShift && preShift.content && (
                <p>
                  <strong>Note </strong>: {preShift.content}
                </p>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    )
  );
}
