import { Input, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getApiRequest, postApiRequest } from "../../api/CommonApi";
import "./QuickCall.css";

const { TextArea } = Input;
const { Option } = Select;

export const Customer = () => {
  const cookies = useCookies();
  const [allCourse, setAllCourse] = useState([]);
  let calltrack = useSelector((state) => state.incomingCallInfo.calltrack);
  const [visible, setVisible] = useState(false);
  const [faqs, setFaqs] = useState([]);
  const [customerInsight, setCustomerInsight] = useState({
    faq_id: "",
    customer_insight_decription: "",
  });

  useEffect(() => {
    fetchFaqs();
  }, []);

  const fetchFaqs = async () => {
    const params = {};
    const response = await getApiRequest(
      "/api/faq/show-all-faq-for-general",
      "",
      params
    );
    setFaqs(response?.data?.data);
  };

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
  };

  function onInputChange(value, field) {
    setCustomerInsight({ ...customerInsight, [field]: value });
  }

  const onSubmit = async (e) => {
    if (!customerInsight.customer_insight_decription) {
      return toast.warning("Field must not be empty!");
    }
    const data = {
      faq_id: customerInsight.faq_id,
      customer_insight_decription: customerInsight.customer_insight_decription,
    };
    const response = await postApiRequest(
      "/api/customer-insight/store",
      "",
      data
    );
    toast.success("Customer Insight has been created");
    console.log("Customer Insight", response);
    setVisible(false);
    setCustomerInsight({});
  };

  const onClickCustomerInsight = () => {
    setCustomerInsight({
      ...customerInsight,
      ["faq_id"]: "",
      ["customer_insight_decription"]: "",
    });
    setVisible(true);
  };

  return (
    <div className="message">
      <button className="btn" onClick={() => onClickCustomerInsight()}>
        customer insight
      </button>

      <Modal
        className="messageModal"
        title="Customer Insight"
        centered
        visible={visible}
        onCancel={() => setVisible(false)}
        width={800}
      >
        <div className="messageModalBody">
          <div className="callTypes">
            <span className="text">FAQ:</span>
            <Select
                showSearch
                allowClear
                placeholder={"select faq"}
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: 300 }}
                onChange={(value) => onInputChange(value, "faq_id")}
                value={customerInsight?.faq_id}
            >
              {faqs &&
                faqs.map((faq, index) => (
                  <Option value={faq.id} key={faq.id}>
                    {faq.title}
                  </Option>
                ))}
            </Select>
          </div>
          <div className="textarea">
            <TextArea
              placeholder="Type Aa.."
              rows={4}
              onChange={(e) =>
                onInputChange(e.target.value, "customer_insight_decription")
              }
              value={customerInsight?.customer_insight_decription}
            />
          </div>
          <div className="text">
            <button onClick={(e) => onSubmit(e)} className="btn">
              send
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
