import { ActionTypes } from "../constants/ActionTypes";

const initialState = {
    types: [],
}

export const typeReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.TYPE_SELECTED:
            return {...state, types: payload };
        default:
            return state;
    }
}