import { Button, Divider, Input } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { RiQuestionAnswerLine } from 'react-icons/ri';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getApiRequest, postApiRequest } from '../../api/CommonApi';
import Preloader from '../common/Preloader';
import './Faq.css';

const { TextArea } = Input;


export default function MenuQ(props) {
    const faqId = props?.faqId;
    const [cookies] = useCookies(['user']);
    const [post, setPost] = useState('');
    const [questions, setQuestions] = useState('');
    const [createQuestion, setCreateQuestion] = useState('');
    const [isLoad, setIsLoad] = useState(false)
    useEffect(async () => {
        if(faqId!=null) {
            const params = {
                faq_id: faqId
            }
            const response = await getApiRequest('/api/faq/get-my-confusion', cookies.access_token, params);
            setQuestions(response.data);
            setIsLoad(true);
        }
    }, [createQuestion])

    const handleChange = (e) => {
        setPost(e.target.value);
    }
    const handleSubmission = async (e) => {
        const data = {
            title: post,
            faq_id: faqId
        }
        const response = await postApiRequest('/api/faq/confusion-create', cookies.access_token, data);
        setCreateQuestion(response.data);  
        toast(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            }); 
    }
    return (
        isLoad == false ? (
            <Preloader/>
        ):(
        <div className="faqPreviousQuestions">
            <div className="card">
                <span className="faqPreviousQTitle">My Previous Questions :</span>
                <Divider />
                {
                    Object.keys(questions).length > 0 ?(
                        questions.map((question) => {
                            return (
                                <ul className="faqPreviousQandAns">
                                    <li className="questionTime">
                                        <span className="qandAns"> <AiOutlineQuestionCircle />{question.title}</span>
                                        <span className="qandAnsTime">Asked : {moment(question.created_at).format('MMMM Do YYYY, h:mm:ss a')}</span>
                                    </li>
                                    {
                                        question.detail !== null ?
                                        (<li className="questionTime">
                                            <span className="qandAns"> <RiQuestionAnswerLine /> {question.detail}</span>
                                            <span className="qandAnsTime">Answered : {moment(question.updated_at).subtract(6, 'days').calendar()}</span>
                                        </li>):''
                                        
                                    }
                                </ul>
                            )
                        })
                    ):''
                }
                <Divider />
                <TextArea className="faqPrevQPostTextBox" placeholder="Type your question here..." allowClear autoSize={{ minRows: 2, maxRows: 6 }} onChange={handleChange}/>
                <Button type="button" className="faqPrevQPostbutton" onClick={handleSubmission} >post</Button>
                <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover/>
            </div>
        </div>
        )
    )
}
