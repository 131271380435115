import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import NewTestCom from '../../components/newtestcom/NewTest'
import axios from 'axios'
import { setProducts } from '../../services/actions/Action'

const NewTest = () => {
    const products = useSelector((state) => state);
    const dispatch = useDispatch();

    const fetchProducts = async() => {
        const response = await axios.get("https://fakestoreapi.com/products")
        .catch((error) => {
            console.log("error", error)
        });
        dispatch(setProducts(response.data));
    };
    useEffect(()=>{
        fetchProducts();
    }, []);
    console.log("products:", products);

   
    return (
        <div>
            {/* {renderProductsHere} */}
            <NewTestCom />
        </div>
    )
}

export default NewTest
