import { Card } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { AiOutlinePlayCircle, AiOutlineStar } from "react-icons/ai";
import { FiUsers } from "react-icons/fi";
import { MdRateReview } from "react-icons/md";
import { VscFileSubmodule } from "react-icons/vsc";
import { getApiRequest, postApiRequest } from "../../api/CommonApi";
import JitsiMeetComponent from "../../pages/webinar/JitsiMeetComponent";
import { urlChecker } from "../../utils/urlChecker";
import "./Webinar.css";

export const WebinarDetails = (props) => {
  const BASEURL = urlChecker();
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const webinar_id = props.webinar_id;
  const [webinar, setWebinar] = useState(null);
  const [showLiveLink, setShowLiveLink] = useState(false);

  useEffect(() => {
    getApiRequest(`/api/webinar/show/${webinar_id}`, "")
      .then((response) => {
        console.log(response?.data?.data);
        setWebinar(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [webinar_id]);

  const handleShowLiveLink = () => {
    webinarAttend()
    setShowLiveLink(!showLiveLink);
  };

  const webinarAttend = async () => {
    const webiner_participated_id = cookies?.webiner_participated_id ? cookies?.webiner_participated_id : 0;
    const data = {
      webinar_id: webinar_id,
      webiner_participated_id: webiner_participated_id
    };

    const response = await postApiRequest(
      "/api/webinar/attend",
      "",
      data
    );
    console.log(" user webinar status", response);
    if(response?.data?.status == 'success'){
      setCookie("webiner_participated_id", response?.data?.data?.id, { path: "/" });
    }else{
      //removeCookie("webiner_participated_id");
    }
  };

  return (
    <>
      {showLiveLink ? (
        <div>
          <button className="btn btn-info" onClick={() => handleShowLiveLink()}>
            Close
          </button>
          <JitsiMeetComponent webinar={webinar}/>
          {/* <div
            dangerouslySetInnerHTML={{ __html: webinar && webinar.embed_link }}
          ></div> */}
        </div>
      ) : (
        <div>
          <Row>
            {webinar && (
              <Col span={12}>
                <Card
                  cover={
                    <div className="elarnigAllCoursCardThumbnails">
                      <img
                        alt="example"
                        src={BASEURL + webinar.banner_img}
                        style={{ width: "100%" }}
                      />

                      <AiOutlinePlayCircle
                        onClick={() => handleShowLiveLink()}
                        className="elarnigAllCoursCardPlaybtn"
                      />
                    </div>
                  }
                >
                  <p style={{ color: "#8D8787" }}>
                    Posted at {moment(webinar.created_at).format("LLL")} | Last
                    updated {moment(webinar.updated_at).fromNow()}
                  </p>
                  <h6>{webinar.title}</h6>
                  <p>{webinar.description}</p>
                  <br />
                  <h6>Host Details</h6>
                  <hr />
                  <div>
                    <h3 className="elearnigMyCourseDetailsSubTitle">
                      {webinar.instructor.name}
                    </h3>
                    <h4 className="elearnigMyCourseDetailsSubTitle2">
                      E-Learning
                    </h4>
                    <div className="elearnigMyCourseDetailsThumbnails">
                      <div className="img">
                        <img src="/images/user.jpg" alt="" />
                      </div>
                      <div className="info">
                        <ul className="list">
                          <li className="listItem">
                            <AiOutlineStar /> 4.4 Instructor Rating
                          </li>
                          <li className="listItem">
                            <MdRateReview /> {webinar.instructor.total_feedback}
                            Reviews
                          </li>
                          <li className="listItem">
                            <FiUsers /> {webinar.instructor.total_enrolled}
                            Students
                          </li>
                          <li className="listItem">
                            <VscFileSubmodule />{" "}
                            {webinar.instructor.total_course}
                            Courses
                          </li>
                        </ul>
                      </div>
                    </div>
                    <p className="elearnigMyCourseDetailsText">
                      {webinar.instructor.bio}
                    </p>
                  </div>
                </Card>
              </Col>
            )}
          </Row>
        </div>
      )}
    </>
  );
};
