import { Divider, Select } from 'antd';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { getApiRequest } from '../../api/CommonApi';
import TrainerCourseCard from '../../components/elearning/allCourseCard/TrainerCourseCard';
import QuickCall from '../../components/quickcall/QuickCall';
import Searchbar from '../../components/searchbar/SearchBar';
import './Elearning.css';

const { Option } = Select;


let searchText='';

export default function TrainerEvaluation() {

    const courses = useSelector(state => state.allCourses.courses)
    const dispatch = useDispatch();
    const [cookies] = useCookies(['user']); 
    const [sort, setSort] = useState('latest');
    const [responseTime, setResponseTime] = useState(0);
    const [countResponseData, setCountResponseData] = useState(0);
    const [instructors, setInstructors] = useState([]);
    const [ratingFilter, setRatingFilter] = useState('descending');
    const [numOfCourseFilter, setNumOfCourseFilter] = useState('');
    const [numOfReviewFilter, setNumOfReviewFilter] = useState('');
    const [load, setLoad] = useState(4);
    const [paginateInfo, setPaginateInfo] = useState(null);

    let availableValue = useSelector(state => state.changeAbleValueReducer.availableValue);

      const fetchInstructors = async () =>{
        const params = {
          load:load,
          search: searchText,
          sort: sort,
          ratingFilter,
          numOfCourseFilter,
          numOfReviewFilter,
          
        }
        console.log('training diip')
        const user =  JSON.parse(localStorage.getItem("user_information"));
        console.log('training diip user', user)
        const response = await getApiRequest(`/api/training/training-evaluation/${user?.id}`, '', params)
        console.log('fetch my instructors response', response);
        setInstructors(response?.data?.data);
        setPaginateInfo(response?.data?.meta);
        setCountResponseData(response?.data?.data ? response?.data?.data?.length : 0)
        setResponseTime(response?.response_time);
      };
      
      // useEffect(() => {
      //   fetchInstructors();
      // }, [searchText, sort, load, ratingFilter, numOfCourseFilter, numOfReviewFilter, availableValue]);


      const handleLoadMore = async () =>{
        setLoad(load + 1)
        await fetchInstructors();
      }

      const handleDropdown = (e) => {
        setSort(e);
      }

      const handleRating = (e) =>{
        setRatingFilter(e);
        setNumOfReviewFilter('')
        setNumOfCourseFilter('')
      }

      const handleNumberOfCourse = (e) =>{
        setNumOfCourseFilter(e)
        setRatingFilter('');
        setNumOfReviewFilter('')
      }
      const handleNumberOfReview = (e) =>{
        setNumOfReviewFilter(e)
        setRatingFilter('');
        setNumOfCourseFilter('')
      }

    return (
        <div className="elearnigAllCourses">
            {/* QuickCall-component  */}
            <QuickCall />

            <div className="elearnigAllCoursesDivisionArea">
                <Searchbar />
                <span className="elearnigAllCourseTitle"> Trainer Evaluation</span>
                <Divider />
                <div className="elearnigAllCoursesResults">
                    <div className="left">
                        <span className="elearnigAllCoursesResultsCount">About {countResponseData} results found ({responseTime?.toFixed(2)} sec)</span>
                    </div>
                    <div className="right">
                        <span className="elearnigAllCoursesortby">Sort by: </span>
                        <Select defaultValue={sort} style={{ width: 100 }} placeholder="Sort" onChange={handleDropdown}>
                        <Option value="latest">Latest</Option>
                        <Option value="older">Older</Option>
                        <Option value="updated">Updated</Option>
                        <Option value="a2z">A to Z</Option>
                        <Option value="z2a">Z to A</Option>
                        </Select>
                    </div>
                </div>
            </div>
            <Row>
                 {/*<Col md={3}>
                  <div className="elearnigFilter">
                    <div className="card">
                        <div className="filterDivision">
                        <div className="searchFilterTag">
                        <span className="eFilterTitle">Rating</span>
                        <Select defaultValue="Descending" className="w-100" onChange={handleRating}>
                            <Option value="ascending">Ascending</Option>
                            <Option value="descending">Descending</Option>
                        </Select>
                        </div>
                        </div>
                        <div className="filterDivision">
                            <span className="eFilterTitle">Number of Courses</span>
                            <Select defaultValue="Select" className="w-100" onChange={handleNumberOfCourse}>
                            <Option value="ascending">Ascending</Option>
                            <Option value="descending">Descending</Option>
                        </Select>
                        </div>
                        <div className="filterDivision">
                            <span className="eFilterTitle">Number of Reviews</span>
                            <Select defaultValue="Select" className="w-100" onChange={handleNumberOfReview}>
                            <Option value="ascending">Ascending</Option>
                            <Option value="descending">Descending</Option>
                            </Select>
                        </div>
                      </div> 
                  </div>
                </Col>*/}
                <Col md={12}>
                    <TrainerCourseCard/>
                    {/* <TrainerCourseCard agentCourses={instructors} paginateInfo={paginateInfo}/> */}
                    {/* {countResponseData > 10 &&
                    <div className="more-btn">
                    <button className="btn" onClick={()=> handleLoadMore()}>Load more</button>
                    </div>
                    } */}
                </Col>
            </Row>
            
        </div>
    )
}

