import { Card, Col, Row } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getApiRequest } from "../../api/CommonApi";
import { urlChecker } from "../../utils/urlChecker";
import "./Webinar.css";

export const AllWebinars = () => {
  const [webinars, setWebinars] = useState([]);

  useEffect(() => {
    getApiRequest("/api/webinar/index", "")
      .then((response) => {
        console.log('webinar response', response);
        setWebinars(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const BASEURL = urlChecker();

  return (
    <div className="site-card-wrapper">
      <Row gutter={16}>
        {webinars.map((webinar, index) => (
          <Col span={8}>
            <Link to={`/webinar/${webinar.id}`}>
              <Card
                cover={
                  <img
                    alt="example"
                    src={BASEURL + webinar.banner_img}
                    style={{ width: "100%", height: "200px" }}
                  />
                }
              >
                <h6>{webinar.title}</h6>
                <p>
                  Host Name : {webinar.instructor.name} <br />
                  Launching Date : {moment(webinar.start_time).format("LLLL")}
                  <br />
                </p>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
    </div>
  );
};
