import { Button, DatePicker, Divider, Select } from 'antd';
import dotenv from "dotenv";
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { AiOutlineSearch } from "react-icons/ai";
import { useDispatch, useSelector } from 'react-redux';
import { getApiRequest } from '../../api/CommonApi';
import ElearnigFilter from '../../components/elearning/elearnigFilter/ElearnigFilter';
import MyCoursesCard from '../../components/elearning/mycoursescard/MyCoursesCard';
import QuickCall from '../../components/quickcall/QuickCall';
import Searchbar from '../../components/searchbar/SearchBar';
import { urlChecker } from '../../utils/urlChecker';
import './Elearning.css';

dotenv.config();
const { Option } = Select;
const { RangePicker } = DatePicker;

function handleChange(value) {
    console.log(`selected ${value}`);
}

let searchText='';

export default function MyCourses() {
    const dispatch = useDispatch();
    const BASEURL = urlChecker();

    const [cookies] = useCookies(['user']); 
    const [baseUrl, setBaseUrl] = useState(BASEURL+'/api/courses/user-courses');
    const [tags, setTags] = useState();
    const [categories, setCategories] = useState();
    const [levels, setLevels] = useState();
    const [languages, setLanguages] = useState();
    const [myCourses, setMyCourses] = useState();
    const [sort, setSort] = useState('latest');
    const [filterDate, setFilterDate] = useState(null);
    const [paginateInfo, setPaginateInfo] = useState([]);


    searchText = useSelector(state => state.selectedSearchTitle.title)

    let selectedCategory = useSelector(state => state.categoryType.categoryTypes)
    selectedCategory = selectedCategory.join(); 

    let selectedtags = useSelector(state => state.tagSelect.tags);
    selectedtags = selectedtags.join();

    let selectedLevels = useSelector(state => state.selectedLevel.levels)
    selectedLevels = selectedLevels.join(); 

    let selectedLanguages = useSelector(state => state.selectedLanguage.languages)
    selectedLanguages = selectedLanguages.join(); 

    useEffect(() => {
        fetchCourseResources();
      }, []);
  
      const fetchCourseResources = async () =>{
        const params = {}
        const response = await getApiRequest('/api/courses/course-resources', '', params)
        setTags(response?.data?.data?.tags);
        setCategories(response?.data?.data?.categories);
        setLevels(response?.data?.data?.levels);
        setLanguages(response?.data?.data?.languages);
      };
      

    const fetchMyCourses = async (filterDate = null, page = 1) => {
        const params = {
            search: searchText,
            category_id: selectedCategory?selectedCategory:'',
            level_id: selectedLevels?selectedLevels:'',
            lang_id: selectedLanguages?selectedLanguages:'',
            tags: selectedtags?selectedtags:'',
            sort: sort,
        }

        const url = filterDate ? `/api/courses/user-courses?start_date=${filterDate[0]}&end_date=${filterDate[1]}&page=${page}` : '/api/courses/user-courses' + `?page=${page}`;


      // const response = await getApiRequest('/api/courses/user-courses', '', params)
      //  console.log('my courses response list pro', response);
       // setMyCourses(response?.data?.data?.myCourses); 

        getApiRequest(url, '', params).then(response => {
            console.log('my response  course list', response)
            setMyCourses(response?.data?.data?.myCourses); 
        }).catch(error => {
            console.log(error);
        });
    }

    useEffect(() => {
    fetchMyCourses();
    }, [searchText, selectedtags, selectedCategory,selectedLevels, selectedLanguages, sort]);

    const getDateFiler = (date, dateString) => {
        console.log(date, dateString);
        const startDate = moment(dateString[0]).format('YYYY-MM-DD');
        const endDate = moment(dateString[1]).format('YYYY-MM-DD');
        setFilterDate([startDate, endDate]);
    }
    
      const filterReport = () => {
        fetchMyCourses(filterDate);
      }

      
    const handleDropdown = (e) => {
        console.log('my course sorting', e)
        setSort(e);
      }

    return (
        <div className="elearnigMyCourses">
             {/* QuickCall-component  */}
             <QuickCall />
            <span className="elearnigAllCourseTitle">My Courses</span>
            <Divider className="featuredDivider" />

            <div className="elearnigAllCoursesDivisionArea">
                <Searchbar />
                <Col md={12} className="">
                    <Button className="float-right" onClick={() => filterReport()}> <AiOutlineSearch /> Filter</Button>
                    <RangePicker className="float-right" onChange={getDateFiler} />
                </Col>
                <Divider />
                <div className="elearnigAllCoursesResults">
                    <div className="left">
                        <span className="elearnigAllCoursesResultsCount">About 12 results found (.03 sec)</span>
                    </div>
                    <div className="right">
                        <span className="elearnigAllCoursesortby">Sort by: </span>
                        <Select defaultValue={sort} style={{ width: 100 }} placeholder="Sort" onChange={handleDropdown}>
                        <Option value="latest">Latest</Option>
                        <Option value="older">Older</Option>
                        <Option value="updated">Updated</Option>
                        <Option value="a2z">A to Z</Option>
                        <Option value="z2a">Z to A</Option>
                        </Select>
                    </div>
                </div>
            </div>

            <Row>
                <Col md={3}>
                    {/* <MyCoursesFilter /> */}
                    <ElearnigFilter 
                    tags={tags} 
                    categories={categories} 
                    levels={levels} 
                    languages={languages}
                    />
                </Col>
                <Col md={9} className="">
                    <Row>
                        {myCourses && 
                         myCourses.map( (course, i) => (
                        <Col md={4} className="pl-0" key={i}>
                            <MyCoursesCard course={course}/>
                        </Col>
                        ))
                    }
                    </Row>
                </Col>
            </Row>
        </div>
    )
}
