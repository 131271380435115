import { getApiRequest } from './CommonApi';

export const videoTagList = async (url, access_token, params) => {
    const response = await getApiRequest(url, access_token, params);
    const types = await response.data.data.map((tag) => ({
            label: tag.name,
            value: tag.id
        }
    ));
    return types;
}