import './VideoFilter.css';
import { Checkbox } from 'antd';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { videoTagList } from '../../api/VideoTagApi';
import { useDispatch } from 'react-redux';
import { tagStore } from '../../services/actions/faq/TagSelectedAction';

export default function VideoFilter() {
    const [types, setType] = useState('');
    const [cookies] = useCookies(['user']);
    const dispatch = useDispatch();
    
    useEffect(async () => {
        const response = await videoTagList("/api/tag/video-tag", cookies.access_token, '');
        setType(response);        
    }, [])

    function onChangeType(checkedValues) {
        dispatch(tagStore(checkedValues));
    }
    
    return (
        <div className="videoFilter">
              <div className="card">
                <div className="searchFilterFaqType">
                    <h4 className="searchfilterTitle">Tags</h4>
                    <Checkbox.Group options={types} onChange={onChangeType} />
                </div>
            </div>
        </div>
    )
}
