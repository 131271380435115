import { ActionTypes } from "../constants/ActionTypes";
const initialState = {
    user: []
}

export const userReducer = (state = initialState, {type, payload})=>{
    switch (type) {
        case ActionTypes.USER_DATA_STORE:
            return { ...state, user: payload};
        default:
            return state;
    }
}