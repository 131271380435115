import { Button, Card, Divider, Select } from "antd";
import dotenv from "dotenv";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { pdfjs } from "react-pdf";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { getApiRequest, postApiRequest } from "../../api/CommonApi";
import StreamPlayer from "../../components/common/StreamPlayer";
import { urlChecker } from "../../utils/urlChecker";
import "./Preshift.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
dotenv.config();
const { Option } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}

let searchText = "";

export default function PreShiftBriefing() {
  // const dispatch = useDispatch();
  const BASEURL = urlChecker();

  const history = useHistory();
  const [cookies] = useCookies(["user"]);
  const [preShift, setPreShift] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [thePdf, setThePdf] = useState(null);
  const flag = useSelector((state) => state.videoFlag);

  console.log("video flag", flag);

  useEffect(() => {
    getActivePreshiftBriefing();
  }, []);

  const getActivePreshiftBriefing = async () => {
    const response = await getApiRequest("/api/preshift-briefings/show");
    console.log("my preshift response", response);

  //  if(response?.data?.data?.title){
      setPreShift(response?.data?.data);
      // const thePdf = await getApiRequest(`/api/get-pdf`, "");
      setThePdf(response?.data?.data?.file);
    // }else{
    //   toast.error(response?.data?.data)
    // }
    
  };

  const startPreshiftQuiz = (id, preShift_id) => {
    postApiRequest(`/api/quiz-attempt/start`, "", {
      quiz_id: id,
      pre_shift_briefing_id: preShift_id,
    })
      .then((res) => {
        if (!res.data.data) {
          toast.error("Quiz already attend!");
          return false;
        }
        console.log("preshift quiz start status", res.status);
        let firstQuestion = res.data.data.question;
        let attempt = res.data.data.attempt;
        res.status === 200 &&
          history.push(`/quiz-attempt/${attempt.id}/${firstQuestion.id}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const showMedia = (file) => {
    // get file extension
    let extension = file?.split(".").pop();
    if (extension === "mp3") {
      return (
        <audio controls>
          <source
            src={BASEURL + file}
            type="audio/mpeg"
          />
        </audio>
      );
    } else if (extension === "mp4") {
      // return (
      //   <video controls style={{ width: "100%" }}>
      //     <source
      //       src={process.env.REACT_APP_BASE_URL + file}
      //       type="video/mp4"
      //     />
      //   </video>
      // );
      return <StreamPlayer fileUrl={file} />;
    } else {
      return <h2>No media found</h2>;
    }
  };

  console.log("preshift pdf add", preShift);

  return preShift ? (
    <div className="elearnigMyCourses">
      <span className="elearnigAllCourseTitle">Preshift Briefing</span>
      <Divider className="featuredDivider" />

      <Row className="relative">
        <Col md={12} className="content-display mb-4 ">
          <div style={{ marginBottom: "10px" }}>
            {preShift?.file?.split(".").pop() == "pdf" ? (
              <>
                <iframe
                  src={BASEURL + preShift?.file}
                  frameborder="0"
                  width="100%"
                ></iframe>
              </>
            ) : (
              showMedia(preShift?.file)
            )}
          </div>
          <Card>
            {/* {preShift && preShift.created_at && (
              <p>
                <strong>Date</strong> :{" "}
                {moment(preShift.created_at).format("DD MMMM YYYY")}
              </p>
            )} */}
            {preShift && preShift?.created_by && (
              <p className="text-capitalize">
                <strong>Created By </strong>: {preShift?.created_by?.name}
              </p>
            )}
            {preShift && preShift?.content && (
              <p>
                <strong>Note </strong>:{" "}
                <div
                  dangerouslySetInnerHTML={{ __html: preShift?.content }}
                ></div>
              </p>
            )}
          </Card>

          {flag && (
            <Card className="mt-3 dkpQuizStart">
              <p>
                After you read the DKB content file please perticipate this
                quiz. Make sure you answer all the questions correctly.
                Otherwise you would not be able to success in the knowledge
                based training program.
              </p>
              <div className="text-center mt-4">
                <Button
                  type="primary"
                  onClick={() =>
                    startPreshiftQuiz(preShift?.quiz?.id, preShift?.id)
                  }
                >
                  <strong>Start Quiz</strong>
                </Button>
              </div>
            </Card>
          )}
        </Col>
      </Row>
    </div>
  ) : (
    ""
  );
}
