import { Button, Form, Input } from "antd";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { getApiRequest, postApiRequest } from "../../api/CommonApi";
import "./Login.css";

export function ForgetPassword() {
  const history = useHistory();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [enableOtpCode, setEnableOtpCode] = useState(false);
  const [enableFirstInterface, setEnableFirstInterface] = useState(true);
  const [requiredOtp, setRequiredOtp] = useState(false);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const sendMobileOTP = async (phone) => {
    var randOTP = Math.floor(1000 + Math.random() * 9000);
    const odata = {
      otp: randOTP,
      message: "OTP " + randOTP,
      emailOrNumber: phone,
      masking: 'GP Learning',
    };
    const response = await postApiRequest(
      "/api/customers/send-message",
      "",
      odata
    );
    return response;
  };

  const handleEnableOtp = async () => {
    const response = await getApiRequest(`/api/check-for-valid-number`, '', {phone: phoneNumber});

    if(response?.data?.status === 'error'){
      toast.error(response?.data?.message);
      return false;
    }
    
    setEnableOtpCode(true);
    setEnableFirstInterface(false);
    const optRes = await sendMobileOTP(phoneNumber);
    const apiResData = JSON.parse(optRes?.data?.data);
    setOtp(apiResData?.id)
  };

  const handleOtp = async () => {
    if(requiredOtp === otp){
      const response = await getApiRequest(`/api/forget-password`, '', {phone: phoneNumber});
      
      if (response?.status === 200) {
        toast.success('OTP Matched');
        history.push('/password/reset/'+response?.data?.data?.password_reset_token)
      }else{
        toast.error("Phone number doesn't exist!");
      }
    }else{
      toast.error('OTP Not Matched');
    }
  }

  return (
    <div className="loginAuthentication">
      <div className="card">
        <div className="brandLogo">
          <img className="logo" src="images/logo.png" alt="" />
        </div>
        { enableFirstInterface &&
          <Form
          name="basic"
          initialValues={{ remember: true }}
          
          layout="vertical"
          >
            <Form.Item
              label="Enter Phone number for OTP"
              rules={[{ required: true, message: "Please input your phone number!" }]}
            >
              <Input type="text" onChange={(e) => setPhoneNumber(e.target.value)} />
            </Form.Item>

            <Form.Item>
              <Button className="primary-btn" htmlType="submit" onClick={handleEnableOtp}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        }

    { enableOtpCode &&
          <Form
          name="basic"
          initialValues={{ remember: true }}
          
          layout="vertical"
          >
            <Form.Item
              label="Enter OTP"
              name="otp"
              rules={[{ required: true, message: "Please input your otp!" }]}
            >
              <Input type="text"  onChange={(e) => setRequiredOtp(e.target.value)}/>
            </Form.Item>

            <Form.Item>
              <Button className="primary-btn" htmlType="submit" onClick={handleOtp}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        }
      </div>
    </div>
  );
}
