import React, { useEffect, useState } from "react";
import { Col, Row } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getApiRequest } from '../../api/CommonApi';
import MyCourseDetails from '../../components/elearning/mycoursedetails/MyCourseDetails';
import MyRightAccordion from '../../components/elearning/mycoursedetailsrightaccordion/MyRightAccordion';
import QuickCall from '../../components/quickcall/QuickCall';
import {
    removeSelectedCourse, selectedCourse
} from "../../services/actions/CourseAction";
import './Elearning.css';


export default function MyCoursesDetails() {
    const { id } = useParams();
    const newQA = useSelector(state => state.newQA.newQA);
    const lessonVid = useSelector((state) => state.activeLesson.course_lesson ? state.activeLesson.course_lesson : '');

    const [cookies] = useCookies(['user']); 
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(true)
    const [fetchMyCourse, setFetchMyCourse] = useState()

    const fetchMyCourseDetail = async (id) => {
        const response = await getApiRequest(`/api/courses/user-course-details/${id}`)
        console.log('my course details response', response);
        dispatch(selectedCourse(response?.data?.data));
        setLoading(false);
        setFetchMyCourse(response?.data?.data);
        
    };

    useEffect(() => {
        if (id && id !== "") fetchMyCourseDetail(id);
        return () => {
          dispatch(removeSelectedCourse());
        };
    }, [id, newQA, lessonVid, isLoading]);

      let course = useSelector((state) => state.course);

    if (isLoading) {
    return <div>Loading ... </div>;
    } else {
    return (
        <div className="elearnigMyCourses test">
             {/* QuickCall-component */}
             <QuickCall />
            <Row className="margin-5">
                <Col md={8} className="ge-card">
                    <MyCourseDetails lessonVid={lessonVid}/>
                </Col>
                <Col md={4}>
                    <MyRightAccordion />
                </Col>
            </Row>
        </div>
    )};
}
