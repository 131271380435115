import { MenuOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Layout } from "antd";
import "antd/dist/antd.css";
import dotenv from "dotenv";
import { instanceOf } from "prop-types";
import React from "react";
import { Cookies, withCookies } from "react-cookie";
import { Link, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { getApiRequest, postApiRequest } from "./api/CommonApi";
import ScrollToTop from "./components/ScrollToTop";
import { HowToActivate } from "./components/campaign/HowToActivate";
import TopBar from "./components/header/TopBar";
import DetailsNewTest from "./components/newtestcom/DetailsNewTest";
import SidebarMenu from "./components/sidebar/Sidebar";
import { Ad } from "./pages/auth/Ad";
import { ForgetPassword } from "./pages/auth/ForgetPassword";
import { Login } from "./pages/auth/Login";
import { Logout } from "./pages/auth/Logout";
import { ResetPassword } from "./pages/auth/ResetPassword";
import CallHandle from "./pages/callhandle/CallHandle";
import { CallHandleDetails } from "./pages/callhandle/CallHandleDetails";
import { GpCampaign } from "./pages/campaign/GpCampaign";
import { Certificate } from "./pages/certificate/Certificate";
import MyConfusions from "./pages/confusion/MyConfusions";
import MyConfusionsDetails from "./pages/confusion/MyConfusionsDetails";
import ConfusionBox from "./pages/confusionbox/ConfusionBox";
import DetailsConfusions from "./pages/confusionbox/DetailsConfusions";
import AllCourses from "./pages/elearning/AllCourses";
import ElearningCoursesDetails from "./pages/elearning/ElearningCoursesDetails";
import MyCourses from "./pages/elearning/MyCourses";
import MyCoursesDetails from "./pages/elearning/MyCoursesDetails";
import MyWishlist from "./pages/elearning/MyWishlist";
import TrainerEvaluation from "./pages/elearning/TrainerEvaluation";
import AllFaqs from "./pages/faq/AllFaqs";
import DetailsFaq from "./pages/faq/DetailsFaq";
import FavouriteFaqs from "./pages/faq/FavouriteFaqs";
import MatrixBase from "./pages/faq/MatrixBase";
import MatrixList from "./pages/faq/MatrixList";
import TableMatrixBase from "./pages/faq/TableMatrixBased";
import Home from "./pages/home/Home";
import { InterActiveDemo } from "./pages/interactivedemo/InterActiveDemo";
import NewTest from "./pages/newtest/NewTest";
import { Notifications } from "./pages/notifications/Notifications";
import PreShiftBriefing from "./pages/preshiftbriefing/PreShiftBriefing";
import { PreShiftBriefingHistory } from "./pages/preshiftbriefing/PreShiftBriefingHistory";
import PreShiftBriefingIndividual from "./pages/preshiftbriefing/PreShiftBriefingIndividual";
/** only related to quiz (by sabbir) */
import { MyQuiz } from "./pages/quiz/MyQuiz";
import { Quiz } from "./pages/quiz/Quiz";
import { QuizAttempt } from "./pages/quiz/QuizAttempt";
import { QuizDetails } from "./pages/quiz/QuizDetails";
import { AgentFaqVsRTC } from "./pages/reports/AgentFaqVsRTC";
import { AgentQuizReport } from "./pages/reports/AgentQuizReport";
import { AgentWiseElearning } from "./pages/reports/AgentWiseElearning";
import { AgentWiseMeeting } from "./pages/reports/AgentWiseMeeting";
import { FAQWiseFCR } from "./pages/reports/FAQWiseFCR";
import { TrainingWiseEvaluation } from "./pages/reports/TrainingWiseEvaluation";
import SearchDetails from "./pages/search/Details";
import GlobalSearch from "./pages/search/GlobalSearch";
import Search from "./pages/search/Search";
import SiteMap from "./pages/sitemap/SiteMap";
import MyFavouriteVideo from "./pages/video/MyFavouriteVideo";
import Video from "./pages/video/Video";
import VideoDetails from "./pages/videodetails/VideoDetails";
import { Webinar } from "./pages/webinar/Webinar";
import { WebinarShow } from "./pages/webinar/WebinarShow";

const { Header, Sider, Content } = Layout;
dotenv.config();

class App extends React.Component {


  
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);
    const { cookies } = props;
    this.getPreshiftReport();
    //this.checkPreShift();
    this.state = {
      collapsed: false,
      token: cookies.get("access_token") ? cookies.get("access_token") : "",
      signoutTime: 1000 * 60 * 20,
      cookies: cookies,
    };
  }

  state = {
    collapsed: false,
    preShiftReport: null,
    preShift: null,
    dataLoaded: false,
    isPreShiftDone: true,
    //token: cookies.get("access_token") ? cookies.get("access_token") : "",
  };

   registerPageLoad = () => {
     var _paq = window._paq || [];
    _paq.push(['setCustomUrl', window.location.href]);
    _paq.push(['setDocumentTitle', document.title]);
    _paq.push(['trackPageView']);
    };

  componentDidMount() {
    this.events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];

    for (var i in this.events) {
      window.addEventListener(this.events[i], this.resetTimeout);
    }
    window.addEventListener('click', this.registerPageLoad);

    // this.setTimeout();

    // Load the event listeners
    // tracker.trackEvents()

    // // Track page views
    // tracker.trackPageView()

  
  }

  clearTimeoutFunc = () => {
    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  };

  // setTimeout = () => {
  //   this.logoutTimeout = setTimeout(this.logout, this.state.signoutTime);
  // };

  resetTimeout = () => {
    this.clearTimeoutFunc();
    // this.setTimeout();
  };

  logout = async () => {
    // Send a logout request to the API
    console.log("Sending a logout request to the API...");
    const response = await postApiRequest("/api/logout");
    // if(response?.data?.status === 'success') {
    this.destroy();
    // }
  };

  destroy = () => {
    //clear the session
    //  browserHistory.push('/');
    this.state.cookies.remove("access_token");
    this.state.cookies.remove("refresh_token");
    this.state.cookies.remove("user");
    this.state.cookies.remove("information_user");
    this.state.cookies.remove("admin");
    this.state.cookies.remove("tracking");
    this.state.cookies.remove("normal_login_attemps");
    localStorage.clear();
    window.location.href = "/";
  };

  getPreshiftReport = () => {
    // if (
    //   !localStorage.getItem("preShiftReport") ||
    //   localStorage.getItem("preShiftReport") == "undefined"
    // ) {
    getApiRequest("/api/preshift-briefings/report-user")
      .then((res) => {
        // console.log('report dkb res', res)
      //  if (res && res.status === 200) {
        if (res && res?.data?.data) {
          let preShift = res?.data?.data;
          this.setState({ preShift: preShift, dataLoaded: true });
          let preShiftReport = res?.data?.data?.report;
          this.setState({ preShiftReport: preShiftReport });
          this.checkPreShift(preShift, preShiftReport);

          localStorage.setItem("preShiftReport", JSON.stringify(preShift));
        } else {
        //  toast.success('no preshift briefinig found for the day!')
          this.setState({ preShift: null });
         // this.checkPreShift(null);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // } else {
    //   let preShift = JSON.parse(localStorage.getItem("preShiftReport"));
    //   this.setState({ preShift: preShift, dataLoaded: true });
    //   let preShiftReport = preShift.report;
    //   this.setState({ preShiftReport: preShiftReport });

    //   this.checkPreShift(preShift, preShiftReport);
    // }
  };

  checkPreShift = (preShift, preShiftReport) => {
    // console.log('my pre return', preShift)
    let location = window.location.pathname;
    let isScoreAchiveFull =
      preShift == null
        ? true
        : preShiftReport?.score_achive_per == 100
        ? true
        : false;

        console.log('isScoreAchiveFull', isScoreAchiveFull)

    if (!isScoreAchiveFull) {
      console.log('true isScoreAchiveFull', isScoreAchiveFull)
      if (!localStorage.getItem("preShift")) {
        if (location != "/pre-shift-briefing") {
          window.location.href = "/pre-shift-briefing";
        }
        this.setState({ isPreShiftDone: false });
      }
      this.setState({ isPreShiftDone: true });
      // localStorage.setItem("preShift", "true");
    }
    
    // else{
    //   window.location.href = "/"
    // }
  };
  // const [preShiftReport, setPreShiftReport] = useState(null);
  // const [preShift, setPreShift] = useState(null);

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  // componentDidMount() {
  //   this.getPreshiftReport();
  //   this.checkPreShift();
  // }
  render() {

    return (
      <>
        <ToastContainer />
        <Router>
          <ScrollToTop />
          {this.state.token ? (
            <Layout>
              <Sider
                width={300}
                trigger={null}
                collapsible
                collapsed={this.state.collapsed}
              >
                <div className="logo">
                  <Link to="/">
                    <img src="/images/logo.png" alt="" />
                  </Link>
                </div>
                {/* {!this.isPreShiftDone && <SidebarMenu />} */}
                <SidebarMenu />
              </Sider>

              <Layout className="site-layout">
                <Header className="site-layout-header" style={{ padding: 0 }}>
                  {React.createElement(
                    this.state.collapsed ? MenuUnfoldOutlined : MenuOutlined,
                    {
                      className: "trigger",
                      onClick: this.toggle,
                    }
                  )}
                  <TopBar />
                </Header>

                <Content className="site-layout-background">
                  <Switch>
                    <Route exact path="/">
                      <Home />
                    </Route>
                    <Route path="/sitemap">
                      {/* <FaqIndex /> */}
                      <SiteMap />
                    </Route>
                    <Route path="/all-faqs">
                      <AllFaqs />
                    </Route>
                    <Route path="/favourite-faqs">
                      <FavouriteFaqs />
                    </Route>
                    <Route path="/matrix-base/:faqId">
                      <MatrixBase />
                    </Route>
                    <Route path="/table-matrix-base/:faqId">
                      <TableMatrixBase />
                    </Route>
                    <Route path="/matrix-list">
                      <MatrixList />
                    </Route>
                    <Route path="/details-faqs/:faqId">
                      <DetailsFaq />
                    </Route>
                    <Route path="/favourite-videos">
                      <MyFavouriteVideo />
                    </Route>
                    <Route path="/my-confusions">
                      <MyConfusions />
                    </Route>
                    <Route path="/my-confusions-details/:id">
                      <MyConfusionsDetails />
                    </Route>
                    <Route path="/search">
                      <Search />
                    </Route>
                    <Route path="/search-details">
                      <SearchDetails />
                    </Route>
                    <Route path="/video-details/:videoId">
                      <VideoDetails />
                    </Route>
                    <Route path="/video">
                      <Video />
                    </Route>
                    <Route path="/site-map">
                      <SiteMap />
                    </Route>
                    <Route path="/confusion-box">
                      <ConfusionBox />
                    </Route>
                    <Route path="/details-confusions/:confusionId">
                      <DetailsConfusions />
                    </Route>

                    {/* quiz-routes */}
                    <Route path="/quiz">
                      <Quiz />
                    </Route>
                    <Route path="/my-quiz">
                      <MyQuiz />
                    </Route>
                    <Route path="/quiz-details/:quiz_attempt_id">
                      <QuizDetails />
                    </Route>
                    <Route path="/quiz-attempt/:quiz_attempt_id/:question_id">
                      <QuizAttempt />
                    </Route>

                    {/*end quiz-routes */}

                    <Route path="/all-courses">
                      <AllCourses />
                    </Route>
                    <Route path="/my-courses">
                      <MyCourses />
                    </Route>
                    <Route path="/my-wishlist">
                      <MyWishlist />
                    </Route>
                    <Route path="/trainer-evaluation">
                      <TrainerEvaluation />
                    </Route>
                    <Route path="/my-course-details/:id">
                      <MyCoursesDetails />
                    </Route>
                    <Route path="/course-details/:id">
                      <ElearningCoursesDetails />
                    </Route>

                    <Route path="/pre-shift-briefing">
                      <PreShiftBriefing />
                    </Route>
                    <Route path="/pre-shift-briefing-individual/:preshift_id">
                      <PreShiftBriefingIndividual />
                    </Route>
                    <Route path="/pre-shift-briefing-history">
                      <PreShiftBriefingHistory />
                    </Route>

                    {/* Webinar routes */}
                    <Route path="/webinars">
                      <Webinar />
                    </Route>

                    <Route path="/webinar/:webinar_id">
                      <WebinarShow />
                    </Route>
                    {/* Webinar routes end*/}

                    {/* notifications */}
                    <Route path="/notifications">
                      <Notifications />
                    </Route>

                    {/* certificate */}
                    <Route path="/certificate">
                      <Certificate />
                    </Route>

                    {/* campaign */}
                    <Route path="/gp-campaign/:id">
                      <GpCampaign />
                    </Route>

                    {/* interactive-demo */}
                    <Route path="/interactive-demo">
                      <InterActiveDemo />
                    </Route>
                    <Route path="/interactive-views/:id">
                      <HowToActivate />
                    </Route>

                    {/* call-handle */}
                    <Route path="/call-handle">
                      <CallHandle />
                    </Route>
                    <Route path="/callhandle-details/:faqId">
                      <CallHandleDetails />
                    </Route>
                    <Route path="/global-search/:title">
                      <GlobalSearch />
                    </Route>

                    {/* reports */}
                    <Route path="/agent-faq-vs-rtc-report">
                      <AgentFaqVsRTC />
                    </Route>
                    <Route path="/agent-fcr-report">
                      <FAQWiseFCR />
                    </Route>
                    <Route path="/agent-wise-elearning-report">
                      <AgentWiseElearning />
                    </Route>
                    <Route path="/agent-wise-training-report">
                      <TrainingWiseEvaluation />
                    </Route>
                    <Route path="/agent-wise-quiz-report">
                      <AgentQuizReport />
                    </Route>
                    <Route path="/agent-wise-preshift-report">
                      <PreShiftBriefingHistory />
                    </Route>
                    <Route path="/agent-wise-meeting-report">
                      <AgentWiseMeeting />
                    </Route>

                    {/* test-routes  */}
                    <Route path="/newtest">
                      <NewTest />
                    </Route>
                    <Route path="/detailsnewtest/:productId">
                      <DetailsNewTest />
                    </Route>
                  </Switch>
                </Content>
              </Layout>
            </Layout>
          ) : (
            <Switch>
              <Route exact path="/forget-password">
                <ForgetPassword />
              </Route>
              <Route exact path="/password/reset/:token">
                <ResetPassword />
              </Route>
              <Route exact path="/alt-login">
                <Ad />
              </Route>
              <Route path="/">
                <Login />
                {/* <Ad /> */}
              </Route>

              <Route path="/logout">
                <Logout />
              </Route>
            </Switch>
          )}
        </Router>
      </>
    );
  }
}

export default withCookies(App);
