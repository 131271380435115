import { CloudUploadOutlined } from '@ant-design/icons';
import { Avatar, Button, Divider, Input, List, Upload } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getApiRequest, postApiRequest } from '../../api/CommonApi';
import Preloader from '../../components/common/Preloader';
import QuickCall from '../../components/quickcall/QuickCall';
import { BASEURL } from '../../config/config';
import './ConfusionBox.css';


const { TextArea } = Input;

export default function DetailsConfusions() {
    const {confusionId} = useParams();
    const [data, setData] = useState('');
    const [listItemsData, setListItemsData] = useState('');
    const [questionText, setQuestionText] = useState('');
    const [isLoad, setIsLoad] = useState(false);
    const [uploadFile, setUploadFile] = useState('');
    const [uploadImage, setUploadImage] = useState('');

    const handleSubmission = async (e) => {
        if(!questionText){
            toast.error("Empty Question Field!")
            return false;
        }
        const data = new FormData() 
        data.append('comment', questionText)
        data.append('forum_image', uploadImage)
        data.append('parent_id', confusionId)
        const response = await postApiRequest('/api/confusion/create', "", data);
        console.log(response.data);
        readTheQuestionsOnConfusion(confusionId);
        toast.success(response.message)
        window.location.href = `/details-confusions/${confusionId}`
    }

    const readSingleConfusion = async (confusionId) => {
        const res = await getApiRequest(`/api/confusion/read/${confusionId}`, "", '');
        console.log(res.data.data);
        const value = [{
            comment:res.data.data.comment,
            created_at: moment(res.data.data.created_at).format('MMMM Do YYYY, h:mm:ss a'),
            forum_image:res.data.data.forum_image,
            id:res.data.data.id
        }]
        setData(value)
        setIsLoad(true)
    }

    const readTheQuestionsOnConfusion = async (confusionId) => {
        const params = {
            parent_id:confusionId,
        }
        const response = await getApiRequest(`/api/confusion/comments`, "", params);
        const result = response.data.data.map((post) => ({
            description:post.comment,
            url:post.forum_image,
            postTime: moment(post.created_at).format('MMMM Do YYYY, h:mm:ss a'),
            id:post.id
        } ))
        setListItemsData(result)
    }

    useEffect(async () => {
        readSingleConfusion(confusionId);
        readTheQuestionsOnConfusion(confusionId);
    }, [])

    function handleFileChange(info) {
        console.log("image ssdfsd", info)
        // if(type == "image"){
            setUploadImage(info?.file?.originFileObj)
        // }else{
        //     setUploadFile(info?.file?.originFileObj)
        // }
    }

    const onChange = (e) => {
        setQuestionText(e.target.value);
    };

    return (
        <div className="detailsConfusions">
            <QuickCall />
            <div className="detailsConfusionsBody">
                <div className="title">
                    <span className="detailsConfusionsTitle">confusion details</span>
                    <Divider />
                </div>
                <div className="list card">
                {/* listBanner */}
                    <div className="listBanner">
                        {isLoad == false ? (
                            <Preloader/>
                        ):(    
                        <List
                            itemLayout="horizontal"
                            dataSource={data}
                            renderItem={item => (
                            <List.Item>
                                <div className="img">
                                    <Avatar src={item.forum_image} />
                                </div>
                                <Link to="/details-confusions" className="postTitle">
                                    <div className="content-des">
                                        <span className="description">{item.comment}</span>
                                        <span className="postTime">{item.created_at}</span>
                                    </div>
                                </Link>
                            </List.Item>
                            )}
                        />
                        )}
                    </div>
                    {/* listItems */}
                    <div className="listItems">
                    <List className="textboxListItem" itemLayout="horizontal">
                        <List.Item>
                            <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                            <div className="textbox">
                                <TextArea
                                placeholder="Type your question here..."
                                autoSize={{ minRows: 2, maxRows: 6 }}
                                onChange={onChange}
                                />
                                
                            </div>
                        </List.Item>
                        <div className="textfiles">
                            <div className="left">
                            <Upload
                                accept="images/*"
                                action={`${BASEURL}/api/courses/test-upload`}
                                maxCount={1}
                                onChange={(info) => handleFileChange(info, 'image')}>
                                    <Button><CloudUploadOutlined/></Button>
                            </Upload>
                            </div>
                            <div className="right">
                                <Button type="button" onClick={handleSubmission}>Submit</Button>
                            </div>
                        </div>
                    </List>
                    <List
                        itemLayout="horizontal"
                        dataSource={listItemsData}
                        renderItem={item => (
                        Object.keys(listItemsData).length === 0 ? (
                            <Preloader/>
                        ):( 
                            <List.Item>
                                <List.Item.Meta
                                avatar={<Avatar src={item.url} />}
                                title={<a href="/">{item.description}</a>}
                                description={<span>{item.postTime}</span>}
                                />
                            </List.Item>
                        )
                        )}
                    />
                    </div>
                </div>
            </div>
        </div>
    )
}
