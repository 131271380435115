import { SearchOutlined } from "@ant-design/icons";
import { Input, Pagination, Select } from "antd";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getApiRequest } from "../../api/CommonApi";
import "./AllQuiz.css";

const { Option } = Select;

export const My_Quiz = () => {
  const [myQuizzes, setMyQuizzes] = useState({
    data: [],
  });
  const [paginateInfo, setPaginateInfo] = useState([]);

  useEffect(() => {
    // getApiRequest("/api/quiz-attempt/users", "")
    //   .then((res) => {
        
    //     console.log('quiz-attempt', res);

    //     res.status === 200 &&
    //       setMyQuizzes({ ...myQuizzes, data: res.data.data });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    getMyQuizzes();

  }, []);

  const getMyQuizzes = async (page = 1, perPage=10) => {
    console.log('page', page);
    console.log('perPage', perPage);

    let url = '/api/quiz-attempt/users' + `?page=${page}&perPage=${perPage}`;

        getApiRequest(url).then(response => {
            console.log('all my quizzes response', response);
            setMyQuizzes({ ...myQuizzes, data: response.data.data });
           // setMyQuizzes({ ...myQuizzes, data: response.data.data });
            setPaginateInfo(response?.data?.meta);
        }).catch(error => {
            console.log(error);
        });

  };

  return (
    <div className="allQuiz">
      <div className="card">
        <div className="AllQuizHeading">
          <div className="left">
            <span className="allQuizTitle">My Quiz</span>
          </div>
          <div className="right">
            <div className="content">
              <Input placeholder="search" prefix={<SearchOutlined />} />
              <div className="sortby">
                <span className="allQuizSort">Sort by:</span>
                <Select showSearch style={{ width: 100 }} placeholder="Latest">
                  <Option value="jack">Jack</Option>
                </Select>
              </div>
            </div>
          </div>
        </div>
        <div className="allQuizTable">
          <Table responsive hover>
            <thead className="allQuizheading">
              <tr>
                <th className="font-bold gray-text-200">Title</th>
                <th className="font-bold gray-text-200">Total Questions</th>
                <th className="font-bold gray-text-200">Total Marks</th>
                <th className="font-bold gray-text-200">My Score(%)</th>
                <th className="font-bold gray-text-200">Total Time</th>
                <th className="font-bold gray-text-200">Average Time</th>
                <th className="font-bold gray-text-200">My Score</th>
                <th className="font-bold gray-text-200">Status</th>
                <th className="font-bold gray-text-200 text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {myQuizzes?.data.map((quiz, index) => {
                return (
                  <tr>
                    <td>{quiz.quiz_title}</td>
                    <td>{quiz.total_questions}</td>
                    <td>{quiz.total_score}</td>
                    <td>{quiz.score_percentage}%</td>
                    <td>{quiz.total_time} mins</td>
                    <td>{quiz.avarage_time} mins</td>
                    <td>{quiz.my_score}</td>
                    <td>
                      <span
                        className={
                          quiz.passing_status != "Pass"
                            ? "failPill"
                            : "passPill"
                        }
                      >
                        {quiz.passing_status}
                      </span>
                    </td>
                    <td className="text-center">
                      <Link to={`/quiz-details/${quiz.id}`}>View Details</Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {paginateInfo && (
              <Pagination defaultCurrent={1} total={paginateInfo.total} onChange={(page, perPage) => {
                getMyQuizzes(page, perPage);
              }} />
          )}
        </div>
      </div>
    </div>
  );
};
