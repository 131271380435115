import { Rate, Tag } from 'antd';
import dotenv from "dotenv";
import moment from 'moment';
import { Link } from 'react-router-dom';
import { urlChecker } from '../../../utils/urlChecker';
import './AllCoursCard.css';
dotenv.config();

export default function FeatureCourseCard(props) {

    const { id, title, cover_image, short_desc, rating, created_at, updated_at, tags } = props.course;

    const BASEURL = urlChecker();

    return (
        <div className="elarnigAllCoursCard elarnigAllCoursCardFeature card">
            <div className="elarnigAllCoursCardContents">
                <div className="elarnigAllCoursCardLeft">
                    <Link to={`/course-details/${id}`}>
                    <div className="elarnigAllCoursCardThumbnails">
                        {cover_image && 
                        <img src={BASEURL +'/'+ cover_image} alt={title} />
                        }
                        
                    </div>
                    </Link>
                </div>
                <div className="elarnigAllCoursCardRight">
                    <Link to={`/course-details/${id}`}>
                    <h3 className="elarnigAllCoursCardTitle">{title}</h3>
                    </Link>
                    <p className="elarnigAllCoursCardDesc">{short_desc}</p>
                    <span className="elarnigAllCoursCardUpTime">Updated at {moment(created_at).format('DD MMMM YYYY HH:mm A')} | Last updated {moment(updated_at).fromNow()}</span>
                    {props?.course?.course_instructor_name && 
                    <span className="elarnigAllCoursCardInstructor">Instructor: {props?.course?.course_instructor_name ? props.course.course_instructor_name : ""}</span>
                    }
                    <span className="elarnigAllCoursCardReview">
                        <span className="elearnigReviewTitle">{rating}</span>
                        <Rate disabled defaultValue={rating} />
                    </span>
                    <div className="elarnigAllCoursCardTags">
                    {tags.map((item, i) => (
                        <Tag key={i}>{item?.tag?.name}</Tag>
                    ))}   
                    </div>
                   
                </div>
            </div>
        </div>
    )

};

