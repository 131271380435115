import { Button, DatePicker, Divider, Pagination, Select } from 'antd';
import dotenv from "dotenv";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { AiOutlineDownload, AiOutlineSearch } from "react-icons/ai";
import { getApiRequest } from '../../api/CommonApi';
import { urlChecker } from '../../utils/urlChecker';
import './Reports.css';


dotenv.config();
const { RangePicker } = DatePicker;
const { Option } = Select;

export const TrainingWiseEvaluation = () => {

   const [trainings, setTrainings] = useState([])
   const [paginateInfo, setPaginateInfo] = useState([]);
   const [filterDate, setFilterDate] = useState(null);
   const [course, setCourse] = useState('');
   const [courseList, setCourseList] = useState([]);

   const BASEURL = urlChecker();

    useEffect(()=> {
        loadAllTrainings();
    }, [course]);

    useEffect(()=> {
        loadAllCourses();
    }, []);

    const loadAllCourses = async () =>{
        const response = await getApiRequest("/api/courses")
        setCourseList(response?.data?.data);
    };

    function handleStatus(e) {
        setCourse(e);
    }


    const getDateFiler = (date, dateString) => {
        console.log(date, dateString);
        const startDate = moment(dateString[0]).format('YYYY-MM-DD');
        const endDate = moment(dateString[1]).format('YYYY-MM-DD');
        setFilterDate([startDate, endDate]);
    }

    const loadAllTrainings = async (filterDate = null, page = 1) =>{
        const params = {
            course,
        }

        const url = filterDate ? `/api/reports/agent/training-evaluation?start_date=${filterDate[0]}&end_date=${filterDate[1]}&page=${page}` : '/api/reports/agent/training-evaluation' + `?page=${page}`;
          getApiRequest(url, '', params).then(response => {
            console.log('response trainer evaluation report', response?.data?.data)
            setTrainings(response?.data?.data);
            setPaginateInfo(response?.data?.meta);
        }).catch(error => {
            console.log(error);
        });

    };

    const filterReport = () => {
        console.log(filterDate);
        loadAllTrainings(filterDate);
    }

    const exportReport = () => {
        const url = filterDate ? `/api/agent/reports/training-evaluation?start_date=${filterDate[0]}&end_date=${filterDate[1]}&export=true` : '/api/reports/agent/training-evaluation?export=true';
        window.open(BASEURL + url);
    }


    return (
        <>
            <div className="TrainerWiseEvaluation">
                <div className="reportTableArea card">
                    <Row>
                        <Col md={6}>
                            <span className="reportTitle">Training Evaluation</span>
                        </Col>
                        <Col md={6} className="">
                            <Button className="float-right" style={{ marginLeft: "10px" }} onClick={() => exportReport()}> <AiOutlineDownload /> Export</Button>
                            <Button className="float-right" onClick={() => filterReport()}> <AiOutlineSearch /> Filter</Button>
                            <RangePicker className="float-right" onChange={getDateFiler} />
                        </Col>
                        </Row><br/>
                        <Row>
                        <Col md={12} className="">
                            <Select
                                showSearch
                                className="select"
                                style={{ width: "100%" }}
                                placeholder="Select Course"
                                onChange={handleStatus}
                                filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }  
                            >
                            
                                <Option value="">All</Option>
                                {courseList && courseList.map((course, index) => (
                                    <Option value={course.id} key={index}>{course.title}</Option>))}
                            </Select>
                           
                        </Col>
                    </Row>
                    <Divider />
                    <Table responsive hover className="reportTable">
                        <thead className="reportheading">
                            <tr>
                                <th className="font-bold gray-text-200">Training Name</th>
                                <th className="font-bold gray-text-200">Start Date</th>
                                <th className="font-bold gray-text-200">End Date</th>
                                <th className="font-bold gray-text-200 text-center">Content Time [Min]</th>
                                <th className="font-bold gray-text-200" width="50">Trainer ID</th>
                                <th className="font-bold gray-text-200 text-center">Trainer Reaction Rate</th>
                                <th className="font-bold gray-text-200 text-center">Module Reaction Rate</th>
                                <th className="font-bold gray-text-200 text-center">Environment Reaction Rate</th>
                                <th className="font-bold gray-text-200 text-center"> Quiz Score </th>
                                <th className="font-bold gray-text-200 text-center">Participation Count</th>
                                <th className="font-bold gray-text-200 text-center">LOB</th>
                            </tr>
                        </thead>
                        <tbody>

                            { trainings && trainings.map((training, i) => (
                            <tr key={i}>
                              
                                <td>{training?.training_name}</td>
                                <td>{moment(training.start_date).isValid() ? moment(training.start_date).format('DD-MM-YYYY') : ''}</td>
                                <td>{moment(training.end_date).isValid() ? moment(training.end_date).format('DD-MM-YYYY') : ''}</td>
                                <td className="text-center">{training?.content_time}</td>
                                <td className="width-100" width="50">{training?.trainer_id}</td>
                                <td className="text-center">{Math.round(training?.trainer_reaction_rate)}%</td>
                                <td className="text-center">{training?.module_reaction_rate}%</td>
                                <td className="text-center">{training?.environment_reaction_rate}%</td>
                                <td className="text-center">{training?.quiz_score_achive}</td>
                                <td className="text-center">{training?.participant}</td>
                                <td className="text-center">{training?.lob}</td>
                            </tr>
                            ))
                            }
                        </tbody>
                    </Table>
                    {paginateInfo && (
                        <Pagination defaultCurrent={1} total={paginateInfo.total} onChange={(page) => {
                            loadAllTrainings(filterDate, page);
                        }} />
                    )}
                </div>
            </div>
        </>
    )
}
