import { DetailsQuiz } from '../../components/allquiz/DetailsQuiz'
import QuickCall from '../../components/quickcall/QuickCall'
import './Quiz.css'


export const QuizDetails = () => {
    return (
        <div>
            {/* QuickCall-component  */}
            <QuickCall/>

            <DetailsQuiz/>
        </div>
    )
}
