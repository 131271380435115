import { Divider, Select } from 'antd';
import dotenv from "dotenv";
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { getApiRequest } from '../../api/CommonApi';
import ElearnigFilter from '../../components/elearning/elearnigFilter/ElearnigFilter';
import MyWishListCard from '../../components/elearning/mycoursescard/MyWishListCard';
import QuickCall from '../../components/quickcall/QuickCall';
import Searchbar from '../../components/searchbar/SearchBar';
import { urlChecker } from '../../utils/urlChecker';
import './Elearning.css';
dotenv.config();
const { Option } = Select;

function handleChange(value) {
    console.log(`selected ${value}`);
}

let searchText='';

export default function MyWishlist() {

    const dispatch = useDispatch();
    const [cookies] = useCookies(['user']); 
    const BASEURL = urlChecker();

    const [baseUrl, setBaseUrl] = useState(BASEURL+'/api/courses/user-courses');
    const [tags, setTags] = useState();
    const [categories, setCategories] = useState();
    const [levels, setLevels] = useState();
    const [languages, setLanguages] = useState();
    const [wishCourses, setWishCourses] = useState();
    const [nextPage, setNextPage] = useState();
    const [sort, setSort] = useState('latest');

    searchText = useSelector(state => state.selectedSearchTitle.title)

    let selectedCategory = useSelector(state => state.categoryType.categoryTypes)
    selectedCategory = selectedCategory.join(); 

    let selectedtags = useSelector(state => state.tagSelect.tags);
    selectedtags = selectedtags.join();

    let selectedLevels = useSelector(state => state.selectedLevel.levels)
    selectedLevels = selectedLevels.join(); 

    let selectedLanguages = useSelector(state => state.selectedLanguage.languages)
    selectedLanguages = selectedLanguages.join(); 

    useEffect(() => {
        fetchCourseResources();
      }, []);
  
      const fetchCourseResources = async () =>{
        const params = {}
        const response = await getApiRequest('/api/courses/course-resources', '', params)
        setTags(response?.data?.data?.tags);
        setCategories(response?.data?.data?.categories);
        setLevels(response?.data?.data?.levels);
        setLanguages(response?.data?.data?.languages);
      };

      const fetchMyWishCourses = async () => {
        const params = {
            search: searchText,
            category_id: selectedCategory?selectedCategory:'',
            level_id: selectedLevels?selectedLevels:'',
            lang_id: selectedLanguages?selectedLanguages:'',
            tags: selectedtags?selectedtags:'',
            sort: sort,
        }

       const response = await getApiRequest('/api/course-wishlist/index', '', params)
        console.log('my wish courses response list', response);
        setWishCourses(response?.data?.data?.wishCourses);
 
    }

    useEffect(() => {
        fetchMyWishCourses();
    }, [searchText, selectedtags, selectedCategory,selectedLevels, selectedLanguages, sort]);

    const handleDropdown = (e) => {
        console.log('my course sorting', e)
        setSort(e);
      }

    return (
        <div className="elearnigMyCourses">
             {/* QuickCall-component  */}
             <QuickCall />
            <span className="elearnigAllCourseTitle">My Wishlist</span>
            <Divider className="featuredDivider" />

            <div className="elearnigAllCoursesDivisionArea">
                <Searchbar />
                <Divider />
                <div className="elearnigAllCoursesResults">
                    <div className="left">
                        <span className="elearnigAllCoursesResultsCount">About 12 results found (.03 sec)</span>
                    </div>
                    <div className="right">
                        <span className="elearnigAllCoursesortby">Sort by: </span>
                        <Select defaultValue={sort} style={{ width: 100 }} placeholder="Sort" onChange={handleDropdown}>
                        <Option value="latest">Latest</Option>
                        <Option value="older">Older</Option>
                        <Option value="updated">Updated</Option>
                        <Option value="a2z">A to Z</Option>
                        <Option value="z2a">Z to A</Option>
                        </Select>
                    </div>
                </div>
            </div>

            <Row>
                <Col md={3}>
                    <ElearnigFilter 
                    tags={tags} 
                    categories={categories} 
                    levels={levels} 
                    languages={languages}
                    />
                </Col>
                <Col md={9} className="">
                    <Row>
                        { wishCourses && wishCourses.map( (course, i) => (
                        <Col md={4} className="pl-0" key={i}>
                            <MyWishListCard course={course}/>
                        </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
        </div>
    )
}
