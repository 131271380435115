import { Collapse, Divider } from 'antd';
import { AiOutlinePlayCircle } from 'react-icons/ai';
import { VscCircleFilled } from 'react-icons/vsc';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { getApiRequest, postApiRequest } from '../../../api/CommonApi';
import { setCourseLesson } from '../../../services/actions/CourseAction';
import './RightAccordion.css';



const { Panel } = Collapse;

function callback(key) {
  console.log(key);
}

export default function MyRightAccordion() {

   let course = useSelector((state) => state.course);
   const dispatch = useDispatch();
   const history = useHistory();

   const showContent = async (lesson, prevLesson, prevSection, currentSection) => {
        console.log('selected lesson', lesson);
        console.log('selected prevLesson', prevLesson);
        console.log('selected prevSection', prevSection);
        console.log('currentSection', currentSection);
      //  return false;

       const lessonStatus = prevLesson ? await checkPreviousLessonStatus(prevLesson) : await checkPreviousSectionCompletationStatus(prevSection);
       console.log('selected lessonStatus', lessonStatus);
       if(!lessonStatus){
        toast.error('Please complete the previous course content!');
        return false;
       }
       
       if(lesson.type == 'document'){
        const response = await getApiRequest(`/api/course-lessons/document/show/${lesson.id}`);
        dispatch(setCourseLesson(response?.data))
       }else{
        dispatch(setCourseLesson(lesson))
       }
   }

   const checkPreviousLessonStatus = async (prevLesson) => {
       const res = await getApiRequest(`/api/courses/lesson-status/${prevLesson.id}`);
       console.log('res content', res);
       return res.data;
   }

   const checkPreviousQuizStatus = async (prevQuiz) => {
    const res = await getApiRequest(`/api/courses/user-previous-quiz/status/${prevQuiz.id}`);
    return res.data;
    }

    const checkPreviousSectionCompletationStatus = async (prevSection, activeContent) => {
        console.log('pevious section', prevSection);
        console.log('pevious section activeContent', activeContent);
        if(prevSection?.section_resources?.length > 0){
            let lastLesson = prevSection?.section_resources?.slice(-1)[0];
            console.log('wwww', lastLesson);
            if(!lastLesson?.lesson_status){
                return false;
            }
            return true;
        }else if(prevSection?.section_quizzes?.length > 0){
            let lastQuiz = prevSection?.section_quizzes?.slice(-1)[0];
            //const result = messages.filter(message => !message['_id'].match(pattern));
            console.log('vvvvv22', lastQuiz);
            console.log('vvvvv activeContent', activeContent?.id);
            if(lastQuiz?.course_quiz_status?.status != "completed"){
                    return false;
            }
            return true;
        }else if(prevSection?.section_lessons.length > 0){
           // let lastLesson = prevSection?.section_lessons?.pop();
            let lastLesson = prevSection?.section_lessons?.slice(-1)[0];
            console.log('xxxx', lastLesson);
            if(!lastLesson?.lesson_status){
                return false;
            }
            return true;
          //  return false;
        }else{
            console.log('zzzz');
            return true;
        }
    }

    const checkCurrentSectionCompletationStatus = async (currentSection, activeContent) => {
        console.log('current section', currentSection);
        console.log('current section activeContent', activeContent);
        //return false;
        if(currentSection?.section_resources?.length > 0){
            let lastLesson = currentSection?.section_resources?.slice(-1)[0];
            console.log('wwww', lastLesson);
            if(!lastLesson?.lesson_status){
                return false;
            }
           // return true;
        }
        else if(currentSection?.section_lessons.length > 0){
            let newLesson = currentSection?.section_lessons?.filter( lesson => lesson.id !== activeContent.id ).map(filteredLesson => (
                filteredLesson.lesson_status?.status == "completed" ? true : false
             ))

             return newLesson.toString() == 'true' ? true : false;
           //  return Boolean(newLesson.toString());
           // let lastLesson = currentSection?.section_lessons?.pop();
            // let lastLesson = currentSection?.section_lessons?.slice(-1)[0];
            // console.log('xxxx', lastLesson);
            // if(!lastLesson?.lesson_status){
            //     return false;
            // }
           // return true;
          //  return false;
        }
        else if(currentSection?.section_quizzes?.length > 0){

            let newData = currentSection?.section_quizzes?.filter( quiz => quiz.id !== activeContent.id ).map(filteredQuiz => (
               filteredQuiz.course_quiz_status?.status == "completed" ? true : false
            ))
            return newData.toString() == 'true' ? true : false;
          //  return newData;

            let lastQuiz = currentSection?.section_quizzes?.slice(-1)[0];
            //const result = messages.filter(message => !message['_id'].match(pattern));
            console.log('vvvvv22', lastQuiz);
            console.log('vvvvv activeContent', activeContent?.id);
            if(lastQuiz?.course_quiz_status?.status != "completed"){
                if(lastQuiz?.id == activeContent?.id){
                    return true;
                }else{
                    return false;
                }
            }
           
          //  return true;
        }
       else{
            console.log('zzzz');
            return true;
        }

    
    }

   const startCourseQuiz = async (courseQuiz, prevQuiz, prevSection, ownSection) => {
    console.log('previous prevSection', prevSection);
    console.log('previous quiz', prevQuiz);
    console.log('ownSection quiz', ownSection);
   // return false;

    if(prevQuiz){
        const quizStatus = prevQuiz ? await checkPreviousQuizStatus(prevQuiz) : '';
        console.log('selected quizStatus', quizStatus);
        if(prevQuiz && !quizStatus){
         toast.error('Please complete the previous quiz!');
         return false;
        }
    }else if(prevSection && ownSection){
        const prevSectionCompletionStatus = await checkPreviousSectionCompletationStatus(prevSection);
        console.log('prevSectionCompletionStatus', prevSectionCompletionStatus);
        if(!prevSectionCompletionStatus){
            toast.error('Please complete the previous section course content!');
            return false;
        }
        const currentSectionCompletionStatus = await checkCurrentSectionCompletationStatus(ownSection, courseQuiz);
        console.log('dfdfd11', currentSectionCompletionStatus);
        //return false;
        if(!currentSectionCompletionStatus){
            toast.error('Please complete the previous course content!');
            return false;
        }
        
       // return true;
    }else if(prevSection){
        const prevSectionCompletionStatus = await checkPreviousSectionCompletationStatus(prevSection);
        console.log('has only pre section', prevSectionCompletionStatus);
        if(!prevSectionCompletionStatus){
            toast.error('Please complete the previous section course content!');
            return false;
        }
        console.log('has pre section response', prevSectionCompletionStatus);
      //  return false;
    }else if(ownSection){
        console.log('own section courseQuiz', courseQuiz);
        const sectionCompletionStatus = await checkCurrentSectionCompletationStatus(ownSection, courseQuiz);
        console.log('dfdfd11', sectionCompletionStatus);
        //return false;
        if(!sectionCompletionStatus){
            toast.error('Please complete the previous course content!');
            return false;
        }
    }else{
        return true;
    }

    console.log('update courseQuiz', courseQuiz);

    const rr = await updateQuizCompleteStatus(courseQuiz);
    console.log('update ddd', rr);
    //return false;

    const response = getApiRequest(`/api/courses/course-quizzes/status/${courseQuiz.id}`);
    const quizId = courseQuiz?.quiz?.id
    postApiRequest(`/api/quiz-attempt/start`, "", { quiz_id: quizId })
    .then((res) => {
    console.log('startCourseQuiz res', res);

      if(!res.data.data){
        toast.error('Quiz already attend!');
        return false;
      }
      const response = getApiRequest(`/api/courses/course-quizzes/status/${courseQuiz.id}`);

      let firstQuestion = res?.data?.data?.question;
      let attempt = res?.data?.data?.attempt;
      res.status === 200 &&
        history.push(`/quiz-attempt/${attempt.id}/${firstQuestion.id}`);
    })
    .catch((err) => {
      console.log(err);
    });
  }

    // const checkCoursePreviousResourceCompletation = async (courseQuiz) => {

    //     const response = await getApiRequest(`/api/agent/course-lesson-quizzes/status/${courseQuiz.course_id}/${courseQuiz.id}`);
    //     if(response.lesson_status){
    //         return true;
    //     }else{
    //         return false;
    //     }
    // }

    const updateQuizCompleteStatus = async (courseQuiz) => {
        const response = await getApiRequest(`/api/agent/course-quizzes/status/${courseQuiz.id}`);
        return response;
    }

  const showResource = async (resource) => {
    console.log('selected resource', resource);

   if(resource.type == 'document'){
       const response = await getApiRequest(`/api/course-resources/document/show/${resource.id}`);
       console.log('other resource pdf response', response);
    dispatch(setCourseLesson(response?.data))
   }else{
    dispatch(setCourseLesson(resource))
   }
}

   var totalTimeInMin = course?.total_course_duration ?? 0;
   let mHours = Math.floor(totalTimeInMin / 60);
   let mMinutes = totalTimeInMin % 60;

    return (
        <div className="myCourseDetailsRightAccordion ge-card">
            <span className="myCourseDetailsRightAccordionTitle">Courses Content</span>
            <Divider />
            <div className="myCourseDetailsRightAccordionDetails">
                <ul className="RightAccordionDetailsList">
                    <li className="RightAccordionDetailsItem">{course && course.course_sections ? course.course_sections.length : 0} Sections</li>
                    <li className="RightAccordionDetailsItem"><VscCircleFilled /> {course && course.course_lessons ? course.course_lessons.length : 0} Lessons</li>
                    <li className="RightAccordionDetailsItem"><VscCircleFilled />  {mHours} hours {mMinutes} mins</li>
                </ul>
            </div>
            <div className="myCourseDetailsRightAccordionArea">
                <Collapse defaultActiveKey={['1']} onChange={callback}>
                { course && course?.course_sections && course?.course_sections.map((section, index) => (
                    <Panel
                        header={
                            <div className="myCourseDetailsRightAccordionHeading">
                                <div className="info">
                                    <h3 className="myCourseDetailsRightAccordionHeadingTitle">{section.title}</h3>
                                    {/* <span className="myCourseDetailsRightAccordionTime"><VscCircleFilled /> {course?.course?.total_course_duration} min</span> */}
                                </div>
                            </div>
                        }
                        key={index+1}>
                       
                        <ul className="rightAccordionDscList">
                            {section.section_lessons.length > 0 && 
                            <li className="rightAccordionDscItem sectionLevel">Course Lesson</li>
                            }
                            { section.section_lessons && section?.section_lessons.map((lesson, i) => (
                              
                            <li className="rightAccordionDscItem skill-body" dataid={lesson.title} onClick={() => showContent(lesson, section.section_lessons[i - 1], course?.course_sections[index-1], course?.course_sections[index])} key={i}>
                                <span className={`rightAccordionDscItemTitle ${(lesson?.lesson_status?.status != 'completed') ? 'font-weight-bold' : ''}`} ><AiOutlinePlayCircle /> {lesson?.title}</span>
                                <span className="rightAccordionDscItemTime">{lesson.duration} min</span>
                            </li>
                            ))}
                            
                            {section.section_quizzes.length > 0 && 
                            <li className="rightAccordionDscItem sectionLevel">Course Quiz</li>
                            }
                            { section.section_quizzes && section?.section_quizzes.map((quiz, i) => (
                            <li className="rightAccordionDscItem" onClick={(e) => startCourseQuiz(quiz, section.section_quizzes[i - 1], course?.course_sections[index-1], course?.course_sections[index])} key={i}>
                                <span className={`rightAccordionDscItemTitle ${quiz?.course_quiz_status == null ? 'font-weight-bold' : ''}`} ><AiOutlinePlayCircle /> {quiz?.quiz?.title}</span>
                                <span className="rightAccordionDscItemTime">{quiz?.quiz?.quiz_duration} min</span>
                            </li>
                            ))}

                            {section.section_resources.length > 0 && 
                                <li className="rightAccordionDscItem sectionLevel">Course Resource</li>
                            }
                            
                            { section.section_resources && section?.section_resources.map((resource, i) => (
                            <li className="rightAccordionDscItem" onClick={() => showResource(resource)} key={i}>
                                <span className={`rightAccordionDscItemTitle`} ><AiOutlinePlayCircle /> {resource?.title}</span>
                            </li>
                            ))}
                        </ul>
                    </Panel>
                    ))}

                    { course.other_resources?.other_lessons.length > 0 &&
                    <Panel 
                        header={
                            <div className="myCourseDetailsRightAccordionHeading">
                                <div className="info">
                                    <h3 className="myCourseDetailsRightAccordionHeadingTitle">Other Resources</h3>
                                </div>
                            </div>
                        }
                        key={3}>
                        <ul className="rightAccordionDscList">
                            { Object.keys(course.other_resources?.other_lessons).length>0 && 
                            <li className="rightAccordionDscItem sectionLevel">Course Lesson</li>
                            }
                            
                            { Object.values(course.other_resources?.other_lessons).map((lesson, i) => (
                            <li className="rightAccordionDscItem" onClick={() => showContent(lesson, course?.other_resources?.other_lessons[i - 1], course?.course_sections[course?.course_sections.length -1], course?.course_sections[course?.course_sections.length])} key={i}>
                                <span className={`rightAccordionDscItemTitle ${(lesson?.lesson_status?.status != 'completed') ? 'font-weight-bold' : ''}`} ><AiOutlinePlayCircle /> {lesson?.title}</span>
                                <span className="rightAccordionDscItemTime">{lesson.duration} min</span>
                            </li>
                            ))}

                            { Object.keys(course.other_resources?.other_quizzes).length>0 && 
                            <li className="rightAccordionDscItem sectionLevel">Course Quiz</li>
                            }
                            
                            { Object.values(course.other_resources.other_quizzes).map((quiz, i) => (
                            <li className="rightAccordionDscItem" onClick={() => startCourseQuiz(quiz, course.other_resources.other_quizzes[i-1])} key={i}>
                                <span className={`rightAccordionDscItemTitle ${(quiz?.quiz_status?.status != 'completed') ? 'font-weight-bold' : ''}`} ><AiOutlinePlayCircle /> {quiz?.quiz?.title}</span>
                                <span className="rightAccordionDscItemTime">{quiz?.quiz?.quiz_duration} min</span>
                            </li>
                            ))}

                            { Object.keys(course.other_resources?.other_resources).length>0 &&
                            <li className="rightAccordionDscItem sectionLevel">Course Resources</li>
                            }
                            
                            { Object.values(course.other_resources?.other_resources).map((resource, i) => (
                            <li className="rightAccordionDscItem"  onClick={() => showContent(resource)} key={i}>
                                <span className={`rightAccordionDscItemTitle font-weight-bold`}><AiOutlinePlayCircle /> {resource?.title}</span>
                            </li>
                            ))}
                        </ul>
                    </Panel>
                    }

                    
                    {/* <button className="myCourseDetailsRightAccordionMoreButton">more view</button> */}
                </Collapse>
            </div>
        </div>
    )
}
