import { DatePicker, Divider, Input, Select } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BiSearchAlt2 } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { getApiRequest } from "../../api/CommonApi";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import QuickCall from "../../components/quickcall/QuickCall";
import SearchContent from "../../components/searchcontent/SearchContent";
import SearchTagFilter from "../../components/searchContentfilter/SearchTagFilter";
import { setFaqs } from "../../services/actions/faq/FaqAction";
import "./CallHandle.css";

const { Option } = Select;
const { RangePicker } = DatePicker;

export default function CallHandle() {
  const faqs = useSelector((state) => state.allFaqs.faqs);
  const dispatch = useDispatch();
  let [cnt, setCnt] = useState(0);
  const [title, setTitle] = useState("");
  const [sort, setSort] = useState("");
  const [status, setStatus] = useState("");
  let selectedCategory = useSelector(
    (state) => state.categoryType.categoryTypes
  );
  selectedCategory = selectedCategory.join(); // make comma seperated value
  let selectedTags = useSelector((state) => state.tagSelect.tags);
  selectedTags = selectedTags.join();
  let selectedFavouriteIds = useSelector(
    (state) => state.selectedFavouriteIds.favouriteId
  );
  let [pagination, setPagination] = useState(1);
  const [filterDate, setFilterDate] = useState({
    start_date: "",
    end_date: "",
  });

  const fetchFaqs = async () => {
    const params = {
      load: pagination,
      type: "click",
      sort: sort,
      tag: selectedTags,
      category_id: selectedCategory ? selectedCategory : "",
      title: title,
      from_date: filterDate.start_date,
      to_date: filterDate.end_date,
      status: status,
    };
    const response = await getApiRequest("/api/faq/new-index", "", params);
    // if (response.data.data.length == 0) {
    //   console.log("empty");
    // }
    dispatch(setFaqs(response?.data?.data));
    setCnt(response?.data?.count);
  };

  useEffect(() => {
    fetchFaqs();
    document.title = "Service Modality";
  }, [
    sort,
    selectedTags,
    filterDate,
    pagination,
    title,
    selectedCategory,
    status,
  ]);

  const handleDropdown = (e) => {
    setSort(e);
  };

  const handleOnClick = (e) => {
    setPagination(pagination + 1);
  };

  const handleKeyDown = (e) => {
    setTitle(e.target.value);
    if (title === e.target.value) {
      fetchFaqs();
    }
  };

  const getDateFiler = (date, dateString) => {
    console.log(date, dateString);
    if (dateString[0] !== "" && dateString[1] !== "") {
      const startDate = moment(dateString[0]).format("YYYY-MM-DD");
      const endDate = moment(dateString[1]).format("YYYY-MM-DD");
      setFilterDate({ start_date: startDate, end_date: endDate });
    } else {
      setFilterDate({ start_date: "", end_date: "" });
    }
  };

  return (
    <div className="all-faqs">
      <QuickCall />

      <BreadCrumb link="Service-Modality" /><br/>

      <div className="search-area">
        <Input
          size="large"
          placeholder="Search here.."
          onChange={handleKeyDown}
          prefix={<BiSearchAlt2 />}
        />
      </div>

      <div className="faqs-content-area">
        <span className="faqTitle">Call Handling Process FAQs</span>
        <Divider />
        <div className="faqResultCounting">
          <div className="results">
            <span className="totalResults">
              About {faqs?.length} results found
            </span>
          </div>
          <div className="sortby">
            <span className="resultsSort">Sort by:</span>
            <Select
              showSearch
              style={{ width: 100 }}
              placeholder="Status"
              onChange={(e) => setStatus(e)}
            >
              <Option value="all">All</Option>
              {/* <Option value="unpublished">Unpublished</Option> */}
              <Option value="current">Current</Option>
              <Option value="archived">Archived</Option>
            </Select>
            <Select
              showSearch
              style={{ width: 100 }}
              placeholder="Sort"
              onChange={handleDropdown}
            >
              <Option value="latest">Latest</Option>
              <Option value="older">Older</Option>
              <Option value="updated">Updated</Option>
              <Option value="a2z">A to Z</Option>
              <Option value="z2a">Z to A</Option>
            </Select>
            <RangePicker className="float-right" onChange={getDateFiler} />
          </div>
        </div>

        <div className="faqResults">
          <Row className="searchContenResults">
            <Col xs={3}>
              <SearchTagFilter />
            </Col>
            <Col xs={9}>
              <SearchContent faqs={faqs} />
            </Col>
            {cnt > 0 && cnt > faqs?.length ? (
              <button className="loardMore" onClick={handleOnClick}>
                Load More
              </button>
            ) : (
              ""
            )}
          </Row>
        </div>
      </div>
    </div>
  );
}
