import { combineReducers } from "redux";
import { categoryTypeSelectReducer } from "./CategorySelectReducer";
import { incomingCallReducer } from "./common/CallTrackReducer";
import { changeAbleValueReducer } from "./common/ChangeAbleValueReducer";
import { languageReducer, levelReducer, newQAReducer } from "./common/CommonReducer";
import { searchTitleReducer } from "./common/SearchTitleReducer";
import { confusionFileReducer, confusionImageReducer } from "./confusion/ConfusionReducer";
import { courseLessonReducer, courseReducer, selectedCourseReducer, selectedCourseTagReducer } from "./CourseReducer";
import { faqReducer, selectedFaqReducer } from "./FaqReducer";
import { selectedFavouriteReducer } from "./FavouriteReducer";
import { loginMethodReducer } from "./LoginMethodReducer";
import { productReducer, selectedProductReducer } from "./Reducer";
import { tagSelectReducer } from "./TagSelectReducer";
import { userReducer } from "./TokenReducer";
import { typeReducer } from "./TypeReducer";
import { selectedVideoReducer, setFlagVideoReducer, videoReducer } from "./video/VideoReducer";

const reducers = combineReducers({
    allProducts: productReducer,
    product: selectedProductReducer,
    user: userReducer,
    allFaqs: faqReducer,
    faq: selectedFaqReducer,
    types: typeReducer,
    allCourses: courseReducer,
    course: selectedCourseReducer,
    activeLesson: courseLessonReducer,
    //featureCourses: featureCourseReducer,
    // courseCategories: courseCategoryReducer,
    // courseTags: courseTagReducer,
    selectedCourseTags: selectedCourseTagReducer,
    selectedLevel: levelReducer,
    selectedLanguage: languageReducer,
    newQA: newQAReducer,
    categoryType: categoryTypeSelectReducer,
    tagSelect: tagSelectReducer,
    allVideos: videoReducer,
    selectedVideo: selectedVideoReducer,
    selectedSearchTitle: searchTitleReducer,
    confusionImage: confusionImageReducer,
    confusionFile: confusionFileReducer,
    incomingCallInfo: incomingCallReducer,
    selectedFavouriteIds: selectedFavouriteReducer,
    loginMethodReducer: loginMethodReducer,
    changeAbleValueReducer: changeAbleValueReducer,
    videoFlag: setFlagVideoReducer,
});

export default reducers;