import { Button, Form, Input } from "antd";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { postApiRequest } from "../../api/CommonApi";
import "./Login.css";

export function ResetPassword() {
    const { token } = useParams();
    const history = useHistory();
    const [password, setPassword] = useState({
        newPassword: "",
        confirmPassword: ""
    });

    useEffect(async () => {
        const response = await postApiRequest(`/api/remove-token`, '', { data: token });
        if (response?.data?.code === 400) {
            toast.error(response.data.message);
            history.push("/");
        }
    }, []);

    const onFinish = async (e) => {
        const response = await postApiRequest(`/api/reset-password`, '', {newPassword: password.newPassword, confirmPassword: password.confirmPassword, token: token});
        if (response?.data?.code === 200) {
            toast.success(response.data.message);
            history.push("/");
        }else{
            toast.error(response.data.message);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <div className="loginAuthentication">
            <div className="card">
                <div className="brandLogo">
                    <img className="logo" src="images/logo.png" alt="" />
                </div>
                <Form
                    name="basic"
                    initialValues={{ remember: true }}

                    layout="vertical"
                >
                    <Form.Item
                        label="New Password"
                        rules={[{ required: true, message: "Please set your new password!" }]}
                    >
                        <Input.Password onChange={(e) => setPassword({ ...password, ['newPassword']: e.target.value })} />
                    </Form.Item>

                    <Form.Item
                        label="Confirm Password"
                        rules={[{ required: true, message: "Please confirm your password!" }]}
                    >
                        <Input.Password onChange={(e) => setPassword({ ...password, ['confirmPassword']: e.target.value })} />
                    </Form.Item>

                    <Form.Item>
                        <Button className="primary-btn" htmlType="submit" onClick={onFinish}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}
