import { Collapse, Select } from "antd";
import dotenv from "dotenv";
import moment from "moment";
import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useStopwatch } from "react-timer-hook";
import { Customer } from "./Customer";
import { Message } from "./Message";
import "./QuickCall.css";
dotenv.config();

const { Panel } = Collapse;
const { Option } = Select;

// const ENDPOINT = process.env.REACT_APP_SOCKET_ENDPOINT;
// const socket = socketIOClient(ENDPOINT);

export default function QuickCall() {
  const dispatch = useDispatch();
  const [cookies] = useCookies(["user"]);
  const [username, setusername] = useState();
  const [startTime, setStartTime] = useState();
  const [time, setTime] = useState([]);

  const [incommingUser, setIncommingUser] = useState({
    userId: "",
    name: "",
    phone_no: "",
    email: "",
    user_type: "",
    call_type: "Recharge",
    start_time: "",
  });

  const [expandIconPosition, setExpandIconPosition] = useState("right");
  const [response, setResponse] = useState("");
  const [openPanel, setOpenPanel] = useState(1);
  const { seconds, minutes, hours, days, isRunning, start, pause, reset } =
    useStopwatch({ autoStart: false });

  const userInfo = JSON.parse(localStorage.getItem("user_information"));

  function callback(key) {
    console.log(key);
    openPanel == 0 ? setOpenPanel(1) : setOpenPanel(0);
  }

  let localDateTime;

  const permissions = (userInfo?.permission) ? (userInfo?.permission[0]) : '';
  
  //   something
  // socket.on(`start`, (data) => {
  //   console.log('socket data', data);
  //   if (data?.user_id == cookies?.user?.id) {
  //     //setOpenPanel(1);
  //     start();
  //     setIncommingUser(data);
  //     dispatch(serIncomingCall(data));
  //   }
  // });

  // socket.on("end", (data) => {
  //   data = JSON.parse(data);
  //   if (data.user_id == userInfo.id) {
  //     pause();
  //    // setOpenPanel(0);
  //   }
  // });


  localDateTime = incommingUser.start_time
    ? moment
        .utc(incommingUser.start_time)
        .local()
        .format("MMMM DD, YYYY hh:mm A")
    : "";

  return (
    <>
      {
        permissions && permissions.includes("sms") ? (
        <div className="quickCall">
          <div className="card">
            {/* {openPanel == 0 ? (
              <div className="call-info">
                <span>No Incoming Call</span>
              </div>
            ) : ( */}
            <Collapse
              activeKey={openPanel}
              destroyInactivePanel={true}
              onChange={callback}
              expandIconPosition={expandIconPosition}
            >
              {/* <Panel
                header={
                  <div className="quickCallCollapse">
                    <div className="quickInCall">
                      <span className="inCallText">Incall</span>
                      <Divider type="vertical" />
                      <span className="inCall">USSD: 
                      <Input
                        style={{ width: 300, marginLeft:10 }}
                        //onChange={(e) => onInputChange(e)}
                        value={incommingUser?.phone_no}
                        placeholder="phone no."
                      />
                      </span>
                    </div>
                    <div className="quickCallingTime">
                      <span className="callTime">
                        {" "}
                        <BsClock /> { hours < 10 ? " 0" + hours : hours} hr :{" "}
                        {minutes < 10 ? "0" + minutes : minutes} min :{" "}
                        {seconds < 10 ? "0" + seconds : seconds} sec
                      </span>
                    </div>
                  </div>
                }
                key="1"
              > */}
                {/* quickCallCollapseBody */}
                <div className="quickCallCollapseBody">
                  {/* <div className="left">
                    <div className="leftContent">
                      <div className="callTypes">
                        <span className="text">call type:</span>
                        <Select
                          defaultValue={incommingUser.call_type}
                          style={{ width: 150 }}
                        >
                          <Option value="Recharge">Recharge</Option>
                          <Option value="Internet Package">Internet Package</Option>
                          <Option value="Bonus">Bonus</Option>
                        </Select>
                      </div>
                      <div className="startTime">
                        {" "}
                        <span className="text">start time:</span>
                        <span className="time">{localDateTime}</span>
                      </div>
                    </div>
                  </div> */}
                  <div className="right">
                    <Customer />
                    <Message />
                  </div>
                </div>
              {/* </Panel> */}
            </Collapse>
            {/* )} */}
          </div>
        </div>  
        ) : ''
      }
    </>
  )
}