import React, { useEffect, useState } from 'react';
//import JitsiMeetJS from "./external_api.min.js";
import { useCookies } from 'react-cookie';
import { useHistory } from "react-router-dom";

function JitsiMeetComponent(props) {
  const webinar = props.webinar;
  const [cookies] = useCookies(['user']); 
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  const jitsiContainerStyle = {
    display: (loading ? 'none' : 'block'),
    width: '100%',
    height: '100%',
  }

  const userInfo = JSON.parse(localStorage.getItem("user_information"));

 function startConference() {
  
  try {
   const domain = '172.22.9.65';
   const options = {
    roomName: webinar?.title,
    height: 400,
    parentNode: document.getElementById('jitsi-container'),
    interfaceConfigOverwrite: {
     filmStripOnly: false,
     SHOW_JITSI_WATERMARK: false,
    },
    configOverwrite: {
     disableSimulcast: false,
    },
   };

  // const api = new JitsiMeetJS.JitsiMeetExternalAPI(domain, options);
   const api = new JitsiMeetExternalAPI(domain, options);
   api.addEventListener('videoConferenceJoined', () => {
    console.log('Local User Joined');
    setLoading(false);
    api.executeCommand('displayName', userInfo?.name ? userInfo?.name : 'Guest user');
   });
   api.on('readyToClose', () => {
    console.log('opaaa close ');
    window.location.href = `/webinar/${webinar.id}`;
    });
  } catch (error) {
   console.error('Failed to load Jitsi API', error);
  }
 }

 useEffect(() => {
  if (window.JitsiMeetExternalAPI) startConference();
  else alert('Jitsi Meet API script not loaded');
 }, []);

 return (
  <div
   style={containerStyle}
  >
   {loading}
   <div
    id="jitsi-container"
    style={jitsiContainerStyle}
   />
  </div>
 );
}

export default JitsiMeetComponent;