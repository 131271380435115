import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import "swiper/components/navigation/navigation.min.css";
import SwiperCore, {
    Navigation
} from 'swiper/core';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import { getApiRequest } from "../../api/CommonApi";
import { urlChecker } from "../../utils/urlChecker";
import './VideoLibrarySlider.css';
  
  SwiperCore.use([Navigation]);

export default function VideoLibrarySlider() {

    const [featureVideos, setFeatureVideos] = useState();

    const BASEURL = urlChecker();

    useEffect( () => {
         fetchFeatureVideo();
    }, []);

    const fetchFeatureVideo = async () => {
        const res = await getApiRequest('/api/video/feature-videos');
        setFeatureVideos(res?.data?.data);
    }


    return (
        <div className="carousel-21 slickSliderVideo">
            <Swiper navigation={true} slidesPerView={1} spaceBetween={5} breakpoints={{
                "640": {
                    "slidesPerView": 2,
                    "spaceBetween": 20
                },
                "768": {
                    "slidesPerView": 4,
                    "spaceBetween": 40
                },
                "1024": {
                    "slidesPerView": 5,
                    "spaceBetween": 50
                }
                }} className="mySwiper">

                {featureVideos && featureVideos.map((video, i) => {
                return(
                <SwiperSlide>
                    <img src={BASEURL + video.thumbnail} alt="" />
                    <Link to={`/video-details/${video.id}`}>
                        <svg width="35" height="35" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M25 0C11.2142 0 0 11.2152 0 25C0 38.7848 11.2142 50 25 50C38.7858 50 50 38.7848 50 25C50 11.2152 38.7858 0 25 0ZM34.9386 25.8759L20.3553 35.2509C20.1844 35.3617 19.987 35.4167 19.7917 35.4167C19.6208 35.4167 19.4479 35.3739 19.2933 35.2896C18.9575 35.1064 18.75 34.7564 18.75 34.375V15.625C18.75 15.2436 18.9575 14.8936 19.2933 14.7104C19.6229 14.5294 20.0358 14.5405 20.3553 14.7491L34.9386 24.1241C35.2356 24.3153 35.4167 24.646 35.4167 25C35.4167 25.354 35.2356 25.6846 34.9386 25.8759Z" fill="#F9F9F9"/>
                        </svg>
                    </Link>
                </SwiperSlide>
                )

                })}

                {/* <SwiperSlide>
                    <img src="images/slider/s2.png" alt="" />
                    <Link to="/video-details">
                        <svg width="35" height="35" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M25 0C11.2142 0 0 11.2152 0 25C0 38.7848 11.2142 50 25 50C38.7858 50 50 38.7848 50 25C50 11.2152 38.7858 0 25 0ZM34.9386 25.8759L20.3553 35.2509C20.1844 35.3617 19.987 35.4167 19.7917 35.4167C19.6208 35.4167 19.4479 35.3739 19.2933 35.2896C18.9575 35.1064 18.75 34.7564 18.75 34.375V15.625C18.75 15.2436 18.9575 14.8936 19.2933 14.7104C19.6229 14.5294 20.0358 14.5405 20.3553 14.7491L34.9386 24.1241C35.2356 24.3153 35.4167 24.646 35.4167 25C35.4167 25.354 35.2356 25.6846 34.9386 25.8759Z" fill="#F9F9F9"/>
                        </svg>
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <img src="images/slider/s3.png" alt="" />
                    <Link to="/video-details">
                        <svg width="35" height="35" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M25 0C11.2142 0 0 11.2152 0 25C0 38.7848 11.2142 50 25 50C38.7858 50 50 38.7848 50 25C50 11.2152 38.7858 0 25 0ZM34.9386 25.8759L20.3553 35.2509C20.1844 35.3617 19.987 35.4167 19.7917 35.4167C19.6208 35.4167 19.4479 35.3739 19.2933 35.2896C18.9575 35.1064 18.75 34.7564 18.75 34.375V15.625C18.75 15.2436 18.9575 14.8936 19.2933 14.7104C19.6229 14.5294 20.0358 14.5405 20.3553 14.7491L34.9386 24.1241C35.2356 24.3153 35.4167 24.646 35.4167 25C35.4167 25.354 35.2356 25.6846 34.9386 25.8759Z" fill="#F9F9F9"/>
                        </svg>
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <img src="images/slider/s4.png" alt="" />
                    <Link to="/video-details">
                        <svg width="35" height="35" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M25 0C11.2142 0 0 11.2152 0 25C0 38.7848 11.2142 50 25 50C38.7858 50 50 38.7848 50 25C50 11.2152 38.7858 0 25 0ZM34.9386 25.8759L20.3553 35.2509C20.1844 35.3617 19.987 35.4167 19.7917 35.4167C19.6208 35.4167 19.4479 35.3739 19.2933 35.2896C18.9575 35.1064 18.75 34.7564 18.75 34.375V15.625C18.75 15.2436 18.9575 14.8936 19.2933 14.7104C19.6229 14.5294 20.0358 14.5405 20.3553 14.7491L34.9386 24.1241C35.2356 24.3153 35.4167 24.646 35.4167 25C35.4167 25.354 35.2356 25.6846 34.9386 25.8759Z" fill="#F9F9F9"/>
                        </svg>
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <img src="images/slider/s5.png" alt="" />
                    <Link to="/video-details">
                        <svg width="35" height="35" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M25 0C11.2142 0 0 11.2152 0 25C0 38.7848 11.2142 50 25 50C38.7858 50 50 38.7848 50 25C50 11.2152 38.7858 0 25 0ZM34.9386 25.8759L20.3553 35.2509C20.1844 35.3617 19.987 35.4167 19.7917 35.4167C19.6208 35.4167 19.4479 35.3739 19.2933 35.2896C18.9575 35.1064 18.75 34.7564 18.75 34.375V15.625C18.75 15.2436 18.9575 14.8936 19.2933 14.7104C19.6229 14.5294 20.0358 14.5405 20.3553 14.7491L34.9386 24.1241C35.2356 24.3153 35.4167 24.646 35.4167 25C35.4167 25.354 35.2356 25.6846 34.9386 25.8759Z" fill="#F9F9F9"/>
                        </svg>
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <img src="images/slider/slide-1.jpg" alt="" />
                    <Link to="/video-details">
                        <svg width="35" height="35" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M25 0C11.2142 0 0 11.2152 0 25C0 38.7848 11.2142 50 25 50C38.7858 50 50 38.7848 50 25C50 11.2152 38.7858 0 25 0ZM34.9386 25.8759L20.3553 35.2509C20.1844 35.3617 19.987 35.4167 19.7917 35.4167C19.6208 35.4167 19.4479 35.3739 19.2933 35.2896C18.9575 35.1064 18.75 34.7564 18.75 34.375V15.625C18.75 15.2436 18.9575 14.8936 19.2933 14.7104C19.6229 14.5294 20.0358 14.5405 20.3553 14.7491L34.9386 24.1241C35.2356 24.3153 35.4167 24.646 35.4167 25C35.4167 25.354 35.2356 25.6846 34.9386 25.8759Z" fill="#F9F9F9"/>
                        </svg>
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <img src="images/slider/slide-2.jpg" alt="" />
                    <Link to="/video-details">
                        <svg width="35" height="35" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M25 0C11.2142 0 0 11.2152 0 25C0 38.7848 11.2142 50 25 50C38.7858 50 50 38.7848 50 25C50 11.2152 38.7858 0 25 0ZM34.9386 25.8759L20.3553 35.2509C20.1844 35.3617 19.987 35.4167 19.7917 35.4167C19.6208 35.4167 19.4479 35.3739 19.2933 35.2896C18.9575 35.1064 18.75 34.7564 18.75 34.375V15.625C18.75 15.2436 18.9575 14.8936 19.2933 14.7104C19.6229 14.5294 20.0358 14.5405 20.3553 14.7491L34.9386 24.1241C35.2356 24.3153 35.4167 24.646 35.4167 25C35.4167 25.354 35.2356 25.6846 34.9386 25.8759Z" fill="#F9F9F9"/>
                        </svg>
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <img src="images/slider/slide-3.jpg" alt="" />
                    <Link to="/video-details">
                        <svg width="35" height="35" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M25 0C11.2142 0 0 11.2152 0 25C0 38.7848 11.2142 50 25 50C38.7858 50 50 38.7848 50 25C50 11.2152 38.7858 0 25 0ZM34.9386 25.8759L20.3553 35.2509C20.1844 35.3617 19.987 35.4167 19.7917 35.4167C19.6208 35.4167 19.4479 35.3739 19.2933 35.2896C18.9575 35.1064 18.75 34.7564 18.75 34.375V15.625C18.75 15.2436 18.9575 14.8936 19.2933 14.7104C19.6229 14.5294 20.0358 14.5405 20.3553 14.7491L34.9386 24.1241C35.2356 24.3153 35.4167 24.646 35.4167 25C35.4167 25.354 35.2356 25.6846 34.9386 25.8759Z" fill="#F9F9F9"/>
                        </svg>
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <img src="images/slider/slide-4.jpg" alt="" />
                    <Link to="/video-details">
                        <svg width="35" height="35" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M25 0C11.2142 0 0 11.2152 0 25C0 38.7848 11.2142 50 25 50C38.7858 50 50 38.7848 50 25C50 11.2152 38.7858 0 25 0ZM34.9386 25.8759L20.3553 35.2509C20.1844 35.3617 19.987 35.4167 19.7917 35.4167C19.6208 35.4167 19.4479 35.3739 19.2933 35.2896C18.9575 35.1064 18.75 34.7564 18.75 34.375V15.625C18.75 15.2436 18.9575 14.8936 19.2933 14.7104C19.6229 14.5294 20.0358 14.5405 20.3553 14.7491L34.9386 24.1241C35.2356 24.3153 35.4167 24.646 35.4167 25C35.4167 25.354 35.2356 25.6846 34.9386 25.8759Z" fill="#F9F9F9"/>
                        </svg>
                    </Link>
                </SwiperSlide> */}

            </Swiper>
        </div>
    )
}
