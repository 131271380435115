import { useEffect, useState } from 'react';
import { useCookies } from "react-cookie";
import './Timer.css';

export default function Timer(){
    const [seconds, setSeconds] = useState(120);
    const [cookies, setCookie, removeCookie] = useCookies(["user"]);
    
    useEffect(() => {
        const interval = setInterval(() => {
          setSeconds(seconds => seconds>0 ? (seconds - 1): 0);
        }, 1000);
        return () => clearInterval(interval);
      }, []);
      
    if(seconds === 0) {
        setCookie("otpBlock", 1);
        window.location.href = '/';
        console.log("Hipppi");
    }
    return (
        <>
        <h3>Time Left = {seconds} seconds</h3>
        </>
    )
}