import { AllQuiz } from '../../components/allquiz/AllQuiz'
import QuickCall from '../../components/quickcall/QuickCall'
import './Quiz.css'

export const Quiz = () => {
    return (
        <div className="allQuiz">
            <QuickCall/>
            
            <AllQuiz/>
        </div>
    )
}
