import axios from 'axios';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';
import { removeSelectedProducts, selectedProducts } from '../../services/actions/Action';
import Preloader from '../common/Preloader';

const DetailsNewTest = (props) => {
    const product = useSelector((state)=> state.product);
    const {image, title, category, price, description} = product;
    const {productId} = useParams();
    const dispatch = useDispatch();
    console.log("sohag:", product)

    const fetchProductDetails = async () => {
        const response = await axios.get(`https://fakestoreapi.com/products/${productId}`)
        .catch((error) => {
            console.log("error", error);
        });
        dispatch(selectedProducts(response.data));
    };

    useEffect(()=>{
        if(productId && productId !== "") fetchProductDetails();
        return () => {
            dispatch(removeSelectedProducts());
        }
    }, [productId]);
    return (
        <div className="detailsnewtest">
            {
                Object.keys(product).length === 0 ? (
                    <Preloader/>
                ): (
                    
                    <div>
                        <img src={image} alt={image} />
                        <div className="info">
                            <h3>{title}</h3>
                            <p>{price}</p>
                            <span>{category}</span>
                            <p>{description}</p>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default DetailsNewTest
