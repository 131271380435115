import { Divider, Input, Tabs } from 'antd';
import moment from 'moment';
import { AiOutlineStar } from 'react-icons/ai';
import { FiUsers } from 'react-icons/fi';
import { MdRateReview } from 'react-icons/md';
import { VscFileSubmodule } from 'react-icons/vsc';
import { useSelector } from "react-redux";
import CourseStream from '../../../pages/elearning/CourseStream';
//import VideoPlay from '../../../pages/elearning/VideoPlay';
//import VideoPlayer from '../../../pages/elearning/VideoPlayer';
//import TestVid from '../../../pages/elearning/TestVid';
import { urlChecker } from '../../../utils/urlChecker';
import DocumentView from '../../common/DocumentView';
import ImageView from '../../common/ImageView';
import Feedback from '../allcoursdescription/Feedback';
import './MyCourseDetails.css';
import QAComponent from "./QAComponent";

const { TextArea } = Input;

const { TabPane } = Tabs;
function callback(key) {
    console.log(key);
}

export default function MyCourseDetails(props) {
    const lessonInfo = props.lessonVid;
    let mCourse = useSelector((state) => state.course);

    const BASEURL = urlChecker();

    if (!mCourse) {
    return <div>Loading ... </div>;
    } else {
   // const { instructor:{name, bio, avatar, total_course, total_enrolled, total_feedback} } = mCourse;

    return (
        <div className="elearnigMyCourseDetails">
            <div className="elearnigMyCoursesVideoPlayer margin-10">
    
                {lessonInfo.type == 'document' && 
                <DocumentView
                fileUrl={lessonInfo ? lessonInfo?.file : ''}
                lesson={lessonInfo ? lessonInfo : ''}
                />
                }

                {lessonInfo.type == 'video' && 
                <CourseStream
                fileUrl={lessonInfo ? lessonInfo?.file : ''}
                lesson={lessonInfo ? lessonInfo : ''}
                />
                }
                
                {lessonInfo.type == 'audio' && 
                <CourseStream
                fileUrl={lessonInfo ? lessonInfo?.file : ''}
                lesson={lessonInfo ? lessonInfo : ''}
                />
                }
                {lessonInfo.type != 'document' && lessonInfo.type != 'image' && lessonInfo.type != 'audio' && lessonInfo.type != 'video' &&
                // <VideoPlayer 
                // fileUrl={lessonInfo ? lessonInfo.file : mCourse.course_file}
                // lesson={lessonInfo ? lessonInfo : ''}
                // />
                
                <CourseStream
                    fileUrl={lessonInfo ? lessonInfo.file : mCourse?.course_file}
                    lesson={lessonInfo ? lessonInfo : ''}
                />
                }

                {lessonInfo.type == 'image' && 
                <ImageView
                fileUrl={lessonInfo ? lessonInfo.file : mCourse?.course_file}
                lesson={lessonInfo ? lessonInfo : ''}
                />
                }
            </div>

            <div className="elearnigMyCourseDetailsVinfo">
            <span className="elearnigMyCourseDetailsPostedTime">Posted at {moment(mCourse.created_at).format('DD MMMM YYYY HH:mm A')} | Last updated {moment(mCourse.updated_at).fromNow()}</span>
                <h3 className="elearnigMyCourseDetailsTitle">
                    {mCourse?.title}
                </h3>
                <p className="elearnigMyCourseDetailsDesc">
                    {mCourse?.short_desc}
                </p>
            </div>
            <div className="elearnigMyCourseDetailsTabs">
            <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="overview" key="1">
                    <h3 className="elearnigMyCourseDetailsSubTitle">About this Course</h3>
                    <Divider />
                    <div dangerouslySetInnerHTML={{__html: mCourse?.long_desc?.replace(/(<? *script)/gi, 'illegalscript')}} >
                </div>
       
                    <h3 className="elearnigMyCourseDetailsSubTitle">Instructo</h3>
                    <Divider />
                    <h3 className="elearnigMyCourseDetailsSubTitle">NFS System</h3>
                    <h4 className="elearnigMyCourseDetailsSubTitle2">E-Learning</h4>
                    {console.log('my all trainers', mCourse.course_trainers)}
                    {mCourse?.course_trainers?.length > 0 && mCourse.course_trainers.map((trainer, i) => {
                        return (
                        <>
                            <div className="elearnigMyCourseDetailsThumbnails">
                                <div className="img">
                                <img src={trainer?.avatar ? BASEURL + trainer?.avatar : BASEURL +"/images/user.jpg"}  alt={trainer?.name} />
                                </div>
                                <div className="info">
                                    <ul className="list">
                                    <li className="item"><FiUsers /> {trainer?.name}</li>
                                    <li className="item"><AiOutlineStar /> {trainer?.rating ? trainer?.rating.toFixed(1) : 0} Instructor Rating</li>
                                    <li className="item"><MdRateReview /> {trainer?.total_feedback} Reviews</li>
                                    <li className="item"><FiUsers /> {trainer?.total_enrolled}  Students</li>
                                    <li className="item"><VscFileSubmodule /> {trainer?.total_course} Courses</li>
                                    </ul>
                                </div>
                            </div>
                            <p className="elearnigMyCourseDetailsText">
                            { mCourse.instructor ? trainer?.bio : '' }
                            </p>
                        </>
                    )
                    })}

                    <Feedback/>

                </TabPane>

                <TabPane tab="q&a" key="2">

                <QAComponent />

                </TabPane>

                <TabPane tab="announcements" key="3">
                    {mCourse && mCourse?.course_announcement?.description && 
                    <div dangerouslySetInnerHTML={{__html: mCourse?.course_announcement?.description.replace(/(<? *script)/gi, 'illegalscript')}} >
                    </div>
                    }
                </TabPane>
            </Tabs>
            </div>
        </div>
    )}
}
