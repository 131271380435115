import React from "react";
import "swiper/components/navigation/navigation.min.css";
// import Swiper core and required modules
import SwiperCore, {
    Navigation
} from 'swiper/core';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.min.css";
import FeatureCourseCard from "../allCourseCard/FeatureCourseCard";
import "./FeatureSlider.css";



// install Swiper modules
SwiperCore.use([Navigation]);

let featureCourses = [];

export default function FeatureSlider(props) {

   // const featureCourses = useSelector((state) => state.featureCourses.courses);

   if(props.featureCourses){
       featureCourses = props.featureCourses;
   }    

    return (
        <div className="featureSlider">
        <Swiper navigation={true} className="mySwiper">
            {/* sllide */}
            {featureCourses.map((course, i) => (
                <SwiperSlide key={i}>
                <FeatureCourseCard course={course}/>
                </SwiperSlide>
            ))}
        </Swiper>
        </div>
      )
}
