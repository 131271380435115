import { Divider, Select } from "antd";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { MdOndemandVideo } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getApiRequest } from "../../api/CommonApi";
import QuickCall from "../../components/quickcall/QuickCall";
import SearchBar from "../../components/searchbar/SearchBar";
import VideoFilter from "../../components/searchvideofilter/VideoFilter";
import VideoCart from "../../components/videocard/VideoCart";
import { setVideos } from "../../services/actions/video/VideoAction";
import "./Video.css";

const { Option } = Select;

export default function Video() {
  const videos = useSelector((state) => state.allVideos.videos);
  let [cnt, setCnt] = useState(0);
  const dispatch = useDispatch();
  const [cookies] = useCookies(["user"]);
  const title = useSelector((state) => state.selectedSearchTitle.title);
  const [sort, setSort] = useState("");
  let selectedFavouriteIds = useSelector(
    (state) => state.selectedFavouriteIds.favouriteId
  );
  let tags = useSelector((state) => state.tagSelect.tags);
  tags = tags.join();
  let [pagination, setPagination] = useState(1);

  useEffect(async () => {
    const params = {
      load: pagination,
      title: title,
      sort: sort,
      tag: tags,
    };
    const response = await getApiRequest(
      "/api/video/index",
      cookies.access_token,
      params
    );
    dispatch(setVideos(response.data.data));
    setCnt(response.data.count);
  }, [title, sort, tags, selectedFavouriteIds, pagination]);

  const handleDropdown = (e) => {
    setSort(e);
  };

  const handleOnClick = (e) => {
    setPagination(pagination + 1);
  };
  return (
    <div className="video-page">
      <QuickCall />
      <SearchBar />
      <span className="videoSectionTitle">
        {" "}
        <MdOndemandVideo /> all videos
      </span>
      <Divider />
      <div className="contentCount">
        <div className="left">
          <span className="contentText">About {cnt} results found</span>
        </div>
        <div className="right">
          <span className="sortby">sort by:</span>
          <Select
            placeholder="Sort"
            style={{ width: 100 }}
            onChange={handleDropdown}
          >
            <Option value="latest">Latest</Option>
            <Option value="older">Older</Option>
            <Option value="updated">Updated</Option>
            <Option value="a2z">A to Z</Option>
            <Option value="z2a">Z to A</Option>
          </Select>
        </div>
      </div>
      <Row>
        <Col md={3}>
          <VideoFilter />
        </Col>
        <Col md={9}>
          <VideoCart videos={videos} />
        </Col>
        {cnt > 0 && cnt > videos.length ? (
          <button className="loardMore" onClick={handleOnClick}>
            Load More
          </button>
        ) : (
          ""
        )}
      </Row>
    </div>
  );
}
