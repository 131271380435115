import { Checkbox, Divider, Select } from "antd";
import axios from "axios";
import dotenv from "dotenv";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { getApiRequest } from "../../api/CommonApi";
import { categoryTypeStore } from "../../services/actions/faq/CategorySelectAction";
import { tagStore } from "../../services/actions/faq/TagSelectedAction";
import { typeStore } from "../../services/actions/faq/TypeSelectedAction";
import { urlChecker } from "../../utils/urlChecker";
import "./SearchFilter.css";
dotenv.config();

const { Option } = Select;
let tagList = "";

const children = [];
for (let i = 10; i < 36; i++) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

const types = [
  { label: "General", value: "general" },
  { label: "Click Based", value: "click" },
];
let categories;

export default function SearchFilter() {
  const [cookies] = useCookies(["user"]);
  const dispatch = useDispatch();

  const BASEURL = urlChecker();

  const fetchFaqCategories = async () => {
    const headers = {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: "Bearer " + cookies.access_token,
    };
    const config = {
      headers: headers,
    };
    const response = await axios
      .get(
        BASEURL + "/api/category/index?status=active",
        config
      )
      .catch((error) => {
        console.log("error", error);
      });
    if (response.data.data) {
      categories = response.data.data.map((category) => ({
        label: category.name,
        value: category.id,
      }));
    }
  };
  useEffect(async () => {
    await fetchFaqCategories();
  }, []);



  useEffect(async () => {
    await allTags();
  }, []);


  const options: SelectProps['options'] = [];

  const allTags = async () => {

    const response = await getApiRequest("/api/tag/index");

     tagList = response?.data?.data?.data?.map((tag) => {
      return <option label={tag.name} value={tag.name}>{tag.name}</option>;
    });

  

    // const headers = {
    //   "Content-type": "application/json; charset=UTF-8",
    //   Authorization: "Bearer " + cookies.access_token,
    // };

    // const config = {
    //   headers: headers,
    //   // params: params
    // };

    // const response = await axios
    //   .get(BASEURL + "/api/tag/index", config)
    //   .catch((error) => {
    //     console.log("error", error);
    //   });
    // console.log('dfdfdfdf', response);
    // tagList = response?.data?.data?.map((tag) => {
    //   return <option value={tag.id}>{tag.name}</option>;
    // });
  };

  // const categories = [
  //   { label: 'GP Offer', value: 'GP Offer' },
  //   { label: 'Bundle Package', value: 'Bundle Package' },
  //   { label: 'Bundle Package', value: 'Bundle Package' },
  //   { label: 'Internet Offer', value: 'Internet Offer' },
  //   { label: 'Internet Offer', value: 'Internet Offer' },
  // ];
  // console.log("a:", a);
  // console.log("categories:", categories);

  function onChangeType(checkedValues) {
    dispatch(typeStore(checkedValues));
  }

  function onChange(checkedValues) {
    dispatch(categoryTypeStore(checkedValues));
  }

  function handleChange(value) {
    dispatch(tagStore(value));
  }

  return (
    <div className="searchFilter">
      <div className="card">
      <div className="searchFilterTag">
          <h4 className="searchfilterTitle">Tags</h4>
          <Select
            className="searchfilterSelect"
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Please select Tag"
            onChange={handleChange}
            // options={tagList}
          >
            {tagList}
          </Select>

          {/* <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Please select"
           // defaultValue={['a10', 'c12']}
            onChange={handleChange}
            options={tagList}
          /> */}
        </div>
        <Divider />
        <div className="searchFilterFaqType">
          <h4 className="searchfilterTitle">FAQ Type</h4>
          <Checkbox.Group options={types} onChange={onChangeType} />
        </div>
        <Divider />
        <div className="searchFilterFaqCategories">
          <h4 className="searchfilterTitle">FAQ Categories</h4>
          <Checkbox.Group options={categories} onChange={onChange} />
        </div>
       
      </div>
    </div>
  );
}
