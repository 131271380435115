import { Checkbox, Divider, Input, Radio } from "antd";
import { useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { HiCheckCircle } from "react-icons/hi";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { getApiRequest } from "../../api/CommonApi";
import { showPlainHtml } from "../../common/Common";
import "./AllQuiz.css";
const TextArea = Input.TextArea;

function onChange(checkedValues) {
  console.log("checked = ", checkedValues);
}

export const DetailsQuiz = () => {
  const history = useHistory();
  const { quiz_attempt_id } = useParams();
  const [quizDetails, setQuizDetails] = useState(null);

  useEffect(() => {
    getApiRequest(`/api/quiz-attempt/users/details/${quiz_attempt_id}`, "")
      .then((res) => {
        console.log('quiz detail', res.data.data);
        console.log(res.data.data.questions);
        setQuizDetails(res.data.data);
        let theQuizAttempt = res?.data?.data;

        if (theQuizAttempt.quiz_type == "Pre-Shift") {
          if (theQuizAttempt.score_percentage == 100) {
            alert("You have passed the quiz");
            localStorage.removeItem("preShiftReport");
            localStorage.removeItem("preShift");
            window.location.href = "/";
          } else {
            alert("You are failed the quiz. Try Again!");
            // window.location.href = "/pre-shift-briefing";
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [quiz_attempt_id]);

  const showAnswer = (answer, userAns) => {
    answer.answers.map((ans) => {
      ans.label = (
        <>
          {ans.content}
          { quizDetails?.show_correct_answer ?  ans.is_correct ? <HiCheckCircle className="iconcheked" /> : null : null}
        </>
      );
      ans.value = ans.id;
    });
    let options = answer.answers;
    console.log('options', options)
    // let getCorrectAnswer = options.filter((option) => option.is_correct);
    // let givenAnswer = JSON.parse(userAns.user_ans_id);
    if (answer.type === "radio") {
      return (
        // <Radio.Group disabled name="radiogroup" defaultValue={givenAnswer[0]}>
        <Radio.Group disabled name="radiogroup">
          {options.map((ans, index) => {
            return (
              <Radio value={ans.id}>
                {ans.content}
                {/* {ans.is_correct ? (
                  <HiCheckCircle className="iconcheked" />
                ) : null} */}
              </Radio>
            );
          })}
        </Radio.Group>
      );
    }
    if (answer.type === "checkbox") {
      // let selected = getCorrectAnswer.map((ans) => (ans.id = ans.id));
      return (
        <Checkbox.Group
          disabled
          options={options}
          // defaultValue={Object.values(givenAnswer)}
          onChange={onChange}
        />
      );
    }

    if (answer.type === "text") {
      return (
        <>
          <div className="text-user-answer">
            <p>{userAns.user_ans_text}</p>
          </div>
          <br />
          <div className="text-user-answer">
            <p>
              <HiCheckCircle className="iconcheked" />
              {answer.answers[0].content}
            </p>
          </div>
        </>
      );
    }
  };

  return (
    quizDetails &&
    quizDetails.quiz_type != "Pre-Shift" && (
      <div className="detailsQuiz">
        {/* card- 1 */}
        <div className="card">
          <div className="detailsQuizOverview">
            <div className="left">
              <h3 className="title">
                Quiz Title :{" "}
                <span className="subtitle">{quizDetails.quiz_title}</span>
              </h3>
            </div>
            <div className="right">
              <HiCheckCircle className="iconcheked" />
              <span>Grade Achieved : </span>
              <span className="persent">{quizDetails.score_percentage}%</span>
            </div>
          </div>
          <Divider />
          <div className="detailsQuizOverviewContent">
            <div className="left">
              <table>
                <tr>
                  <th>Total Question </th>
                  <td>:</td>
                  <td>{quizDetails.total_questions}</td>
                </tr>
                <tr>
                  <th>Total Marks </th>
                  <td>:</td>
                  <td>{quizDetails.total_score}</td>
                </tr>
                <tr>
                  <th>Total Time </th>
                  <td>:</td>
                  <td>{quizDetails.total_time} mins</td>
                </tr>
              </table>
            </div>
            <div className="right">
              <table>
                <tr>
                  <th>Answered </th>
                  <td>:</td>
                  <td>{quizDetails.total_attempted_question}</td>
                </tr>
                <tr>
                  <th>My Score </th>
                  <td>:</td>
                  <td>{quizDetails.my_score}</td>
                </tr>
                <tr>
                  <th>Time Taken</th>
                  <td>:</td>
                  <td>{quizDetails.time_taken} mins</td>
                </tr>
                <tr>
                  <th>Date</th>
                  <td>:</td>
                  <td>{quizDetails.created_at}</td>
                </tr>
                <tr>
                  <th>Start Time</th>
                  <td>:</td>
                  <td>{ quizDetails.start_time}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        {/* card-2  */}
        {quizDetails.questions &&
          quizDetails.questions.map((question, i) => {
            return (
              <div className="card" key={i}>
                <div className="detailsQuizOverview">
                  <div className="left">
                    <h3 className="title">Question {i + 1}</h3>
                  </div>
                  <div className="right">
                    {quizDetails.questions_answer[i].is_correct ? (
                      <HiCheckCircle className="iconcheked" />
                    ) : (
                      <AiFillCloseCircle className="iconClosed" />
                    )}

                    <span>
                      Point {quizDetails.questions_answer[i].score}/
                      {question.answers_log.score}
                    </span>
                  </div>
                </div>
                <Divider />
                <div className="detailsQuizQuestoins">
                  <p className="question">
                    {showPlainHtml(question.answers_log.question)}
                  </p>
                  {showAnswer(
                    question.answers_log,
                    quizDetails.questions_answer[i]
                  )}
                </div>
              </div>
            );
          })}

        {/* <div className="more">
          <button className="viewcertificaate bgc-primary">
            view certificaate
          </button>
        </div> */}
      </div>
    )
  );
};
