import { useCookies } from 'react-cookie';
import { postApiRequest } from '../../api/CommonApi';

export const Logout = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const handleLogout = async () => {
        const response = await postApiRequest('/api/logout', cookies.access_token, '');
        removeCookie('access_token');
        removeCookie('refresh_token');
        removeCookie('user');
        removeCookie('information_user');
        removeCookie('admin');
        removeCookie("normal_login_attemps");
        localStorage.clear();
        window.location.href = "/";
    }
    return (
        <div>
            <button 
                aria-label="Log Out"
                className="btn primary-btn btn-sm block"
                onClick={() => handleLogout()}
            >
                Logout    
            </button>
        </div>
    )
}