import { Input } from 'antd';
import { HiOutlineSearch } from 'react-icons/hi';
import { useDispatch } from 'react-redux';
import { searchTitleSet } from '../../services/actions/common/SearchTitleAction';
import './SearchBar.css';


export default function SearchBar() {
    const dispatch = useDispatch();
    const handleSearch = (e) => {
        console.log('search test', e.target.value)
        dispatch(searchTitleSet(e.target.value));
    }
    return (
        <div className="searchBar">
            <div className="">
                <Input placeholder="Search here..." onKeyDown={handleSearch} prefix={<HiOutlineSearch />} />
            </div>
        </div>
    )
}
