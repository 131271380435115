import { Tabs } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getApiRequest } from "../../api/CommonApi";
import "./FeaturedArticle.css";

const { TabPane } = Tabs;

export default function FeaturedArticle() {
  const [create, setCreate] = useState("");
  const [update, setUpdate] = useState("");
  const [fav, setFav] = useState("");
  const loadResource = async () => {
    const res = await getApiRequest("/api/agent/dashboard");
    setCreate(res?.data?.data?.recentlyAdded);
    setUpdate(res?.data?.data?.recentlyUpdated);
    setFav(res?.data?.data?.myFavorite);
  };

  useEffect(() => {
    loadResource();
  }, []);
  function callback(key) {
    console.log(key);
  }
  return (
    <section className="featured-article card">
      <Tabs defaultActiveKey="1" onChange={callback}>
        <TabPane tab="Recently Updated" key="1">
          <Table responsive hover>
            <thead className="featuredTheading">
              <tr>
                <th className="font-bold gray-text-200">Title</th>
                <th className="font-bold gray-text-200">Created at</th>
                <th className="font-bold gray-text-200">Updated at</th>
              </tr>
            </thead>
            <tbody>
              {update &&
                update.map((faq) => {
                  return (
                    <tr>
                      <td className="gray-text-200">
                        <Link to={faq?.type === 'general' ? `/details-faqs/${faq?.id}` : (faq?.type === 'matrix' ? `/table-matrix-base/${faq?.id}` : (faq?.type === "click" ? `/callhandle-details/${faq?.id}` : '/'))}>
                          {faq?.title}
                        </Link>
                        </td>
                      <td className="gray-text-200">
                        {moment(faq?.created_at).format(
                          "Do MMMM YYYY, h:mm:ss a"
                        )}
                      </td>
                      <td className="gray-text-200">
                        {moment(faq?.updated_at).format(
                          "Do MMMM YYYY, h:mm:ss a"
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </TabPane>

        <TabPane tab="Recently Added" key="2">
          <Table responsive hover>
            <thead className="featuredTheading">
              <tr>
                <th className="font-bold gray-text-200">Title</th>
                <th className="font-bold gray-text-200">Created at</th>
                <th className="font-bold gray-text-200">Updated at</th>
              </tr>
            </thead>
            <tbody>
              {create &&
                create.map((faq) => {
                  return (
                    <tr>
                      <td className="gray-text-200">
                        <Link to={faq?.type === 'general' ? `/details-faqs/${faq?.id}` : (faq?.type === 'matrix' ? `/table-matrix-base/${faq?.id}` : (faq?.type === "click" ? `/callhandle-details/${faq?.id}` : '/'))}>
                          {faq?.title}
                        </Link>
                        </td>
                      <td className="gray-text-200">
                        {moment(faq?.created_at).format(
                          "Do MMMM YYYY, h:mm:ss a"
                        )}
                      </td>
                      <td className="gray-text-200">
                        {moment(faq?.updated_at).format(
                          "Do MMMM YYYY, h:mm:ss a"
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </TabPane>

        <TabPane tab="My Favourite" key="3">
          <Table responsive hover>
            <thead className="featuredTheading">
              <tr>
                <th className="font-bold gray-text-200">Title</th>
                <th className="font-bold gray-text-200">Created at</th>
                <th className="font-bold gray-text-200">Updated at</th>
              </tr>
            </thead>
            <tbody>
              {fav &&
                fav.map((item) => {
                  return (
                    <tr>
                      <td className="gray-text-200">
                        <Link to={item?.faq?.type === 'general' ? `/details-faqs/${item?.faq?.id}` : (item?.faq?.type === 'matrix' ? `/table-matrix-base/${item?.faq?.id}` : (item?.faq?.type === "click" ? `/callhandle-details/${item?.faq?.id}` : '/'))}>
                          {item?.faq?.title}
                        </Link>
                      </td>
                      <td className="gray-text-200">
                        {moment(item?.faq?.created_at).format(
                          "Do MMMM YYYY, h:mm:ss a"
                        )}
                      </td>
                      <td className="gray-text-200">
                        {moment(item?.faq?.updated_at).format(
                          "Do MMMM YYYY, h:mm:ss a"
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </TabPane>
      </Tabs>
    </section>
  );
}
