import { Tag } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { Badge, Col, ListGroup, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { postApiRequest } from "../../api/CommonApi";
import {
  removeSelectedIds,
  selectedFavouriteIds
} from "../../services/actions/faq/SelectedFavourite";
import { urlChecker } from "../../utils/urlChecker";
import "./SearchContent.css";
// search-results

export default function SearchContent(props) {
  // console.log('props tags', props?.faqs[0]?.tags);
  // return false;
  const dispatch = useDispatch();
  const faqs = useSelector((state) => state.allFaqs.faqs);
  const [isLoad, setIsLoad] = useState(false);
  const BASEURL = urlChecker();

  useEffect(() => {
    if (faqs.length > 0) {
      setIsLoad(true);
    }
  }, [faqs]);

  async function handleFavourite(e, id, action) {
    if (action == "favourite") {
      dispatch(selectedFavouriteIds(id));
      const response = await postApiRequest("/api/favourite/set", "", {
        fk_id: id,
        type: 1,
      });
      if (response.data.code === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } else {
      dispatch(removeSelectedIds());
      const response = await postApiRequest("/api/favourite/unset", "", {
        fk_id: id,
        type: 1,
      });
      if (response.data.code === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    }
  }

  return (
    <div className="searchContent">
      <Row>
        <Col xs={12}>
          {isLoad == false ? (
            <p>No Data</p>
          ) : (
            <div className="faqList">
              {faqs.map((faq, i) => {
                return (
                  <div className="card">
                    <ListGroup variant="flush">
                      <ListGroup.Item key={i}>
                        <Link to={faq.type == 'general' ? `/details-faqs/${faq?.id}` : `/callhandle-details/${faq?.id}`}>
                          <div className="resultContentImage">
                            {faq?.feature_image && (
                              <img
                                className="result-img"
                                width="200"
                                height="auto"
                                src={
                                  BASEURL +
                                  "/" +
                                  faq?.feature_image
                                }
                                alt={faq?.title}
                              />
                            )}
                          </div>
                          <div className="resultContent">
                            
                            <Link
                              className="resultTitle"
                              to={faq.type == 'general' ? `/details-faqs/${faq?.id}` : `/callhandle-details/${faq?.id}`}
                            >
                              <h2>{faq?.title}</h2> 
                              {
                                (faq.status == 'archived' || moment(faq?.expiry_date).diff(moment.now(), 'days') < 0) ? <Badge className="archBadge">archived</Badge> : ''
                              }
                            </Link>
                            {/* <span className="resultContents" dangerouslySetInnerHTML={{__html: faq?.detail.replace(/(<? *script)/gi, 'illegalscript')}}>
                            </span> */}
                            <span className="resultContents">
                              {/* {faq?.summary} */}
                              {
                                faq && faq.summary && faq.summary == "undefined" ? '' : faq?.summary
                              }
                              </span>
                            <div className="resultsTagTimes">
                              <div className="resultsCategory">
                                <span>Category:</span>
                                <Tag value={faq?.category_id}>
                                  {faq?.category?.name}
                                </Tag>
                              </div>
                              <div className="resultsTag">
                                <span>Tags: </span>
                                {/* Tag show */}
                                {faq?.tags.map((tag) => {
                                  return (
                                    <Tag>
                                      <Link value={tag?.tag[0]?.id}>
                                        {tag?.tag[0]?.name}
                                      </Link>
                                    </Tag>
                                  );
                                })}
                              </div>
                            </div>

                            <span className="uploadTime">
                              Posted at{" "}
                              {moment(faq?.created_at).format(
                                "MMMM Do YYYY"
                              )}{" "}
                              | Last updated{" "}
                              {moment(faq?.updated_at)
                                .subtract(6, "days")
                                .calendar()}
                              | Expiry date{" "}
                              {faq.expiry_date ? moment(faq?.expiry_date).format(
                                "MMMM Do YYYY"
                              ) : "N/A"}
                            </span>
                          </div>
                        </Link>
                        <div className="favouriteFaqIcon">
                          {faq.favourite && faq.favourite.id ? (
                            <svg
                              className="resultsBookmarks"
                              value={faq?.id}
                              onClick={(e) =>
                                handleFavourite(e, faq?.id, "unfavourite")
                              }
                              width="16"
                              height="22"
                              viewBox="0 0 16 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14.4518 0H0.644531C0.288578 0 0 0.288578 0 0.644531V21.3555C0 21.6162 0.157051 21.8512 0.397891 21.9509C0.477641 21.984 0.561387 22 0.644402 22C0.812109 22 0.976981 21.9346 1.10026 21.8112L7.54828 15.3632L13.996 21.8112C14.1804 21.9956 14.4576 22.0507 14.6984 21.9509C14.9393 21.8512 15.0963 21.6162 15.0963 21.3555V0.644531C15.0963 0.288578 14.8078 0 14.4518 0Z"
                                fill="#E59830"
                              />
                            </svg>
                          ) : (
                            <svg
                              className="resultsBookmarks"
                              value={faq?.id}
                              onClick={(e) =>
                                handleFavourite(e, faq?.id, "favourite")
                              }
                              width="16"
                              height="22"
                              viewBox="0 0 16 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14.904 0H1.09668C0.740726 0 0.452148 0.288578 0.452148 0.644531V21.3555C0.452148 21.6162 0.609199 21.8512 0.850039 21.9509C0.929789 21.984 1.01354 22 1.09655 22C1.2643 22 1.42913 21.9346 1.55245 21.8112L8.00047 15.3633L14.4482 21.8112C14.6326 21.9955 14.9098 22.0507 15.1506 21.9509C15.3915 21.8512 15.5485 21.6162 15.5485 21.3555V0.644531C15.5485 0.288578 15.2599 0 14.904 0ZM8.00042 13.8072C7.82949 13.8072 7.66557 13.8751 7.5447 13.996L1.74121 19.7994V1.28906H14.2594V19.7994L8.45619 13.996C8.33532 13.8751 8.1714 13.8072 8.00042 13.8072Z"
                                fill="#E59830"
                              />
                            </svg>
                          )}
                        </div>
                      </ListGroup.Item>
                    </ListGroup>
                  </div>
                );
              })}
            </div>
          )}
          {/* {renderFaqs} */}
        </Col>
      </Row>
    </div>
  );
}
