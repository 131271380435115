import { Divider, Image, Radio, Tag } from "antd";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getApiRequest, postApiRequest } from "../../api/CommonApi";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import Preloader from "../../components/common/Preloader";
import FaqDetailsView from "../../components/faq/FaqDetailsView";
import QuickCall from "../../components/quickcall/QuickCall";
import SearchCallHandleContent from "../../components/searchcontent/SearchCallHandleContent";
import { urlChecker } from "../../utils/urlChecker";
import "./CallHandle.css";
let questionTree = [];
let previousPosition = 0;
let alreadyAdded = [];

export const CallHandleDetails = () => {
  const histroy = useHistory();
  const { faqId } = useParams();
  const [question, setQuestion] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const [textContent, setTextContent] = useState("");
  const [faq, setFaq] = useState("");
  const [tagsRender, setTagsRender] = useState("");
  const [tags, setTags] = useState("");
  const [category, setCategory] = useState("");
  const [isLoadFaq, setIsLoadFaq] = useState(false);
  const [relatedFaqs, setFaqs] = useState("");
  let [pagination, setPagination] = useState(1);
  const [resolution, setResolution] = useState("");
  const [resolutionMedia, setResolutionMedia] = useState("");
  const [flag, setFlag] = useState(false);

  const BASEURL = urlChecker();

  const handleClick = async (e, position) => {
    console.log('position', position);
    // loadResource(23, e.target.value);
    if (!alreadyAdded.includes(e.target.value)) {
      alreadyAdded.push(e.target.value);
      const response = await getApiRequest("/api/click/index", "", {
        faq_id: faqId,
        parent_id: e.target.value,
      });
      if(position >= previousPosition) {
        console.log('add');
        previousPosition++;
      }else{
        console.log('remove');
        while(questionTree.length > position+1) {
          questionTree.pop();
        }
        previousPosition = position;
      }
      if (response?.data?.data.length > 0) {
        response.data.data.map((item) => {
          questionTree.push(item);
        });
        setQuestion([...questionTree]);
        // setQuestion([...question, ...response.data.data]);
        // setQuestion([...question, ...questionTree]);
      } else {
        const r = await getApiRequest(
          "/api/click/single-click-based-question",
          "",
          { id: e.target.value }
        );
        setQuestion([...questionTree])
        setResolution(r?.data?.data?.resolution);
        setResolutionMedia(r?.data?.data?.resolution_media);
      }
    }
  };

  console.log("you can do this", questionTree);

  const loadResource = async (id, parent) => {
    setResolution("");
    setResolutionMedia("");
    setQuestion([]);
    const response = await getApiRequest("/api/click/index", "", {
      faq_id: id,
      parent_id: parent,
    });
    setQuestion(response.data.data);
    questionTree.push(response.data.data[0]);
    setIsLoad(true);
    const res = await getApiRequest(`/api/faq/read/${faqId}`, "", "");
    setFaq(res.data);
    setIsLoadFaq(true);
    setCategory(res.data.data.category);
    setTags(res.data.data.tags);
    if (res.data.data) {
      const tagsContent = res.data.data.tags.map((tag) => {
        return <Tag>{tag.tag[0].name}</Tag>;
      });
      setTagsRender(tagsContent);
    }
    const params = {
      faq_id: faqId,
      category_id: category?.id,
      tag: tags,
      load: pagination,
    };
    document.title = res?.data?.data?.title;
    const responseRelated = await getApiRequest(
      "/api/faq/new-index",
      "",
      params
    );
    setFaqs(responseRelated?.data?.data);
  };

  const handleSubmission = async () => {
    const res = await postApiRequest("/api/click/store", "", {
      faq_id: faqId,
      question: textContent,
      parent_id: "",
    });
    if (res.data.status === "success") {
      toast.success(res.data.message);
    } else {
      toast.error(res.data.message);
    }
    histroy.push(`/callhandle-details/${faqId}`);
  };

  const handleKeyPress = (e) => {
    setTextContent(e.target.value);
  };

  const handleOnClick = (e) => {
    setPagination(pagination + 1);
  };

  useEffect(() => {
    loadResource(faqId, "");
  }, [faqId, pagination]);
  return (
    <div className="callHandleDetails">
      <QuickCall />

      <BreadCrumb link="Service-Modality/Detail-Service-Modality"/> <br/>

      <Row>
        <Col xs lg="12">
          {/* details question */}
          {/* <div className="detailsContent card">details view component</div>  */}
          <FaqDetailsView
            faq={faq.data}
            category={category}
            tagsRender={tagsRender}
          />

          {/* click based process flow */}
          <div className="callHandleDetailsQuestions">
            <ul className="questionsList">
              {isLoad == false ? (
                <Preloader />
              ) : (
                question &&
                question.map((qus, index) => {
                  return (
                    <li className="questionsListItems card">
                      <h3 className="noOfQ">Questions {index + 1} :</h3>
                      <Divider />
                      <h2
                        dangerouslySetInnerHTML={{
                          __html: qus?.question.replace(
                            /(<? *script)/gi,
                            "illegalscript"
                          ),
                        }}
                      ></h2>
                      <div className="qOptions">
                        <Radio.Group name="radiogroup">
                          {qus.children.map((child, i) => {
                            return (
                              <Radio value={child.id} onClick={(e) => handleClick(e, index)}>
                                {" "}
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: child?.question.replace(
                                      /(<? *script)/gi,
                                      "illegalscript"
                                    ),
                                  }}
                                ></p>
                                {child.media_file && (
                                  <Image
                                    className="click_image"
                                    src={
                                      BASEURL +
                                      child.media_file
                                    }
                                  />
                                )}
                              </Radio>
                            );
                          })}
                        </Radio.Group>
                        {}
                      </div>
                    </li>
                  );
                })
              )}
            </ul>
            {resolution && (
              <div className="card bg-red">
                <h2>Resolution</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: resolution.replace(
                      /(<? *script)/gi,
                      "illegalscript"
                    ),
                  }}
                ></p>
                {resolutionMedia && (
                  <Image
                    width={200}
                    src={BASEURL + resolutionMedia}
                  />
                )}
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <div>
          <Col xs lg="12">
            <h3>Related FAQs</h3>
            <SearchCallHandleContent faqs={relatedFaqs} />
            <button className="loardMore" onClick={handleOnClick}>
              Load More
            </button>
          </Col>
        </div>
      </Row>
    </div>
  );
};
