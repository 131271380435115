import { ActionTypes } from "../../constants/ActionTypes";

const initialState = {
    initState: [],
}

export const incomingCallReducer = ( state = initialState, {type, payload}) => {
    switch(type){
        case ActionTypes.CALL_TRACK_INFO:
            return {...state, calltrack:payload};
        default:
            return state;
    }
}
