import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getApiRequest } from "../../api/CommonApi";
import { QuizCheckbox } from "../../components/allquiz/QuizCheckbox";
import { QuizRadio } from "../../components/allquiz/QuizRadio";
import { QuizText } from "../../components/allquiz/QuizText";
import { QuizTime } from "../../components/allquiz/QuizTime";
import "./Quiz.css";

export const QuizAttempt = (props) => {
  const { quiz_attempt_id, question_id } = useParams();
  const [question, setQuestion] = useState([]);
  const [isLast, setIsLast] = useState(false);
  const [isPreShift, setIsPreShift] = useState(false);
  const [quiz, setQuiz] = useState();
  const [quesNo, setQuesNo] = useState(1);

  useEffect(() => {
    getApiRequest(`/api/question/${question_id}/${quiz_attempt_id}`, "").then(
      (res) => {
        console.log(res);
        if (res.status === 200) {
          setQuiz(res.data.data.quiz);
          setQuestion(res.data.data.question);
          setIsLast(res.data.data.is_last);
          setIsPreShift(res.data.data.is_preshift);
        }
      }
    );
  }, [question_id]);

  const shuffle = (array) => {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  };

  const getQuestionOption = (question) => {
    if (question.type == "radio") {
      let answers = shuffle(question.answers);
      return (
        <QuizRadio
          question={question}
          answers={answers}
          quiz_attempt_id={quiz_attempt_id}
          question_id={question_id}
          isLast={isLast}
          isPreShift={isPreShift}
          timeRestriction={quiz.time_restriction}
          duration={quiz.duration}
        />
      );
    }
    if (question.type == "checkbox") {
      let answers = shuffle(question.answers);
      return (
        <QuizCheckbox
          question={question}
          answers={answers}
          quiz_attempt_id={quiz_attempt_id}
          question_id={question_id}
          isLast={isLast}
          isPreShift={isPreShift}
          timeRestriction={quiz.time_restriction}
          duration={quiz.duration}
        />
      );
    }

    if (question.type == "text") {
      return (
        <QuizText
          question={question}
          answers={question.answers}
          quiz_attempt_id={quiz_attempt_id}
          question_id={question_id}
          isLast={isLast}
          isPreShift={isPreShift}
          timeRestriction={quiz.time_restriction}
          duration={quiz.duration}
        />
      );
    }
  };

  return (
    <div>
      {quiz ? <QuizTime quiz={quiz} /> : null}

      {getQuestionOption(question)}
    </div>
  );
};
