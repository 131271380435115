import { Checkbox, Divider, Select } from "antd";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getApiRequest } from "../../api/CommonApi";
import { categoryTypeStore } from "../../services/actions/faq/CategorySelectAction";
import { tagStore } from "../../services/actions/faq/TagSelectedAction";
import "./SearchFilter.css";

const { Option } = Select;
let tagList = "";
let categories;
export default function SearchTagFilter() {
  const dispatch = useDispatch();

  const allTags = async () => {
    const response = await getApiRequest("/api/tag/index", "", "");
    tagList = response.data.data.map((tag) => {
      return <option value={tag.id}>{tag.name}</option>;
    });
  };

  const fetchFaqCategories = async () => {
    const response = await getApiRequest(
      "/api/category/index?status=active",
      "",
      ""
    );
    if (response?.data?.data) {
      categories = response?.data?.data.map((category) => ({
        label: category.name,
        value: category.id,
      }));
    }
  };

  useEffect(() => {
    allTags();
    fetchFaqCategories();
  }, []);

  function handleChange(value) {
    dispatch(tagStore(value));
  }

  function onChange(checkedValues) {
    dispatch(categoryTypeStore(checkedValues));
  }

  return (
    <div className="searchFilter">
      <div className="card">
        <div className="searchFilterFaqCategories">
          <h4 className="searchfilterTitle">Categories</h4>
          <Checkbox.Group options={categories} onChange={onChange} />
        </div>
        <Divider />
        <div className="searchFilterTag">
          <h4 className="searchfilterTitle">Tags</h4>
          <Select
            className="searchfilterSelect"
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Please select Tag"
            onChange={handleChange}
          >
            {tagList}
          </Select>
        </div>
      </div>
    </div>
  );
}
