import { Button, DatePicker, Divider, Pagination, Select, Tag } from "antd";
import moment from 'moment';
import { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { AiOutlineDownload, AiOutlineSearch } from "react-icons/ai";
import { Link } from "react-router-dom";
import { getApiRequest } from "../../api/CommonApi";
import { urlChecker } from "../../utils/urlChecker";

const { RangePicker } = DatePicker;
const Option = Select;

export const PreShiftBriefingHistory = () => {
  const BASEURL = urlChecker();

  const [allPreshift, setAllPreshift] = useState([]);
  const [preshiftData, setPreshiftData] = useState([]);
  const [filterDate, setFilterDate] = useState(null);
  const [paginateInfo, setPaginateInfo] = useState([]);
  const [filterParams, setFilterParams] = useState({
    quiz_id: null,
  });

  useEffect(() => {
    getPreshiftData();
    loadPreshiftBriefing();
  }, []);

  const getPreshiftData = async (filterDate = null, others = null, page = 1) => {
    // const response = await getApiRequest(
    //   "/api/preshift-briefings/history-report-user"
    // );
    // console.log('getPreshiftData response', response);

    // setPreshiftData(response?.data?.data);

    let url = filterDate ? `/api/preshift-briefings/history-report-user?start_date=${filterDate[0]}&end_date=${filterDate[1]}&page=${page}` : '/api/preshift-briefings/history-report-user' + `?page=${page}`;

        if (others != null) {
            url = url + others;
        }
        getApiRequest(url, '', filterParams).then(response => {
          console.log('getPreshiftData response', response);
            setPreshiftData(response?.data?.data);
            setPaginateInfo(response?.data?.meta);
            console.log('return response', response);
        }).catch(error => {
            console.log(error);
        });

  };

  const loadPreshiftBriefing = async () => {
    const response = await getApiRequest("/api/preshift-briefings/index");
    console.log("my preshift response", response);
    setAllPreshift(response?.data?.data);
  };

  const exportReport = () => {
      const url = filterDate ? `/api/agent-reports?start_date=${filterDate[0]}&end_date=${filterDate[1]}&export=true` : '/api/agent-reports?export=true';
      window.open(BASEURL + url);
  }

  const getDateFiler = (date, dateString) => {
      console.log(date, dateString);
      const startDate = moment(dateString[0]).format('YYYY-MM-DD');
      const endDate = moment(dateString[1]).format('YYYY-MM-DD');
      setFilterDate([startDate, endDate]);
  }

  const filterReport = () => {
      let myextraUrl = '';
      if (filterParams.quiz_id != null) {
          myextraUrl += `&quiz_id=${filterParams.quiz_id}`;
      }
      console.log("asdasd", myextraUrl);
      if (myextraUrl != '') {
        getPreshiftData(filterDate, myextraUrl);
      } else {
        getPreshiftData(filterDate);
      }

  }

  const onInputChange = (name, value) => {
      setFilterParams({ ...filterParams, [name]: value.join() });
      getPreshiftData()
  }

  console.log('allPreshift', allPreshift);
  

  return (
    <>
      <div className="TrainingEfficacyReport">
        <div className="reportTableArea card">
          <div className="filters">
            {/* <div>
              <span className="reportTitle">TrainingEfficacyReport</span>
            </div>
            <div>
              <Input placeholder="Basic usage" />
            </div> */}
          </div>
          <Divider />
          <Row>
              <Col md={6}>
              <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select quiz"
                  onChange={(value) => onInputChange("quiz_id", value)}
                  filterOption={(input, option) =>
                      option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
              >
                  {allPreshift &&
                      allPreshift.map((preshift, index) => (
                          <Option value={preshift?.quiz?.id}>{preshift?.quiz.title}</Option>
                      ))}
              </Select>
              </Col>
              <Col md={6} className="">
                  <Button className="float-right" style={{ marginLeft: "10px" }} onClick={() => exportReport()}> <AiOutlineDownload /> Export</Button>
                  <Button className="float-right" onClick={() => filterReport()}> <AiOutlineSearch /> Filter</Button>
                  <RangePicker className="float-right" onChange={getDateFiler} />
              </Col>
          </Row>
          <Table responsive hover className="reportTable">
            <thead className="reportheading">
              <tr>
                {/* <th className="font-bold gray-text-200">Id</th> */}
                <th className="font-bold gray-text-200">Pre-Shift Name</th>
                <th className="font-bold gray-text-200">Start Date</th>
                <th className="font-bold gray-text-200">End Date</th>
                <th className="font-bold gray-text-200">Time Taken</th>
                <th className="font-bold gray-text-200">LOB</th>
                <th className="font-bold gray-text-200">Content Time [Min]</th>
                <th className="font-bold gray-text-200">Watch Count</th>
                <th className="font-bold gray-text-200">Completion Status</th>
                {/* <th className="font-bold gray-text-200">DKB Details</th> */}
              </tr>
            </thead>
            <tbody>
              {preshiftData &&
                preshiftData.map((item, index) => (
                  <tr>
                    {/* <td>{item.user_email}</td> */}
                    <td><Link to={`/pre-shift-briefing-individual/${item.id}`}>{item.quiz_title}</Link></td>
                    <td>{moment(item.start).format("DD/MM/YYYY")}</td>
                    <td>{moment(item.end).format("DD/MM/YYYY")}</td>
                    <td>{item.time_taken}</td>
                    <td>
                      {item.processes.map((el) => {
                        return <Tag>{el}</Tag>;
                      })}
                    </td>
                    <td>{item.content_time}</td>
                    <td>{item.watch_count}</td>
                    <td>{item.status}</td>
                    {/* <td>
                      <Link to={`/pre-shift-briefing-individual/${item.id}`}>
                        View
                      </Link>
                    </td> */}
                  </tr>
                ))}
            </tbody>
          </Table>
          {paginateInfo && (
              <Pagination defaultCurrent={1} total={paginateInfo.total} onChange={(page) => {
                getPreshiftData(filterDate, extraUrl, page);
              }} />
          )}
        </div>
      </div>
    </>
  );
};
