import { ActionTypes } from "../../constants/ActionTypes";

const initialState = {
    videos: [],
}

export const videoReducer = ( state = initialState, {type, payload}) => {
    switch(type){
        case ActionTypes.SET_VIDEOS:
            return {...state, videos:payload};
        default:
            return state;
    }
}

export const selectedVideoReducer = (state = {}, {type, payload}) => {
    switch (type){
        case ActionTypes.SELECTED_VIDEO:
            return {...state, ...payload};
        case ActionTypes.REMOVE_SELECTED_VIDEO:
            return {};
        default:
            return state;
    }
}

export const setFlagVideoReducer = (state = false, {type, payload}) => {
    switch (type){
        case ActionTypes.SET_FLAG_VIDEO:
            return payload;
        default:
            return state;
    }
}