import { Breadcrumb } from "antd";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./BreadCrumb.css";

const BreadCrumb = (props) => {
  const location = useLocation();

  const breadCrumbView = () => {
    // const {pathname} = location;
    if (props.link != "" || props.link != undefined || props.link != null) {
      const pathname = props.link;
      const pathnames = pathname.split("/").filter((item) => item);
      return (
        <>
          <Breadcrumb separator=">">
            {pathnames.length > 0 ? (
              <Breadcrumb.Item className="result-content">
                <Link to="/dashboard">Dashboard</Link>
              </Breadcrumb.Item>
            ) : (
              <Breadcrumb.Item className="result-content">
                Dashboard
              </Breadcrumb.Item>
            )}

            {/* <Breadcrumb.Item>
                <Link to={`${props.crud}`}>{props.title} List</Link>
                </Breadcrumb.Item> */}

            {pathnames.map((path, index) => {
              const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
              const isLast = index === pathnames.length - 1;
              path = path.replace(/-/g, " ");
              path = path.charAt(0).toUpperCase() + path.slice(1);
              return isLast ? (
                isNaN(path) && (
                  <Breadcrumb.Item className="result-content" key={index}>
                    {path}
                  </Breadcrumb.Item>
                )
              ) : (
                <Breadcrumb.Item className="result-content" key={index}>
                  {index == 1 ? <Link to={`${routeTo}`}>{path}</Link> : path}
                </Breadcrumb.Item>
                // <Breadcrumb.Item key={index}>
                // {path}
                // </Breadcrumb.Item>
              );
            })}
          </Breadcrumb>
        </>
      );
    }
  };

  return <>{breadCrumbView()}</>;
};

export default BreadCrumb;
