import { UserOutlined } from "@ant-design/icons";
import { Avatar, Divider } from "antd";
import moment from 'moment';
import { getApiRequest } from "../../api/CommonApi";
import "./Certificate.css";


export const Certificate = (props) => {

  const { id, title, cover_image, short_desc, rating, created_at, total_course_duration, enroll_user_info: {completion_ratio, expired_at, user, updated_at} } = props.course;

  const downloadCertification = async (id) => {
    console.log("my cert course id", id);
    const response = await getApiRequest(
      `/api/courses/certification/${id}`
    ).then((response) => {
      console.log('certificate response ', response);
    //  return false;
      const filename = response.data.filename;
      const url = response.data.file;
      const a = document.createElement("a");
      a.href = url;
      a.download = url;
      a.setAttribute("download", filename);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  };
  return (
    <div className="certificate">
      {/* <QuickCall/> */}

      <div className="certificateHeading card">
        <div>
          <h2 className="title">
            Certificate on : <span className="subtitle">{title}</span>
          </h2>
        </div>
        <div>
          <h2 className="title">
            Recipient : <span className="subtitle">{user?.name}</span>
          </h2>
        </div>
      </div>

      <div className="mainBody">
        <div className="certificateBody">
          <img src="/images/certificateframe-active.png" className="position-abs"/>
          <div
            className="frame"
            // style={{
            //   backgroundImage: `url("images/rsz_1rsz_2certificateframe.png")`,
            //   backgroundRepeat: `no-repeat`,
            //   backgroundSize: `cover`,
            // }}
          >
            <div className="borderArea">
              <div className="contents">
                <h1 className="title">CERTIFICATE OF ACHIEVMENT</h1>
                <img src="/images/genex.png" alt="genex-logo"/>
                <p className="complete">Completed by : </p>
                <h2 className="name">{user?.name}</h2>

                <p className="successmeg">
                  For Successfully Completing the course of {title}
                </p>
                <Divider />
                <h2 className="courseName">
                  {" "}
                  Course Name :{" "}
                  <span className="courseTitle">{title}</span>
                </h2>
                <h2 className="courseGrade">
                  {" "}
                  Final Grade : <span className="coursePersent">{completion_ratio} %</span>
                </h2>
                <Divider />

                <div className="footerArea">
                  <div className="autor">
                    <p>Verified by Authority</p>
                    <p>Code : VBG12Kh</p>
                    <p>Training & Knowledge Management </p>
                  </div>
                  <div className="date">
                    <span>Date Completed : {moment(updated_at).format('MMMM DD, YYYY')}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="certificateinfo">
          <div className="img">
            <Avatar size={50} icon={<UserOutlined />} />
          </div>
          <div className="info">
            <h2 className="complete">
              Completed by <span className="name">{user?.name}</span>
            </h2>
            <h2 className="date">{moment(updated_at).format('MMMM DD, YYYY')}</h2>
            <h2 className="total">Total {total_course_duration} mins</h2>
            <h2 className="grade">Grade Achieved {completion_ratio} %</h2>
            <button
              className="btn btn-success"
              onClick={() => downloadCertification(props?.course?.id)}
            >
              Download Certificate
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
