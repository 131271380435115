import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "swiper/components/navigation/navigation.min.css";
import SwiperCore, {
    Navigation
} from 'swiper/core';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import { getApiRequest } from "../../api/CommonApi";
import { urlChecker } from "../../utils/urlChecker";
import './FeatureContentHome.css';

  
  SwiperCore.use([Navigation]);

export default function FeatureContentHome() {

    const [featureContents, setFeatureContents] = useState();

    const BASEURL = urlChecker();

    useEffect( () => {
         fetchFeatureContent();
    }, []);

    const fetchFeatureContent = async () => {
      const response = await getApiRequest('/api/faq/feature-faqs');
      setFeatureContents(response?.data?.faqs);
    }

    return (
        <div className="carousel-21 slickSliderVideo">
            <Swiper navigation={true} slidesPerView={1} spaceBetween={5} breakpoints={{
                "640": {
                    "slidesPerView": 2,
                    "spaceBetween": 20
                },
                "768": {
                    "slidesPerView": 4,
                    "spaceBetween": 40
                },
                "1024": {
                    "slidesPerView": 5,
                    "spaceBetween": 50
                }
                }} className="mySwiper">
                {featureContents && featureContents.map((feature, i) => {
                return(
                <SwiperSlide>
                    <Link to={`/details-faqs/${feature.id}`}>
                        {
                            feature.feature_image ? (
                                <img src={BASEURL + feature.feature_image}/>
                            ) : (
                                <img src={BASEURL + "/images/default.jpg"}/>
                            )
                        }
                    </Link>
                </SwiperSlide>
                )
                })}
            </Swiper>
        </div>
    )
}
