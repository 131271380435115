import { ActionTypes } from "../../constants/ActionTypes"

const initialState = {
    title: '',
}

export const searchTitleReducer = ( state = initialState, {type, payload}) => {
    switch(type){
        case ActionTypes.SEARCH_TITLE_SET:
            return {...state, title:payload};
        default:
            return state;
    }
}