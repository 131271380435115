import React from 'react';
import Marquee from "react-fast-marquee";
import './MarqueeText.css';

export default function MarqueeText(props) {
    return (
        <div>
            <div className="head-marquee">
                <Marquee className="marqueeArea">
                    {
                        props?.text && props?.text.map((text, index) => {
                            return (
                                <p>
                                   * {text.slider_text} *
                                </p>
                            )
                        })
                    // props.text ? props.text : ''
                    }
                </Marquee>
            </div>
        </div>
    )
}
