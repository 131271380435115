import { Button, DatePicker, Divider, Pagination, Select } from "antd";
import moment from 'moment';
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { AiOutlineSearch } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { getApiRequest } from "../../api/CommonApi";
import AllCourseCard from "../../components/elearning/allCourseCard/AllCourseCard";
import ElearnigFilter from "../../components/elearning/elearnigFilter/ElearnigFilter";
import FeatureSlider from "../../components/elearning/featureslider/FeatureSlider";
import QuickCall from "../../components/quickcall/QuickCall";
import Searchbar from "../../components/searchbar/SearchBar";
import { setCourses } from "../../services/actions/CourseAction";
import "./Elearning.css";

const { Option } = Select;
const { RangePicker } = DatePicker;

let searchText = "";

export default function AllCourses() {
  const courses = useSelector((state) => state.allCourses.courses);
  const dispatch = useDispatch();
  const [cookies] = useCookies(["user"]);
  const [featureCourses, setFeatureCourses] = useState();
  const [tags, setTags] = useState();
  const [categories, setCategories] = useState();
  const [levels, setLevels] = useState();
  const [languages, setLanguages] = useState();
  const [sort, setSort] = useState("latest");
  const [responseTime, setResponseTime] = useState(0);
  const [countResponseData, setCountResponseData] = useState(0);
  const [load, setLoad] = useState(1);
  const [filterDate, setFilterDate] = useState(null);
  const [paginateInfo, setPaginateInfo] = useState([]);

  searchText = useSelector((state) => state.selectedSearchTitle.title);

  let selectedCategory = useSelector(
    (state) => state.categoryType.categoryTypes
  );
  selectedCategory = selectedCategory.join();

  let selectedtags = useSelector((state) => state.tagSelect.tags);
  selectedtags = selectedtags.join();

  let selectedLevels = useSelector((state) => state.selectedLevel.levels);
  selectedLevels = selectedLevels.join();

  let selectedLanguages = useSelector(
    (state) => state.selectedLanguage.languages
  );
  selectedLanguages = selectedLanguages.join();

  useEffect(() => {
    fetchCourses();
  }, [
    searchText,
    selectedtags,
    selectedCategory,
    selectedLevels,
    selectedLanguages,
    sort,
    load,
  ]);
  
  useEffect(() => {
    fetchCourseResources();
  }, []);

  const fetchCourseResources = async () => {
    const params = {};
    const response = await getApiRequest(
      "/api/courses/course-resources",
      "",
      params
    );
    setFeatureCourses(response?.data?.data?.featureCourses);
    setTags(response?.data?.data?.tags);
    setCategories(response?.data?.data?.categories);
    setLevels(response?.data?.data?.levels);
    setLanguages(response?.data?.data?.languages);
  };

  const fetchCourses = async (filterDate = null, page = 1) => {
    const params = {
      load: load,
      search: searchText,
      category_id: selectedCategory ? selectedCategory : "",
      level_id: selectedLevels ? selectedLevels : "",
      lang_id: selectedLanguages ? selectedLanguages : "",
      tags: selectedtags ? selectedtags : "",
      sort: sort,
    };
    
    const url = filterDate ? `/api/agent/courses/index?start_date=${filterDate[0]}&end_date=${filterDate[1]}&page=${page}` : '/api/agent/courses/index' + `?page=${page}`;

    //  const url = '/api/agent/courses/index' + `?page=${page}`;
      getApiRequest(url, '', params).then(response => {
          console.log('my response  course list', response)
          if (response?.data?.data) {
            dispatch(setCourses(response?.data?.data));
          }
          setCountResponseData(
            response?.data?.data ? response?.data?.data.length : 0
          );
          setResponseTime(response?.response_time);
          setPaginateInfo(response?.data?.meta);
      }).catch(error => {
          console.log(error);
      });
    
  };

  const getDateFiler = (date, dateString) => {
    console.log(date, dateString);
    const startDate = moment(dateString[0]).format('YYYY-MM-DD');
    const endDate = moment(dateString[1]).format('YYYY-MM-DD');
    setFilterDate([startDate, endDate]);
}

  const filterReport = () => {
    fetchCourses(filterDate);
  }


  const handleLoadMore = async () => {
    setLoad(load + 1);
    await fetchCourses();
  };

  const handleDropdown = (e) => {
    setSort(e);
  };

  console.log('paginateInfo', paginateInfo);

  return (
    <div className="elearnigAllCourses">
      {/* QuickCall-component  */}
      <QuickCall />

      <span className="elearnigAllCourseTitle">Featured Courses</span>
      <Divider className="featuredDivider" />
      {/* FeatureSlider-component  */}
      <FeatureSlider featureCourses={featureCourses} />

      <div className="elearnigAllCoursesDivisionArea">
        <Searchbar />
        <Col md={12} className="">
            <Button className="float-right" onClick={() => filterReport()}> <AiOutlineSearch /> Filter</Button>
            <RangePicker className="float-right" onChange={getDateFiler} />
        </Col>
        <span className="elearnigAllCourseTitle">All Development Courses</span>
        <Divider />
        <div className="elearnigAllCoursesResults">
          <div className="left">
            <span className="elearnigAllCoursesResultsCount">
              About {countResponseData ?? ""} results found (
              {responseTime ? responseTime.toFixed(2) : ""} sec)
            </span>
          </div>
          <div className="right">
            <span className="elearnigAllCoursesortby">Sort by: </span>
            <Select
              defaultValue={sort}
              style={{ width: 100 }}
              placeholder="Sort"
              onChange={handleDropdown}
            >
              <Option value="latest">Latest</Option>
              <Option value="older">Older</Option>
              <Option value="updated">Updated</Option>
              <Option value="a2z">A to Z</Option>
              <Option value="z2a">Z to A</Option>
            </Select>
          </div>
        </div>
      </div>
      <Row>
        <Col md={3}>
          <ElearnigFilter
            tags={tags}
            categories={categories}
            levels={levels}
            languages={languages}
          />
        </Col>
        <Col md={9}>
          <AllCourseCard />
          {paginateInfo && (
              <Pagination defaultCurrent={1} total={paginateInfo.total} showTotal={total => `Total ${total} items`} pageSize={ parseInt(paginateInfo.per_page)} onChange={(page) => {
                fetchCourses(null, page);
              }} />
          )}

          {/* {countResponseData > 0 && (
            <div className="more-btn">
              <button className="btn" onClick={() => handleLoadMore()}>
                Load more
              </button>
            </div>
          )} */}
        </Col>
      </Row>
    </div>
  );
}
