import { ActionTypes } from "../constants/ActionTypes";

export const setCourses =(courses) => {
    return{
        type: ActionTypes.SET_COURSES,
        payload: courses,
    };
};

export const selectedCourse =(course) => {
    return{
        type: ActionTypes.SELECTED_COURSE,
        payload: course,
    };
};
export const removeSelectedCourse =(course) => {
    return{
        type: ActionTypes.REMOVE_SELECTED_COURSE,
    };
};

export const newCourseQuestion =(newQuestion) => {
    return{
        type: ActionTypes.SET_NEW_QUESTION,
        payload: newQuestion,
    };
};

export const setFeatureCourses =(courses) => {
    return{
        type: ActionTypes.SET_FEATURE_COURSES,
        payload: courses,
    };
};

export const setCourseLesson =(lesson) => {
    return{
        type: ActionTypes.SET_COURSE_LESSON,
        payload: lesson,
    };
};



// export const setCourseCategories =(categories) => {
//     return{
//         type: ActionTypes.SET_COURSE_CATEGORIES,
//         payload: categories,
//     };
// };

// export const setCourseTags =(tags) => {
//     return{
//         type: ActionTypes.SET_COURSE_TAGS,
//         payload: tags,
//     };
// };

export const setSelectedCourseTags =(tags) => {
    return{
        type: ActionTypes.SELECTED_COURSE_TAGS,
        payload: tags,
    };
};
