import { Button, Divider, Input, Pagination } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getApiRequest } from "../../api/CommonApi";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import Preloader from "../../components/common/Preloader";
import { urlChecker } from "../../utils/urlChecker";
import "./Faq.css";

function MatrixList() {
  const [faqs, setFaqs] = useState("");
  const [isLoded, setIsLoded] = useState(false);
  const [paginateInfo, setPaginateInfo] = useState(null);
  const [title, setTitle] = useState("");
  const BASEURL = urlChecker();

  const loadResources = async (page = 1) => {
    const url = title
      ? "/api/matrix/read?page=" + page + "&title=" + title
      : "/api/matrix/read?page=" + page;
    const response = await getApiRequest(url, "", "");
    setFaqs(response?.data?.data?.data);
    setPaginateInfo(response?.data?.data);
    setIsLoded(true);
  };

  useEffect(async () => {
    loadResources();
    document.title = "Matrix List";
  }, [title]);

  const onChangeSearch = (e) => {
    setTitle(e.target.value);
  };

  const exportReport = (id) => {
    const url = "/api/matrix/export?id=" + id;
    window.open(BASEURL + url);
  };

  return isLoded == false ? (
    <Preloader />
  ) : (
    <div className="MatrixList">
      <BreadCrumb link="matrix-List" /><br />
      <div className="card">
        <div className="heading">
          <div className="left">
            <h3 className="siteTitle">Matrix List :</h3>
          </div>
          <Divider />
        </div>
        <Row>
          <Col md={12}>
            <Input
              size="large"
              placeholder="Search here.."
              onChange={onChangeSearch}
            />
          </Col>
        </Row>
        <Divider />
        <Table striped bordered hover>
          <thead>
            <tr>
              {/* <th>#</th> */}
              <th>Name of FAQ</th>
              <th className="text-center">Last Updated</th>
              <th className="text-center">Download</th>
              {/* <th className="text-center">Status</th>
              <th className="text-center">Action</th> */}
            </tr>
          </thead>
          <tbody>
            {Object.keys(faqs).length > 0 && faqs != null && faqs != "" && faqs != undefined
              ? faqs.map((faq, index) => (
                  <tr>
                    {/* <td>{index + 1}</td> */}
                    <td>
                      <Link to={`/table-matrix-base/${faq?.id}`}>
                        <h3>{faq?.title}</h3>
                      </Link>
                    </td>
                    <td className="text-center">
                      {moment(faq?.updated_at).format("YYYY-MM-DD")}
                    </td>
                    <td className="text-center">
                      {faq?.show_on_search_matrix == 0 ? (
                        <Button onClick={() => exportReport(faq?.id)} className="mx-1">
                          Download
                        </Button>
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                ))
              : ""}
          </tbody>
        </Table>
        {paginateInfo && (
          <Pagination
            defaultCurrent={1}
            total={paginateInfo.total}
            showTotal={(total) => `Total ${total} items`}
            onChange={(p) => {
              loadResources(p);
            }}
          />
        )}
      </div>
    </div>
  );
}

export default MatrixList;
