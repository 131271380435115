export const ActionTypes = {
    SET_PRODUCTS : "SET_PRODUCTS",
    SELECTED_PRODUCT : "SELECTED_PRODUCT",
    REMOVE_SELECTED_PRODUCT : "REMOVE_SELECTED_PRODUCT",
    USER_DATA_STORE : "USER_DATA_STORE",
    SET_FAQS: "SET_FAQS",
    SELECTED_FAQ: "SELECTED_FAQ",
    REMOVE_SELECTED_FAQ : "REMOVE_SELECTED_FAQ",
    TYPE_SELECTED: "TYPE_SELECTED",
    SET_COURSES: "SET_COURSES",
    SELECTED_COURSE: "SELECTED_COURSE",
    REMOVE_SELECTED_COURSE : "REMOVE_SELECTED_COURSE",
    SET_COURSE_LESSON: "SET_COURSE_LESSON",
    //SET_FEATURE_COURSES: "SET_FEATURE_COURSES",
    //SET_COURSE_CATEGORIES: "SET_COURSE_CATEGORIES",
    //SET_COURSE_TAGS: "SET_COURSE_TAGS",
    SELECTED_COURSE_TAGS: "SELECTED_COURSE_TAGS",
    CATEGORY_TYPE_SELECT: "CATEGORY_TYPE_SELECT",
    SELECTED_LEVEL: "SELECTED_LEVEL",
    SELECTED_LANGUAGE: "SELECTED_LANGUAGE",
    SET_NEW_QUESTION: "SET_NEW_QUESTION",
    TAG_SELECTED:"TAG_SELECTED",
    SET_VIDEOS:"SET_VIDEOS",
    SELECTED_VIDEO:"SELECTED_VIDEO",
    REMOVE_SELECTED_VIDEO:"REMOVE_SELECTED_VIDEO",
    SEARCH_TITLE_SET:"SEARCH_TITLE_SET",
    FILE_SET:"FILE_SET",
    IMAGE_SET:"IMAGE_SET",
    CALL_TRACK_INFO: "CALL_TRACK_INFO",
    SELECTED_FAVOURITE_IDS:"SELECTED_FAVOURITE_IDS",
    REMOVED_FAVOURITE_IDS:"REMOVED_FAVOURITE_IDS",
    SELECTED_LOGIN_METHOD: "SELECTED_LOGIN_METHOD",
    CHANGE_ABLE_VALUE: "CHANGE_ABLE_VALUE",
    SET_FLAG_VIDEO: "SET_FLAG_VIDEO",
 }