import { SearchOutlined } from "@ant-design/icons";
import { Input, Select } from "antd";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { getApiRequest, postApiRequest } from "../../api/CommonApi";
import "./AllQuiz.css";
const { Option } = Select;

export const AllQuiz = () => {
  const history = useHistory();
  const [allQuiz, setAllQuiz] = useState({
    data: [],
  });

  useEffect(() => {
    getApiRequest("/api/quiz/index", "").then((res) => {
      setAllQuiz({ ...allQuiz, data: res.data.data });
    });
  }, []);

  const startQuiz = (id) => {
    postApiRequest(`/api/quiz-attempt/start`, "", { quiz_id: id })
      .then((res) => {
        let firstQuestion = res.data.data.question;
        let attempt = res.data.data.attempt;
        res.status === 200 &&
          history.push(`/quiz-attempt/${attempt.id}/${firstQuestion.id}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const { data } = allQuiz;
  return (
    <div className="allQuiz">
      <div className="card">
        <div className="AllQuizHeading">
          <div className="left">
            <span className="allQuizTitle">All Quiz</span>
          </div>
          <div className="right">
            <div className="content">
              <Input placeholder="search" prefix={<SearchOutlined />} />
              <div className="sortby">
                <span className="allQuizSort">Sort by:</span>
                <Select showSearch style={{ width: 100 }} placeholder="Latest">
                  <Option value="jack">Jack</Option>
                </Select>
              </div>
            </div>
          </div>
        </div>
        <div className="allQuizTable">
          <Table responsive hover>
            <thead className="allQuizheading">
              <tr>
                <th className="font-bold gray-text-200">Title</th>
                <th className="font-bold gray-text-200">Total Questions</th>
                {/* <th className="font-bold gray-text-200">Total Marks</th> */}
                <th className="font-bold gray-text-200">Success Rate</th>
                <th className="font-bold gray-text-200">Total Time</th>
                <th className="font-bold gray-text-200">Average Time</th>
                <th className="font-bold gray-text-200 text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item) => (
                <tr>
                  <td>{item.title}</td>
                  <td>{item.total_answerable_questions}</td>
                  {/* <td>{item.total_score}</td> */}
                  <td>40%</td>
                  <td>{item.quiz_duration} mins</td>
                  <td>{item.avarage_time_taken} mins</td>
                  <td className="text-center">
                    <button
                      onClick={() => startQuiz(item.id)}
                      className="btn btn-success allQuizTableStartbtn"
                    >
                      Start
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};
