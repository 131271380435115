import { Button, Form, Input } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, Row } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { getApiRequest, postApiRequest } from "../../api/CommonApi";
import Timer from "../../components/login/Timer";
import { selectedLoginMethod } from "../../services/actions/common/LoginMethod";
import "./Login.css";

export const Login = () => {
  let history = useHistory();
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const [enableOTPLogin, setEnableOTPLogin] = useState(false);
  const [enableOTPInputField, setEnableOTPInputField] = useState(false);
  const [otpCode, setOtpCode] = useState("");
  const dispatch = useDispatch();
  const [attemps, setAttemps] = useState(1);
  const [disable, setDisable] = useState(false);
  const [enableNormalLogin, setEnableNormalLogin] = useState(false);
  // const [generalSettings, setGeneralSettings] = useState();
  const [generalLoginOtp, setGeneralLoginOtp] = useState(false);
  const [generalOtp, setGeneralOtp] = useState("");
  const [userInformationName, setUserInformationName] = useState("");
  const [userInformationPass, setUserInformationPass] = useState("");
  const [adLoginOption, setAdLoginOption] = useState(false);

  if (
    cookies.normal_login_attemps == undefined ||
    cookies.normal_login_attemps == null ||
    cookies.normal_login_attemps == ""
  ) {
    setCookie("normal_login_attemps", 1, { path: "/" });
  }

  if (cookies.otpBlock === "1") {
    let expTime = moment().add("5", "m");
    setCookie("expired_time", expTime);
    setCookie("otpBlock", 2);
    setCookie("normal_login_attemps", 3);
    console.log("exp time", expTime);
  }

  if (cookies.expired_time < moment().toISOString()) {
    setCookie("normal_login_attemps", 1);
    removeCookie("expired_time");
    removeCookie("otpBlock");
  }

  useEffect(() => {
    if (cookies.normal_login_attemps && cookies.normal_login_attemps >= 3) {
      setDisable(true);
    }
    // loadGeneralSetting();
    getIP();
  }, []);

  // const loadGeneralSetting = async () => {
  //     const response = await getApiRequest("/api/settings/general");
  //     setGeneralSettings(response?.data?.data);
  // }

  const getIP = async () => {
    // const response = await getApiRequest("/api/get-ip");
    if (window.location.href === process.env.REACT_APP_IP) {
      console.log("Bhoom");
      setAdLoginOption(true);
    }
    console.log("ENV IP", process.env.REACT_APP_IP);
    console.log("URL CHECK", window.location.href)
  };

  const onFinish = async (values) => {
    const data = {
      client_id: process.env.REACT_APP_CLIENT_ID,
      grant_type: process.env.REACT_APP_GRANT_TYPE,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      username: values.username,
      password: values.password,
    };

    const response = await postApiRequest("/v1/oauth/token", "", data);

    if (response?.status == 200) {
      const userResponse = await postApiRequest(
        "/api/user/availability/check",
        "",
        {
          username: values.username,
          general: 1,
        }
      );

      const optRes = await sendMobileOTP(userResponse?.data?.data);
      const apiResData = JSON.parse(optRes?.data?.data);
      setUserInformationName(values.username);
      setUserInformationPass(values.password);
      setGeneralOtp(apiResData?.id);
      setGeneralLoginOtp(true);
      setEnableNormalLogin(false);

      return false;
    } else {
      toast.error("Credentials are incorrect");
      setCookie("normal_login_attemps", cookies.normal_login_attemps++, {
        path: "/",
      });
      if (cookies.normal_login_attemps && cookies.normal_login_attemps >= 3) {
        setDisable(true);
        setCookie("timeout_for_login", moment().add(5, "m"));
      }
    }
  };

  console.log("otpCode global", otpCode);
  console.log("otpCode GENERAL global", generalOtp);

  const sendMobileOTP = async (user) => {
    var randOTP = Math.floor(1000 + Math.random() * 9000);
    const odata = {
      otp: randOTP,
      message: "OTP " + randOTP,
      emailOrNumber: user.phone,
      masking: "GP Learning",
    };
    const response = await postApiRequest(
      "/api/customers/send-message",
      "",
      odata
    );
    return response;
  };

  const handleOTPLogin = async (values) => {
    const userData = {
      username: values.username,
    };
    const userResponse = await postApiRequest(
      "/api/user/availability/check",
      "",
      userData
    );

    if (userResponse?.data?.data == undefined) {
      toast.error("User not found!");
      return false;
    }

    if (otpCode == "") {
      setEnableOTPInputField(true);
      const optRes = await sendMobileOTP(userResponse?.data?.data);
      const apiResData = JSON.parse(optRes?.data?.data);
      setOtpCode(apiResData?.id);
      return false;
    }

    if (values.otp == otpCode) {
      const data = {
        client_id: process.env.REACT_APP_CLIENT_ID,
        grant_type: process.env.REACT_APP_GRANT_TYPE,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        username: userResponse.data.data.email,
        password: "123456",
      };
      const response = await postApiRequest("/v1/oauth/token", "", data);

      if (response.status == 200) {
        const res = await getApiRequest(
          "/api/user-information",
          response.data.access_token,
          ""
        );

        if (res.status == 200) {
          dispatch(selectedLoginMethod("form-login"));
          setCookie("access_token", response.data.access_token, { path: "/" });
          setCookie("refresh_token", response.data.refresh_token, {
            path: "/",
          });
          setCookie("user", res.data.data, { path: "/" });
          setCookie("admin", "false", { path: "/" });
          setCookie("tracking", "false", { path: "/" });
        } else {
          console.log("error: ", res.data.message);
        }
        setOtpCode("");
        window.location.href = "/";
      } else {
        toast.error(response.data.message);
      }
    } else {
      toast.error("OTP Mismatched!");
    }
  };

  const generalOtpHandle = async (values) => {
    if (values.otp == generalOtp) {
      const data = {
        client_id: process.env.REACT_APP_CLIENT_ID,
        grant_type: process.env.REACT_APP_GRANT_TYPE,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        username: userInformationName,
        password: userInformationPass,
      };

      const response = await postApiRequest("/v1/oauth/token", "", data);

      if (response?.status == 200) {
        const res = await getApiRequest(
          "/api/user-information",
          response.data.access_token,
          ""
        );
        dispatch(selectedLoginMethod("form-login"));
        removeCookie("normal_login_attemps");
        removeCookie("otp_block");
        setCookie("access_token", response?.data?.access_token, { path: "/" });
        setCookie("refresh_token", response?.data?.refresh_token, {
          path: "/",
        });
        setCookie("information_user", res?.data?.data, { path: "/" });
        localStorage.setItem(
          `user_information`,
          JSON.stringify(res?.data?.data)
        );
        setCookie("admin", "false", { path: "/" });
        setCookie("tracking", "false", { path: "/" });
        setCookie("logged_id", "false", { path: "/" });
        window.location.href = "/";
      } else {
        toast.error(res?.data?.message);
      }
    }
  };

  const loginOTP = async () => {
    setEnableOTPLogin(true);
    setGeneralLoginOtp(false);
  };

  const mailLogin = async () => {
    setEnableOTPLogin(false);
    setGeneralLoginOtp(false);
  };

  // getPreshiftReport = () => {
  //   if (!localStorage.getItem("preShiftReport")) {
  //     getApiRequest("/api/preshift-briefings/report-user", "")
  //       .then((res) => {
  //         if (res && res.status === 200) {
  //           let preShift = res?.data?.data;
  //           this.setState({ preShift: preShift, dataLoaded: true });
  //           let preShiftReport = res?.data?.data?.report;
  //           this.setState({ preShiftReport: preShiftReport });
  //           this.checkPreShift(preShift, preShiftReport);

  //           localStorage.setItem("preShiftReport", JSON.stringify(preShift));
  //         } else {
  //           this.setState({ preShift: null });
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // };

  // checkPreShift = (preShift, preShiftReport) => {
  //   let isScoreAchiveFull =
  //     preShift == null
  //       ? true
  //       : preShiftReport?.score_achive_per == 100
  //       ? true
  //       : false;

  //   console.log("what", isScoreAchiveFull);
  //   if (!isScoreAchiveFull) {
  //     if (!localStorage.getItem("preShift")) {
  //       window.location.href = "/pre-shift-briefing";
  //       this.setState({ isPreShiftDone: false });
  //     }
  //     this.setState({ isPreShiftDone: true });
  //     // localStorage.setItem("preShift", "true");
  //   }
  // };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const enableGeneralLogin = () => {
    setEnableNormalLogin(true);
    setGeneralLoginOtp(false);
  };
  return (
    <>
      <div className="login-component bg-light-100">
        <Row>
          <Card className="login-card">
            <img className="logo" src="/images/logo.png" alt="" />
            {enableNormalLogin && !enableOTPLogin && (
              <Form
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
              >
                <Form.Item
                  label="Email"
                  name="username"
                  rules={[
                    { required: true, message: "Please input your Email!" },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item>
                  <Button
                    className="primary-btn"
                    htmlType="submit"
                    disabled={disable}
                  >
                    Login
                  </Button>
                </Form.Item>
              </Form>
            )}

            {generalLoginOtp && (
              <>
                <Form
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={generalOtpHandle}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                >
                  <Form.Item
                    label="OTP"
                    name="otp"
                    rules={[
                      { required: true, message: "Please input your OTP!" },
                    ]}
                  >
                    <Input placeholder="OTP" />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      className="primary-btn"
                      htmlType="submit"
                      disabled={disable}
                    >
                      Login
                    </Button>
                  </Form.Item>
                  {/* <small>Please, input the username for otp request.</small> */}
                </Form>
                <Timer />
              </>
            )}

            {enableOTPLogin && (
              <Form name="basic" onFinish={handleOTPLogin} layout="vertical">
                <Form.Item
                  label="Username"
                  name="username"
                  rules={[
                    { required: true, message: "Please input your username!" },
                  ]}
                >
                  <Input placeholder="username" />
                </Form.Item>
                {/* <small>Please, input the username for otp request.</small> */}

                {enableOTPInputField && (
                  <Form.Item
                    label="OTP"
                    name="otp"
                    rules={[
                      { required: true, message: "Please input your OTP!" },
                    ]}
                  >
                    <Input placeholder="OTP" />
                  </Form.Item>
                )}

                <Form.Item>
                  <Button className="primary-btn" htmlType="submit">
                    Login
                  </Button>
                </Form.Item>
              </Form>
            )}

            {/* {!enableOTPLogin && (
              <Form.Item>
                <Button onClick={loginOTP} disabled={disable}>
                  Login With OTP
                </Button>
              </Form.Item>
            )} */}

            {/* {enableOTPLogin && (
              <Form.Item>
                <Button onClick={mailLogin} disabled={disable}>
                  Login With Mail
                </Button>
              </Form.Item>
            )} */}

            {!adLoginOption && (
              <Form.Item>
                <Link to="/alt-login">
                  <Button disabled={disable}>Login With AD</Button>
                </Link>
              </Form.Item>
            )}
            
            {/* {generalSettings?.general_email_login == 1 &&  */}
            <Form.Item>
              <Button disabled={disable} onClick={enableGeneralLogin}>
                General Login{" "}
              </Button>
            </Form.Item>
            {/* } */}
            <br/><br/>
            <a className="login-form-forgot" href="/forget-password">
              {" "}
              Forget Password?
            </a>
            <br />

            {/* <p className="title secondary-text capitalize font-bold">first time ever rendezvous with telenor <span className="uppercase">cmo</span></p> */}
            {/* <SignInButton /> */}
            {/* { isAuthenticated ? <SignInButton /> : <SignInButton /> } */}
          </Card>
        </Row>
      </div>
    </>
  );
};

export default Login;
