import React from "react";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import SwiperCore, {
  Autoplay,
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination
} from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import { urlChecker } from "../../utils/urlChecker";
import "./BannerCarousel.css";

SwiperCore.use([Navigation, Pagination, Mousewheel, Keyboard, Autoplay]);

export default function BannerCarousel(props) {
  let sliderImage = [];
  
  const BASEURL = urlChecker();

  if (props?.AllSliders) {
    if (
      props &&
      props?.AllSliders !== undefined &&
      Object.keys(props?.AllSliders).length !== 0
    ) {
      const images = props?.AllSliders;
      images.map((image) => {
        sliderImage.push(
          <SwiperSlide>
            {image.link ? (
              <a href={image.link} target="_blank">
                <img
                  className="sliderImg"
                  src={BASEURL + image.url}
                  alt=""
                />
              </a>
            ) : (
              <img
                className="sliderImg"
                src={BASEURL + image.url}
                alt=""
              />
            )}
          </SwiperSlide>
        );
      });
    }
  } else {
    sliderImage.push(
      <SwiperSlide>
        <img
          className="sliderImg"
          src={BASEURL + `/images/slider_image/f1.jpg`}
          alt=""
        />
      </SwiperSlide>
    );
  }
  return (
    <div>
      <section className="banner-carousel-area">
        <Swiper
          cssMode={true}
          navigation={true}
          pagination={true}
          mousewheel={true}
          keyboard={true}
          autoplay={true}
          className="mySwiper"
        >
          {/* <SwiperSlide>
                        <img src="images/slider/slider-7.jpeg" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="images/slider/slider-8.jpeg" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="images/slider/slider-9.jpeg" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="images/slider/slider-6.jpeg" alt="" />
                    </SwiperSlide> */}
          {sliderImage ? sliderImage : ""}
        </Swiper>
      </section>
    </div>
  );
}
