import { Button, DatePicker, Divider, Pagination, Select, Tag } from 'antd';
import dotenv from "dotenv";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { AiOutlineDownload, AiOutlineSearch } from "react-icons/ai";
import { getApiRequest } from '../../api/CommonApi';
import { urlChecker } from '../../utils/urlChecker';
import './Reports.css';


dotenv.config();
const { RangePicker } = DatePicker;
const { Option } = Select;

export const AgentWiseElearning = () => {

    const [enrollUsers, setEnrollUsers] = useState([]);
    const [paginateInfo, setPaginateInfo] = useState([]);
    const [filterDate, setFilterDate] = useState(null);
    const [courseList, setCourseList] = useState([]);
    const [course, setCourse] = useState('');
    const BASEURL = urlChecker();

    
    useEffect(()=> {
        loadAllEnrolledUser();
    }, [course]);

    useEffect(()=> {
        loadAllCourses();
    }, []);

    function handleStatus(e) {
        setCourse(e);
    }

    const loadAllCourses = async () =>{
        const response = await getApiRequest("/api/courses")
        setCourseList(response?.data?.data);
    };


    const getDateFiler = (date, dateString) => {
        console.log(date, dateString);
        const startDate = moment(dateString[0]).format('YYYY-MM-DD');
        const endDate = moment(dateString[1]).format('YYYY-MM-DD');
        setFilterDate([startDate, endDate]);
    }

    const loadAllEnrolledUser = async (filterDate = null, page = 1) =>{

        const params = {
            course,
        }

        const url = filterDate ? `/api/course-enrolled/agent/index?start_date=${filterDate[0]}&end_date=${filterDate[1]}&page=${page}` : '/api/course-enrolled/agent/index' + `?page=${page}`;

        getApiRequest(url, '', params).then(response => {
        console.log('my response report enroll', response?.data?.data)
        setEnrollUsers(response?.data?.data);
        setPaginateInfo(response?.data?.meta);
        }).catch(error => {
            console.log(error);
        });

    };

    const filterReport = () => {
        console.log(filterDate);
        loadAllEnrolledUser(filterDate);
    }

    const exportReport = () => {
        const url = filterDate ? `/api/course-enrolled/index?start_date=${filterDate[0]}&end_date=${filterDate[1]}&export=true` : '/api/course-enrolled/index?export=true';
        window.open(BASEURL + url);
    }

    return (
        <>
            <div className="AgentWiseElearning">
                <div className="reportTableArea card">
                    <Row>
                        <Col md={4}>
                            <span className="reportTitle">Agent Elearning Report</span>
                        </Col>
                        <Col md={8} className="">
                            <Button className="float-right" style={{ marginLeft: "10px" }} onClick={() => exportReport()}> <AiOutlineDownload /> Export</Button>
                            <Button className="float-right" onClick={() => filterReport()}> <AiOutlineSearch /> Filter</Button>
                            <RangePicker className="float-right" onChange={getDateFiler} />
                        </Col>
                    </Row><br/>
                    <Row>
                    <Col md={12} className="">
                        <Select
                                showSearch
                                className="select"
                                style={{ width: "100%" }}
                                placeholder="Select Course"
                                onChange={handleStatus}
                                filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }  
                            >
                            
                                <Option value="">All</Option>
                                {courseList && courseList.map((course, index) => (
                                    <Option value={course.id} key={index}>{course.title}</Option>))}
                            </Select>
                           
                        </Col>
                    </Row>
                    <Divider />
                    <Table responsive hover className="reportTable">
                        <thead className="reportheading">
                            <tr>
                                <th className="font-bold gray-text-200">Course Name</th>
                                <th className="font-bold gray-text-200">Start Date</th>
                                <th className="font-bold gray-text-200">Completion Date</th>
                                <th className="font-bold gray-text-200">Expiry Date</th>
                                <th className="font-bold gray-text-200 text-center">Content Time [Min]</th>
                                <th className="font-bold gray-text-200">Spend Time [Min]</th>
                                <th className="font-bold gray-text-200">Quiz Result</th>
                                <th className="font-bold gray-text-200">Quiz Attempt</th>
                                <th className="font-bold gray-text-200">Quality Score</th>
                                <th className="font-bold gray-text-200">CSAT Score</th>
                                <th className="font-bold gray-text-200">AHT[Sec] </th>
                                <th className="font-bold gray-text-200">LOB </th>
                            </tr>
                        </thead>
                        <tbody>
                            {enrollUsers && enrollUsers.map((enroll, i) => {
                            return(
                                <tr>
                                <td>{enroll.course_titile}</td>
                                <td>{ moment(enroll.created_at).isValid() ? moment(enroll.created_at).format('DD-MM-YYYY') : ''}</td>
                                <td>{ (enroll.completion_ratio == 100) ? moment(enroll.updated_at).format('DD-MM-YYYY') : 'on progress'}</td>
                                <td>{moment(enroll.expired_at).isValid() ? moment(enroll.expired_at).format('DD-MM-YYYY') : ''}</td>
                                <td className="text-center">{enroll?.content_time}</td>
                                <td>{enroll?.spend_time}</td>
                                <td>{enroll?.quiz_result ? enroll?.quiz_result+'%' : 0}</td>
                                <td>{enroll?.quiz_attempt}</td>
                                <td></td>
                                <td></td>
                                <td>{enroll?.aht}</td>
                                <td>
                                {enroll?.groups && enroll?.groups.map((group) => {
                                    return (<Tag color="blue" key={group.id}>{group.name}</Tag>);
                                })}
                                </td>
                            </tr>
                            )
                            })}
                        </tbody>
                    </Table>
                    {paginateInfo && (
                        <Pagination defaultCurrent={1} total={paginateInfo.total} onChange={(page) => {
                            loadAllEnrolledUser(filterDate, page);
                        }} />
                    )}
                </div>
            </div>
        </>
    )
}
