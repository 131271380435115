import dotenv from "dotenv";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { postApiRequest } from "../../api/CommonApi";
import { setFlagVideo } from '../../services/actions/video/VideoAction';
import { urlChecker } from "../../utils/urlChecker";
import VideoJS from './VideoJS';
dotenv.config();

function StreamPlayer(props) {
  const playerRef = React.useRef(null);
  const dispatch = useDispatch();
  const [chatFocus, setChatFocus] = useState(true);
  const posters = "images/slider/slide-1.jpg";
  const mURL = props.fileUrl ? props.fileUrl : "";

  const BASEURL = urlChecker();

  const fullPath = BASEURL + mURL;

  useEffect(() => {
    window.addEventListener('focus', handleActivityTrue);
    window.addEventListener('blur', handleActivityFalse);
}, [chatFocus]);

const handleActivityFalse = () => {
  setChatFocus(false);
   const player = playerRef.current;
   (player && player.player_ !=null) ? player?.pause() : '';
};

const handleActivityTrue = () => {
  setChatFocus(true);
  const player = playerRef.current;
  (player && player.player_ !=null) ? playerRef.current?.play() : '';
};

  const videoJsOptions = { 
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    aspectRatio: '16:9',
    sources: [{
      src: fullPath,
      type: 'video/mp4'
    }]
  }

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    player.on('waiting', () => {
      console.log('player is waiting');
    });

    player.on('dispose', () => {
      console.log('player will dispose');
    });

    player.on('ended', () => {
      dispatch(setFlagVideo(true));
      console.log('player is ended');
    });

    player.on('play', async (e) => {
      if(props?.videoId){
        console.log('player start', e);
        const response = await postApiRequest('/api/video/views-update','',{video_id:props?.videoId});
        console.log('return start response', response);
      }
    });

  };

  return (
    <div onContextMenu={e => e.preventDefault()}>
      <VideoJS 
      options={videoJsOptions} 
      onReady={handlePlayerReady}
      />
    </div>
  );
}

export default StreamPlayer;