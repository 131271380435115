import { Divider, Select } from "antd";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { MdOndemandVideo } from "react-icons/md";
import { useSelector } from "react-redux";
import { getApiRequest } from "../../api/CommonApi";
import QuickCall from "../../components/quickcall/QuickCall";
import SearchBar from "../../components/searchbar/SearchBar";
import VideoFilter from "../../components/searchvideofilter/VideoFilter";
import FavouriteVideoContent from "../../components/videocard/FavouriteVideoContent";
import "./Video.css";

const { Option } = Select;

export default function MyFavouriteVideo() {
  const [videos, setVideos] = useState([]);
  let [cnt, setCnt] = useState(0);
  const title = useSelector((state) => state.selectedSearchTitle.title);
  let selectedFavouriteIds = useSelector(
    (state) => state.selectedFavouriteIds.favouriteId
  );
  let tags = useSelector((state) => state.tagSelect.tags);
  tags = tags.join();
  let [pagination, setPagination] = useState(1);

  useEffect(async () => {
    console.log("Hello world");
    const params = {
      load: pagination,
      title: title,
      tag: tags,
      type: 2,
    };
    const response = await getApiRequest(
      "/api/favourite/read-video",
      "",
      params
    );
    setVideos(response?.data?.data);
    setCnt(response.data.count);
  }, [title, tags, selectedFavouriteIds]);

  const handleDropdown = (e) => {
    setSort(e);
  };
  const handleOnClick = (e) => {
    setPagination(pagination + 1);
  };
  return (
    <div className="video-page">
      <QuickCall />
      <SearchBar />
      <span className="videoSectionTitle">
        {" "}
        <MdOndemandVideo /> My Favourite Videos
      </span>
      <Divider />
      <div className="contentCount">
        <div className="left">
          <span className="contentText">About {cnt} results found</span>
        </div>
        {/* <div className="right">
                    <span className="sortby">sort by:</span>
                    <Select placeholder="Sort" style={{ width: 100 }} onChange={handleDropdown}>
                        <Option value="latest">Latest</Option>
                        <Option value="older">Older</Option>
                        <Option value="updated">Updated</Option>
                        <Option value="a2z">A to Z</Option>
                        <Option value="z2a">Z to A</Option>
                    </Select>
                </div> */}
      </div>
      <Row>
        <Col md={3}>
          <VideoFilter />
        </Col>
        <Col md={9}>
          <FavouriteVideoContent videos={videos} />
          {
              cnt%5 === 0 ? (
                <button className="loardMore" onClick={handleOnClick}>
                  Load More
                </button>
              ):""
            }
        </Col>
      </Row>
    </div>
  );
}
