import { ActionTypes } from "../../constants/ActionTypes";

export const selectedLevelStore = (levels) => {
    return {
        type: ActionTypes.SELECTED_LEVEL,
        payload: levels,
    }
}

export const selectedLanguageStore = (languages) => {
    return {
        type: ActionTypes.SELECTED_LANGUAGE,
        payload: languages,
    }
}

