import QuickCall from "../../components/quickcall/QuickCall";
import { AllWebinars } from "../../components/webinars/AllWebinars";
import "./Webinar.css";

export const Webinar = () => {
  return (
    <div className="allQuiz">
      <QuickCall />

      <AllWebinars />
    </div>
  );
};
