import { Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { GiStabbedNote } from 'react-icons/gi';
import { useParams } from 'react-router';
import { getApiRequest } from '../../api/CommonApi';
import MenuFaqDetail from '../../components/faq/MenuFaqDetail';
import MenuQ from '../../components/faq/MenuQ';
import QuickCall from '../../components/quickcall/QuickCall';
import { CallHandleForMenu } from '../callhandle/CallHandleForMenu';
import TableMatrixBase from '../faq/TableMatrixBased';
import { InterActiveDemoForMenu } from '../interactivedemo/InterActiveDemoForMenu';
import './Campaign.css';

const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

export const GpCampaign = () => {
    const [menuData, setMenuData] = useState('');
    const [pages, setPages] = useState('');
    const [tags, setTags] = useState('');
    const {id} = useParams();

    useEffect(async () => {
        console.log('resres222')
        setPages("");
        setMenuData("");
        const res = await getApiRequest(`/api/menu/${id}`);
        console.log('resres', res)
        setMenuData(res?.data?.data);
        setPages(res?.data?.data?.pages);
      }, [id]);
    
    return (
        <div className="GpCampaign">
            <QuickCall/>

            <h2 className="campaignTitle">{menuData?.title}:</h2>

            <div className="campaignTab">
            <Tabs defaultActiveKey="1" onChange={callback}>
                {
                    pages ? (
                        pages.map((page, index) => {
                            console.log('my page', page)
                            return (
                                <TabPane tab={
                                        <span>
                                        <GiStabbedNote />
                                        {page?.title}
                                        </span>
                                    } key={index+1} >
                                        {
                                            page?.pageable && page?.pageable?.type == 'general' ? 
                                            <MenuFaqDetail faq={page?.pageable} category={page?.pageable?.category} tagsRender={tags} /> 
                                            : ''
                                        }
                                        {
                                            page?.pageable && page?.pageable?.type == 'matrix' ? 
                                            <TableMatrixBase faqId={page?.pageable?.id} /> 
                                            : ''
                                        }
                                        {
                                            page?.pageable && page?.pageable?.type == 'click' ? 
                                            <CallHandleForMenu faqId={page?.pageable?.id}/> 
                                            : ''
                                        }
                                        {
                                            page?.pageable_type == 'App\\Models\\UssdApp' ?
                                            <InterActiveDemoForMenu uId={page?.pageable?.id}/> : ''
                                        }
                                        {
                                             page?.pageable_type == 'App\\Models\\UssdApp' ? '' : 
                                             <MenuQ faqId={page?.pageable?.id}/>
                                        }
                                </TabPane>
                            )
                        })
                    ):''
                }
            </Tabs>
            </div>
        </div>
    )
}
