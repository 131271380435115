import { DatePicker, Divider, Input, Select } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { BiSearchAlt2 } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { getApiRequest } from "../../api/CommonApi";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import QuickCall from "../../components/quickcall/QuickCall";
import { FavouriteContent } from "../../components/searchcontent/FavouriteContent";
import SearchFilter from "../../components/searchContentfilter/SearchFilter";
import "./Faq.css";

const { Option } = Select;
const { RangePicker } = DatePicker;

export default function FavouriteFaqs() {
  const [favouriteList, setFavouriteList] = useState("");
  const dispatch = useDispatch();
  const [cookies] = useCookies(["user"]);
  const [title, setTitle] = useState("");
  let [cnt, setCnt] = useState(0);
  let types = useSelector((state) => state.types.types);
  types = types.join(); // make comma seperated value
  const [sort, setSort] = useState("");
  const [filterDate, setFilterDate] = useState({
    start_date: "",
    end_date: "",
  });
  let selectedCategory = useSelector(
    (state) => state.categoryType.categoryTypes
  );
  selectedCategory = selectedCategory.join(); // make comma seperated value
  let selectedTags = useSelector((state) => state.tagSelect.tags);
  selectedTags = selectedTags.join();
  let [pagination, setPagination] = useState(1);
  let selectedFavouriteIds = useSelector(
    (state) => state.selectedFavouriteIds.favouriteId
  );

  const fetchFaqs = async () => {
    const params = {
      load: pagination,
      title: title,
      types: types,
      sort: sort,
      category_id: selectedCategory ? selectedCategory : "",
      tag: selectedTags,
      type: 1,
      from_date: filterDate.start_date,
      to_date: filterDate.end_date,
    };
    const response = await getApiRequest("/api/favourite/read", "", params);
    console.log(window.getCookie("access_token"));
    setFavouriteList(response.data.data);
    setCnt(response.data.count);
  };

  useEffect(() => {
    fetchFaqs();
    document.title = "Favourite Faqs";
  }, [
    title,
    types,
    sort,
    selectedCategory,
    selectedTags,
    selectedFavouriteIds,
    pagination,
    filterDate,
  ]);

  const handleKeyDown = (e) => {
    setTitle(e.target.value);
    if (title === e.target.value) {
      fetchFaqs();
    }
  };

  const handleDropdown = (e) => {
    setSort(e);
  };

  const handleOnClick = (e) => {
    setPagination(pagination + 1);
  };

  const getDateFiler = (date, dateString) => {
    console.log(date, dateString);
    if (dateString[0] !== "" && dateString[1] !== "") {
      const startDate = moment(dateString[0]).format("YYYY-MM-DD");
      const endDate = moment(dateString[1]).format("YYYY-MM-DD");
      setFilterDate({ start_date: startDate, end_date: endDate });
    } else {
      setFilterDate({ start_date: "", end_date: "" });
    }
  };

  return (
    <div className="all-faqs">
      <QuickCall />

      <BreadCrumb link="Favourite-FAQs" /><br />

      <div className="search-area">
        <Input
          size="large"
          placeholder="Search here.."
          prefix={<BiSearchAlt2 />}
          onChange={handleKeyDown}
        />
      </div>

      <div className="faqs-content-area">
        <span className="faqTitle">Favourite FAQ</span>
        <Divider />
        <div className="faqResultCounting">
          <div className="results">
            <span className="totalResults">
              About {favouriteList?.length} results found
            </span>
          </div>
          <div className="sortby">
            <span className="resultsSort">Sort by:</span>
            <Select
              showSearch
              style={{ width: 100 }}
              placeholder="Sort"
              onChange={handleDropdown}
            >
              <Option value="latest">Latest</Option>
              <Option value="older">Older</Option>
              {/* <Option value="updated">Updated</Option>
                        <Option value="a2z">A to Z</Option>
                        <Option value="z2a">Z to A</Option> */}
            </Select>
            <RangePicker className="float-right" onChange={getDateFiler} />
          </div>
        </div>

        <div className="faqResults">
          <Row className="searchContenResults">
            <Col xs={3}>
              <SearchFilter />
            </Col>
            <Col xs={9}>
              <FavouriteContent faqs={favouriteList} />
            </Col>
            {cnt > 0 && cnt > favouriteList.length ? (
              <button className="loardMore" onClick={handleOnClick}>
                Load More
              </button>
            ) : (
              ""
            )}
          </Row>
        </div>
      </div>
    </div>
  );
}
