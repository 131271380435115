import "./AllQuiz.css";
export const QuizTime = (props) => {

  return (
    <div className="card">
      <div className="quizTime">
        <div className="left">
          <span className="title">
            Quiz Title : <span className="subtitle">{props.quiz.title}</span>
          </span>
        </div>
        <div className="right">
          <div className="content">
            <span className="title">
              Total Time :
              <span className="subtitle">{props.quiz.quiz_duration} mins</span>
            </span>
            <span className="title">
              Total Marks :<span className="subtitle">{props.quiz.total_score}</span>
            </span>
            <span className="title">
              Total Question :
              <span className="subtitle"> {props.quiz.total_questions}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
