import { Button, DatePicker, Divider, Pagination } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { Col, Row, Table } from 'react-bootstrap';
import { AiOutlineDownload, AiOutlineSearch } from "react-icons/ai";
import { getApiRequest } from '../../api/CommonApi';
import Preloader from "../../components/common/Preloader";
import { urlChecker } from '../../utils/urlChecker';
import './Reports.css';

const { RangePicker } = DatePicker;

export const AgentWiseMeeting = () => {

    const [allFaqs, setAllFaqs] = useState([]);
    const [isLoded, setIsLoded] = useState(false);
    const [paginateInfo, setPaginateInfo] = useState(null);
    const [filterDate, setFilterDate] = useState(null);
    const BASEURL = urlChecker();

    const loadResources = async (filterDate = null, page = 1) => {
        const url = filterDate ? `/api/reports/agent-wise-aht?start_date=${filterDate[0]}&end_date=${filterDate[1]}&page=${page}` : `/api/reports/agent-wise-aht?page=${page}`;
        const response = await getApiRequest(url);
        setAllFaqs(response?.data?.data?.data);
        setPaginateInfo(response?.data?.data);
        setIsLoded(true);
    }

    const filterReport = () => {
        console.log(filterDate);
        loadResources(filterDate);
    }

    useEffect(() => {
        loadResources();
    }, []);

    const getDateFiler = (date, dateString) => {
        console.log(date, dateString);
        const startDate = moment(dateString[0]).format('YYYY-MM-DD');
        const endDate = moment(dateString[1]).format('YYYY-MM-DD');
        // return { startDate, endDate };
        // getQuizReport([startDate, endDate]);
        setFilterDate([startDate, endDate]);
    }

    const exportReport = () => {
        const url = filterDate ? `/api/reports/agent-wise-aht?report=agentWiseAht&start_date=${filterDate[0]}&end_date=${filterDate[1]}&export=true` : `/api/reports/agent-wise-aht?report=agentWiseAht&export=true`;

        window.open(BASEURL + url);
    }
    return (
        <>
            <div className="AgentWiseMeeting">
                <div className="reportTableArea card">
                    <Row>
                        <Col md={6}>
                            <span className="reportTitle">Agent Wise AHT Report</span>
                        </Col>
                        <Col md={6}>
                            <Button className="float-right" style={{ marginLeft: "10px" }} onClick={() => exportReport()}> <AiOutlineDownload /> Export</Button>
                            <Button className="float-right" onClick={() => filterReport()}> <AiOutlineSearch /> Filter</Button>
                            <RangePicker className="float-right" onChange={getDateFiler} />
                            {/* <Input placeholder="Faq Name" /> */}
                        </Col>
                    </Row>

                    <Divider />
                    <Table responsive hover className="reportTable">
                        <thead className="reportheading">
                            <tr>
                                <th className="font-bold gray-text-200">ID</th>
                                <th className="font-bold gray-text-200">Date</th>
                                <th className="font-bold gray-text-200">Time</th>
                                <th className="font-bold gray-text-200">RTC/Complaint</th>
                                <th className="font-bold gray-text-200">Open FAQ Name</th>
                                <th className="font-bold gray-text-200">Served MSISDN</th>
                                <th className="font-bold gray-text-200">AHT [Sec]</th>
                                <th className="font-bold gray-text-200">MSISDN Repeated [Same Day]</th>
                                <th className="font-bold gray-text-200">LOB</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                isLoded == false ? <Preloader /> : (
                                    allFaqs && allFaqs.map((item) => {
                                        return (
                                            <tr>
                                                <td>{item?.user?.name}</td>
                                                <td>{moment(item.created_at).format('MMMM Do YYYY')}</td>
                                                <td>{moment(item?.start_time).format('HH:mm:ss')}</td>
                                                <td>----</td>
                                                <td>{item?.faq?.title}</td>
                                                <td>----</td>
                                                <td>----</td>
                                                <td>----</td>
                                                <td>----</td>
                                            </tr>
                                        )
                                    })
                                )
                            }

                        </tbody>
                    </Table>
                    {paginateInfo && (
                        <Pagination defaultCurrent={1} total={paginateInfo.total} showTotal={total => `Total ${total} items`} onChange={(p) => {
                            loadResources(null, p);
                        }} />
                    )}
                </div>
            </div>
        </>
    )
}
