import dotenv from "dotenv";
import { useEffect, useState } from "react";
import { AiOutlinePlayCircle } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { postApiRequest } from '../../../api/CommonApi';
import { urlChecker } from "../../../utils/urlChecker";
import './MyCoursesCard.css';
dotenv.config();

export default function MyWishListCard(props) {
     const { id, title, cover_image, short_desc} = props.course;
     const [enrollStatus, setEnrollStatus] = useState(false);

     const BASEURL = urlChecker();

     useEffect(() => {
        props.course.enroll_user_info ? setEnrollStatus(true) : setEnrollStatus(false);
     }, [])

    const enrollToCourse = async () => {
        const data = {
          course_id: id,
        };
        const response = await postApiRequest(
          "/api/course-enrolled/create",
          "",
          data
        );
        toast.success(response?.data?.message);
        response?.data?.data ? setEnrollStatus(true) : setEnrollStatus(false);
        props.course.enroll_user_info = response.data.data;
        console.log("course enroll response", response);
    };


    return (
        <div className="myCoursesCard">
            <div className="card">
                <div className="myCoursesCardThumbnails">
                { cover_image &&
                        <img src={BASEURL +'/'+ cover_image} alt={title} />
                    }
                    <Link to={`/my-course-details/${id}`}>
                        <AiOutlinePlayCircle className="myCoursesCardPlaybtn" />
                    </Link>
                </div>
                <div className="myCoursesCardInfo">
                    <Link to={`/my-course-details/${id}`}>
                    <span className="myCoursesCardTitle">{title}</span>
                    </Link>
                    {props?.course?.course_instructor_name && 
                    <span className="myCoursesCardInstructor">Instructor Name : {props?.course?.course_instructor_name}</span>
                    }
                    <span className="myCoursesCardDesc">
                    <p
                        dangerouslySetInnerHTML={{
                        __html: short_desc,
                        }}
                    ></p>
                    </span>
                </div>

                {/* <div className="myCoursesCardRatingPStatus">
                 { enrollStatus ? (
                    <Button type="button" className="faqPrevQPostbutton enroll-btn w-100">Enrolled</Button>
                 ) : (
                    <Button type="button" className="faqPrevQPostbutton w-100" onClick={enrollToCourse}>Enroll Now</Button>
                 )}  
                </div> */}
            </div>
        </div>
    )

}
