import { ActionTypes } from "../constants/ActionTypes";

const initialState = {
    faqs: [],
}

export const faqReducer = ( state = initialState, {type, payload}) => {
    switch(type){
        case ActionTypes.SET_FAQS:
            return {...state, faqs:payload};
        default:
            return state;
    }
}

export const selectedFaqReducer = (state = {}, {type, payload}) => {
    switch (type){
        case ActionTypes.SELECTED_FAQ:
            return {...state, ...payload};
        case ActionTypes.REMOVE_SELECTED_FAQ:
            return {};
        default:
            return state;
    }
}