import { Collapse, Divider } from "antd";
import { useEffect, useState } from "react";
import { getApiRequest } from "../../api/CommonApi";
import "./InterActiveDemo.css";

const { Panel } = Collapse;

function callback(key) {
  console.log(key);
}

export const InterActiveDemoForMenu = (props) => {
  const [ussd, setUssd] = useState("");
  const [isLoad, setIsLoad] = useState(false);
  const id = props?.uId;
  console.log("come on my id", id);
  useEffect(async () => {
    if (id != null && id != undefined) {
      const response = await getApiRequest("/api/ussd-app/single-app", "", {
        id: id,
      });
      setUssd(response.data.data);
      setIsLoad(true);
    }
  }, [id]);

  return (
    <div className="interActiveDemo">
      <Divider />
      <h2 className="interactivedemoTitle">interactive demo</h2>
      <Divider />
      <a href={`/interactive-views/${ussd?.id}`}>
        <p className="card ussdPan">
          {ussd?.name}
        </p>
      </a>
    </div>
  );
};
