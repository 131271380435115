import { ActionTypes } from "../constants/ActionTypes";

const initialState = {
    favouriteId: [],
}

export const selectedFavouriteReducer = (state = initialState, {type, payload}) => {
    switch (type){
        case ActionTypes.SELECTED_FAVOURITE_IDS:
            return {...state, favouriteId:payload};
        case ActionTypes.REMOVED_FAVOURITE_IDS:
            return {...state, favouriteId:[]}
        default:
            return state;
    }
}
