import { Divider, Input } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getApiRequest } from "../../api/CommonApi";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import QuickCall from "../../components/quickcall/QuickCall";
import "./MyConfusions.css";

export default function MyConfusions() {
  const [confusions, setConfusions] = useState([]);
  const [search, setSearch] = useState("");
  let [cnt, setCnt] = useState(0);
  const [isLoad, setIsLoad] = useState(false);
  let [pagination, setPagination] = useState(1);
  useEffect(async () => {
    const response = await getApiRequest("/api/faq/get-all-confusion", "", {
      pagination: pagination,
      name: search,
    });
    setConfusions(response?.data?.data);
    setCnt(response?.data?.count);
    setIsLoad(true);
  }, [pagination, search]);

  const handleOnClick = (e) => {
    setPagination(pagination + 1);
  };

  const handleKeyDown = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div className="myConfusions">
      <QuickCall />

      <BreadCrumb link="my-Confusions"/><br/>

      <div className="myConfusionsTable card">
        <span className="confusionsTitle">My Confusions</span>
        <span className="searchBar">
          <Input placeholder="Search here" onKeyUp={handleKeyDown} />
        </span>
        <Divider />
        <Table responsive hover>
          <thead className="myConfusionsheading">
            <tr>
              <th className="font-bold gray-text-200">Question</th>
              <th className="font-bold gray-text-200">FAQs</th>
              <th className="font-bold gray-text-200">Date & Time</th>
              <th className="font-bold gray-text-200">Status</th>
              <th className="font-bold gray-text-200">Action</th>
            </tr>
          </thead>
          <tbody>
            {
              // isLoad == false ? <Preloader/> : (
              confusions.map((confusion) => {
                return (
                  <tr>
                    <td>{confusion.title}</td>
                    <td>
                      <a href={`/details-faqs/${confusion?.flag[0]?.faq?.id}`}>
                        {confusion?.flag[0]?.faq?.title}
                      </a>
                    </td>
                    <td>
                      {moment(confusion.created_at).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    </td>
                    <td>{confusion.status}</td>
                    <td>
                      <Link to={`/my-confusions-details/${confusion.id}`}>
                        View
                      </Link>
                    </td>
                  </tr>
                );
              })
              // )
            }
          </tbody>
        </Table>
        {cnt > 0 && cnt > confusions.length ? (
          <button className="loardMore" onClick={handleOnClick}>
            Load More
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
