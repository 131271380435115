import { Divider, Input } from 'antd';
import { useEffect, useState } from 'react';
import { BsQuestionCircle } from 'react-icons/bs';
import { RiQuestionnaireLine } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import { getApiRequest } from '../../api/CommonApi';
import BreadCrumb from '../../components/breadcrumb/BreadCrumb';
import QuickCall from '../../components/quickcall/QuickCall';
import './MyConfusions.css';

const { TextArea } = Input;

export default function MyConfusionsDetails() {
    const {id} = useParams();
    const [confusion, setConfusion] = useState('');
    useEffect(async () => {
        const response = await getApiRequest('/api/faq/get-single-confusion','',{id:id})
        console.log(response.data.data.flag[0].faq.title)
        setConfusion(response.data.data)
    }, [])
    
    return (
        <div className="myConfusionsDetails">
            <QuickCall />

            <BreadCrumb link="my-Confusions/my-Confusion-Details"/> <br/>

            <div className="myConfusionsTable card">
                <span className="confusionsTitle">FAQ  Confusion Details</span>
                <Divider />
                {
                    confusion.length == 0 ? 'No Data' : (
                        <div className="myConfusionsDetailsContents">
                            <div className="left">
                                <span className="confusionsTitle">{confusion.flag[0].faq.title}</span>
                                <ul className="myConfusionsDetailsListLeft">
                                    <li><BsQuestionCircle />{confusion.title}</li>
                                    <li><RiQuestionnaireLine /> {confusion.detail}</li>
                                </ul>
                            </div>
                            <div className="right">
                                {
                                    confusion.status == 'answered' ? <span className="confusionsDetailsPill">Answered</span> : <span className="confusionsDetailsPillUnanswred">Unanswered</span>
                                }
                                
                                <ul className="myConfusionsDetailsListRight">
                                    <li>Asked : 12 July 2021 ; 02 :50 pm</li>
                                    <li>Answered : 03 mins ago</li>
                                </ul>
                            </div>
                        </div>
                    )
                }
                
            </div>
        </div>
    )
}
