import { Divider, Select } from 'antd';
import dotenv from "dotenv";
import { AiOutlineStar } from 'react-icons/ai';
import { FiUsers } from 'react-icons/fi';
import { MdRateReview } from 'react-icons/md';
import { VscFileSubmodule } from 'react-icons/vsc';
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { urlChecker } from '../../../utils/urlChecker';
import RightAccordion from '../mycoursedetailsrightaccordion/RightAccordion';
import './AllCoursDescription.css';
dotenv.config();

const { Option } = Select;


export default function AllCoursDescription() {
    const { id } = useParams();
    let course = useSelector((state) => state.course);
   // const { title, image, long_desc, rating, created_at, updated_at, instructor:{name, bio, avatar, total_course, total_enrolled, total_feedback}} = course;
    const { title, image, long_desc, rating, created_at, updated_at} = course;

    const BASEURL = urlChecker();


    return (
        <div className="allCoursDescription">
            <div className="info">
                <h3 className="allCoursDescriptionTitle">Description :</h3>
                <Divider />
                <div dangerouslySetInnerHTML={{__html: course?.long_desc?.replace(/(<? *script)/gi, 'illegalscript')}} >
                </div>
                
            </div>
            <div className="accordion">
                <RightAccordion course={course}/>
            </div>
            <div className="info">

                    <h3 className="elearnigMyCourseDetailsSubTitle">Instructor</h3>
                    <Divider />
                    <h3 className="elearnigMyCourseDetailsSubTitle">NFS System</h3>
                    <h4 className="elearnigMyCourseDetailsSubTitle2">E-Learning</h4>
                    {course?.course_trainers?.length > 0 && course.course_trainers.map((trainer, i) => {
                        return (
                        <>
                            <div className="elearnigMyCourseDetailsThumbnails">
                                <div className="img">
                                <img src={trainer?.avatar ? BASEURL + trainer?.avatar : BASEURL +"/images/user.jpg"}  alt={trainer?.name} />
                                </div>
                                <div className="info">
                                    <ul className="list">
                                    <li className="item"><FiUsers /> {trainer?.name}</li>
                                    <li className="item"><AiOutlineStar /> {trainer?.rating ? trainer?.rating.toFixed(1) : 0} Instructor Rating</li>
                                    <li className="item"><MdRateReview /> {trainer?.total_feedback} Reviews</li>
                                    <li className="item"><FiUsers /> {trainer?.total_enrolled}  Students</li>
                                    <li className="item"><VscFileSubmodule /> {trainer?.total_course} Courses</li>
                                    </ul>
                                </div>
                            </div>
                            <p className="elearnigMyCourseDetailsText">
                            { course.instructor ? trainer?.bio : '' }
                            </p>
                        </>
                    )
                    })}



                {/* <Feedback/> */}
            </div>
        </div>
    )
}
