//import { useMsal } from "@azure/msal-react";
// import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { getApiRequest } from "../../api/CommonApi";
import BannerCarousel from "../../components/bannerCarousel/BannerCarousel";
import FeatureContentHome from "../../components/FeatureContentHome/FeatureContentHome";
import FeaturedArticle from "../../components/featuredArticle/FeaturedArticle";
import Marquee from "../../components/marquee/MarqueeText";
import QuickCall from "../../components/quickcall/QuickCall";
import VideoLibrarySlider from "../../components/videoLibrarySlider/VideoLibrarySlider";
import "./Home.css";

export default function Home() {
  // const { enableLinkTracking } = useMatomo()
  // const { trackPageView, trackEvent } = useMatomo()

  // enableLinkTracking()

 // const { instance, accounts } = useMsal();
  const user = useSelector((state) => state);
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const [settings, setSettings] = useState("");
  const [AllSliders, setAllSliders] = useState([]);

  const dispatch = useDispatch();

  const fetchUser = async (res) => {
    
    const response = await getApiRequest(
      "/api/get-user-data",
      "",
      ""
    );
    console.log('yo response',response);
    if (response?.data?.status === 'success') {
        localStorage.setItem("user_information", JSON.stringify(response?.data?.user));
        setCookie('information_user', response?.data?.user, { path: '/' });
        setCookie('logged_id', 'true', { path: '/' });
      }
    
  };
  useEffect(async () => {
    const settingsRes = await getApiRequest("/api/settings/read");
    setSettings(settingsRes?.data?.data);
    loadSliders();
    document.title = "Dashboard";
    if(cookies?.logged_id == 'false'){
      fetchUser();
    }
    // instance
    //   .acquireTokenSilent({
    //     ...loginRequest,
    //     account: accounts[0],
    //   })
    //   .then(async (response) => {
    //     fetchUser(response.account);
    //     const settingsRes = await getApiRequest("/api/settings/read");
    //     setSettings(settingsRes?.data?.data);
    //   }, []);

   // trackEvent({ category: 'sample-page', action: 'click-event' })

  //  trackPageView({
  //   documentTitle: 'Page title', // optional
  //   href: 'https://LINK.TO.PAGE', // optional
  //   customDimensions: [
  //     {
  //       id: 1,
  //       value: 'loggedIn',
  //     },
  //   ], // optional
  // })
  }, []);


  const loadSliders = async () =>{
    const response = await getApiRequest("/api/settings/sliders");
    setAllSliders(response?.data?.data);
};
  

  return (
    <div className="home-page">
      {/* QuickCall-component  */}
      <QuickCall />

      {/* head-marquee */}
      {settings && 
      <Marquee text={settings} />
      }

      {/* carousel-area */}
      { AllSliders && 
      <BannerCarousel AllSliders={AllSliders} />
      }

      {/* FeaturedArticle-area */}
      <FeaturedArticle />

      {/* slick-slider-area */}
      <div className="card">
        <h3 className="hoomeHeading">Featured Content</h3>
        <Divider/>

        <FeatureContentHome />
      </div>
      {/* slick-slider-Video-area */}
      <div className="card">
      <div className="videoLibraryArea">
        <h3 className="hoomeHeading capitalize">Video library </h3>
        <a href="/video" className="more">View More</a>
      </div>
      <Divider/>
      <VideoLibrarySlider />
      </div>
      
    </div>
  );
}
