import React from 'react'
import Data from '../../components/datatable/Data';
import Marquee from "../../components/marquee/MarqueeText";

export default function Details() {
    return (
        <div className="search-details">
            <Marquee />
            <div className="card">
                <h3>View details results title</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio neque quibusdam magni nulla ad quod, repellendus amet suscipit accusantium, et recusandae eos autem explicabo in nesciunt voluptates voluptatum nam provident harum? Illum mollitia tenetur molestiae voluptatibus aliquam sint eos eligendi! Enim repudiandae voluptatibus obcaecati accusamus, corporis sit expedita libero repellendus, aliquid qui, doloribus beatae. Quaerat beatae sequi quae fugit quisquam veritatis, ratione error maxime illum incidunt laboriosam tenetur saepe est architecto optio repellendus modi, accusantium, sint dicta earum. Facere, dolore nisi. Architecto mollitia sed explicabo nesciunt saepe cum impedit totam culpa non fugiat modi repellat at laborum sint, animi iusto?</p>
            </div>

            <div className="card">
                <Data />
            </div>
        </div>
    )
}
