import { Button, Checkbox, Col, Modal, Row } from "antd";
import React, { useState } from "react";
import "./Matrix.css";

export default function DynamicModal(props) {
  const [visible, setVisible] = useState(false);
  const [checked, setChecked] = useState({
    value: "",
    name: "",
  });

  // console.log("real props ",props.value);

  props?.value.sort(function (a, b) {
    return a.localeCompare(b);
  });

  function onChange(name, checkedValues) {
    
    //  console.log("name = ", name);
    //  console.log("checked = ", checkedValues);

    setChecked({
      value: checkedValues,
      name: name,
    });
  }

  function onOk() {
    setVisible(false);
    props.passValueToParent(checked);
  }
  function onCancel() {
    setVisible(false);
  }

  const uniqueValue = [...new Set(props.value.map(item => item))];

  return (
    <>
      <Button className="btn-filter" onClick={() => setVisible(true)}>
        {(props?.name[0].toUpperCase() + props?.name.slice(1)).replace(
          /_/g,
          " "
        )}
      </Button>
      <Modal
        title={(props?.name[0].toUpperCase() + props?.name.slice(1)).replace(
          /_/g,
          " "
        )}
        centered
        visible={visible}
        onOk={() => onOk()}
        onCancel={() => onCancel()}
        width={1000}
        footer={[
          <Row>
            <Col span={20}>
              <p className="left hint">
                Hint: Do not press the OK button until you select/unselect any
                option. Press (x) or Cancel if not selected any option.{" "}
              </p>
              <p className="left hint">
                {" "}
                It might not show the exact result you expected.
              </p>
              ,
            </Col>
            <Col span={4}>
              <Button key="back" onClick={() => onCancel()}>
                Cancel
              </Button>
              <Button key="submit" type="primary" onClick={() => onOk()}>
                OK
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Checkbox.Group
          style={{ width: "100%" }}
          onChange={(value) => onChange(props?.name, value)}
        >
          <Row>
            {/* {props?.value.map(function (template_value) { */}
            {uniqueValue.map(function (template_value) {
              return (
                <Col span={8}>
                  <Checkbox value={template_value}>{template_value}</Checkbox>
                </Col>
              );
            })}
          </Row>
        </Checkbox.Group>
      </Modal>
    </>
  );
}
