import { ActionTypes } from "../../constants/ActionTypes";

export const setFaqs = (faqs) => {
    return {
        type: ActionTypes.SET_FAQS,
        payload: faqs,
    };
}

export const selectedFaq = (faq) => {
    return {
        type: ActionTypes.SELECTED_FAQ,
        payload: faq
    };
}

export const removeSelectedFaq = () => {
    return {
        type: ActionTypes.REMOVE_SELECTED_FAQ,
    };
}