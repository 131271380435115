import { AutoComplete, DatePicker, Divider, Select } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { BiSearchAlt2 } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { getApiRequest } from "../../api/CommonApi";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import QuickCall from "../../components/quickcall/QuickCall";
import SearchFilter from "../../components/searchContentfilter/SearchFilter";
import SearchContent from "../../components/searchcontent/SearchContent";
import { setFaqs } from "../../services/actions/faq/FaqAction";
import "./Faq.css";

const { Option } = Select;
const { RangePicker } = DatePicker;

export default function AllFaqs() {
  const faqs = useSelector((state) => state.allFaqs.faqs);
  const dispatch = useDispatch();
  const [cookies] = useCookies(["user"]);
  const [title, setTitle] = useState("");
  let [cnt, setCnt] = useState(0);
  let types = useSelector((state) => state.types.types);
  types = types.join(); // make comma seperated value
  const [sort, setSort] = useState("");
  const [status, setStatus] = useState("");
  const [filterDate, setFilterDate] = useState({
    start_date: "",
    end_date: "",
  });
  let selectedCategory = useSelector(
    (state) => state.categoryType.categoryTypes
  );
  selectedCategory = selectedCategory.join(); // make comma seperated value
  let selectedTags = useSelector((state) => state.tagSelect.tags);
  selectedTags = selectedTags.join();
  let selectedFavouriteIds = useSelector(
    (state) => state.selectedFavouriteIds.favouriteId
  );
  let [pagination, setPagination] = useState(1);
  let [faqOption, setFaqOption] = useState([]);
  
  const fetchFaqs = async () => {
    const params = {
      load: pagination,
      title: title,
      type: types,
      sort: sort,
      category_id: selectedCategory ? selectedCategory : "",
      tag: selectedTags,
      from_date: filterDate.start_date,
      to_date: filterDate.end_date,
      status: status,
    };
    const r = await getApiRequest("/api/faq/new-index", "", params);
    dispatch(setFaqs(r.data.data));
    setCnt(r?.data?.count);

    let newObj = [];
    r?.data?.data.map((val) => {
      newObj.push({
        value : val.title
      })
    });
    setFaqOption(newObj);

  };

  useEffect(() => {
    fetchFaqs();
    document.title = "FAQs";
  }, [
    title,
    types,
    sort,
    selectedCategory,
    selectedTags,
    selectedFavouriteIds,
    pagination,
    filterDate,
    status,
  ]);

  const handleKeyDown = (e) => {
    // console.log('hello faq man', e);
    // return false;
    // setTitle(e.target.value);
    // if (title === e.target.value) {
    //   fetchFaqs();
    // }

    setTitle(e);
    if (title === e) {
      fetchFaqs();
    }
  };

  const handleDropdown = (e) => {
    setSort(e);
  };

  const handleOnClick = (e) => {
    setPagination(pagination + 1);
  };

  const getDateFiler = (date, dateString) => {
    console.log(date, dateString);
    if (dateString[0] !== "" && dateString[1] !== "") {
      const startDate = moment(dateString[0]).format("YYYY-MM-DD");
      const endDate = moment(dateString[1]).format("YYYY-MM-DD");
      setFilterDate({ start_date: startDate, end_date: endDate });
    } else {
      setFilterDate({ start_date: "", end_date: "" });
    }
  };

  // console.log("status", status);

  return (
    <div className="all-faqs">
      <QuickCall />

      <BreadCrumb link="all-FAQs" />
      <br />

      <div className="search-area">
        {/* <Input
          size="large"
          placeholder="Search here.."
          onChange={handleKeyDown}
          prefix={<BiSearchAlt2 />}
        /> */}
        <AutoComplete
          style={{
            width: "100%",
          }}
          size="large"
          placeholder="Search here.."
          prefix={<BiSearchAlt2 />}
          options={faqOption}
          onChange={handleKeyDown}
        />
      </div>

      <div className="faqs-content-area">
        <span className="faqTitle">All FAQs</span>
        <Divider />
        <div className="faqResultCounting">
          <div className="results">
            <span className="totalResults">
              About {faqs?.length} results found
            </span>
          </div>
          <div className="sortby">
            <span className="resultsSort">Status:</span>
            <Select
              showSearch
              style={{ width: 100 }}
              placeholder="Status"
              onChange={(e) => setStatus(e)}
            >
              <Option value="all">All</Option>
              {/* <Option value="unpublished">Unpublished</Option> */}
              <Option value="current">Current</Option>
              <Option value="archived">Archived</Option>
            </Select>
            <span className="resultsSort">Sort by:</span>
            <Select
              showSearch
              style={{ width: 100 }}
              placeholder="Sort"
              onChange={handleDropdown}
            >
              <Option value="latest">Latest</Option>
              <Option value="older">Older</Option>
              <Option value="updated">Updated</Option>
              <Option value="a2z">A to Z</Option>
              <Option value="z2a">Z to A</Option>
            </Select>
            <RangePicker className="float-right" onChange={getDateFiler} />
          </div>
        </div>

        <div className="faqResults">
          <Row className="searchContenResults">
            <Col xs={3}>
              <SearchFilter />
            </Col>
            <Col xs={9}>
              <SearchContent faqs={faqs} />
            </Col>
            {cnt > 0 && cnt > faqs.length ? (
              <button className="loardMore" onClick={handleOnClick}>
                Load More
              </button>
            ) : (
              ""
            )}
          </Row>
        </div>
      </div>
    </div>
  );
}
