import React from 'react'
import { My_Quiz } from '../../components/allquiz/My_Quiz'
import QuickCall from '../../components/quickcall/QuickCall'

export const MyQuiz = () => {
    return (
        <div>
            <QuickCall/>
            <My_Quiz/>
        </div>
    )
}
