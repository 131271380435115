import { Menu } from "antd";
import "antd/dist/antd.css";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Link, useHistory, useLocation } from "react-router-dom";
import { getApiRequest } from "../../api/CommonApi";
import "./Sidebar.css";

const { SubMenu } = Menu;
const rootSubmenuKeys = [
  "sub1",
  "sub2",
  "sub3",
  "sub4",
  "sub5",
  "sub6",
  "sub7",
  "sub8",
  "sub9",
];

function Sidebar(props) {
  const history = useHistory();
  const location = useLocation();
  const [openKeys, setOpenKeys] = useState(["none"]);
  const [preShiftReport, setPreShiftReport] = useState(null);
  const [preShift, setPreShift] = useState(null);
  const [menuList, setMenuList] = useState("");

  window.getCookie = function (name) {
    var match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
    if (match) return match[2];
  };

  window.removeCookie = function (name) {
    document.cookie =
      name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  };
  // const logoutRequest = {
  //   account: instance.getAccountByHomeId(window.getCookie("account")),
  //   postLogoutRedirectUri: "/",
  // };
  const [cookies, setCookie] = useCookies(["user"]);
  // if(cookies.admin=='true'){
  //   window.removeCookie("access_token");
  //   window.removeCookie("refresh_token");
  //   window.removeCookie("user");
  //   instance
  //   .acquireTokenSilent({
  //     ...logoutRequest,
  //     account: accounts[0],
  //   })
  // }
  const onOpenChange = (keys) => {
    document.title = 'Learn Fun-GP';
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  useEffect(() => {
    getPreshiftReport();
    menuData();
  }, []);

  // useEffect(() => {
  //   let isScoreAchiveFullR =
  //     preShift == null
  //       ? true
  //       : preShiftReport?.score_achive_per == 100
  //       ? true
  //       : false;

  //   if (!isScoreAchiveFullR) {
  //     if (location.pathname != "pre-shift-briefing")
  //       window.location.href = "pre-shift-briefing";
  //   }
  // }, [location]);

  const menuData = async (e) => {
    const res = await getApiRequest(`/api/menu/index?for=agent`);
    setMenuList(res?.data?.data);
  };

  const getPreshiftReport = () => {
    getApiRequest("/api/preshift-briefings/report-user", "")
      .then((res) => {
        console.log('user preshift', res)
        if (res && res.status === 200) {
          setPreShift(res?.data?.data);
          setPreShiftReport(res?.data?.data?.report);
          if (localStorage.getItem("preshift")) {
            localStorage.setItem("preshift", JSON.stringify(res?.data?.data));
          }
        } else {
          setPreShift(null);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const isScoreAchiveFull =
    preShift == null
      ? true
      : preShiftReport?.score_achive_per == 100
      ? true
      : false;

  return (
    <>
      <Menu
        className="sider-menu"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        mode="inline"
        defaultSelectedKeys={["1"]}
      >
        {isScoreAchiveFull && (
          <Menu.Item
            key="1"
            icon={
              <svg
                width="15"
                height="15"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.70828 6.66672H1.45828C0.654144 6.66672 0 6.01257 0 5.20828V1.45828C0 0.654144 0.654144 0 1.45828 0H7.70828C8.51257 0 9.16672 0.654144 9.16672 1.45828V5.20828C9.16672 6.01257 8.51257 6.66672 7.70828 6.66672ZM1.45828 1.25C1.34338 1.25 1.25 1.34338 1.25 1.45828V5.20828C1.25 5.32333 1.34338 5.41672 1.45828 5.41672H7.70828C7.82333 5.41672 7.91672 5.32333 7.91672 5.20828V1.45828C7.91672 1.34338 7.82333 1.25 7.70828 1.25H1.45828Z"
                  fill="#8D8787"
                />
                <path
                  d="M7.70828 20.0002H1.45828C0.654144 20.0002 0 19.3461 0 18.5419V9.79193C0 8.98764 0.654144 8.3335 1.45828 8.3335H7.70828C8.51257 8.3335 9.16672 8.98764 9.16672 9.79193V18.5419C9.16672 19.3461 8.51257 20.0002 7.70828 20.0002ZM1.45828 9.5835C1.34338 9.5835 1.25 9.67688 1.25 9.79193V18.5419C1.25 18.6568 1.34338 18.7502 1.45828 18.7502H7.70828C7.82333 18.7502 7.91672 18.6568 7.91672 18.5419V9.79193C7.91672 9.67688 7.82333 9.5835 7.70828 9.5835H1.45828Z"
                  fill="#8D8787"
                />
                <path
                  d="M18.5417 20.0002H12.2917C11.4874 20.0002 10.8333 19.3461 10.8333 18.5419V14.7919C10.8333 13.9876 11.4874 13.3335 12.2917 13.3335H18.5417C19.3458 13.3335 20 13.9876 20 14.7919V18.5419C20 19.3461 19.3458 20.0002 18.5417 20.0002ZM12.2917 14.5835C12.1766 14.5835 12.0833 14.6769 12.0833 14.7919V18.5419C12.0833 18.6568 12.1766 18.7502 12.2917 18.7502H18.5417C18.6566 18.7502 18.75 18.6568 18.75 18.5419V14.7919C18.75 14.6769 18.6566 14.5835 18.5417 14.5835H12.2917Z"
                  fill="#8D8787"
                />
                <path
                  d="M18.5417 11.6667H12.2917C11.4874 11.6667 10.8333 11.0126 10.8333 10.2083V1.45828C10.8333 0.654144 11.4874 0 12.2917 0H18.5417C19.3458 0 20 0.654144 20 1.45828V10.2083C20 11.0126 19.3458 11.6667 18.5417 11.6667ZM12.2917 1.25C12.1766 1.25 12.0833 1.34338 12.0833 1.45828V10.2083C12.0833 10.3233 12.1766 10.4167 12.2917 10.4167H18.5417C18.6566 10.4167 18.75 10.3233 18.75 10.2083V1.45828C18.75 1.34338 18.6566 1.25 18.5417 1.25H12.2917Z"
                  fill="#8D8787"
                />
              </svg>
            }
          >
            <Link to="/">Dashboard</Link>
          </Menu.Item>
        )}

        {isScoreAchiveFull && (
          <SubMenu
            key="sub1"
            icon={
              <svg
                width="18"
                height="18"
                viewBox="0 0 20 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.23605 18C5.07477 18 4.91196 17.9703 4.75632 17.9095C4.28741 17.7261 3.98438 17.2979 3.98438 16.8187V15.5646C3.98438 15.1171 3.59894 14.7532 3.125 14.7532C1.40182 14.7532 0 13.4295 0 11.8025V2.95063C0 1.32361 1.40182 0 3.125 0H16.875C18.5982 0 20 1.32361 20 2.95063V8.11423C20 8.52168 19.6503 8.85189 19.2188 8.85189C18.7872 8.85189 18.4375 8.52168 18.4375 8.11423V2.95063C18.4375 2.13719 17.7365 1.47532 16.875 1.47532H3.125C2.26349 1.47532 1.5625 2.13719 1.5625 2.95063V11.8025C1.5625 12.616 2.26349 13.2778 3.125 13.2778C4.46045 13.2778 5.54688 14.3036 5.54688 15.5646V16.1073L7.24808 14.501C8.0835 13.7122 9.19418 13.2778 10.3757 13.2778H16.875C17.7365 13.2778 18.4375 12.616 18.4375 11.8025C18.4375 11.3951 18.7872 11.0649 19.2188 11.0649C19.6503 11.0649 20 11.3951 20 11.8025C20 13.4294 18.5982 14.7532 16.875 14.7532H10.3757C9.61166 14.7532 8.89328 15.0341 8.35297 15.5441L6.11893 17.6535C5.87921 17.88 5.56076 18 5.23605 18ZM10.7812 11.1017C10.7812 10.6943 10.4315 10.3641 10 10.3641C9.56848 10.3641 9.21875 10.6943 9.21875 11.1017C9.21875 11.5092 9.56848 11.8394 10 11.8394C10.4315 11.8394 10.7812 11.5092 10.7812 11.1017ZM10.7812 8.51995V8.00416C10.7812 7.96238 10.8066 7.92276 10.8403 7.9121C11.953 7.562 12.6988 6.54354 12.6543 5.43547C12.6019 4.13391 11.4812 3.07569 10.1027 3.02627C9.37363 3.00005 8.68271 3.24843 8.15796 3.72546C7.6329 4.20278 7.34375 4.84448 7.34375 5.53243C7.34375 5.93987 7.69348 6.27009 8.125 6.27009C8.55652 6.27009 8.90625 5.93987 8.90625 5.53243C8.90625 5.24918 9.02542 4.98481 9.24164 4.78829C9.45755 4.59192 9.74243 4.48963 10.0433 4.50043C10.6104 4.52075 11.0713 4.95614 11.093 5.49152C11.1113 5.94852 10.8043 6.36835 10.3462 6.51257C9.67194 6.72479 9.21875 7.32413 9.21875 8.00416V8.51995C9.21875 8.92739 9.56848 9.2576 10 9.2576C10.4315 9.2576 10.7812 8.92739 10.7812 8.51995Z"
                  fill="#8D8787"
                />
              </svg>
            }
            title="FAQ Library"
          >
            <Menu.Item key="faq-11">
              <Link to="/all-faqs">All FAQs</Link>
            </Menu.Item>
            <Menu.Item key="faq-12">
              <Link to="/favourite-faqs">Favourite FAQs</Link>
            </Menu.Item>
            <Menu.Item key="faq-13">
              <Link to="/my-confusions">My confusions</Link>
            </Menu.Item>
            <Menu.Item key="faq-133">
              <Link to="/matrix-list">Matrix based</Link>
            </Menu.Item>
            {isScoreAchiveFull && (
              <Menu.Item key="faq-31">
                <Link to="/call-handle">service modality</Link>
              </Menu.Item>
            )}
            <Menu.Item key="faq-30">
              <Link to="/interactive-demo">Interactive App</Link>
            </Menu.Item>
          </SubMenu>
        )}
        {/* submenu-1  */}

        {isScoreAchiveFull && (
          <SubMenu
            key="sub2"
            icon={
              <svg
                width="17"
                height="17"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.59579 4.34769C9.11865 4.07074 8.54843 4.06982 8.07037 4.34509C7.59094 4.62128 7.30469 5.11703 7.30469 5.67108V9.56329C7.30469 10.1175 7.59094 10.6131 8.07053 10.8893C8.30872 11.0265 8.56979 11.0951 8.83102 11.0951C9.09378 11.0951 9.35654 11.0257 9.59579 10.8867L12.9495 8.94073C13.4264 8.66409 13.7109 8.16925 13.7109 7.61719C13.7109 7.06512 13.4264 6.57028 12.9495 6.29364L9.59579 4.34769ZM8.86719 9.50302V5.73135L12.1172 7.61719L8.86719 9.50302ZM18.4375 8.16406V3.125C18.4375 2.26349 17.7365 1.5625 16.875 1.5625H3.125C2.26349 1.5625 1.5625 2.26349 1.5625 3.125V12.1094C1.5625 12.9709 2.26349 13.6719 3.125 13.6719H16.875C17.7365 13.6719 18.4375 12.9709 18.4375 12.1094C18.4375 11.6779 18.7872 11.3281 19.2188 11.3281C19.6503 11.3281 20 11.6779 20 12.1094C20 13.8326 18.5982 15.2344 16.875 15.2344H3.125C1.40182 15.2344 0 13.8326 0 12.1094V3.125C0 1.40182 1.40182 0 3.125 0H16.875C18.5982 0 20 1.40182 20 3.125V8.16406C20 8.59558 19.6503 8.94531 19.2188 8.94531C18.7872 8.94531 18.4375 8.59558 18.4375 8.16406ZM20 18.3984C20 18.83 19.6503 19.1797 19.2188 19.1797H9.17969C8.74817 19.1797 8.39844 18.83 8.39844 18.3984C8.39844 17.9669 8.74817 17.6172 9.17969 17.6172H19.2188C19.6503 17.6172 20 17.9669 20 18.3984ZM6.83594 18.4375C6.83594 19.3004 6.13632 20 5.27344 20C4.67911 20 4.16245 19.6681 3.89816 19.1797H0.78125C0.349731 19.1797 0 18.83 0 18.3984C0 17.9669 0.349731 17.6172 0.78125 17.6172H3.94363C4.2189 17.1719 4.71146 16.875 5.27344 16.875C6.13632 16.875 6.83594 17.5746 6.83594 18.4375Z"
                  fill="#8D8787"
                />
              </svg>
            }
            title="Video Library"
          >
            <Menu.Item key="video-14">
              <Link to="/video">all videos</Link>
            </Menu.Item>
            <Menu.Item key="video-15">
              <Link to="/favourite-videos">My favourite</Link>
            </Menu.Item>
          </SubMenu>
        )}

        {/* submenu-3  */}
        {isScoreAchiveFull && (
          <SubMenu
            key="sub3"
            icon={
              <svg
                width="17"
                height="17"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.82812 4.6875C9.15172 4.6875 9.41406 4.42516 9.41406 4.10156C9.41406 3.77848 9.67691 3.51562 10 3.51562C10.3231 3.51562 10.5859 3.77848 10.5859 4.10156C10.5859 4.34852 10.4299 4.57031 10.1977 4.65348C9.72898 4.82137 9.41406 5.26859 9.41406 5.76637V5.85938C9.41406 6.18297 9.67641 6.44531 10 6.44531C10.3236 6.44531 10.5859 6.18297 10.5859 5.85938V5.76637C10.5859 5.76223 10.5888 5.75816 10.5929 5.75676C11.2896 5.50711 11.7578 4.84195 11.7578 4.10156C11.7578 3.1323 10.9693 2.34375 10 2.34375C9.03074 2.34375 8.24219 3.1323 8.24219 4.10156C8.24219 4.42516 8.50453 4.6875 8.82812 4.6875Z"
                  fill="#8D8787"
                />
                <path
                  d="M10 8.20312C10.3236 8.20312 10.5859 7.94079 10.5859 7.61719C10.5859 7.29358 10.3236 7.03125 10 7.03125C9.6764 7.03125 9.41406 7.29358 9.41406 7.61719C9.41406 7.94079 9.6764 8.20312 10 8.20312Z"
                  fill="#8D8787"
                />
                <path
                  d="M19.4141 5.85938H17.6172V4.10156C17.6172 3.77797 17.3548 3.51562 17.0312 3.51562H14.9718C14.2463 1.46953 12.2917 0 10 0C7.70832 0 5.75375 1.46953 5.0282 3.51562H2.96875C2.64516 3.51562 2.38281 3.77797 2.38281 4.10156V5.85938H0.585938C0.262344 5.85938 0 6.12172 0 6.44531V18.2422C0 18.5658 0.262344 18.8281 0.585938 18.8281H7.41355L8.41383 19.8284C8.52371 19.9383 8.67273 20 8.82812 20H11.1719C11.3273 20 11.4763 19.9383 11.5862 19.8284L12.5864 18.8281H19.4141C19.7377 18.8281 20 18.5658 20 18.2422V6.44531C20 6.12172 19.7377 5.85938 19.4141 5.85938ZM16.4453 4.6875V15.3125H11.1719C10.9664 15.3125 10.7693 15.3483 10.5859 15.4133V12.4024C10.9686 11.3088 11.7238 10.3798 12.7193 9.78125C14.2641 8.85246 15.2734 7.17098 15.2734 5.27344C15.2734 5.07539 15.2621 4.87996 15.2407 4.6875H16.4453ZM10 1.17188C12.2616 1.17188 14.1016 3.01184 14.1016 5.27344C14.1016 6.71949 13.3591 8.02922 12.1154 8.77695C11.2405 9.30301 10.5157 10.0356 10 10.9012C9.4843 10.0356 8.75945 9.30297 7.88453 8.77695C6.6409 8.02922 5.89844 6.71949 5.89844 5.27344C5.89844 3.01184 7.7384 1.17188 10 1.17188ZM3.55469 4.6875H4.7593C4.73793 4.87996 4.72656 5.07539 4.72656 5.27344C4.72656 7.175 5.7393 8.85449 7.2807 9.78125C8.27633 10.3799 9.03148 11.3088 9.41406 12.4024V15.4133C9.23066 15.3483 9.03356 15.3125 8.82812 15.3125H3.55469V4.6875ZM18.8281 17.6562H12.3438C12.1883 17.6562 12.0393 17.718 11.9295 17.8279L10.9292 18.8281H9.07082L8.07055 17.8279C7.96066 17.718 7.81164 17.6562 7.65625 17.6562H1.17188V7.03125H2.38281V15.8984C2.38281 16.222 2.64516 16.4844 2.96875 16.4844H8.82812C9.15121 16.4844 9.41406 16.7472 9.41406 17.0703C9.41406 17.3939 9.67641 17.6562 10 17.6562C10.3236 17.6562 10.5859 17.3939 10.5859 17.0703C10.5859 17.0703 10.5859 17.0702 10.5859 17.0702V17.0702C10.5859 16.7471 10.8488 16.4844 11.1719 16.4844H17.0312C17.3548 16.4844 17.6172 16.2221 17.6172 15.8985V7.03125H18.8281V17.6562Z"
                  fill="#8D8787"
                />
              </svg>
            }
            title="Quiz"
          >
            <Menu.Item key="quiz-16">
              <Link to="/quiz">all quiz</Link>
            </Menu.Item>
            <Menu.Item key="quiz-17">
              <Link to="/my-quiz">my quiz</Link>
            </Menu.Item>
          </SubMenu>
        )}
        {/* submenu-3  */}
        {isScoreAchiveFull && (
          <SubMenu
            key="sub4"
            icon={
              <svg
                width="16"
                height="16"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.13671 6.42386L4.99456 7.12616V10.9316C4.99456 11.156 5.123 11.3607 5.32511 11.4583C6.77222 12.1574 8.38874 12.5269 9.99995 12.5269C11.6112 12.5269 13.2277 12.1574 14.6748 11.4583C14.8769 11.3607 15.0053 11.156 15.0053 10.9316V7.12616L16.0714 6.72316V8.10437C16.0714 8.42745 16.3333 8.68937 16.6564 8.68937C16.9794 8.68937 17.2414 8.42745 17.2414 8.10437V5.87659C17.2414 5.63331 17.0908 5.41542 16.8632 5.32937L10.2068 2.81315C10.0735 2.7628 9.9264 2.7628 9.79312 2.81315L3.13671 5.32944C2.90909 5.41546 2.75854 5.63335 2.75854 5.87667C2.75854 6.11995 2.90909 6.33784 3.13671 6.42386ZM13.8353 10.5567C12.6327 11.081 11.3144 11.3569 9.99995 11.3569C8.6855 11.3569 7.36718 11.081 6.16456 10.5567V7.56847L9.79312 8.94015C9.9264 8.9905 10.0735 8.9905 10.2068 8.94015L13.8353 7.56847V10.5567ZM9.99995 3.98577L15.0019 5.87667L9.99995 7.76753L4.99796 5.87663L9.99995 3.98577Z"
                  fill="#8D8787"
                />
                <path
                  d="M18.83 0.0239258H1.17C0.524844 0.0239258 0 0.548769 0 1.19393V16.5322C0 17.1773 0.524844 17.7022 1.17 17.7022H7.66203V18.8065H3.96488C3.6418 18.8065 3.37988 19.0685 3.37988 19.3915C3.37988 19.7146 3.6418 19.9765 3.96488 19.9765H16.0351C16.3582 19.9765 16.6201 19.7146 16.6201 19.3915C16.6201 19.0685 16.3582 18.8065 16.0351 18.8065H12.338V17.7022H18.83C19.4751 17.7022 20 17.1773 20 16.5322V1.19393C20 0.548769 19.4752 0.0239258 18.83 0.0239258ZM18.83 1.19393L18.8306 14.1987H1.17V1.19393H18.83ZM11.168 18.8065H8.83203V17.7022H11.168V18.8065ZM18.83 16.5322H1.17V15.3687H18.8307L18.8307 16.5321C18.8307 16.5321 18.8305 16.5322 18.83 16.5322Z"
                  fill="#8D8787"
                />
              </svg>
            }
            title="Elearning"
          >
            <Menu.Item key="course-18">
              <Link to="/all-courses">all courses</Link>
            </Menu.Item>
            <Menu.Item key="course-19">
              <Link to="/my-courses">my courses</Link>
            </Menu.Item>
            <Menu.Item key="course-20">
              <Link to="/my-wishlist">wish lists</Link>
            </Menu.Item>
            <Menu.Item key="course-21">
              <Link to="/trainer-evaluation">
                Trainer and Training Evaluation
              </Link>
            </Menu.Item>
          </SubMenu>
        )}

        {/* webinars */}
        {isScoreAchiveFull && (
          <SubMenu
            key="sub5"
            icon={
              <svg
                width="16"
                height="16"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.87503 5.46875H8.63284V9.96094C8.63284 10.2845 8.89518 10.5469 9.21878 10.5469H17.0313C17.3549 10.5469 17.6172 10.2845 17.6172 9.96094V2.92969C17.6172 2.60609 17.3549 2.34375 17.0313 2.34375H9.21878C9.08171 2.34375 8.94897 2.3918 8.84366 2.47957L6.49991 4.4327C6.31042 4.59062 6.24011 4.85031 6.32409 5.0823C6.40807 5.3143 6.62835 5.46875 6.87503 5.46875ZM9.43093 3.51562H16.4453V9.375H9.80471V4.88281C9.80471 4.55922 9.54237 4.29688 9.21878 4.29688H8.49343L9.43093 3.51562Z"
                  fill="#8D8787"
                />
                <path
                  d="M11.5625 5.85938H14.6875C15.0111 5.85938 15.2734 5.59703 15.2734 5.27344C15.2734 4.94984 15.0111 4.6875 14.6875 4.6875H11.5625C11.2389 4.6875 10.9766 4.94984 10.9766 5.27344C10.9766 5.59703 11.2389 5.85938 11.5625 5.85938Z"
                  fill="#8D8787"
                />
                <path
                  d="M11.5625 8.20312H14.6875C15.0111 8.20312 15.2734 7.94078 15.2734 7.61719C15.2734 7.29359 15.0111 7.03125 14.6875 7.03125H11.5625C11.2389 7.03125 10.9766 7.29359 10.9766 7.61719C10.9766 7.94078 11.2389 8.20312 11.5625 8.20312Z"
                  fill="#8D8787"
                />
                <path
                  d="M18.2422 0H1.75781C0.788555 0 0 0.788555 0 1.75781V14.3359C0 15.3052 0.788555 16.0938 1.75781 16.0938H7.03512C6.92535 17.013 6.55996 17.8934 5.97859 18.62L5.81211 18.8281H4.53125C4.20766 18.8281 3.94531 19.0905 3.94531 19.4141C3.94531 19.7377 4.20766 20 4.53125 20H15.4688C15.7923 20 16.0547 19.7377 16.0547 19.4141C16.0547 19.0905 15.7923 18.8281 15.4688 18.8281H14.1879L14.0214 18.62C13.44 17.8934 13.0746 17.013 12.9648 16.0938H18.2422C19.2114 16.0938 20 15.3052 20 14.3359V1.75781C20 0.788555 19.2114 0 18.2422 0ZM1.75781 1.17188H18.2422C18.5653 1.17188 18.8281 1.43473 18.8281 1.75781V12.1094H8.24219V11.9141C8.24219 10.8835 7.70684 9.97617 6.90008 9.45379C7.24832 9.0718 7.46094 8.56418 7.46094 8.00781C7.46094 6.82316 6.49715 5.85938 5.3125 5.85938C4.12785 5.85938 3.16406 6.82316 3.16406 8.00781C3.16406 8.56418 3.37668 9.0718 3.72492 9.45379C2.91816 9.97617 2.38281 10.8835 2.38281 11.9141V12.1094H1.17188V1.75781C1.17188 1.43473 1.43473 1.17188 1.75781 1.17188ZM4.33594 8.00781C4.33594 7.46934 4.77402 7.03125 5.3125 7.03125C5.85098 7.03125 6.28906 7.46934 6.28906 8.00781C6.28906 8.54629 5.85098 8.98438 5.3125 8.98438C4.77402 8.98438 4.33594 8.54629 4.33594 8.00781ZM5.3125 10.1562C6.28176 10.1562 7.07031 10.9448 7.07031 11.9141V12.1094H3.55469V11.9141C3.55469 10.9448 4.34324 10.1562 5.3125 10.1562ZM12.7308 18.8281H7.26918C7.79492 18.0061 8.12016 17.0653 8.21395 16.0938H11.7861C11.8799 17.0653 12.2051 18.0061 12.7308 18.8281ZM18.2422 14.9219H1.75781C1.43473 14.9219 1.17188 14.659 1.17188 14.3359V13.2812H18.8281V14.3359C18.8281 14.659 18.5653 14.9219 18.2422 14.9219Z"
                  fill="#8D8787"
                />
              </svg>
            }
            title="Webinars"
          >
            <Menu.Item key="webinar-23">
              <Link to="/webinars">all webinars</Link>
            </Menu.Item>
            <Menu.Item key="webinar-24">
              <Link to="/my-webinars">my webinars</Link>
            </Menu.Item>
          </SubMenu>
        )}

        {/* {preShift && !isScoreAchiveFull && ( */}
        <SubMenu
          key="sub9"
          icon={
            <svg
              width="18"
              height="18"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.33203 10.1833H12.7487"
                stroke="#8D8787"
                strokeWidth="1.2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.33203 13.8501H10.347"
                stroke="#8D8787"
                strokeWidth="1.2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.16536 4.49992H11.832C13.6654 4.49992 13.6654 3.58325 13.6654 2.66659C13.6654 0.833252 12.7487 0.833252 11.832 0.833252H8.16536C7.2487 0.833252 6.33203 0.833252 6.33203 2.66659C6.33203 4.49992 7.2487 4.49992 8.16536 4.49992Z"
                stroke="#8D8787"
                strokeWidth="1.2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14 3C17.33 3.16018 19 4.25473 19 8.32147V13.6607C19 17.2202 18 19 13 19H7C2 19 1 17.2202 1 13.6607V8.32147C1 4.26363 2.67 3.16018 6 3"
                stroke="#8D8787"
                strokeWidth="1.2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          }
          title="Pre Shifts"
        >
          <Menu.Item key="preshift-22">
            <Link to="/pre-shift-briefing">Pre Shift Briefing</Link>
          </Menu.Item>
          <Menu.Item key="preshift-23">
            <Link to="/pre-shift-briefing-history">Pre Shift History</Link>
          </Menu.Item>
        </SubMenu>

        {/* )} */}

        {/* Campaign */}
        {isScoreAchiveFull && menuList
          ? menuList.map((menu, index) => {
              return menu.children.length > 0 ? (
                <SubMenu
                  icon={
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.6201 11.7644C17.3913 11.5356 17.0203 11.5356 16.7915 11.7644C16.5627 11.9932 16.5627 12.3642 16.7915 12.593L17.9735 13.775C18.0879 13.8894 18.2378 13.9466 18.3878 13.9466C18.5377 13.9466 18.6877 13.8894 18.8021 13.775C19.0309 13.5462 19.0309 13.1752 18.8021 12.9464L17.6201 11.7644Z"
                        fill="#8D8787"
                      />
                      <path
                        d="M17.2058 6.91537C17.3557 6.91537 17.5057 6.85815 17.6201 6.74377L18.8021 5.56178C19.0309 5.33299 19.0309 4.96197 18.8021 4.73314C18.5733 4.50432 18.2023 4.50432 17.9735 4.73314L16.7915 5.91514C16.5627 6.14393 16.5627 6.51494 16.7915 6.74377C16.9059 6.85819 17.0558 6.91537 17.2058 6.91537Z"
                        fill="#8D8787"
                      />
                      <path
                        d="M19.4138 8.66846H17.7422C17.4186 8.66846 17.1562 8.9308 17.1562 9.25439C17.1562 9.57799 17.4186 9.84033 17.7422 9.84033H19.4138C19.7374 9.84033 19.9997 9.57799 19.9997 9.25439C19.9997 8.9308 19.7374 8.66846 19.4138 8.66846Z"
                        fill="#8D8787"
                      />
                      <path
                        d="M15.5956 0.921406C15.4106 0.817773 15.184 0.822226 15.0032 0.932929L8.29285 5.0425H5.31391C4.6777 5.00887 4.07246 5.23059 3.60785 5.66793C3.43492 5.83066 3.28914 6.01851 3.17297 6.2232H1.69871C0.762031 6.2232 0 6.98523 0 7.92191V10.5864C0 11.5231 0.762031 12.2852 1.69871 12.2852H3.17004C3.40598 12.707 3.76215 13.0566 4.19516 13.288L2.15371 16.8239C2.07539 16.9596 2.05465 17.121 2.09609 17.2721C2.13758 17.4231 2.23781 17.5513 2.37441 17.6279L4.95965 19.0783C5.05027 19.1291 5.14871 19.1533 5.24582 19.1533C5.44844 19.1533 5.64535 19.048 5.75375 18.8602L8.66883 13.8111L15.0098 17.5794C15.102 17.6342 15.2056 17.6616 15.3092 17.6616C15.4091 17.6616 15.5091 17.6361 15.5989 17.585C15.782 17.4808 15.8951 17.2863 15.8951 17.0757V1.43262C15.8951 1.22059 15.7805 1.02508 15.5956 0.921406ZM1.69871 11.1133C1.4082 11.1133 1.17188 10.877 1.17188 10.5864V7.92191C1.17188 7.63141 1.4082 7.39508 1.69871 7.39508H2.85707C2.85699 7.40383 2.85641 7.41254 2.85641 7.42129V11.0871C2.85641 11.0959 2.85695 11.1045 2.85707 11.1133H1.69871ZM4.02828 7.42129C4.02828 7.07824 4.16777 6.7502 4.41098 6.52129C4.64246 6.30344 4.94449 6.19387 5.26187 6.21332C5.27371 6.21402 7.87215 6.21437 7.87215 6.21437V12.4183H5.3166C4.59281 12.3663 4.02828 11.7845 4.02828 11.087V7.42129ZM5.02828 17.7731L3.46516 16.8962L5.33934 13.65C5.35063 13.6304 5.36035 13.6104 5.36914 13.5902H7.44324L5.02828 17.7731ZM14.7232 16.0459L9.04402 12.6709V5.95668L14.7232 2.47855V16.0459Z"
                        fill="#8D8787"
                      />
                    </svg>
                  }
                  title={menu.title}
                  key={index}
                >
                  {menu.children.map((m, i) => {
                    return (
                      <Menu.Item key={"childmenu-" + i}>
                        <Link to={`/gp-campaign/${m.id}`}>{m.title}</Link>
                      </Menu.Item>
                    );
                  })}
                </SubMenu>
              ) : !menu.parent ? (
                <Menu.Item
                  key={`menu-${index + 1}`}
                  icon={
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.6201 11.7644C17.3913 11.5356 17.0203 11.5356 16.7915 11.7644C16.5627 11.9932 16.5627 12.3642 16.7915 12.593L17.9735 13.775C18.0879 13.8894 18.2378 13.9466 18.3878 13.9466C18.5377 13.9466 18.6877 13.8894 18.8021 13.775C19.0309 13.5462 19.0309 13.1752 18.8021 12.9464L17.6201 11.7644Z"
                        fill="#8D8787"
                      />
                      <path
                        d="M17.2058 6.91537C17.3557 6.91537 17.5057 6.85815 17.6201 6.74377L18.8021 5.56178C19.0309 5.33299 19.0309 4.96197 18.8021 4.73314C18.5733 4.50432 18.2023 4.50432 17.9735 4.73314L16.7915 5.91514C16.5627 6.14393 16.5627 6.51494 16.7915 6.74377C16.9059 6.85819 17.0558 6.91537 17.2058 6.91537Z"
                        fill="#8D8787"
                      />
                      <path
                        d="M19.4138 8.66846H17.7422C17.4186 8.66846 17.1562 8.9308 17.1562 9.25439C17.1562 9.57799 17.4186 9.84033 17.7422 9.84033H19.4138C19.7374 9.84033 19.9997 9.57799 19.9997 9.25439C19.9997 8.9308 19.7374 8.66846 19.4138 8.66846Z"
                        fill="#8D8787"
                      />
                      <path
                        d="M15.5956 0.921406C15.4106 0.817773 15.184 0.822226 15.0032 0.932929L8.29285 5.0425H5.31391C4.6777 5.00887 4.07246 5.23059 3.60785 5.66793C3.43492 5.83066 3.28914 6.01851 3.17297 6.2232H1.69871C0.762031 6.2232 0 6.98523 0 7.92191V10.5864C0 11.5231 0.762031 12.2852 1.69871 12.2852H3.17004C3.40598 12.707 3.76215 13.0566 4.19516 13.288L2.15371 16.8239C2.07539 16.9596 2.05465 17.121 2.09609 17.2721C2.13758 17.4231 2.23781 17.5513 2.37441 17.6279L4.95965 19.0783C5.05027 19.1291 5.14871 19.1533 5.24582 19.1533C5.44844 19.1533 5.64535 19.048 5.75375 18.8602L8.66883 13.8111L15.0098 17.5794C15.102 17.6342 15.2056 17.6616 15.3092 17.6616C15.4091 17.6616 15.5091 17.6361 15.5989 17.585C15.782 17.4808 15.8951 17.2863 15.8951 17.0757V1.43262C15.8951 1.22059 15.7805 1.02508 15.5956 0.921406ZM1.69871 11.1133C1.4082 11.1133 1.17188 10.877 1.17188 10.5864V7.92191C1.17188 7.63141 1.4082 7.39508 1.69871 7.39508H2.85707C2.85699 7.40383 2.85641 7.41254 2.85641 7.42129V11.0871C2.85641 11.0959 2.85695 11.1045 2.85707 11.1133H1.69871ZM4.02828 7.42129C4.02828 7.07824 4.16777 6.7502 4.41098 6.52129C4.64246 6.30344 4.94449 6.19387 5.26187 6.21332C5.27371 6.21402 7.87215 6.21437 7.87215 6.21437V12.4183H5.3166C4.59281 12.3663 4.02828 11.7845 4.02828 11.087V7.42129ZM5.02828 17.7731L3.46516 16.8962L5.33934 13.65C5.35063 13.6304 5.36035 13.6104 5.36914 13.5902H7.44324L5.02828 17.7731ZM14.7232 16.0459L9.04402 12.6709V5.95668L14.7232 2.47855V16.0459Z"
                        fill="#8D8787"
                      />
                    </svg>
                  }
                >
                  <Link to={`/gp-campaign/${menu.id}`}>{menu.title}</Link>
                </Menu.Item>
              ) : (
                ""
              );
            })
          : ""}
        {/* <SubMenu key="sub7" icon={
          <svg width="17" height="17" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.6201 11.7644C17.3913 11.5356 17.0203 11.5356 16.7915 11.7644C16.5627 11.9932 16.5627 12.3642 16.7915 12.593L17.9735 13.775C18.0879 13.8894 18.2378 13.9466 18.3878 13.9466C18.5377 13.9466 18.6877 13.8894 18.8021 13.775C19.0309 13.5462 19.0309 13.1752 18.8021 12.9464L17.6201 11.7644Z" fill="#8D8787"/>
          <path d="M17.2058 6.91537C17.3557 6.91537 17.5057 6.85815 17.6201 6.74377L18.8021 5.56178C19.0309 5.33299 19.0309 4.96197 18.8021 4.73314C18.5733 4.50432 18.2023 4.50432 17.9735 4.73314L16.7915 5.91514C16.5627 6.14393 16.5627 6.51494 16.7915 6.74377C16.9059 6.85819 17.0558 6.91537 17.2058 6.91537Z" fill="#8D8787"/>
          <path d="M19.4138 8.66846H17.7422C17.4186 8.66846 17.1562 8.9308 17.1562 9.25439C17.1562 9.57799 17.4186 9.84033 17.7422 9.84033H19.4138C19.7374 9.84033 19.9997 9.57799 19.9997 9.25439C19.9997 8.9308 19.7374 8.66846 19.4138 8.66846Z" fill="#8D8787"/>
          <path d="M15.5956 0.921406C15.4106 0.817773 15.184 0.822226 15.0032 0.932929L8.29285 5.0425H5.31391C4.6777 5.00887 4.07246 5.23059 3.60785 5.66793C3.43492 5.83066 3.28914 6.01851 3.17297 6.2232H1.69871C0.762031 6.2232 0 6.98523 0 7.92191V10.5864C0 11.5231 0.762031 12.2852 1.69871 12.2852H3.17004C3.40598 12.707 3.76215 13.0566 4.19516 13.288L2.15371 16.8239C2.07539 16.9596 2.05465 17.121 2.09609 17.2721C2.13758 17.4231 2.23781 17.5513 2.37441 17.6279L4.95965 19.0783C5.05027 19.1291 5.14871 19.1533 5.24582 19.1533C5.44844 19.1533 5.64535 19.048 5.75375 18.8602L8.66883 13.8111L15.0098 17.5794C15.102 17.6342 15.2056 17.6616 15.3092 17.6616C15.4091 17.6616 15.5091 17.6361 15.5989 17.585C15.782 17.4808 15.8951 17.2863 15.8951 17.0757V1.43262C15.8951 1.22059 15.7805 1.02508 15.5956 0.921406ZM1.69871 11.1133C1.4082 11.1133 1.17188 10.877 1.17188 10.5864V7.92191C1.17188 7.63141 1.4082 7.39508 1.69871 7.39508H2.85707C2.85699 7.40383 2.85641 7.41254 2.85641 7.42129V11.0871C2.85641 11.0959 2.85695 11.1045 2.85707 11.1133H1.69871ZM4.02828 7.42129C4.02828 7.07824 4.16777 6.7502 4.41098 6.52129C4.64246 6.30344 4.94449 6.19387 5.26187 6.21332C5.27371 6.21402 7.87215 6.21437 7.87215 6.21437V12.4183H5.3166C4.59281 12.3663 4.02828 11.7845 4.02828 11.087V7.42129ZM5.02828 17.7731L3.46516 16.8962L5.33934 13.65C5.35063 13.6304 5.36035 13.6104 5.36914 13.5902H7.44324L5.02828 17.7731ZM14.7232 16.0459L9.04402 12.6709V5.95668L14.7232 2.47855V16.0459Z" fill="#8D8787"/>
          </svg>
        } title="Campaign">
          <Menu.Item key="24">
            <Link to="/gp-campaign">Gp Campaign</Link>
          </Menu.Item>
          <Menu.Item key="25">
            <Link to="/pre-shift-briefing">Skitto Campaign</Link>
          </Menu.Item>
        )}
        {/* Campaign */}
        <SubMenu
          key="report"
          icon={
            <svg
              width="18"
              height="18"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.78571 15.0928V13.0557M10 15.0928V11.0187M13.2143 15.0928V8.98164M15.3571 19.1668H4.64286C4.07454 19.1668 3.52949 18.9522 3.12763 18.5702C2.72576 18.1882 2.5 17.67 2.5 17.1298V2.87053C2.5 2.33028 2.72576 1.81215 3.12763 1.43013C3.52949 1.04811 4.07454 0.833496 4.64286 0.833496H10.6279C10.912 0.833554 11.1845 0.940899 11.3854 1.13192L17.1861 6.64618C17.387 6.83715 17.4999 7.09617 17.5 7.36627V17.1298C17.5 17.67 17.2742 18.1882 16.8724 18.5702C16.4705 18.9522 15.9255 19.1668 15.3571 19.1668Z"
                stroke="#8D8787"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          }
          title="Reports"
        >
          <Menu.Item key="report-43">
            <Link to="/agent-faq-vs-rtc-report">Agent FAQ vs RTC report</Link>
          </Menu.Item>
          <Menu.Item key="report-44">
            <Link to="/agent-fcr-report">Agent FCR report</Link>
          </Menu.Item>
          <Menu.Item key="report-50">
            <Link to="/agent-wise-elearning-report">Agent Elearning report</Link>
          </Menu.Item>
          <Menu.Item key="report-51">
            <Link to="/agent-wise-training-report">Agent Training evaluation report</Link>
          </Menu.Item>
          <Menu.Item key="report-53">
            <Link to="/agent-wise-quiz-report">Agent Quiz Report</Link>
          </Menu.Item>
          <Menu.Item key="report-54">
            <Link to="/agent-wise-preshift-report">Agent Preshift Report</Link>
          </Menu.Item>
        </SubMenu>
      </Menu>
    </>
  );
}

export default Sidebar;
