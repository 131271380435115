import { Input, Modal, Radio } from 'antd';
import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { postApiRequest } from '../../api/CommonApi';
import './QuickCall.css';
const { TextArea } = Input;

export const Message = () => {
    const [visible, setVisible] = useState(false);
    const [textColor, setTextColor] = useState('green');
    const inputRef = useRef(null)
    const [sendMessage, setSendMessage] = useState({
        message: '',
        type: '',
        emailOrNumber:'',
        masking: '',
    });

    const onChange = e => {
        console.log('radio checked', e.target.value);
      };

      function onInputChange(value, field){
          if(field == 'message' && value.length > 320){
            setTextColor('red')
          }else if(field == 'message' && value.length < 320){
            setTextColor('green')
        }
        setSendMessage({...sendMessage, [field]: value})
    }

    const onSubmit = async e => {
        if (!sendMessage.message) {
            return toast.warning("Text field must not be empty!")
        }
        if (!sendMessage.type) {
            return toast.warning("Delivery Option field required!")
        }
        if (sendMessage.type === "sms" && !sendMessage.masking) {
            return toast.warning("Masking field required!")
        }
        if (!sendMessage.emailOrNumber) {
            return toast.warning("Email or Phone Number field required!")
        }

        var randOTP = Math.floor(1000 + Math.random() * 9000);
        const odata = {
            otp: randOTP,
            message: sendMessage.message,
            emailOrNumber: sendMessage.emailOrNumber,
            masking: sendMessage.masking,
          }

        const response = await postApiRequest("/api/customer-insight/send-message", "", odata);
        console.log("Message send response", response)
        
        if(response?.data?.status == 'errors'){
            console.log('response?.data?.data', response?.data?.data);

            const errorItems = response?.data?.data?.message.map( (error, i) => {
                toast.error(error, {autoClose:false});
            });
            return false;
        }else{
            response?.data?.status == 'False' ? toast.error(response?.data?.developerMessage) : toast.success(response?.data?.message);
            setVisible(false)
            setSendMessage({})
        }
        
    }

    const onClickSendMessage = () => {
        setSendMessage({...sendMessage, ['message']: '', ['type']: '', ['emailOrNumber']: '', ['masking']: ''})
        setVisible(true)
    }


    return (
        <div className="message">
            {/* <button className="btn">customer insight</button> */}
            <button className="btn" onClick={() => onClickSendMessage()}>send message</button>

            <Modal
                className="messageModal"
                title="Send Message"
                centered
                visible={visible}
                onCancel={() => setVisible(false)}
                width={800}
            >
                <div className="messageModalBody">
                    <div className="textarea">
                        <TextArea placeholder="Type Aa.." rows={4} onChange={(e) => onInputChange(e.target.value, 'message')} value={sendMessage?.message}/>
                        <small className='float-left' style={{color: textColor}}>characters count : {sendMessage?.message?.length}</small>
                        <small className='float-right'>hint: characters limit : 320</small>
                    </div>
                    <div className="text row-content">
                        <h2 className="title"> Masking</h2>
                        <div className="radio masking" >
                            <Radio.Group onChange={(e) => onInputChange(e.target.value, 'masking')} value={sendMessage?.masking}>
                                {/* <Radio value="GP IPCC">GP IPCC</Radio> */}
                                <Radio value="GPInfo">GPInfo</Radio>
                                {/* <Radio value="GP Learning">GP Learning</Radio> */}
                                <Radio value="28787">28787</Radio>
                            </Radio.Group>
                        </div>
                    </div>
                    <div className="text row-content">
                        <h2 className="title"><span className='star'>*</span> Choose delivery option</h2>
                        <div className="radio">
                            <Radio.Group onChange={(e) => onInputChange(e.target.value, 'type')} value={sendMessage?.type}>
                                <Radio value="sms">SMS</Radio>
                                <Radio value="email">Email</Radio>
                            </Radio.Group>
                            <Input placeholder="email or number" onChange={(e) => onInputChange(e.target.value, 'emailOrNumber')} value={sendMessage?.emailOrNumber}/>
                        </div>
                        <small className='float-right'>hint: Only GP Number can be used, Number format: 017xxxxxxxx</small>
                        <button className="btn" onClick={ e=> onSubmit(e)}>send</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
