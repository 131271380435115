import { ActionTypes } from "../../constants/ActionTypes";

const initialState = {
    file: [],
    image: [],
}

export const confusionFileReducer = ( state = initialState.file, {type, payload}) => {
    switch(type){
        case ActionTypes.FILE_SET:
            return {...state, file:payload};
        default:
            return state;
    }
}

export const confusionImageReducer = ( state = initialState.image, {type, payload}) => {
    switch(type){
        case ActionTypes.IMAGE_SET:
            return {...state, image:payload};
        default:
            return state;
    }
}