import { Divider, Input } from "antd";
import { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { AiOutlineClockCircle, AiOutlineRight } from "react-icons/ai";
import { useHistory, useParams } from "react-router-dom";
import { getApiRequest, postApiRequest } from "../../api/CommonApi";
import { showPlainHtml } from "../../common/Common";
import "./AllQuiz.css";
const { TextArea } = Input;
export const QuizText = (props) => {
  const history = useHistory();
  const { quiz_attempt_id, question_id } = useParams();
  const [answers, setAnswers] = useState({
    id: [],
    user_ans_text: "",
  });
  const [time, setTime] = useState([]);
  const [timeAll, setTimeAll] = useState([]);

  props.answers.map((answer) => {
    answer.label = answer.content;
    answer.value = answer.id;
  });

  useEffect(() => {
    // set start time at local store
    let startTime = localStorage.getItem(
      `startTime-${quiz_attempt_id}-${question_id}`
    );
    let startTimeAll = localStorage.getItem(`startTime-${quiz_attempt_id}`);
    if (!startTime) {
      startTime = Date.now();
      localStorage.setItem(
        `startTime-${quiz_attempt_id}-${question_id}`,
        startTime
      );
    } else {
      startTime = parseInt(startTime);
    }

    if (!startTimeAll) {
      startTimeAll = Date.now();
      localStorage.setItem(`startTime-${quiz_attempt_id}`, startTimeAll);
    } else {
      startTimeAll = parseInt(startTimeAll);
    }
    setTimeAll(startTimeAll);
    setTime(startTime);
  }, [question_id]);

  const answerTyped = (e) => {
    setAnswers({
      ...answers,
      user_ans_text: e.target.value,
      id: [props.question.id],
    });
  };
  const answerSubmit = (is_time_end = false) => {
    if (props.isPreShift) {
      /** check if question is correct */
      postApiRequest(`/api/question/check-answer/${props.question_id}`, "", {
        user_ans_id: [props.question.id],
        user_ans_text: answers.user_ans_text,
      })
        .then((res) => {
          console.log("is_correct", res.data.message.is_correct);
          if (!res.data.message.is_correct) {
            alert("Wrong answer");
            return;
          } else {
            return postAnswer(is_time_end);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      return postAnswer(is_time_end);
    }
  };

  const postAnswer = (is_time_end = false) => {
    postApiRequest(`/api/take-answer/create`, "", {
      quiz_attempt_id: props.quiz_attempt_id,
      question_id: props.question_id,
      user_ans_id: [props.question.id],
      user_ans_text: answers.user_ans_text,
    })
      .then((res) => {
        if (res.status == 200) {
          if (res?.data?.data?.question) {
            if (props.timeRestriction != 2) {
              localStorage.clear();
            }
            if (!is_time_end) {
              history.push(
                `/quiz-attempt/${props.quiz_attempt_id}/${res.data.data.question.id}`
              );
            } else {
              window.location.href = `/quiz-attempt/${props.quiz_attempt_id}/${res.data.data.question.id}`;
            }
          } else {
            localStorage.clear();
            if (is_time_end) {
              window.location.href = `/quiz-details/${props.quiz_attempt_id}`;
            }
            history.push(`/quiz-details/${props.quiz_attempt_id}`);
          }
        } else {
          alert("Something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const endOfQuiz = () => {
    getApiRequest(`/api/quiz-attempt/end-of-quiz/${props.quiz_attempt_id}`)
      .then((res) => {
        if (res.status === 200) {
          localStorage.clear();
          window.location.href = `/quiz-details/${props.quiz_attempt_id}`;
        } else {
          alert("Something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const timeRestriction = (props) => {
    {
      console.log("time", props);
      if (
        !props.isPreShift &&
        props.timeRestriction === 1 &&
        props.question.duration > 0
      ) {
        return (
          <div className="mid">
            <AiOutlineClockCircle className="iconClosed" />
            <span>
              <b>Time Left-</b>
              <Countdown
                date={time + props.question.duration * 60 * 1000}
                renderer={renderer}
              ></Countdown>
              mins
            </span>
          </div>
        );
      } else if (props.timeRestriction === 2) {
        return (
          <div className="mid">
            <AiOutlineClockCircle className="iconClosed" />
            <span>
              <b>Time Left-</b>
              <Countdown
                date={timeAll + props.duration * 60 * 1000}
                renderer={renderer}
              ></Countdown>
              mins
            </span>
          </div>
        );
      }
    }
  };

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      if (props.timeRestriction == 2) {
        localStorage.clear();
        return endOfQuiz();
      }
      return postAnswer(true);
    } else {
      return (
        <span>
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };
  return (
    <div className="detailsQuiz">
      {/* card-2  */}
      <div className="card">
        <div className="detailsQuizOverview">
          <div className="left">
            <h3 className="title">Question 01</h3>
          </div>
          {/* time restriction will be here. */}
          {timeRestriction(props)}
          <div className="right">
            {/* <HiCheckCircle className="iconcheked" /> */}
            <span>Point {props.question.score} </span>
          </div>
        </div>
        <Divider />
        <div className="detailsQuizQuestoins">
          <p className="question">{showPlainHtml(props.question.question)}</p>
          <TextArea
            className="detailsQuizQuestoinsTextArea"
            placeholder="Type your answer  here..."
            allowClear
            onChange={answerTyped}
          />
        </div>
      </div>

      <div className="pagination">
        <div className="left">
          {/* <button to="/quiz-q-01" className="paginationbtn bgc-primary">
            <AiOutlineLeft />
            previous
          </button> */}
        </div>
        <div className="right">
          {/* {props.isLast ? (
            <button className="btn btn-success" onClick={answerSubmit}>
              Submit
            </button>
          ) : ( */}
          <button
            to="quiz-q-02"
            className="paginationbtn bgc-primary"
            onClick={() => answerSubmit()}
          >
            Next <AiOutlineRight />
          </button>
          {/* )} */}
        </div>
      </div>
    </div>
  );
};
