import { SearchOutlined } from "@ant-design/icons";

import { Image, Input } from "antd";

import { useEffect, useState } from "react";

import {
  VscChevronLeft,
  VscChevronRight,
  VscCircleFilled,
} from "react-icons/vsc";

import { useParams } from "react-router-dom";

import { getApiRequest } from "../../api/CommonApi";

import { urlChecker } from "../../utils/urlChecker";
import "./Campaign.css";

 

export const HowToActivate = () => {

  const { id } = useParams();

  const [ussd, setUssd] = useState("");

  const [renderState, setRenderState] = useState("");

  const [renderImage, setRenderImage] = useState("");

  const [images, setImages] = useState("");

  const [imageId, setImageId] = useState("");

  const [name, setName] = useState("");

  const [imageSlide, setImageSlide] = useState(0);

 

  useEffect(async () => {

    if (imageId !== "") {

      const res = await getApiRequest(

        "/api/ussd-app/details/single-action-details",

        "",

        { id: imageId }

      );

      setImages(res?.data?.data);

    }

  }, [imageId]);

 

  useEffect(async () => {

    const response = await getApiRequest("/api/ussd-app/single-app", "", {

      id: id,

      action_name: name,

    });

    setUssd(response.data.data);

    setImageId(response?.data?.data?.actions[0]?.id);

    document.title = response?.data?.data?.name;

    setRenderState(response.data.data.actions);

  }, [name]);

 

  const handleKeyPress = (e) => {

    setName(e.target.value);

  };

 
  const BASEURL = urlChecker();

  return (

    <div className="howToActivate">

      <div className="interActiveDemoSearch card">

        <Input

          size="large"

          placeholder="Search ..."

          onKeyUp={handleKeyPress}

          prefix={<SearchOutlined />}

        />

      </div>

      <div className="Interactive_main_area">

        <div className="interactiveApp_Tab">

          {renderState &&

            renderState?.map((data, i) => (

              <div key={i} onClick={() => setImageId(data?.id)}>

                <p

                  className={

                    data?.id === imageId

                      ? "interactiveApp_Tab_text_active"

                      : "interactiveApp_Tab_text"

                  }

                >

                  <span className="interactiveApp_Tab_dot">

                    <VscCircleFilled />

                  </span>

                  {data?.action_name}

                </p>

              </div>

            ))}

        </div>

        <div className="interactive_view_area">

          <p className="howToActivateAction">Action : {ussd?.name}</p>

          {images ? (

            <div className="interact_slider">

              <button

                disabled={imageSlide === 0}

                onClick={() => setImageSlide(imageSlide - 1)}

                className="interact_slider_left"

              >

                <VscChevronLeft />

              </button>

              <button

                disabled={imageSlide + 1 === images?.length}

                onClick={() => setImageSlide(imageSlide + 1)}

                className="interact_slider_right"

              >

                <VscChevronRight />

              </button>

              <p>Image - </p>

              <Image

                width="100%"

                src={BASEURL + images[imageSlide]?.detail_image}

                alt=""

              />

            </div>

          ) : (

            <p>No Image found</p>

          )}

        </div>

      </div>

    </div>

  );

};