import { Rate, Tag } from 'antd';
import dotenv from "dotenv";
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { urlChecker } from '../../../utils/urlChecker';
import './AllCoursCard.css';
dotenv.config();

export default function AllCourseCard(props) {

    const courses = useSelector((state) => state.allCourses.courses);
    const renderList = courses.map((course) => {
    const { id, title, cover_image, short_desc, rating, created_at, updated_at } = course;

    const BASEURL = urlChecker();
    
    return (
        <div className="elarnigAllCoursCard card" key={id}>
            <div className="elarnigAllCoursCardContents">
                <div className="elarnigAllCoursCardLeft">
                    <Link to={`/course-details/${id}`}>
                    <div className="elarnigAllCoursCardThumbnails">
                        { cover_image &&
                        <img src={BASEURL +'/'+ cover_image} alt={title} />
                        }                        
                    </div>
                    </Link>
                </div>
                <div className="elarnigAllCoursCardRight">
                    <Link to={`/course-details/${id}`}>
                    <h3 className="elarnigAllCoursCardTitle">{title}</h3>
                    </Link>
                    <p className="elarnigAllCoursCardDesc">{short_desc}</p>
                    <span className="elarnigAllCoursCardUpTime">Updated at {moment(created_at).format('DD MMMM YYYY HH:mm A')} | Last updated {moment(updated_at).fromNow()}</span>
                    {course?.course_instructor_name && 
                    <span className="elarnigAllCoursCardInstructor">Instructor: {course?.course_instructor_name}</span>
                    }
                    <span className="elarnigAllCoursCardReview">
                        <span className="elearnigReviewTitle">{rating ? rating.toFixed(1) : ''}</span>
                        <Rate allowHalf disabled defaultValue={rating} />
                    </span>
                    <div className="elarnigAllCoursCardTags">
                    {course?.tags?.map((item, i) => (
                        <Tag key={i}>{item?.tag?.name}</Tag>
                    ))}   
                    </div>
                </div>
            </div>
        </div>
    )

 });

return (
    <div className="searchContent">
        <Row>
            <Col xs={12}>
                {renderList}
            </Col>
        </Row>
    </div>
)

};

