import React from 'react';
import './Search.css';
import { BrowserRouter as Route, Link } from 'react-router-dom';
import SearchVideoList from "../../components/relatedvideolist/RelatedVideoList";
import { Row, Col, } from 'react-bootstrap';
import { Input, AutoComplete, Select, List, Avatar, Tabs, Divider} from 'antd';
import SearchContent from '../../components/searchcontent/SearchContent';
import SearchContentFilter from '../../components/searchContentfilter/SearchFilter';
import VideoFilter from '../../components/searchvideofilter/VideoFilter';
import QuickCall from '../../components/quickcall/QuickCall';
import SearchBar from '../../components/searchbar/SearchBar';
const { TabPane } = Tabs;
function callback(key) {
  console.log(key);
}



//   select-option and search area 
const { Option } = Select;


export default function Search() {

    return (
        <div className="search-page">
          <QuickCall />
            {/* search-area */}
            <SearchBar />
            {/* end search-area */}
            
            <Row className="mx-20">
              <Col>
              <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="Contents" key="1">
                  <div className="searchContentResultCounting">
                    <div className="results">
                      <span className="totalResults">About 10 results found(0.3 sec)</span>
                    </div>
                    <div className="sortby">
                      <span className="resultsSort">Sort by:</span>
                      <Select
                        showSearch
                        style={{ width: 100 }}
                        placeholder="Latest"
                      >
                        <Option value="jack">Jack</Option>
                      </Select>
                    </div>
                  </div>
                  <Row className="searchContenResults">
                    <Col xs={3}>
                      <SearchContentFilter />
                    </Col>
                    <Col xs={9}>
                      <SearchContent />
                    </Col>
                  </Row>
                </TabPane>
                
                {/* searchVideoResults */}
                <TabPane tab="Video" key="2">
                <div className="searchContentResultCounting">
                    <div className="results">
                      <span className="totalResults">About 10 results found(0.3 sec)</span>
                    </div>
                    <div className="sortby">
                      <span className="resultsSort">Sort by:</span>
                      <Select
                        showSearch
                        style={{ width: 100 }}
                        placeholder="Latest"
                      >
                        <Option value="jack">Jack</Option>
                      </Select>
                    </div>
                  </div>
                  <Row className="searchVideoResults">
                    <Col xs={3}>
                      <VideoFilter />
                    </Col>
                    <Col xs={9}>
                      <SearchVideoList />
                    </Col>
                  </Row>
                </TabPane>
              </Tabs>
              </Col>
            </Row>
            {/* search-paginations */}
            <Row className="mx-auto block text-center">
                <button className="searchLoadMore capitalize">Load more</button>
            </Row>
        </div>
    )
}
