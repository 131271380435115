import { ActionTypes } from "../constants/ActionTypes";

const initialState = {
    tags: [],
}

export const tagSelectReducer = ( state = initialState, {type, payload}) => {
    switch(type){
        case ActionTypes.TAG_SELECTED:
            return {...state, tags:payload};
        default:
            return state;
    }
}