import { ActionTypes } from "../constants/ActionTypes";

const initialState = {
    categoryTypes:[]
}

export const categoryTypeSelectReducer = ( state = initialState, {type, payload}) => {
    switch(type){
        case ActionTypes.CATEGORY_TYPE_SELECT:
            return {...state, categoryTypes:payload};
        default:
            return state;
    }
}